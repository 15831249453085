import React, { useEffect, useRef, useState } from "react";
import "../../../style/ui-components/accordion.css"
import Button2 from "../buttons/Button2";
import $ from "jquery"
import { isPotraitMode } from "../../../../utils/customizein3d/common";
import { waitFor } from "../../../../utils/methods";
import { wardrobeAccordionClickActions } from "../../../../utils/customizein3d/modules/wardrobe";


const AccordionForProccessCss: React.FC<{
  label:string
  Content:any
  SubHeader:any
  index:number
  Footer?:any
  onClick?:()=>void
  changeButtonText?:string
  classNames?:string
}> = (props) => {

  // const[isOpen,setIsOpen] = useState(props.isOpen)
  const accRef = useRef(null)

  useEffect(()=>{
    if(props.index === 1 && accRef){
      setTimeout(() => {
        if(!isPotraitMode()){
          let container = $(accRef.current)
          container.addClass("--is-active")
          clickHandle(container)
        }
      }, 1000);
    }
  },[props.index])

  function toggle(event:any) {
    if(props.onClick){
      props.onClick()
      return
    }
    event.stopPropagation()
    let container = $(event.target).parent()
    container.toggleClass("--is-active")
    clickHandle(container)

    let accordionText = container.find(".accordion-text").text()
    wardrobeAccordionClickActions(accordionText)
  }

  function clickHandle(container:any) {
    $(".accordion-wrapper").find(".content").css("max-height", 0+"px")
    if(!container.hasClass("--is-active")){
      container.find(".content").css("height", 0+"px")
      container.find(".content").css("max-height", 0+"px")
    }else{
      let height = getContainerHeight()
      if(height < 220){
        height = 300
      }
      container.find(".content").css("max-height", height+"px")
      container.find(".content").css("height", height+"px")
    }
    closeOther(container)
  }

  function getContainerHeight() {
    let mainWrapperHeight = $(".accordion-wrapper").outerHeight()
    let padding = 20
    // console.log(mainWrapperHeight)
    let totalHeader = $(".accordion-wrapper").children().length
    let headerHeight = $(".accordion-header").innerHeight()
    // console.log(headerHeight)
    let index = getCurrentIndex()
    let resultHeight = mainWrapperHeight - ((headerHeight * totalHeader) +  (headerHeight * (index - 1)))
    return resultHeight - padding
  }

  // async function scrollToBottom() {
  //   await waitFor(500)
  //   let isScrollToBottom = getCurrentIndex()>1?true:false
  //   let element = $(".accordion-wrapper")
  //   var height = element[0].scrollHeight;
  //   if(isScrollToBottom){
  //     element.scrollTop(height);
  //   }else{
  //     element.scrollTop(0);
  //   }
  // }

  function closeOther(currElement:any){
    $(".accordion-container").each((index:number,element:any)=>{
      if(!currElement.is($(element))){
        $(element).removeClass("--is-active")
      }
    })
  }

  function getCurrentIndex(){
    let result = 1
    $(".accordion-container").each((index:number,element:any)=>{
      if($(element).hasClass("--is-active")){
        result = index + 1
      }
    })
    return result
  }

 
  return (
    <React.Fragment>
      <div ref={accRef} id={`accordion_${props.index}`} className={`accordion-container marginTop5 full-width accordion-proccess ${props.classNames} ${props.index === 1?"":" "}`}>

          <div className='display-flex-row position-relative pointer no-wrap space-between accordion-header' onClick={toggle}>
              <div className='pointer-none display-flex-row no-wrap '>
                <div className="accordion-badge">{props.index}</div>
                <div className="marginleft" style={{marginTop:"2px"}}>
                  <div className="display-flex-row no-wrap">
                    <div className="heading2 accordion-text store-color-primary capitalize font-normal center-aligned-column">{props.label} </div>
                      <span className="center-aligned-column marginleft10 confirm-icon"> <i className="fas fa-check-square font-large color-warning"></i> </span>
                  </div>

                </div>
              </div>
              {/* <div className='pointer-none center-aligned-column'><i className='fas fa-chevron-down accordion-icon'></i></div> */}
              <div className='right-middle pointer-none'>
                <div className="display-flex-row no-wrap ">
                  {/* {!props.isOpen? */}
                      <props.SubHeader/>
                    {/* :null} */}
                  {/* {props.isConfirmed? */}
                  <div className="center-aligned-column">
                    <Button2
                      configObj={{id:""}}
                      action={()=>{}}
                      label={`${props.changeButtonText?props.changeButtonText:"Change"}`}
                      fontAwesomeIcon={"fas fa-cube color-warning"}
                      iconPos=""
                      classNames={"button-ex-small bg-color-"}
                    />
                  </div>
                  {/* :null} */}

                </div>
               
              </div>
          </div>

          {/* {props.isOpen? */}
            <div className="position-relative">
            <div className={`content position-relative`}>
              <props.Content/>
              <div className="bottom-left full-width">
                {props.Footer?
                  <props.Footer/>  
                :null}
              </div>
            </div>
            
            </div>
          {/* :null} */}

         

          
      </div>
    </React.Fragment>
  
  );
};

export default AccordionForProccessCss;
