import React, { useEffect, useState } from "react";
import { hideImage } from "../../../../utils/UI_methods/global";
import { createBounceEffectEvents, rippleEffectOnClick } from "../../../../utils/UI_methods/ui_effects";
import HorizontalScroll from "../../ui-components/scrollbar/HorizontalScroll";
import { applicationConfig, getObjectByParameter, stringWithoutWhiteSpace } from "../../../../utils/methods";
import DropDown2 from "../../ui-components/filters/DropDown2";
import { categoryTypesMapping } from "../../../../utils/data";


  
  const Categories: React.FC<{
    updateSubCategories:(cate:string)=>void
    categories:Array<any>
  }> = (props) => {
 
    // const categoryTypesMapping = {
    //   "Loose Furniture":["Sofa","Cot","CenterTable","Console","DiningTable","Tables"],
    //   "Modular Furniture":["PujaUnit","CrockeryUnit","Wardrobe","TvUnit","ShoeRack","Partition","Kitchen","Dresser","KidsFurniture","HomeOfficeFurniture"],
    //   "Decor":["Decor","Lighting"],
    //   "Soft Furnishing":["Curtain","Carpet"],
    //   "Wall Furnishing":["Wallpaper","WallPaint","WallCladding",],
    // }
   

    const[categoriesList,setCategoriesList] = useState(props.categories)
    const[currCategoryType,setcurrCategoryType] = useState("All")
    const[categoryTypes] = useState(["All","Loose Furniture","Modular Furniture","Decor","Soft Furnishing","Wall Furnishing"])

    function CategoryTypes() {
      return(
          <>
            {categoryTypes.map((categoryType:any,index:number)=>{
              let categoryTypes = getObjectByParameter(categoryTypesMapping,"category_type",categoryType)
              let categoryList = []
              if(categoryTypes){
                categoryList = props.categories.filter(currCategory => categoryTypes.categories.includes(currCategory))
              }
              return(
                <>
                {categoryList.length || categoryType === "All"?
                  <div className={currCategoryType===categoryType?"marginleft5  marginTop5 bg-color-primary color-white":"marginleft5 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                    <div className='heading4 text-center color-black' onClick={()=>setcurrCategoryType(categoryType)}>{categoryType}</div>
                  </div>
                :null}
                </>
              )
            })}
          </>
      )
    }

    useEffect(()=>{
      if(currCategoryType === "All"){
        setCategoriesList(props.categories)
      }else{
        let categoryTypes = getObjectByParameter(categoryTypesMapping,"category_type",currCategoryType)
        if(categoryTypes){
          setCategoriesList(props.categories.filter(currCategory => categoryTypes.categories.includes(currCategory)))
        }
      }
    },[currCategoryType,props.categories])

    return (
      <div className="full-width">
       
       <div className="marginBottom5">
          <HorizontalScroll
            itemsClass="sub-categories-container"
            Component={CategoryTypes}   
            itemsList={categoryTypes}
            id={`categoryTypesList`}     
          />
        </div>
        {categoriesList?.length?
         <div className="categories-wrapper display-flex-row  full-height padding5" style={{paddingBottom:"-10rem",width:"95%"}}  >
         {categoriesList?.map((category:any,index:number)=>{
           return(
             <div   className="cat-sub-wrapper no-shadow center-aligned-column" data-category-name={`${category}`} key={index}>
               <div id={index === 0 ? "categoryFirstElement" : ""} className="category click-effect position-relative overflow-hidden shadow-medium white-card pointer center-aligned-column"  style={{width:"100%",height:"100%"}}
               onPointerDown={rippleEffectOnClick} onClick={()=>{props.updateSubCategories(category)}}
               >
                 <div>
                     <div className="icon pointer-none center-aligned-row" style={{width:"100%"}}>
                     <img src={`${applicationConfig.aws_public_url}assets/images/icons/productIcons/${stringWithoutWhiteSpace(category)}.png`} onError={hideImage} alt="" style={{width:"4.5rem",aspectRatio:"1/1",opacity:"0.7"}} />
                     </div>
                     <div className="name pointer-none center-aligned-column font-md-small text-center heading4 ">{category}</div>
                 </div>
                 
 
               </div>
             </div>
           )
         })}
 
         <div className="cat-sub-wrapper center-aligned-column">
         </div>
         <div className="cat-sub-wrapper center-aligned-column">
         </div>
         <div className="cat-sub-wrapper center-aligned-column">
         </div>
         <div className="cat-sub-wrapper center-aligned-column">
         </div>
         <div className="cat-sub-wrapper center-aligned-column">
         </div>
         <div className="cat-sub-wrapper center-aligned-column">
         </div>
         <div className="cat-sub-wrapper center-aligned-column">
         </div>
         <div className="cat-sub-wrapper center-aligned-column">
         </div>
         <div className="cat-sub-wrapper center-aligned-column">
         </div>
 
        </div>
        :
        
        
        
        <div className="middle heading4 font-normal">Products Not Found</div>
        
        }
       
      </div>
    );
  };

  export default Categories;