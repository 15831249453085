import React, { useEffect, useState } from "react";
import { hideComponentLoader } from "../../../../utils/UI_methods/global";
import Loader from "../../../common/shared-components/main-ui/Loader";
import Button2 from "../../../common/ui-components/buttons/Button2";
import { applicationConfig } from "../../../../utils/methods";
import $ from "jquery"
 

const ConfiguratorLinks: React.FC<{
}> = (props) => {

 
  const[isOpenConfigurator,setisOpenConfigurator] = useState(false)

  useEffect(()=>{
    applicationConfig.setFunctionRef("elevate",{setisOpenConfigurator:setisOpenConfigurator})
  },[])


  function Configurator1() {
    return(
      <div className="configurator1  configurator-link marginTop10 full-width">
       <div className="section details">
          <div className="heading1 font-ex-large">Furnitures That Bring Out Your Creative Side</div>
          <div className="heading4 color-black ion-margin-top font-normal line-height-2">
          {/* Allow customers to effortlessly navigate through and choose
          their desired product, seamlessly switching between all
          available variants such as color combinations, shapes, materials,
          and finishes. */}
          Want to see how different armrest styles complement your space? Curious about the effect of various upholstery fabrics or colors? With just a few clicks, you can visualize it all in stunning 3D.
          </div>
          <div className="heading4 color-black ion-margin-top font-normal line-height-2">
          No more guesswork. With our 3D configurator, what you see is truly what you get. Experiment with different configurations and preview your custom creation from every angle. Dive into virtual reality and experience your dream sofa as if it's already in your home.
          </div>
          <div className="heading4 color-black ion-margin-top font-normal line-height-2">
          With just a few simple steps, your personalized Fantasy sofa is on its way to your doorstep.
          </div>
          <div className="ion-margin-top center-aligned-column">
            <Button2
                configObj={{id:""}}
                action={()=>{setisOpenConfigurator(true)}}
                label={"Configure in 3D"}
                fontAwesomeIcon={"fas fa-cogs elevate-color-primary font-large"}
                iconPos=""
                classNames={"shadow-light no-border button-large text-center uppercase margin0 bg-color-primary"}
              />
          </div>
       </div>
       <div className="section padding0 center-aligned-column">
          <img style={{objectFit:"cover"}} className="full-height-width" src="https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/images/Configurator.png" alt="" />
       </div>
    </div> 
    )
  }

  function Configurator2() {
    return(
      <div className="configurator-link full-width">

      <div className="section padding0 center-aligned-column">
        <img style={{objectFit:"cover"}} className="full-height-width" src="https://cgifurniture.com/wp-content/uploads/2021/06/3d-augmented-furniture-for-apps-View04.jpg" alt="" />
      </div>

       <div className="section details">
          <div className="heading1 font-ex-large">View In Your Space</div>
          <div className="heading4 color-black ion-margin-top font-normal line-height-2">
          Augmented reality enables customers to visualize a new
          sofa, chair, or bed in their home. They can check the
          color and measurements to be sure the product meets
          all their requirements before hitting the buy button.

          </div>
          <div className="heading4 color-black ion-margin-top font-normal line-height-2">
          Built-in business rules ensure designs are error-free and
          only manufacturable products can be purchased.
          </div>
          <div className="ion-margin-top center-aligned-column">
            <Button2
                configObj={{id:""}}
                action={()=>{setisOpenConfigurator(true)}}
                label={"Explore 3D & AR Viewer Solutions"}
                fontAwesomeIcon={"far fa-eye elevate-color-primary font-large"}
                iconPos=""
                classNames={"shadow-light no-border button-large text-center margin0 bg-color-light"}
              />
          </div>
       </div>
      
    </div> 
    )
  }
 

  function onLoadHandle() {
    hideComponentLoader("iFrameLoader")
    const iframe:any = document.getElementById('configuratorIframe');
    // Add an event listener to capture clicks on the button inside the iframe
    iframe.contentWindow.document.addEventListener('click', function(event:any) {
        // Check if the clicked element is a button
        if ($(event.target)?.attr("id")?.includes("closeIframeButton")){
            // Prevent the default action of the button
            event.preventDefault();
            // Refresh the entire browser window
            window.location.reload();
        }
    });
  }
 

  function getSource() {
    return `${window.location.origin}/customizein3d/Fantasy`
  }
 
  return (

    <div className="full-width">
{/* 
      <Modal2
          isOpen = {isOpenConfigurator}
          Content = {()=>(
            <>
            {isOpenConfigurator?
            
            :null}
            </>
          )}
          onDismiss = {()=>setisOpenConfigurator(false)}
          FooterContent={()=>(<></>)}
          heading="Configurator"
          classNames="large-x-modal"
          id=""
      /> */}
        {isOpenConfigurator?
        <div className="full-height-width zindex100 top-left" style={{position:"fixed",background:"rgba(0,0,0,0.5)"}}>
          <iframe id="configuratorIframe" onLoad={onLoadHandle} src={`${getSource()}`} className="middle" style={{width:"90%",height:"90%"}}>
          </iframe>

          <div className="top-right" onClick={()=>{setisOpenConfigurator(false)}}>
            <i className="fas fa-times color-white font-large"></i>
          </div>



          <Loader
            id="iFrameLoader"
            data={{message:"Fetching data"}}
            loaderType="dots"
            className="--is-active fade-background-loader"
          />


        </div>
        :null}

            

      

      <Configurator1/>
      <Configurator2/>

    </div>
    
  );
};

export default ConfiguratorLinks;
