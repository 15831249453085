import React, { useEffect, useState } from "react";
import { filterUIElements, hideComponentLoader } from "../../../../utils/UI_methods/global";
import { isDeviceIpadPotrait, updateActiveClassCotSelection } from "../../../../utils/customizein3d/modules/UI_methods";
import { getProductModuleUrl } from "../../../../utils/customizein3d/modules/helper";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../../utils/methods";
import { addEventListenerOnImages } from "../../../../utils/moodboard/helper";
import ControlsTab from "../../ui-components/tabs/ControlsTab";
import Loader from "../main-ui/Loader";
import HorizontalScroll from "../../ui-components/scrollbar/HorizontalScroll";
import Button2 from "../../ui-components/buttons/Button2";
import Modal2 from "../Modals/Modal2";
import { loadModuleImages } from "../../../../utils/customizein3d/UImethods";


const CotModules: React.FC<{
  action:(module:any)=>void
  moduleName:string
  dragStart:(event:any)=>void
  product:any
  parentContainerId:string
}> = (props) => {

  const[isOpenFilters,setIsOpenFilters] = useState(false)
  
  // const[modules,setModules]:any = useState([])
  const[productModules,setProductModules]:any = useState([])
  const[filteredModules,setFilteredModules]:any = useState([])
  const[currModuleType,setCurrModuleType] = useState("")
  const[product]:any = useState(props.product)

  const[moduleImagesLoader] = useState("ModuleImagesLoader"+props.parentContainerId||"")

  const[tabItems,setTabItems] = useState([])

  const[subModuleTypes,setSubModuleTypes] = useState([])
  const[currSubModuleType,setCurrSubModuleType] = useState(null)


  const[currSize,setCurrSize] = useState(null)

  useEffect(()=>{
    if(props.moduleName.toLowerCase() != "customizein3d"){
      addEventListenerOnImages(".module-image")
    }
    if(productModules.length){
      let items = getArrayOfDistinctValues(productModules,"module_type")
      let tabList = []

      if(props.moduleName !== "kitchen"){
        items = items.filter(item=>item !== "Base" && item !== "Wall" && item !== "High")
        items.sort((a,b)=>a>b?1:-1)
        items.splice(0,0,"Left")
        let indexOfLeft = items.lastIndexOf("Left")
        items.splice(indexOfLeft,1)
      }else{
        items = items.filter(item=>item !== "Left" && item !== "Centre" && item !== "Corner")
      }
      for (const currItem of items) {
        tabList.push({tabName:currItem,label:currItem})
      }
      setTabItems(tabList)
    }
  },[productModules])
  

  useEffect(()=>{
    let filteredArray = []
    let subModuleTypes = []
    filteredArray = applicationConfig?.data?.productModules.filter(currModule => currModule.product_id === product.product_id)
    setProductModules(filteredArray)
    setFilteredModules(filteredArray)
    subModuleTypes = getArrayOfDistinctValues(filteredArray,"sub_module_type")
    if(subModuleTypes.length){
      setSubModuleTypes(subModuleTypes)
      // setCurrSubModuleType(subModuleTypes[0])
    }
    setCurrModuleType(filteredArray[0]?.module_type)
  },[])

  useEffect(()=>{
    // if(product && productModules.length){
    //   loadModuleImages(moduleImagesLoader,productModules,product.category_name,props.parentContainerId||"module_")
    // }else{
    // }
    hideComponentLoader(moduleImagesLoader)
  },[productModules,product])

  useEffect(()=>{
    if(productModules.length){
      // let filteredArray = getFilteredArray(productModules,"sub_module_type",currSubModuleType)
      // if(currSize === "XL"){
      //   filteredArray = filteredArray.filter(currModule => currModule.module_name.includes("XL"))
      // }else{
      //   filteredArray = filteredArray.filter(currModule => !currModule.module_name.includes("XL"))
      // }
      // setFilteredModules(filteredArray)
      setTimeout(() => {
        updateActiveClassCotSelection()
      }, 500);
    }
  },[productModules,currSubModuleType,currSize])


  function HintText(){
    return(
      <div className="hint-text-container controls-tab-content">
        <strong className="hint-text heading2 color-dark">
          No modules available
        </strong>
      </div>
    )
  }

  function SubModuleTypes() {
    return(
        <>
          {subModuleTypes.map((category:any,index:number)=>{
            return(
              <div className={currSubModuleType===category?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrSubModuleType(category)}>{category}</div>
              </div>
            )
          })}
        </>
    )
  }

  function depthClickHandle(event:any,value:string) {
    setCurrSubModuleType(value)
    // $(".depth-button").removeClass()
  }

  function sizeClickHandle(event:any,value:string) {
    setCurrSize(value)
    // $(".size-button").removeClass()

  }

  function filterModules() {
      let filteredArray = productModules
      if(currSubModuleType){
        filteredArray = getFilteredArray(productModules,"sub_module_type",currSubModuleType)
      }
      if(currSize){
        if(currSize === "XL"){
          filteredArray = filteredArray.filter(currModule => currModule.module_name.includes("XL"))
        }else{
          filteredArray = filteredArray.filter(currModule => !currModule.module_name.includes("XL"))
        }
      }
      
      setFilteredModules(filteredArray)
      setIsOpenFilters(false)
  }

  function resetFilters() {
    setFilteredModules(productModules)
    setIsOpenFilters(false)
    setCurrSubModuleType(null)
    setCurrSize(null)
  }


  function Filters() {
    const sizes = ["Standard","XL"]
    return(
      <div className="full-height-width">
        <div className="display-flex-row header-content no-wrap space-between">
          <div className="heading1 padding10 text-center">All Filters</div>
          {/* <div className="center-aligned-column padding5"><i className="fas fa-times color-dark"></i></div> */}
        </div>
        <div className="body-content">

          <div className="padding5 border-bottom">
            <div className="display-flex-row space-between">
              <div className="heading3 padding5 text-center">Size</div>
            </div>
            <div className="display-flex-row marginTop5">
              {sizes.map((size:any,index:number)=>{
                return(
                  <div onClick={(event)=>{sizeClickHandle(event,size)}} className="" key={index}>
                    <Button2
                      configObj={{id:""}}
                      action={()=>{}}
                      label={size}
                      fontAwesomeIcon={"fas fa-undo color-dark"}
                      iconPos=""
                      classNames={`no-shadow size-button button-small pointer-none ${size === currSize?" bg-color-primary-outline":" "}`}
                    />
                  </div>
                  
                )
              })}
            </div>
          </div>

          <div className="padding5 border-bottom">
            <div className="display-flex-row space-between">
              <div className="heading3 padding5 text-center">Depth</div>
            </div>
            <div className="display-flex-row marginTop5">
              {subModuleTypes.map((currModule:any,index:number)=>{
                return(
                  <div onClick={(event)=>{depthClickHandle(event,currModule)}} className="" key={index}>
                    <Button2
                      configObj={{id:""}}
                      action={()=>{}}
                      label={currModule}
                      fontAwesomeIcon={"fas fa-undo color-dark"}
                      iconPos=""
                      classNames={`no-shadow depth-button button-small pointer-none ${currModule === currSubModuleType?" bg-color-primary-outline":" "}`}
                    />
                  </div>

                  
                )
              })}
            </div>
          </div>
         
        </div>
        <div className="footer-content position-relative">
          <div className="right-middle">
            <div className="display-flex-row no-wrap">
              <Button2
                  configObj={{id:""}}
                  action={resetFilters}
                  label={"Reset"}
                  fontAwesomeIcon={"fas fa-undo color-dark"}
                  iconPos=""
                  classNames={"no-shadow"}
              />
              <Button2
                  configObj={{id:""}}
                  action={filterModules}
                  label={"Show Results"}
                  fontAwesomeIcon={"fas fa-filter color-primary"}
                  iconPos=""
                  classNames={"no-shadow bg-color-primary"}
              />
          </div>
          </div>
          
        </div>
      </div>

    )
  }

  function getFilterCount(){
    let count = 0
    if(currSubModuleType){
      count ++
    }
    if(currSize){
      count ++
    }
    return count
  }
 

 
  return (
    <div className="product-modules-wrapper position-relative" id={`${props.parentContainerId}`}>

        <Modal2
          id=""
          isOpen={isOpenFilters}
          classNames="payment-modal no-shadow"
          onDismiss={()=>setIsOpenFilters(false)}
          Content={()=>(
            <Filters/>
          )}
        />


      {productModules.length?
          <>
      <Loader
        loaderType="spinner"
        className="fade-background-loader --is-active zindex1"
        id={moduleImagesLoader}
        data={{}}  
      />

      <div className="marginTop5 marginleft5">
        <div className="display-flex-row space-between" >
          <div></div>
          <div className="position-relative marginRight10">
            <Button2
                configObj={{id:""}}
                action={()=>{setIsOpenFilters(true)}}
                label={""}
                fontAwesomeIcon={"fas fa-filter color-black font-large"}
                iconPos="left"
                classNames={"no-shadow no-border button-small"}
                // svgUrl="assets/icon/svg/filter.svg"
            />
            {currSize || currSubModuleType ? 
              <div className="top-right pointer-none center-aligned-column font-samll" id="" style={{top:"-0.9rem",right:"-0.5rem",zIndex:100}}><i className="far fa-check-circle color-black font-"></i></div>
            :null}
          </div>
          
          {/* <div onClick={resetFilters} className="center-aligned-column font-small color-primary padding5 heading3 marginRight5">View All</div> */}
        </div>
        
      </div>
 
      <div className="full-height marginTop5 product-module-container" id="productModulesWrapper">


        {/* <ControlsTab
          configObj={props.configObj}
          tabItems={moduleTypeTabs}
          currSelectedValue={currModuleTypeType}
          action={setCurrModuleTypeType}
          theme="black"
        /> */}

        {/* <div className="marginTop5 marginBottom5">
            <HorizontalScroll
              itemsClass="sub-categories-container"
              Component={SubModuleTypes}   
              itemsList={subModuleTypes}
              id={`SubModuleTypesCot`}     
            />
        </div> */}

        {/* <div className="center-aligned-row padding5 ion-margin-bottom">
          <div className="switch-selection-container display-flex-row no-wrap">
            <div onClick={(event)=>{setCurrSize("Standard")}} className={`option ${currSize === "Standard"?" --is-active":" "}`}>Standard</div>
            <div onClick={(event)=>{setCurrSize("XL")}}  className={`option ${currSize === "XL"?" --is-active":" "}`}>XL</div>
          </div>
        </div> */}

        

        {/* <ControlsTab
          configObj={applicationConfig}
          tabItems={tabItems}
          currSelectedValue={currModuleType}
          action={setCurrModuleType}
          theme="black"
        /> */}

        
        <div className="display-flex-row full-height  overflow-y-scroll" style={{alignContent:"baseline",paddingBottom:"12rem"}}>
              {filteredModules?.map((module:any,index:number)=>{
              let key = getProductModuleUrl(applicationConfig?.BASE_URL_ASSETS,applicationConfig?.clientName,module.module_name,product.category_name,module.sub_category_name)
              return(
                <div className={`module-container click-effect center-aligned-column ${isDeviceIpadPotrait()?" --is-potrait-mode":""}`} 
                  data-product-name={module.module_name}
                  data-product-category={module.category_name}
                  data-product-subcategory={module.sub_category_name || "Default"}
                  data-sub-module-type={module.sub_module_type || "Default"}
                  data-product-price={module.price || 0}
                  data-product-id={module.module_id}
                  data-image-type="module"
                  
                  onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                  draggable={props.moduleName === "kitchen"?"true":"false"}

                  id={(props.parentContainerId||"module_")+key} 
                  data-module-type={module.module_type} 
                  key={index} 
                  onClick={(event:any)=>{
                  if(props.moduleName === "moodboard"){
                    props.action(event)
                  }else{
                    props.action(module)
                  }
                }}>
                  <img 
                    data-product-name={module.module_name}
                    data-product-category={module.category_name}
                    data-product-subcategory={module.sub_category_name || "Default"}
                    data-sub-module-type={module.sub_module_type || "Default"}
                    data-product-price={module.price || 0}
                    data-module-type={module.module_type} 
                    data-product-id={module.module_id}
                    data-image-type="module"
                    
                    onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                    className="module-image zindex1"
                    draggable="false"
                    src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/store/icons/Cot/${module.module_name}.svg`}
                    alt="" style={{padding:"1rem",height:"8rem",objectFit:"contain"}} 
                  />
                  <div className="center-aligned-row text-center module-details pointer-none store-color-primary heading3"> {module.display_name.replace("_78","")}</div>
                  <div className="padding5 center-aligned-row text-center module-details pointer-none heading4 font-small">{module.module_description.replace("_78","")}</div>
                </div>
              )
            })}
       
        </div>
   
      </div>
      </>
      :

      <div className="full-width fill-available-height center-aligned-column">
        <HintText/>
      </div>
      
      }


    </div>
  );
};

export default CotModules;
