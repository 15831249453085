import { Texture, TextureLoader } from "three";
import { camera, controls, group, groupBoundingBox, scene } from "./customizein3d";
import $ from "jquery"
import { hideComponentLoader, showComponentLoader } from "../../UI_methods/global";
import { updateFraming } from "../common";
import { downloadURI } from "../../moodboard/helper";
import { logger, waitFor } from "../../methods";
import { getAndSaveImageToCache } from "../../cache/cache";
import { getBlobObjectUrl } from "../../fabricate/fabricate";


export class SceneBackgound{
    backgrounds = [
        "https://img.freepik.com/free-photo/japandi-living-room-interior-design_53876-145502.jpg?size=626&ext=jpg",
        "https://img.freepik.com/premium-photo/empty-minimal-room-interior-design-with-fishbone-flooring_327072-21374.jpg",
        "https://img.freepik.com/premium-photo/white-wall-empty-room-with-plants-floor_333547-6945.jpg?w=360",
        "https://img.freepik.com/premium-photo/empty-minimal-room-interior-design-with-fishbone-flooring_327072-21374.jpg",
        "https://media.istockphoto.com/id/1165379888/photo/interior-background-of-living-room-with-plant-and-door-3d-render.jpg?s=170667a&w=0&k=20&c=EmN4o1MDFM-PNME3RL24X7UUYJ5huAm8TliRFc-vKr0="
    ]

    currBackground = ""
    enabled:boolean = false
    
    
    constructor(){

    }

    enable(){
        this.enabled = true
        $(".hide-on-select").addClass("display-none")
        $(".show-on-background").removeClass("display-none")
        $("#sceneBackgroundsWindow").removeClass("display-none")
        $("#backgroundHintText").removeClass("display-none")
        logger?.info("customizein3d","E-BG")
    }

    disable(){
        $(".hide-on-select").removeClass("display-none")
        $(".show-on-background").addClass("display-none")
        $("#sceneBackgroundsWindow").addClass("display-none")
        $("#backgroundHintText").addClass("display-none")
        scene.background = null
        this.enabled = false
        updateFraming(group,groupBoundingBox,camera,controls)
        logger?.info("customizein3d","D-BG")
    }

    setBackgroundCustomizeMode(){
        $("#sceneBackgroundsWindow").addClass("display-none")  
        scene.background = null
        this.enabled = false
    }

    setBackground(key:string) {
        showComponentLoader("mainLoaderSpinner")
        this.loadImageAsTexture(key);
        logger?.info("customizein3d","S-BG")
    }
// Function to load image using fetch
    async loadImageAsTexture(key:string) {
    try {
        // Fetch the image as a blob
            getAndSaveImageToCache(key).then((data:any) => {
                var image = new Image()
                let url = getBlobObjectUrl(data.Body)

                image.crossOrigin = 'anonymous'; // Enable CORS
                image.src = url

                image.onload = function() {
                const texture = new Texture(image);
                texture.needsUpdate = true;  
                scene.background = texture;
                hideComponentLoader("mainLoaderSpinner");
                };

                image.onerror = function() {
                console.error('Failed to load image');
                hideComponentLoader("mainLoaderSpinner");
            }
            })
            .catch((err) => {
                console.log(err)
                hideComponentLoader("mainLoaderSpinner");
            });
        } catch (error) {
            console.error('Failed to load texture', error);
            hideComponentLoader("mainLoaderSpinner");
        }
    }

// Call the function to load the image and apply the texture


 

}


export async function shareCanvasAsPng(){
    showComponentLoader("mainLoaderSpinner")
    let navigatorObject:any = window.navigator
    if(!navigatorObject?.share){
    return
    }
    var parentContainer = document.getElementById("mainCustomizein3DWrapper");
    var canvas = parentContainer.querySelector("canvas");
    var canvases = parentContainer.querySelectorAll("canvas");
    if(canvases.length > 1){
      canvas = canvases[1]
    }

    let dataURL = canvas.toDataURL("image/png");
    let file = dataURLToFile(dataURL, "share.png");
    let filesArray = [file];
    await waitFor(500)
    await navigatorObject.share({
    title: "Sharing",
    files:filesArray
    }).then(() => {
        hideComponentLoader("mainLoaderSpinner")
    }).catch(err => {
        console.log(err);
        hideComponentLoader("mainLoaderSpinner")
    });
}


export function downloadCanvasAsPng(){
    showComponentLoader("mainLoaderSpinner")
    let image = getCanvasURI()
    downloadURI(image,String(Number(new Date())))
    hideComponentLoader("mainLoaderSpinner")
}


export function getCanvasURI() {
    var parentContainer = document.getElementById("mainCustomizein3DWrapper");
    var canvas = parentContainer.querySelector("canvas");
    var canvases = parentContainer.querySelectorAll("canvas");
    if(canvases.length > 1){
      canvas = canvases[1]
    }
    return canvas.toDataURL("image/png");
}


export function dataURLToFile(dataURL, filename) {
    // Decode the data URL
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // Convert binary string to an array buffer
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    // Create a Blob from the array buffer
    const blob = new Blob([u8arr], { type: mime });

    // Create a File from the Blob
    return new File([blob], filename, { type: mime });
}