// js example - hiding walls based on camera position
import $ from 'jquery';
import {
	AdditiveBlending,
	Box3,
	BoxHelper,
	Color,
	DoubleSide,
	FrontSide,
	Group, Mesh,
	MeshBasicMaterial,
	Object3D,
	PlaneGeometry,
	ShaderMaterial,
	Vector2,
	Vector3
} from 'three';

import { showToast } from '../../UI_methods/global';
import { convert3DpointsTo2D, detachModule, getModelBoundingBox, removeAllChildrenFromMesh, setModelBoundingBox } from '../common';
import { set3dControls, setSpacePlannerControls } from '../controls';
import { generatedEnvironmentMap } from '../enviornment';
import { wallMaterial } from '../floorplanner/floorplan';
import { BoundingBoxProperties, getModelPositions, getObjectDimensionPosition } from '../modules/helper';
import { CURR_SELECTED_PRODUCT } from '../raycasting';
import { rotate } from './actions';
import { hideAreaLabel, resetCurrSnappedDistances, showAreaLabel, updateDistanceCheckPointsPosition } from './annotations';
import {
	camera,
	controls,
	CURR_AREA_GROUP,
	customizerConfig,
	deselectProduct,
	disableSpacePlanner,
	floorplanner,
	IS_SPACE_PLANNER_MODE, maskForHiddenElementsInFloorplan, orthoCamera,
	perspectiveCamera,
	projectConfiguration,
	renderer,
	scene,
	selectProduct,
	switchCameraArea,
	virtaulPlanesGroup
} from './area3dModel';
import { addDragControlsInProduct } from './dragControls';
import { updateBoxHelper } from './dragging';
import { checkCollision } from './helper';
import { isInteriorVisualization } from './interior_visualization';
import { setDraggedModelPosition, updateNormalInConfiguration } from './modules';
import { updateAttachIconVisibility } from './UI_methods';


export var northWall:Mesh = null
export var All_WALLS = []
export var WALL_WINDOWS = []
var initialWallColor = "0xdddddd"

export var CURR_FRONT_PLANE = null


export var isAutoWallsHiding = false
export var isWallFocusMode = false
var isCreatedVirtualPlane = false
var planes:any = null

export var areaWallsBoundingBoxMapping:any = null
export var areaWallsGroupsMapping:any = {}

export var WALLS_HIDING = true

var highlightWallBox = null

const areaCenter = new Vector3()

var v = new Vector3();

export var wallBoxHelpersGroup = new Group()

// callbacks
var onBeforeRender = function() {


	return function onBeforeRender( renderer, scene, camera, geometry, material, group ) {
		if(isInteriorVisualization || IS_SPACE_PLANNER_MODE){
			return
		}
		if ( v.subVectors( camera.position, this.userData.hiddenWall.userData.position ).dot( this.userData.hiddenWall.userData.normal ) < 0 ) {
			if(WALLS_HIDING){
				geometry.setDrawRange( 0, 0 )
			}else{
				geometry.setDrawRange( 0, Infinity )
			}
		}

	};

}();

var onAfterRender = function( renderer, scene, camera, geometry, material, group ) {
	if(isInteriorVisualization){
		return
	}
	geometry.setDrawRange( 0, Infinity )
	// this.visible = true
};

export async function initialWallsSetup(object:any) {
	if(wallBoxHelpersGroup){
		detachModule(wallBoxHelpersGroup)
	}
	wallBoxHelpersGroup = new Group()
	All_WALLS = []
	WALL_WINDOWS = []
	object.traverse(async (mesh:any)=>{
		if(mesh.isMesh){
			if(mesh.name.toLowerCase().includes("window")){
				WALL_WINDOWS.push(mesh)
				setModelBoundingBox(mesh)
			}
			if((mesh.name.includes("Wall_")) && !mesh.name.toLowerCase().includes("skirting") && !mesh.name.toLowerCase().includes("vertex")){
				// if(mesh.name.includes("Wall_") && !mesh.name.toLowerCase().includes("skirting")){
				let data = getObjectDimensionPosition(null,mesh)
				let v1 = new Vector3(data.positions.min.x,data.positions.min.y,data.positions.min.z) 
				let v2 = new Vector3(data.positions.max.x,data.positions.min.y,data.positions.max.z) 
				let v3 = new Vector3(data.positions.min.x,data.positions.max.y,data.positions.min.z) 
				let v4 = new Vector3(data.positions.max.x,data.positions.max.y,data.positions.max.z) 
				var points = [v1.clone(), v2.clone(), v3.clone(), v4.clone()];


				var geometry = new PlaneGeometry().setFromPoints(points)
				//Wall to update visiblity
				var hiddenWall = new Mesh(geometry,wallMaterial);
				hiddenWall.visible = false
				hiddenWall.userData.isHiddenWall = true
				mesh.userData.hiddenWall = hiddenWall
				floorplanner.group.add(hiddenWall)

				hiddenWall.name =  "hidden_wall"
				// mesh.visible = false

				addBoxHelperToWall(mesh)
				setModelBoundingBox(mesh)
				
				All_WALLS.push(mesh)
				WALL_WINDOWS.push(mesh)
				//To update the material, issue in wall select in floorplan mode / materail is not uniqe
			}
		}
	})

	updateTexture()
	updateNorthWall()
	rotateNorthWallIcon()
	scene.add(wallBoxHelpersGroup)
}

export function updateNorthWall() {
	All_WALLS.forEach(currWall=>{
		if(currWall.name === projectConfiguration.northWall){
			northWall = currWall
		}
	})
}


// let areaData = getObjectDimensionPosition(null,group)
export function makeWallsTransparent(walls:Array<Mesh>,center:Vector3) {

	walls.forEach(mesh=>{

		let data = getObjectDimensionPosition(null,mesh)

		let v1 = new Vector3(data.positions.min.x,data.positions.min.y,data.positions.min.z) 
		let v2 = new Vector3(data.positions.max.x,data.positions.min.y,data.positions.max.z) 
		let v3 = new Vector3(data.positions.min.x,data.positions.max.y,data.positions.min.z) 

		let hiddenWall = mesh.userData.hiddenWall

		let vector1 = new Vector3()
		let vector2 = new Vector3()
		let result = new Vector3()
	
		vector1.subVectors(v3,v1).normalize()
		vector2.subVectors(v2,v1).normalize()
		result = vector1.cross(vector2)
	
	
		let isFacingAreaCenter = new Vector3().subVectors(center,getObjectDimensionPosition(null,hiddenWall).center).dot(result)
	
		hiddenWall.userData.normal = new Vector3(result.round().x,result.round().y,result.round().z) 
		if(isFacingAreaCenter < 0){
			hiddenWall.userData.normal = new Vector3(result.round().round().x * -1,result.round().y * -1,result.round().z * -1)
		}
	
		mesh.userData.hiddenWall = hiddenWall
		hiddenWall.userData.position = getObjectDimensionPosition(null,hiddenWall).center
	
		mesh.onBeforeRender = onBeforeRender
		mesh.onAfterRender = onAfterRender
	
		// For Doors put walls normal 
		mesh.children.forEach(object=>{
			object.traverse((wallElement:any)=>{
				if(wallElement.isMesh){
					wallElement.userData.hiddenWall = hiddenWall
					wallElement.onBeforeRender = onBeforeRender
					wallElement.onAfterRender = onAfterRender
				}
			})
		})
	})

	
}



export function addBoxHelperToWall(wall:Mesh) {
	let door = null
	try {
		wall?.traverse(child=>{
			if(child?.name?.toLowerCase().includes("door")){
				if(child.parent){
					door = child
					// children.push(child)
					child.parent?.remove(child)
				}
			}
		})	
	} catch (error) {
		
	}
	let boxHelper = new BoxHelper( wall, 0x6c757d)
	boxHelper.layers.set(maskForHiddenElementsInFloorplan)
	// boxHelper.scale.set(floorplanner.traceScale,floorplanner.traceScale,floorplanner.traceScale)
	wall.userData.box = boxHelper
	wallBoxHelpersGroup.add(boxHelper)
	if(door){
		wall.add(door)
	}

}

export function updateWallsVisibility(){
	if(All_WALLS.length){
		All_WALLS.forEach(currWall=>{
			if ( v.subVectors( camera.position, currWall.userData.hiddenWall.position ).dot( currWall.userData.hiddenWall.userData.normal ) < 0 ) {
				currWall.visible = false
				currWall.userData.box.visible = true
			}else{
				currWall.visible = true
				currWall.userData.box.visible = false
			}
		})
	}
}

export function showAllWalls(){
	if(All_WALLS.length){
		All_WALLS.forEach(currWall=>{
			// currWall.visible = true
			currWall.geometry.setDrawRange(0,Infinity)
		})
	}
}


function updateTexture() {
	var url = "assets/textures/wallmap_yellow.png"
	floorplanner.group.traverse((mesh:any)=>{
		if(mesh.isMesh && (mesh.name.includes("Wall") || mesh.name.includes("Vertex")) ){
			// if(mesh.isMesh && (mesh.name.includes("Wall") || mesh.name.includes("Vertex") || mesh.name.includes("Floor_")) ){
			// let material:any = new MeshPhysicalMaterial({
			// 	transparent:true,
			// 	side: DoubleSide,
			// })
			// textureLoader.load(url,function(textureMap:any){
			// 	textureMap.wrapS = RepeatWrapping;
			// 	textureMap.wrapT = RepeatWrapping;
			// 	textureMap.repeat.set(2,2);
			// 	textureMap.encoding  = sRGBEncoding
			// 	textureMap.needsUpdate = true
			// 	material.map = textureMap
			// 	material.envMap = generatedEnvironmentMap.texture
			// 	mesh.material = material
			//   },function(loaded:any){
			//   },function(error:any) {
			// 	console.log(error)
			//   })

			const originalMaterial = mesh.material;
            const clonedMaterial = originalMaterial.clone();
            clonedMaterial.envMap = generatedEnvironmentMap.texture;
            mesh.material = clonedMaterial;
		}
	})
	
  }




function getNormalFromDrection(direction:string) {
	switch (direction.toLowerCase()) {
		case "east": // front
			return new Vector3( - 1, 0, 0 )
		break;

		case "west": //back
			return new Vector3( 1, 0, 0 );
		break;
		case "north": // right
			return new Vector3( 0, 0, 1 )
		break;
		case "south"://left
			return new Vector3( 0, 0, - 1 );
		break;
	
		default:
		break;
	}
}


  
export function setInitialWallColor() {
	if(highlightWallBox){
		scene.remove(highlightWallBox)
	}
	if(All_WALLS.length){
		All_WALLS.forEach(currWall=>{
			currWall.material.color.setHex(initialWallColor)
			currWall.material.opacity = 1
			currWall.userData.isClicked = false
		})
	}
}

// var CURR_WALL_PRODUCT = null

export function setObjectInWallBoundaries(object:any,wall:any) {
	let data = getObjectDimensionPosition(null,wall)
	object.userData.limit = {
	  min: new Vector3(data.positions.min.x,object.position.y,data.positions.min.z),
	  max: new Vector3(data.positions.max.x,object.position.y,data.positions.max.z)
	};

	object.userData.update = function(){
		object.position.clamp(object.userData.limit.min, object.userData.limit.max);
	}
	// CURR_WALL_PRODUCT = object
	// animate()
	
}



export function getIntersectWallForSnapping(draggingObject:any,direction:any) {
    let intersectObjects = checkCollision(draggingObject,direction)
    intersectObjects = intersectObjects?.filter((object:any)=>object.object.parent.uuid!=draggingObject.uuid 
    && object.object.name.toLowerCase().includes("wall_")
    )
    if(intersectObjects.length){
      return intersectObjects[0].object
    }
    return null
  }



  export function detachObjectFromWall() {
	CURR_SELECTED_PRODUCT.userData.isAttachedToWall = false
	let normal = CURR_SELECTED_PRODUCT?.userData?.normal?.clone() || new Vector3(0,0,1)
	// CURR_SELECTED_PRODUCT.translateX(normal.x * 0.5);
	// CURR_SELECTED_PRODUCT.translateZ(normal.z * 0.5);
	if(normal.x){
		CURR_SELECTED_PRODUCT.position.setX(CURR_SELECTED_PRODUCT.position.x + normal.x * 0.5)
	}
	if(normal.z){
		CURR_SELECTED_PRODUCT.position.setZ(CURR_SELECTED_PRODUCT.position.z + normal.z * 0.5)
	}
	showToast("Deteched",2000)
	addDragControlsInProduct(CURR_SELECTED_PRODUCT)
	updateNormalInConfiguration(CURR_SELECTED_PRODUCT,null)
	// if(isKitchenPlannerMode && !CURR_SELECTED_PRODUCT.userData.isWallMounted){
	// 	kitchenPlanner.detachModule(CURR_AREA_GROUP,CURR_SELECTED_PRODUCT)
	// }
	updateBoxHelper()
	updateAttachIconVisibility()
  }

 

  export function toggleElevationView() {
	if($("#elevationView").hasClass("--is-active")){
		disabeSetWallProductPositionMode()	
		$("#elevationView").removeClass("--is-active")
	}else{
		enableSetWallProductPositionMode(CURR_SELECTED_PRODUCT)
		$("#elevationView").addClass("--is-active")
	}
  }



  export function toggleWallsHiding(event:any = null) {
	$(event?.target).toggleClass("--is-active")
	if(WALLS_HIDING){
		disableWallsHiding(event)
	}else{
		enableWallsHiding(event)
	}
  }

  export function showWallsBoxHelpers() {
	All_WALLS.forEach(wall=>{
		if(wall.visible){
			wall.userData.box.visible = true
		}
	})
  }

	export function hideWallsBoxHelpers() {
		All_WALLS.forEach(wall=>{
			wall.userData.box.visible = false
		})
	}


  export function enableWallsHiding(event:any = null) {
	WALLS_HIDING = true
	isAutoWallsHiding = false
	showWallsBoxHelpers()
	$("#wallsVisibiltyToggle").find(".state-text").text("on")
	$("#wallsVisibiltyToggle").addClass("--is-active")
  }

  export function autoWallsHiding() {
	WALLS_HIDING = false
	isAutoWallsHiding = true
	hideWallsBoxHelpers()
	$("#wallsVisibiltyToggle").find(".state-text").text("auto")
	$("#wallsVisibiltyToggle").addClass("--is-active")

  }

  export function disableWallsHiding(event:any = null) {
	WALLS_HIDING = false
	isAutoWallsHiding = false
	hideWallsBoxHelpers()
	$("#wallsVisibiltyToggle").find(".state-text").text("off")
	$("#wallsVisibiltyToggle").removeClass("--is-active")
  }

  let cameraPosition = new Vector3()

  export function updateWallsHiding() {
	if(areaWallsBoundingBoxMapping){
		let maxY = areaWallsBoundingBoxMapping["all"].boundingBox.positions.max.y + 5
		camera.getWorldPosition(cameraPosition)
		if(camera.position.y > maxY || areaWallsBoundingBoxMapping["all"].boundingBox.positions.containsPoint(camera.position)){
			WALLS_HIDING = false
			hideWallsBoxHelpers()
		}else{
			WALLS_HIDING = true
			showWallsBoxHelpers()
		}
	}
  }

  export function updateAreaLabelVisibility() {
	if(areaWallsBoundingBoxMapping){
		let maxY = areaWallsBoundingBoxMapping["all"].boundingBox.positions.max.y

		if(camera.position.y > maxY || areaWallsBoundingBoxMapping["all"].boundingBox.positions.containsPoint(camera.position)){
			showAreaLabel()
		}else{
			hideAreaLabel()
		}
	}
  }


  export function checkCollisionWithWalls(object:Mesh) {
	let result = null
	All_WALLS.forEach(wall=>{
		if(getModelBoundingBox(wall).intersectsBox(getModelPositions(object,new Box3()))){
			result = wall
		}
	})
	return result
    
  }


  export function createAreaWallsBoundingBoxMapping() {

	let configuration = projectConfiguration.projectConfiguration
	if(!areaWallsBoundingBoxMapping){
		areaWallsBoundingBoxMapping = {}
	}
	let floorplanGroup = floorplanner.group.children.find(currObject => currObject.name.includes("floorplan")) || floorplanner.group
 
	if(floorplanGroup){
		for (const areaKey in configuration) {
			let area = configuration[areaKey]
			let group = new Group()
			floorplanGroup.add(group)
			for (const index in area.walls) {
				let wall = findWall(area.walls[index])
				if(wall){
					let clonnedWall = wall.clone() 
					removeAllChildrenFromMesh(clonnedWall)
					group.add(clonnedWall)
				}
			}
			areaWallsBoundingBoxMapping[areaKey] = {
				boundingBox:getObjectDimensionPosition(null,group)
			}
			areaWallsGroupsMapping[areaKey] = {
				group:group
			}
			group.parent.remove(group)
		}

		areaWallsBoundingBoxMapping[projectConfiguration.projectName] = {
			boundingBox:getObjectDimensionPosition(null,floorplanGroup)
		}
		areaWallsBoundingBoxMapping["all"] = {
			boundingBox:getObjectDimensionPosition(null,floorplanGroup)
		}
		areaWallsBoundingBoxMapping["Other"] = {
			boundingBox: new BoundingBoxProperties(new Box3(),new Vector3(),{})
		}
	}

    
	
	

  }


 



  export function getAreaFromWallsBoundingBox(object:any) {
	let result = "Other"

	for (const area of projectConfiguration.areasList) {
		if(areaWallsBoundingBoxMapping[area.area_name].boundingBox.positions.containsPoint(getObjectDimensionPosition(null,object).center)){
			result = area.area_name
		}
	}
	return result  
  }

  export function getAreaFromPoint(center:Vector3) {
	let result = "Other"

	for (const area of projectConfiguration.areasList) {
		if(areaWallsBoundingBoxMapping[area.area_name].boundingBox.positions.containsPoint(center)){
			result = area.area_name
		}
	}
	return result || "Other"
  }


  export function getWallCenterFromArea(areaName:string) {
	return areaWallsBoundingBoxMapping[areaName].center
  }

  function findWall(name:string) {
	let result = null
	All_WALLS.forEach(wall=>{
		if(wall.name === name){
			result = wall
		}
	})
	return result
  }


  export function getGlowMaterial() {
    let uniforms:any = { 
        "c":   { type: "f", value: 1.0 },
        "p":   { type: "f", value: 0.7 },
        glowColor: { type: "c", value: new Color(0xffff00) },
        viewVector: { type: "v3", value: camera.position }
    }
    return new ShaderMaterial( 
    {
        uniforms:uniforms,
        vertexShader:   document.getElementById( 'vertexShader'   ).textContent,
        fragmentShader: document.getElementById( 'fragmentShader' ).textContent,
        side: FrontSide,
        blending: AdditiveBlending,
        transparent: true
    }   );
}
 

var FacePoints1_0,FacePoints1_1,FacePoints1_2,FacePoints1_3
var FacePoints2_0,FacePoints2_1,FacePoints2_2,FacePoints2_3
var initialRotationVector = new Vector3(0,0,1)
var referenceVector = new Vector2(0,1)
export function rotateWallProductOnDrag(mesh:Mesh,areaName:string){

	if(!CURR_SELECTED_PRODUCT){
		return
	}
	planes = getFrontAndBackPlane(mesh,areaName)

	let FrontPlane = planes.FrontPlane
	CURR_FRONT_PLANE =  FrontPlane

	CURR_SELECTED_PRODUCT.rotation.set(0,0,0)

	let planeNormal = FrontPlane.userData.normal
 
	// let angle = planeNormal.clone().angleTo(initialRotationVector)
	// let angle = Math.atan2(planeNormal.x-initialRotationVector.x,planeNormal.z-initialRotationVector.z)
	CURR_SELECTED_PRODUCT.userData.normal =  planeNormal.clone()
	let angle = new Vector2(planeNormal.z,planeNormal.x).angle()
	if(angle){
		rotate(CURR_SELECTED_PRODUCT,angle)
	}
	virtaulPlanesGroup.add(FrontPlane)
	// virtaulPlanesGroup.add(BackPlane)
}

export function getWallBoundingBoxWithoutChildren(wall:Mesh) {
	let boundingbox:any = new Box3()
	let children = []

	try {
		wall?.traverse(child=>{
			if(child?.name?.toLowerCase().includes("door") || child?.name?.toLowerCase().includes("skirting")){
				if(child.parent){
					// door = child
					children.push(child)
					// child.parent?.remove(child)
				}
			}
		})	
	} catch (error) {
		
	}
	if(children.length){
		wall.remove(...children)
	}
	boundingbox?.setFromObject(wall);
	if(children.length){
		wall.add(...children)
	}

	return boundingbox
}

export function getFrontAndBackPlane(wall:Mesh,areaName:string){
	
	let boundingbox = getWallBoundingBoxWithoutChildren(wall)
	
	// let boundingbox = getObjectDimensionPosition(null,mesh).positions
    let width = boundingbox.max.x-boundingbox.min.x
    let depth = boundingbox.max.z-boundingbox.min.z
    let height = boundingbox.max.y-boundingbox.min.y


	let tempVector = new Vector3(0,0,0)
    
    if(width>depth)
    {
		tempVector.set(0,0,depth)
        // plane1
        FacePoints1_0 = boundingbox.min
        FacePoints1_1 = new Vector3(boundingbox.max.x,boundingbox.min.y,boundingbox.min.z)
        FacePoints1_2 = new Vector3(boundingbox.min.x,boundingbox.max.y,boundingbox.min.z)
        FacePoints1_3 = new Vector3(boundingbox.max.x,boundingbox.max.y,boundingbox.min.z)
        // plane2
        FacePoints2_0 = FacePoints1_0.clone().add(tempVector)
        FacePoints2_1 = FacePoints1_1.clone().add(tempVector)
        FacePoints2_2 = FacePoints1_2.clone().add(tempVector)
        FacePoints2_3 = FacePoints1_3.clone().add(tempVector)
    }
    else
    {
		tempVector.set(width,0,0)
        // plane1
        FacePoints1_0 = boundingbox.min
        FacePoints1_1 = new Vector3(boundingbox.min.x,boundingbox.min.y,boundingbox.max.z)
        FacePoints1_2 = new Vector3(boundingbox.min.x,boundingbox.max.y,boundingbox.min.z)
        FacePoints1_3 = new Vector3(boundingbox.min.x,boundingbox.max.y,boundingbox.max.z)
        // plane2
		
        FacePoints2_0 = FacePoints1_0.clone().add(tempVector) 
        FacePoints2_1 = FacePoints1_1.clone().add(tempVector)
        FacePoints2_2 = FacePoints1_2.clone().add(tempVector)
        FacePoints2_3 = FacePoints1_3.clone().add(tempVector)
    }

	var pointsForPlane1 = [FacePoints1_0, FacePoints1_1, FacePoints1_2, FacePoints1_3];
	var pointsForPlane2 = [FacePoints2_0, FacePoints2_1, FacePoints2_2, FacePoints2_3];
			 

    let plane1 = createVirtualPlane(pointsForPlane1)
    let plane2 = createVirtualPlane(pointsForPlane2)


    let areaCenter = areaWallsBoundingBoxMapping[areaName].boundingBox.center


    let FrontPlane = getObjectDimensionPosition(null,plane1).center.distanceTo(areaCenter) <  getObjectDimensionPosition(null,plane2).center.distanceTo(areaCenter) ? plane1 : plane2
    let BackPlane = getObjectDimensionPosition(null,plane2).center.distanceTo(areaCenter) >  getObjectDimensionPosition(null,plane1).center.distanceTo(areaCenter) ? plane2 : plane1
	
	FrontPlane.userData.wall = wall
	BackPlane.userData.wall = wall


	setPlaneNormalVector(FrontPlane,pointsForPlane1,areaCenter)
	setPlaneNormalVector(BackPlane,pointsForPlane2,areaCenter)


	let normal = FrontPlane.userData.normal
	FrontPlane.translateOnAxis(normal,customizerConfig.offsetForFrontWall)

	 
	isCreatedVirtualPlane = true
	
	return {
		FrontPlane:FrontPlane,
		BackPlane:BackPlane
	}

}

export function createVirtualPlane(points:any) {
	const material = new MeshBasicMaterial({
		opacity: 1,
		// visible:false,
		transparent:true,
		side: DoubleSide,
		color:"red"
	});
	var geometryForPlane2 = new PlaneGeometry().setFromPoints(points)
	return new Mesh(geometryForPlane2,material);
}

export function setPlaneNormalVector(plane:Mesh,points:Array<any>,center:Vector3) {
	let vector1 = new Vector3()
	let vector2 = new Vector3()
	vector1.subVectors(points[0],points[1]).normalize()
	vector2.subVectors(points[0],points[2]).normalize()
	let result = vector1.cross(vector2)


	 
	let isFacingAreaCenter = new Vector3().subVectors(center,getObjectDimensionPosition(null,plane).center).dot(result)

	let normal = new Vector3(result.round().x,result.round().y,result.round().z)
	if(isFacingAreaCenter < 0){
		normal = new Vector3(result.round().round().x * -1,result.round().y * -1,result.round().z * -1)
	}
	// if(isKitchenPlannerMode && CURR_SELECTED_PRODUCT.userData.isWallMounted){
	// 	var angle = kitchenPlanner.group.rotation.y
	// 	normal.applyAxisAngle( axis, -angle ).normalize()
	// }
	plane.userData.normal = normal
}

export function resetWallProductParameters(isRemovePlanes:boolean = false){
	if(isRemovePlanes){
		removeVirtualPlanes()
	}
	isCreatedVirtualPlane = false
	CURR_FRONT_PLANE = null
}


export function removeVirtualPlanes(){
	// virtaulPlanesGroup?.children.forEach(mesh=>{
	// 	virtaulPlanesGroup.remove(mesh)
	// })
	virtaulPlanesGroup.remove(...virtaulPlanesGroup.children);
	// scene.remove(virtaulPlanesGroup)
}

export async function enableSetWallProductPositionMode(object:Object3D = CURR_SELECTED_PRODUCT) {
	if((CURR_SELECTED_PRODUCT.userData.subCategoryName === "Vase" || CURR_SELECTED_PRODUCT.userData.subCategoryName === "Planters") ){
		enableSurfaceItemPositionMode()
		return
	}
	let plane = object.userData.snappedWall
	if(!plane){
		createSnappedPlane(object)
		plane = object.userData.snappedWall
	}
    if(plane){
		let normal = plane?.userData?.normal
		let center = getObjectDimensionPosition(null,plane)?.center
		// center.add(new Vector3(normal.x,normal.y,normal.z).multiplyScalar(3))
		await controls.setPosition(center.x + normal.x * 3,center.y,center.z + normal.z * 3,false)
		switchCameraArea(controls,orthoCamera)
		controls.setTarget(center.x,center.y,center.z,false)
		controls.fitToBox( plane, false, { paddingTop:1, paddingLeft:1, paddingBottom:1, paddingRight:1 },false )
		// orthoCamera.near = 0.001
		$(".hide-on-wall-focus").css("opacity","0")
		$(".disable-close-wall-button").removeClass("--is-hide")
		setSpacePlannerControls(controls)
		selectProduct()
		isWallFocusMode = true
		// updateWallsVisibilityForElevationView(plane)
    }
}

export async function enableSurfaceItemPositionMode() {
	 
	let center = getObjectDimensionPosition(null,CURR_SELECTED_PRODUCT)?.center
	await controls.setPosition(center.x,center.y+2,center.z,false)
	switchCameraArea(controls,orthoCamera)
	controls.setTarget(center.x,center.y,center.z,false)
	controls.fitToBox( CURR_SELECTED_PRODUCT, false, { paddingTop:1, paddingLeft:1, paddingBottom:1, paddingRight:1 },false )
	$(".hide-on-wall-focus").css("opacity","0")
	$(".disable-close-wall-button").removeClass("--is-hide")
	setSpacePlannerControls(controls)
	selectProduct()
	isWallFocusMode = true
}

export function createSnappedPlane(object:Object3D) {
	//If not snapped wall add snapped wall first 
	updateDistanceCheckPointsPosition(object)
	let areaName = projectConfiguration.getAreaNameFromProduct(object.userData.productInstanceName)
	setDraggedModelPosition(object,areaName)
}



export function disabeSetWallProductPositionMode() {
	$(".hide-on-wall-focus").css("opacity","1")
	$(".disable-close-wall-button").addClass("--is-hide")
	disableSpacePlanner()
	deselectProduct()
	set3dControls(controls)
	isWallFocusMode = false
}

export function updateWallsVisibilityForElevationView(plane:any) {
	updateVisibilityOfAllWalls(false)
	updateVisibilityOfWall(plane.userData.wall,true)
}

export function updateVisibilityOfAllWalls(visible:boolean) {
	All_WALLS.forEach(currWall=>{
		updateVisibilityOfWall(currWall,visible)
	})
}

export function updateVisibilityOfWall(wall:Mesh,visible:boolean) {
	wall.visible = visible
	wall.userData.box.visible = visible
}

export function toggleVisibilityOfCurrAreaWalls(visible:boolean) {
	let wallNames = []
	CURR_AREA_GROUP.children.forEach(currWall => {
		wallNames.push(currWall.name)
	});
	All_WALLS.forEach(currWall => {
		if(wallNames.includes(currWall.name)){
			currWall.visible = visible
		}
	});
}

export function resetWallsColor() {
	All_WALLS.forEach(currWall => {
		currWall.material.opacity = 1
	});
	resetCurrSnappedDistances()
}


export function rotateNorthWallIcon() {
	if(northWall){
		const startPoint = getNorthWallAreaCenter()
		const endPoint = getObjectDimensionPosition(null,northWall).center
		if(startPoint && endPoint){
			const pos1 = convert3DpointsTo2D(renderer,perspectiveCamera,orthoCamera,startPoint,IS_SPACE_PLANNER_MODE)
			const pos2 = convert3DpointsTo2D(renderer,perspectiveCamera,orthoCamera,endPoint,IS_SPACE_PLANNER_MODE)
			// const dimLines = document.querySelectorAll('line');
			// let svg = dimLines[0]
			// dimensions.drawLine(svg,pos1,pos2)
			const angle = getAngleFromSvg(pos1,pos2)
			const anchorElement = $("#compassContainer")
			anchorElement.css("transform",`rotateZ(${angle+90}deg)`)
		}
	}
}

function getNorthWallAreaCenter() {
	const areaNames = getAreaNamesFromWall(northWall)
	if(areaNames.length){
		const areaName = areaNames[0]
		const plane = getFrontAndBackPlane(northWall,areaName).FrontPlane
		if(plane){
			const normal = plane?.userData?.normal
			const center = getObjectDimensionPosition(null,plane)?.center
			areaCenter.x = center.x + normal.x * 3
			areaCenter.y = center.y
			areaCenter.z = center.z + normal.z * 3
			return areaCenter
		} 
		return null
	}
	return null
	
}


export function getAreaNamesFromWall(wall:any) {
    const areaNames = []
    const configuration = projectConfiguration.projectConfiguration
    for (const areaKey in configuration) {
        const walls = configuration[areaKey].walls
        if(walls.length){
            if(walls.includes(wall.name)){
                areaNames.push(areaKey)
            }
        }
    } 
    return areaNames 
}

function getAngleFromSvg(pos1:any,pos2:any) {
	return Math.atan2(pos2.y - pos1.y, pos2.x - pos1.x) * (180 / Math.PI);
}

export function removeWallsElementOfShapeFloorplan() {
	floorplanner.group.children.forEach((currChild:any) => {
		if (currChild.isMesh) {
			if (currChild.name.includes("Floor_") || currChild.name.includes("hidden_wall")) {
				detachModule(currChild)
			}
		}
	});
	let floorplan = floorplanner.group.children.find(currMesh => currMesh.name.includes("floorplan"))
	floorplan?.children.forEach((currChild:any) => {
		if (currChild.isMesh) {
			if (currChild.name.includes("Floor_") || currChild.name.includes("hidden_wall")) {
				detachModule(currChild)
			}
		}
	});
	detachModule(wallBoxHelpersGroup)
	if(floorplanner.floor){
		detachModule(floorplanner.floor)
	}

}
  


