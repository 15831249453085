import { IonSkeletonText, IonSpinner } from "@ionic/react";
import React, { useEffect, useState } from "react";
import "../../../style/common/loader.css";

const Loader: React.FC<{
  data: any;
  className: string;
  id: string;
  loaderType: string;
}> = (props) => {
  const [classNames, setClassNames]: Array<any> = useState();
  useEffect(() => {
    let classes = [
      "center-aligned-column component-loader white-background full-height-width top-left",
    ];
    classes.push(props.className);
    setClassNames(classes);
  }, []);

  switch (props.loaderType) {
    case "spinner":
      return (
        <div className={classNames?.join(" ")} id={props.id}>
          <IonSpinner name="crescent" />
        </div>
      );

      break;

    case "dots":
      return (
        <div className={classNames?.join(" ")} id={props.id}>
          <IonSpinner name="dots" />
        </div>
      );

      break;

    case "progress":
      return (
        <div className={classNames?.join(" ")} id={props.id}>
          <div className="progress-loader-container position-relative shadow-medium center-aligned-column">
            <div className="center-aligned-row" style={{ padding: 0 }}>
              <img
                src="assets/icon/icon.png"
                alt=""
                style={{ width: "50px" }}
              />
            </div>
            <div className="heading2 color-black" style={{ paddingTop: 0 }}>
              Opus
            </div>
            <div className="progress-bar-container">
              <div
                id={props.id + "progressBar"}
                className="progress-bar"></div>
            </div>
            {/* <div id={props.id+"progressBarValue"}>0%</div> */}
            <div
              className="center-aligned-row heading2 color-black"
              id={props.id + "progressMessage"}>
              Please Wait
            </div>
          </div>
        </div>
      );

      break;

    case "progressCompact":
      return (
        <div className={classNames?.join(" ")} id={props.id}>
          <div className="progress-loader-container progress-compact-loader position-relative shadow-medium center-aligned-column">
            <div
              className="center-aligned-row no-wrap heading2 color-black"
              id={props.id + "progressMessage"}>
              Please Wait
            </div>
            <div className="progress-bar-container">
              <div
                id={props.id + "progressBar"}
                className="progress-bar"></div>
            </div>
            <div id={props.id + "progressBarValue"}>0%</div>
          </div>
        </div>
      );

      break;

    case "spinnerProgress":
      return (
        <div className={classNames?.join(" ")} id={props.id}>
          <div
            className="progress-loader-container position-relative shadow-medium center-aligned-column"
            style={{ width: "300px" }}>
            <div className="center-aligned-row" style={{ padding: 0 }}>
              <img
                src="assets/icon/icon.png"
                alt=""
                style={{ width: "50px" }}
              />
            </div>
            <div className="heading2 color-black" style={{ paddingTop: 0 }}>
              Opus
            </div>
            <IonSpinner name="crescent" />
            {/* <div id={props.id+"progressBarValue"}>0%</div> */}
            <div
              className="center-aligned-row heading2 color-black"
              id={props.id + "progressMessage"}>
              Please Wait
            </div>
          </div>
        </div>
      );

      break;

    case "skeleton":
      return (
        <div className={classNames?.join(" ")} id={props.id}>
          {props.data.isAnimated ?
            <IonSkeletonText className="top-left custom-skeleton-text" animated style={{ width: '100%', height: "100%" }} />
            :
            <IonSkeletonText className="top-left custom-skeleton-text" style={{ width: '100%', height: "100%" }} />
          }
        </div>
      )

      break;

    case "dotsText":
      return (
        <div className={classNames?.join(" ")} id={props.id}>
          <div
            className="progress-loader-container dots-loader-container position-relative white-card center-aligned-column"
          >

            <div
              className="center-aligned-row heading2 font-medium ion-text-capitalize color-black"
              id={props.id + "progressMessage"}>
              {props.data?.message}
            </div>

           

            <div className="stage" style={{ padding: "8px" }}>
              <div className="dot-flashing"></div>
            </div>

          </div>
        </div>
      );

      break;

    case "gif":
      return (
        <div className={classNames?.join(" ")} id={props.id}>
          <div className="">
            <img src={props.data.gifSrc} alt="" width={200} />
          </div>
        </div>
      );

      break;

    case "svg":
      return (
        <div className={classNames?.join(" ")} id={props.id}>
          <div className="">

            <svg version="1.1" id="L1" xmlns="http://www.w3.org/26c757d/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100" >
              <circle fill="none" stroke="#6c757d" strokeWidth="6" strokeMiterlimit="15" strokeDasharray="14.2472,14.2472" cx="50" cy="50" r="47" >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="5s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite" />
              </circle>
              <circle fill="none" stroke="#6c757d" strokeWidth="1" strokeMiterlimit="10" strokeDasharray="10,10" cx="50" cy="50" r="39">
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="5s"
                  from="0 50 50"
                  to="-360 50 50"
                  repeatCount="indefinite" />
              </circle>
              <g fill="#6c757d">
                <rect x="30" y="35" width="5" height="30">
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    type="translate"
                    values="0 5 ; 0 -5; 0 5"
                    repeatCount="indefinite"
                    begin="0.1" />
                </rect>
                <rect x="40" y="35" width="5" height="30" >
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    type="translate"
                    values="0 5 ; 0 -5; 0 5"
                    repeatCount="indefinite"
                    begin="0.2" />
                </rect>
                <rect x="50" y="35" width="5" height="30" >
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    type="translate"
                    values="0 5 ; 0 -5; 0 5"
                    repeatCount="indefinite"
                    begin="0.3" />
                </rect>
                <rect x="60" y="35" width="5" height="30" >
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    type="translate"
                    values="0 5 ; 0 -5; 0 5"
                    repeatCount="indefinite"
                    begin="0.4" />
                </rect>
                <rect x="70" y="35" width="5" height="30" >
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    type="translate"
                    values="0 5 ; 0 -5; 0 5"
                    repeatCount="indefinite"
                    begin="0.5" />
                </rect>
              </g>
            </svg>

          </div>
        </div>
      );

      break;


      case "topProgressBar":
        return (
          <div className={classNames?.join(" ")} id={props.id}>

            <div className="progress-bar-container top-left full-width" style={{borderRadius:"0",overflow:"visible"}}> 
              <div id={props.id + "progressBar"} className="progress-bar progress-bar-top position-relative" style={{height:"5px",borderRadius:"0"}}></div>
            </div>

            {props.id != "addProductLoader"?
            <div className="progress-loader-container dots-loader-container position-relative white-card center-aligned-column" >

            <div className="center-aligned-row heading2 font-medium ion-text-capitalize color-black" id={props.id + "progressMessage"}>Please wait
            </div>

            <div className="stage" style={{ padding: "8px" }}>
              <div className="dot-flashing"></div>
            </div>

          </div>

          :null}


{/* 
            <div className="position-relative center-aligned-column">
             
              <div className="font-ex-large letter-spacing heading1 color-white ion-margin" id={props.id + "progressMessage"}>Please wait</div>
              <div className="center-aligned-row text-center  no-wrap  color-white">
                Please wait while we loading images from saved configuration 
              </div>

            </div> */}
          </div>
        );
  
        break;

    default:
      return <div>Loading default...</div>;
      break;
  }


  return (
    <div>Loading...</div>
  )
};

export default Loader;
