import { IonModal } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import { applicationConfig, getUser } from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Modal from "../../../../common/shared-components/Modals/Modal";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
import Home from "../../productActions/Home";
import AddToCart from "../cart/AddToCart";
import PriceBreakup from "../product-info/PriceBreakup";
import ProductInfo from "../product-info/ProductInfo";

const ProductsList: React.FC<{
  productsList:any
  closeModal?:any
}> = (props) => {

 

  const [recentConfigure,setRecentConfigure] = useState(getItemFromLocalStorage("recentCustomizeProduct"));
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const [currProduct,setCurrProduct] = useState(null);
  const [productLoaderClass] = useState<string>("product-loader");

  const [loadLimit, setLoadLimit] = useState(20);

  let history = useHistory()

  // useEffect(() => {
  //   if (productsList?.length) {
  //     setProductsList((prevProducts) => {
  //       return prevProducts.concat(
  //         props.productsList?.slice(loadLimit - 20, loadLimit)
  //       );
  //     });
  //   }
  // }, [props.productsList, loadLimit]);

  useEffect(() => {
    setLoadLimit(20)
    // setProductsList(props.productsList)
  }, [props.productsList]);


  function clickHandle(product:any) {
    setCurrProduct(product)
    STORE.currProduct = product
    setIsOpenOptions(true)
    addItemToLocalStorage("sharedItem",null)
    applicationConfig.sharedItem = null
    addItemToLocalStorage("currProduct",product)
  }

  function customizeAction() {
    setIsOpenOptions(false)
    setIsOpen(true)
  }

  function Footer() {
    return(
      <div className="display-flex-row ">
        <div className="display-flex-row no-wrap left-middle padding5 pointer">
          <span className="heading4 font-normal color-black center-aligned-column">Total Price - </span>
          <span className="heading1 marginleft5 rupee-sign font-large total_price store-color-primary center-aligned-column">{0} </span>
          <PriceBreakup/>
        </div>
        <div className="right-middle">
          <AddToCart currProduct={currProduct} onAdd={()=>{setIsOpen(false)}}/>
        </div>
      </div>
    )
  }

  function Wrapper() {
    return(
      <div className="full-height-width">
        <div className="header-content bg-color-dark heading2 font-medium color-white center-aligned-column">
          {currProduct.product_name}
        </div>
        <div className="body-content">
          <ProductInfo product={currProduct}/>
        </div>
        <div className="footer-content position-relative bg-color-light">
          <Footer/>
        </div>
      </div>
    )
  }




  function customizein3dAction() {
    if(getUser()?.isShareMode){
      window.location.href = "/customizein3d"
    }else{
      setIsOpenOptions(false)
      if(props.closeModal){
        props.closeModal()
      }
      history.push("/customizein3d")
    }
  }

  function nextPage() {
    $("#imageSmallButton").click()
    setLoadLimit((prevValue) => {
      if (prevValue >= props.productsList?.length) {
        return prevValue;
      }
      return prevValue + 20;
    });
  }

  function viewAll() {
    setLoadLimit(props.productsList.length)
  }

  return (
    <>
    <div className="display-flex-row product-images-container full-height overflow-y-scroll" id="subCategoryProductsList" style={{paddingBottom:"4rem"}}>
       <IonModal
          id=""
          cssClass="medium-y-modal"
          onDidDismiss={()=>setIsOpen(false)}
          isOpen={isOpen}
          >
            {currProduct?
              <Wrapper/>
            :null}
      </IonModal>

   


      <Modal
          id=""
          classNames="medium-y-modal"
          onDismiss={()=>setIsOpenOptions(false)}
          isOpen={isOpenOptions}
          heading={currProduct?.product_name}
          FooterContent={()=>(<></>)}
          Content={()=>(
            <>
          
              {currProduct?
                <Home
                  configObj={applicationConfig}
                  currProductId={currProduct.product_id}
                  setOpenProjectAction={()=>setIsOpenOptions(false)}
                  currProduct={currProduct}
                  viewIn360Action={customizeAction}
                  gotoCustomizeIn3D={customizein3dAction}
                  quickCustomizeAction={customizeAction}
                /> 
              :null}
            </>
          )}
          >
      </Modal>



      {props.productsList?.map((product:any,index:number)=>{
        return(
          <React.Fragment key={index}>
          {Number(index) < loadLimit ? (
          <div onClick={()=>clickHandle(product)} className={`category-details-wrapper padding5 image-small`} key={index}>
            <div className="white-card shadow-medium">

              <div className="image  product-image-container position-relative">
                {recentConfigure?.product_id === product.product_id ?
                 <div className="top-left color-white bg-color-primary font-ex-small heading4" style={{padding:"0.3rem 0.7rem"}}>
                  Recently Configured
                </div>
                :null}
            {/* <div className="top-right zindex100">{product.product_id}</div> */}
               
                <ProductImage product={product}/>
              </div>
              <div className="text heading1 padding5 text-center">{product.product_name}</div>
              <div className="no-shadow marginleft5  display-flex-row no-wrap">
              </div>
            </div>
          </div>
          ) : null}
          </React.Fragment>
        )
      })}

      {loadLimit < props.productsList?.length ? (
      <div className="center-aligned-row marginTop10 full-width">
        <div className="display-flex-row no-wrap">
          <Button2
            configObj={{}}
            action={()=>{nextPage()}}
            label={"Load More"}
            fontAwesomeIcon={"fas fa-share-alt color-primary"}
            iconPos=""
            classNames={"button-small bg-color-primary-outline"}
          />
           <Button2
            configObj={{}}
            action={()=>{viewAll()}}
            label={"Show All"}
            fontAwesomeIcon={"fas fa-share-alt color-primary"}
            iconPos=""
            classNames={"button-small bg-color-light"}
          />
        </div>
        
      </div>
    ) : null}

    </div> 
     
    </>
  );
};

export default ProductsList;
