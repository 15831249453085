import { IonAlert, IonPage } from "@ionic/react";
import React, { forwardRef, useEffect, useState } from "react";
import { showComponentLoader, updateLoaderProgress } from "../../../../../utils/UI_methods/global";
import { getItemFromLocalStorage } from "../../../../../utils/cache/localstorage";
import { projectConfiguration, setProjectConfigObject } from "../../../../../utils/customizein3d/area3dmodel/area3dModel";
import CurrentConfig from "../../../../../utils/customizer/CustomizerConfig";
import { ProjectConfiguration } from "../../../../../utils/customizer/ProjectConfiguration";
import { getApplicationFiltersMaterials, getFilteredArray, getProjectFiltersMaterials } from "../../../../../utils/methods";
import { getPageTourElements } from "../../../../../utils/siteTour/elements";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import DragControlsWrapperIcon from "../../../../common/ui-components/DragControlsWrapperIcon";
import ToggleControlsWrapper from "../../../../common/ui-components/ToggleControlsWrapper";
import HomeButton from "../../../../common/ui-components/links/HomeButton";
import Toast from "../../../../common/ui-components/notification/Toast";
import '../../../../style/admin/customizer.css';
// import Canvas from "./canvas/Canvas";
import Area3DScene from "./canvas/Area3dModel";
import CustomizerHeader from "./canvas/CustomizerHeader";
import ReviewOverlay from "./common/ReviewOverlay";
import Controls from "./controls/Controls";

const Customizer: React.FC<{
  configObj:any
  setIsLoadedPageConfiguration:(val:any)=>void
  isLoadedPageConfiguration:any
  isLogin:boolean
}> = forwardRef((props,ref) => {


  const[areaId,setAreaId] = useState<any>(null)
  const[areaName,setAreaName] = useState<any>(null)
  //initial view 
  const[currentConfigObject,setCurrentConfigObject] = useState(null)
  const[renderCameraSettings,setIsRenderCameraSettings] = useState(Number(new Date()))
  const[textures,setTextures] = useState([])
  const[tourElementsList] = useState(getPageTourElements("Customizer"))
  var projectAreas:Array<any> = []
  // const[canvasList] = useState(generateCanvasElements(30)) 
  const [showAlert, setShowAlert] = useState(false);
 
  const[isProductConfigureMode,setIsProductConfigurationMode] = useState(false)
  const[isKitchenPlannerMode,setIsKitchenPlannerMode] = useState(false)
  const[isIsolationView,setIsIsolationView] = useState(false)
  const[isInteriorVisualization,setIsInteriorVisualization] = useState(false)

  

  useEffect(()=>{
    if(props.configObj){
      props.configObj.setFunctionRef("customizer",{updateArea:updateArea})
      props.configObj.setFunctionRef("customizer",{setIsRenderCameraSettings:setIsRenderCameraSettings})
      props.configObj.setFunctionRef("customizer",{setIsProductConfigurationMode:setIsProductConfigurationMode})
      props.configObj.setFunctionRef("customizer",{setIsProductConfigurationMode:setIsProductConfigurationMode})
      props.configObj.setFunctionRef("customizer",{setIsKitchenPlannerMode:setIsKitchenPlannerMode})
      props.configObj.setFunctionRef("customizer",{setIsIsolationView:setIsIsolationView})
      props.configObj.setFunctionRef("customizer",{setIsInteriorVisualization:setIsInteriorVisualization})
    }
  },[props.configObj])

  useEffect(()=>{
    if(props.configObj){
      createPageConfig()
      setProjectAreas()
    } 
  },[props.configObj])

  useEffect(()=>{
    if(currentConfigObject){
      setProjectMaterials()
      let projectProducts = getFilteredArray(props.configObj?.data.projectAreaProductsList,"project_id",props.configObj.projectId) 
      currentConfigObject.setProjectProductsList(projectProducts)
    }
  },[currentConfigObject,props.configObj])

  useEffect(()=>{
      if(props.isLogin && !props.isLoadedPageConfiguration){
        init()
      }
  },[props.isLogin,props.isLoadedPageConfiguration])

  
  async function init() {
    let currProject = getItemFromLocalStorage("currProject")
    let projectId = currProject.project_id
    let projectName = currProject.project_name
    showComponentLoader("mainLoader")
    updateLoaderProgress("mainLoader",1,1,"Creating config..")
    props.configObj.setCurrArea("all")
    props.configObj.setCurrAreaId(0)

    props.configObj.setProjectName("NewDemo")
    props.configObj.setProjectId(projectId)
    
    let projectConfiguration = new ProjectConfiguration(props.configObj.clientId,props.configObj.clientName,projectId,projectName)
    projectConfiguration.updateLocalStorage()
    setProjectConfigObject(projectConfiguration)
    let isUpdateConfiguration = false
    //If config already exists update the configuration 
    let savedConfigFromDatabase = getFilteredArray(props.configObj.data.customizerSavedConfigs,"project_id",projectId)
    if(savedConfigFromDatabase?.length){
      isUpdateConfiguration = true
    }

    props.setIsLoadedPageConfiguration(projectConfiguration)
    // await waitFor(500)
    // saveConfiguration(isUpdateConfiguration,false).then((data:any)=>{
    //   if(!isUpdateConfiguration){
    //     projectConfiguration.setConfigId(data.data.data.insertId)
    //   }
    // }).catch(err=>{
    //   console.log(err)
    // })
  }

 

  function setProjectMaterials() {
    let materials = []
    materials = getApplicationFiltersMaterials("Visualizer",props.configObj?.data.materials,props.configObj?.data.applicationMaterials)
    materials = getProjectFiltersMaterials(materials,props.configObj?.data.projectMaterials,projectConfiguration?.projectId)
    materials = materials.filter(currMaterial => currMaterial.company_name === "OVL" || currMaterial.company_name === "GreenLam")
    setTextures(materials)
    currentConfigObject.setMaterials(materials)
  }


  function setProjectAreas() {
    projectAreas = props.configObj?.data.projectAreasList.filter((area:any)=>area.project_id===props.configObj.projectId)
  }

  function createPageConfig() {
    let currConfigObj = new CurrentConfig(projectAreas) 
    setCurrentConfigObject(currConfigObj)
  }

 

  function updateArea(area:any){
    props.configObj?.setCurrArea(area.area_name)
    props.configObj?.setCurrAreaId(area.area_id)
    setAreaId(area.area_id)
    setAreaName(area.area_name)
    // updateAreaModel(area)
  }

 
  
  return (
    <IonPage>
      <IonAlert
        isOpen={showAlert}
        mode="ios"
        onDidDismiss={() => setShowAlert(false)}
        cssClass="bg-color-white"
        header={"Confirm!"}
        message={"Go to virtual tour !!"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Configuration not finalized");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              window.history.back();
            },
          },
        ]}
      />

      {props.isLoadedPageConfiguration && props.configObj && currentConfigObject?
      <>
      {/* <SiteTour
        tourElementsList={tourElementsList}
        moduleName={"Customizer"}
      /> */}
      <CustomizerHeader
        configObj={props.configObj}
        currentConfigObject={currentConfigObject}
        isProductConfigureMode={isProductConfigureMode}
        isKitchenPlannerMode={isKitchenPlannerMode}
        isInteriorVisualization={isInteriorVisualization}
      />

      <div className="customize-canvas-controls-wrapper position-relative op-customize-canvas-controls-wrapper" id="mainCustomizerWrapper">
      <Loader className="--is-active"
        id={"canvasLoader"}
        data={{}}
        loaderType="topProgressBar"
      />
        <HomeButton/>
        <div className="customize-canvas-wrapper center-aligned-row shadow-light "
          id="customizeCanvasWrapper">
          <Toast/>
          <div className="canvas-icon-bar">
          <ToggleControlsWrapper position="right" />
          </div>
            <Area3DScene
              configObj={props.configObj}
              currentConfigObject={currentConfigObject}
              areaName={areaName}
              projectConfig={props.isLoadedPageConfiguration}
              isProductConfigureMode={isProductConfigureMode}
              isKitchenPlannerMode={isKitchenPlannerMode}
              isInteriorVisualization={isInteriorVisualization}
            />
        </div>

        <div className="customize-controls-wrapper customize-finish-controls-wrapper white-card" style={{marginTop:"0.07rem"}}>
          
          <DragControlsWrapperIcon/>
          <ReviewOverlay/>
          <Controls
            textures={textures}
            currentConfigObject={currentConfigObject}
            configObj={props.configObj}
            isProductConfigureMode={isProductConfigureMode}
            isKitchenPlannerMode={isKitchenPlannerMode}
          />
        </div>
      </div>

      {/* <div id="cameraViewFrame" className="camera-view-frame display-toggle-wrapper pointer-none full-height padding0 top-left" style={{width:`${cameraSettingViewData.width*100}%`}}>
      </div>
      <div className={`customize-camera-settings-wrapper bottom-left display-flex-row no-wrap display-none`} id="cameraSettingsContainer" style={{background:"transparent"}}>
        <CameraSettings
          currentConfigObject={renderCameraSettings}
        />
      </div> */}
      </>
      :
      <div>Please wait...</div>}

   
    </IonPage>
  );
});

export default Customizer;
