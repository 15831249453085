import { IonSpinner } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../../utils/UI_methods/global";
import { closeControlsPanelAndHideCreateRender } from "../../../../../utils/customizein3d/area3dmodel/UI_methods";
import { resetCreateRenderParameters } from "../../../../../utils/customizein3d/area3dmodel/floorplan/render2d";
import { configuration } from "../../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig, share2DRender, stringWithoutWhiteSpace, waitFor } from "../../../../../utils/methods";
import Modal from "../../../shared-components/Modals/Modal";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../ui-components/buttons/Button2";
import { updateProductTarFile } from "../../../../../utils/products/createproduct";
import Loader from "../../../shared-components/main-ui/Loader";

const Gallery: React.FC<{}> = (props) => {
  const [rendersList2d, setMyImages]: any = useState([]);
  const[isOpenImage,setIsOpenImage] = useState(false)
  const[timestamp,setCurrTimestamp] = useState(Number(new Date()))


  useEffect(()=>{
    applicationConfig.setFunctionRef("customizein3d",{refreshGallery:refreshGallery})
  },[])

  useEffect(() => {
    let productName = configuration.product.productName;
    let albumName = "public";
    var albumPhotosKey = encodeURIComponent(albumName) +"/Renders/" + applicationConfig?.clientName + "/" + productName;
    applicationConfig.awsConfig.viewAlbumContents(albumPhotosKey)
      .then((photos: any) => {
        setMyImages(photos.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }, [applicationConfig,timestamp]);


  function refreshGallery() {
    setCurrTimestamp(Number(new Date()))
  }

  function viewImageHandle(src:string) {
    // setIsViewRenderMode(true)
    setIsOpenImage(true)
    setTimeout(() => {
      displayCameraImage(src,"viewRenderImage")
    }, 1000);
  }

  async function loadViewHandle() {
    showComponentLoader("canvasLoader")
    // setIsViewRenderMode(true)
    // set2dCameraForRender(currCamera)
    resetCreateRenderParameters()
    await waitFor(100)
    closeControlsPanelAndHideCreateRender()
  }

  async function displayCameraImage(src:any,elementId:any = null){
    $("#"+elementId).attr("src",src)
  }

  function shareRenderHandle(src:any) {
    share2DRender("Summary",src)
  }

  function downloadRender(src:any) {
    var link = document.createElement('a');
    link.setAttribute("target", "blank")
    link.href = src
    link.download = String(Number(new Date)) + '.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  function ImageViewer() {
    return(
          <div className='full-height-width display-flex-row no-wrap'>
            <div className='full-width center-aligned-column full-height padding10'>
              <div className='border padding5 center-aligned-column full-height  position-relative'>
                <img id="viewRenderImage" className='full-height' src="" alt="" style={{width:"auto",objectFit:"contain"}} />
                
              </div>
            </div>
            <div className='width30 display-none padding10'>
              <div className='display-flex-row'>

              <button className="op-btn marginleft5 op-btn-primary-inverse op-btn">
                  <i className="fas fa-download font- color-danger" aria-hidden="true"></i> 
                  <span className="letter-spacing text marginleft10" >Download</span>
                </button>

                <button className="op-btn op-btn-primary op-btn marginleft10">
                  <i className="fas fa-share-alt font color-white" aria-hidden="true"></i> 
                  <span className="letter-spacing text color-white marginleft5" >Share</span>
                </button>
                
              </div>
            </div>
            
          </div>
    )
  }



  async function setImageAsDefault(url:string){
    showComponentLoader("setAsDefaultLoader")
    try {
      url = url.replace("https://opusassets.s3.ap-south-1.amazonaws.com/","")
      let categoryName = configuration.product.categoryName
      let subCategoryName = configuration.product.subCategoryName
      let productName = stringWithoutWhiteSpace(configuration.product.productName) 
      let product = STORE.currProduct
      let clientName = applicationConfig?.clientName
      let fileName = productName + ".png"
      
      if(product){
        clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
      }
      // let destination = `productImages/${applicationConfig?.clientName}/${categoryName}/${subCategoryName}/${productName}/${productName}.png`
      const data:any =  await applicationConfig.awsConfig.getObject(url)
      const blob = data.Body;
      // const blob = new Blob([bufferView]);
      // const imageUrl = URL.createObjectURL(blob);
      // let file = applicationConfig.awsConfig.getFileFromCanvasBlob(blob,fileName)
      var file = new File([blob],fileName,{ type: "image/jpeg" })
      // let prefix = stringWithoutWhiteSpace(`productImages/${applicationConfig?.clientName}/${categoryName}/${subCategoryName}/${productName}/`)
      await uploadProductImage(file,categoryName,subCategoryName,productName)
      hideComponentLoader("setAsDefaultLoader")
      showGlobalToast("Success",2000)
    } catch (error) {
      console.log(error)
      showGlobalToast("Error",2000,"error")
    }
  }


  async function uploadProductImage(file:any,categoryName:string,subCategory:string,productName:string) {
    let fileName = productName + ".png"
    let prefix = stringWithoutWhiteSpace(`productImages/${applicationConfig?.clientName}/${categoryName}/${subCategory}/${productName}/`)
    let albumName = "productImages"
    var albumPhotosKey = encodeURIComponent(albumName) + "/" + applicationConfig?.clientName + "/" + categoryName + "/" + subCategory + "/" + stringWithoutWhiteSpace(productName) + "/" 
    var deleteKey = encodeURIComponent(albumName) + "/" + applicationConfig?.clientName + "/" + categoryName + "/" + subCategory + "/" + stringWithoutWhiteSpace(productName) + "/" + stringWithoutWhiteSpace(productName) + ".tar.gz"
    let promises = []
    await applicationConfig.awsConfig.deleteObjectFromS3(deleteKey)
    promises.push(await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,file,fileName)) 
    //Upload images other then default image
    await Promise.all(promises).then(async (data)=>{
      fileName = stringWithoutWhiteSpace(`${productName}.tar.gz`)
      await updateProductTarFile(prefix,fileName) 
    })
  }


  return (
    <div className="display-flex-row">
      <Modal
          onDismiss={()=>setIsOpenImage(false)} 
          isOpen={isOpenImage}
          id=""
          heading='Image viewer'
          classNames='image-viewer-modal'
          FooterContent={()=>(<div></div>)}
          Content={()=>(
            <ImageViewer/>
          )}
        />

        <Loader
          id="setAsDefaultLoader"
          data={{message:"Fetching data"}}
          loaderType="spinner"
          className=""
        />

      {configuration.isCreatingRender?
        <div className='width50 pointer padding10'>
              <div className='render-image center-aligned-column padding5 border' style={{aspectRatio:"4/3"}}>
                <img  src="" width={70} alt="" />
              </div>
              <div className='details padding5'>
                <div className='display-flex-row no-wrap'>
                  <div className='loader'><IonSpinner name="crescent"/></div>
                  <div className='marginleft10'>
                    <div className='heading4 font-small'>Processing render...</div>
                    </div>
                </div>
              </div>
            </div>
      :null}
        
      {rendersList2d.length? rendersList2d?.map((photoKey: any, index: number) => {
            let baseUrl = "https://opusassets.s3.ap-south-1.amazonaws.com/";
            let src = baseUrl+photoKey

            return (
              <div key={index} className="area-item position-relative pointer padding5">
                <div className="render-image position-relative center-aligned-column padding5 border" style={{ aspectRatio: "4/3" }}>
                  <div className=" top-left padding10">
                    <div className="white-card no-shadow font-small heading2 center-aligned-column"
                      style={{ padding: "3px 7px" }}>
                      {index + 1}
                    </div>
                  </div>
                  <img src={`${src}`} className="full-height" style={{ objectFit: "cover" }}/>

                  <div className="middle">
                  <div className='minimap-actions'>
                    <div  onClick={()=>viewImageHandle(src)} className="customize-link border marginTop5 display-flex-row no-wrap">
                      <div className='cener-aligned-column pointer-none'> <i className='fas fa-eye color-white'></i> </div>
                      <div className="color-white pointer-none center-aligned-column text marginleft5">View Render</div>
                    </div>
                  </div>
                </div>
                </div>

                <div className="top-right padding10">
                  <div className='display-flex-row no-wrap padding5'>
                    <div className='padding5'onClick={()=>shareRenderHandle(src)}>
                      <img src={`${applicationConfig.aws_public_url}assets/icon/svg/share.svg`} width={17} className='pointer-none' alt="" />
                    </div>
                    <div className='padding5' onClick={()=>downloadRender(src)}>
                      <img src={`${applicationConfig.aws_public_url}assets/icon/svg/download.svg`} width={17} className='pointer-none' alt="" />
                    </div>
                    <div className='padding5'>
                      <img src={`${applicationConfig.aws_public_url}assets/icon/svg/trash.svg`} width={17} className='pointer-none' alt="" />
                    </div>
                  </div>
                </div>

                {applicationConfig.isMasterLogin()?
                  <div className="bottom-middle">
                    <Button2
                      configObj={{id:""}}
                      action={()=>{setImageAsDefault(src)}}
                      label={"Set As Default"}
                      fontAwesomeIcon={"fas fa-cog color-black"}
                      iconPos=""
                      classNames={"shadow-medium no-border button-ex-small"}
                    />
                  </div>
                :null}

                

                {/* <div className="bottom-right ion-padding">
                  <div
                    className="display-flex-row no-wrap bg-color-white no-shadow"
                    style={{ margin: "5px", padding: "0 2px" }}>
                    <span className="heading3 font-small color-black marginleft5">
                      0 Sec
                    </span>
                  </div>
                </div> */}
              </div>
            );
          })
        : null}
    </div>
  );
};
export default Gallery;
