import React, { useEffect, useState } from "react";
import {
  disableConfigureMode
} from "../../../../../utils/customizein3d/modules/UI_methods";
import {
  appendModel,
  sprites
} from "../../../../../utils/customizein3d/modules/customizein3d";
import { customizein3dTabItems } from "../../../../../utils/data";
import ProductModules from "../../../shared-components/product-modules/ProductModules";
import CloseMenu from "../common/CloseMenu";
import Header from "../common/Header";
import Addons from "./Addons";
import DesktopTabs from "./DesktopTabs";
import Finish from "./Finish";
import MobileTabs from "./MobileTabs";
import SpacePlanner from "./SpacePlanner";
import Gallery from "./Gallery";
import SpritesMaterials from "./SpritesMaterials";

const Controls: React.FC<{
  configObj: any;
  isSpacePlannerMode: boolean;
  currProduct:any
  setIsSpacePlannerMode: (value: boolean) => void;
  isViewProductMode:boolean
  modulesConfiguration:any
}> = (props) => {

  const[tabItems,setTabItems] = useState([])

  useEffect(()=>{
    let updatedTabItems = customizein3dTabItems
    if(props.isViewProductMode){
      updatedTabItems = updatedTabItems.filter(currTab => !currTab.tabName.toLowerCase().includes("addmodules"))
    }
    // if(!props.isViewProductMode){
    //   updatedTabItems = updatedTabItems.filter(currTab => !currTab.tabName.toLowerCase().includes("addons"))
    // }
    if(!props.modulesConfiguration.allowSpacePlanner){
      updatedTabItems = updatedTabItems.filter(currTab => !currTab.tabName.toLowerCase().includes("roomlayout"))
    }
    if(!props.modulesConfiguration.allowFinish){
      updatedTabItems = updatedTabItems.filter(currTab => !currTab.tabName.toLowerCase().includes("finish"))
    }
    if(!props.modulesConfiguration.allowAddons){
      updatedTabItems = updatedTabItems.filter(currTab => !currTab.tabName.toLowerCase().includes("addons"))
    }
    setTabItems(updatedTabItems)
  },[props.isViewProductMode,props.modulesConfiguration])


 
  return (
    <React.Fragment>
      <DesktopTabs
        tabItems={tabItems}
        setIsSpacePlannerMode={props.setIsSpacePlannerMode}
        isSpacePlannerMode={props.isSpacePlannerMode}
        configObj={props.configObj}
      />
        <div className="customize-product-options position-relative full-height-width">


        <MobileTabs
          configObj={props.configObj}
          tabItems={tabItems}
          setIsSpacePlannerMode={props.setIsSpacePlannerMode}
          isSpacePlannerMode={props.isSpacePlannerMode}
        />

        {!props.modulesConfiguration.isViewProductMode?
        <div id="addModulesContainer" className="--is-active tab-content position-relative">
          <Header heading="Add Modules" configObj={props.configObj} />
          <ProductModules
            configObj={props.configObj}
            productId={props.modulesConfiguration.product?.productId}
            moduleRef={{}}
            action={(module: any) => appendModel(module, false)}
            moduleName="customizein3d"
            dragStart={()=>{}}
            parentContainerId={"addModulesContainer"}
          />
        </div>
        :null}

        {props.modulesConfiguration.allowSpacePlanner?
          <div id="roomLayoutContainer" className="tab-content full-width padding0 top-left">
            <div className="heading2 color-black bg-color-active position-relative zindex1 padding1rem text-center shadow-light border-bottom">
            Room Layout
            </div>
            <SpacePlanner
              configObj={props.configObj}
            />
          </div>
        :null}

        {props.modulesConfiguration.allowFinish?
          <div id="finishContainer" className="tab-content  customizein3d-leftside-wrapper">
            <Header heading="Choose Finish" configObj={props.configObj} />
            <Finish
              configObj={props.configObj}
            />
          </div>
        :null}

          
        {props.modulesConfiguration.allowAddons?
          <div id="addonsContainer" className="tab-content customizein3d-leftside-wrapper">
            <Header heading="Addons" configObj={props.configObj} />
            <Addons configObj={props.configObj} />
          </div>
        :null}

        <div id="rendersGalleryContainer" className="tab-content full-width customizein3d-leftside-wrapper top-left">
            <div className="heading2 color-black bg-color-active position-relative zindex1 padding1rem text-center shadow-light border-bottom">
            Gallery
            </div>
            <Gallery/>
        </div>

          <CloseMenu
            heading="Back to Menu"
            action={disableConfigureMode}
          />

        </div>
    </React.Fragment>
  );
};

export default Controls;
