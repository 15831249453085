
import $ from "jquery";
import { setTimeout } from 'timers';
import { awsLambdaApiTrigger, getShareLink, uploadGeneratedModelToS3 } from '../../services/api';
import { clearProgressBarInterval, hideComponentLoader, showComponentLoader, showToast, updateLoaderProgress } from '../UI_methods/global';
import { camera as cameraClass } from "../customizer/ProjectConfiguration";
import { applicationConfig, logger, stringWithoutWhiteSpace, waitFor } from '../methods';
import { Configuration } from "./Configuration";
import { cameraFor2dRender, renderSettingConfig } from "./area3dmodel/floorplan/render2d";
import { camera, configObj, configuration, group, renderer, scene } from './modules/customizein3d';
import { saveConfiguration } from "./modules/helper";
import { Quaternion, Scene, WebGLRenderer } from "three";
import { ARButton } from "three/examples/jsm/webxr/ARButton";
import { STORE } from "../store/storeConfiguration";


export async function testAr() {
    showComponentLoader("canvasLoader")
    let url = "https://opusassets.s3.ap-south-1.amazonaws.com/public/models/DemoClient2/ZinCorner/ZinCorner_L5_C1_C1_C_C1_C1_R5.glb"
    let modelUrl = `<a href="intent://arvr.google.com/scene-viewer/1.0?file=${url}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://ovlapps.com/error;end;">Avocado</a>;`
    $("#sceneViewer").attr("href", modelUrl);
    setTimeout(() => { 
        hideComponentLoader("canvasLoader")
        document.getElementById("sceneViewer").click() 
    }, 3000);
}


export function enableModelViewer(url:string,parentContainerId:string,isWebXr:boolean = false) {
  showComponentLoader("canvasLoader")
  $(".model-viewer-element").remove()
  let container =  $("#"+parentContainerId) 

  if(!container){
    showToast("Try again",2000,"error")
    return
  }
  let element = $(`<model-viewer src="${url}" auto-rotate="false" tone-mapping="neutral" environment-image="legacy" shadow-intensity='1' disable-pan max-camera-orbit="Infinity 90deg auto" camera-orbit="auto auto 4m" shadow-softness='0.5'  id="modelViewerElement" ar ar-modes="scene-viewer quick-look" camera-controls class='scene-viewer model-viewer-element'></model-viewer>`)
  let arButton = $(`<button slot="ar-button" class="button2 bg-color-primary zindex100 display-none white-card shadow-light font-md-small button-rounded top-middle marginTop5 heading3 display-flex-row no-wrap" id="ar-button"> <span class="center-aligned-column"> <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/icon/svg/cube_white.svg" width="25" /> </span><span class="center-aligned-column heading2 marginleft5 font-normal color-white text">View in your space</span></button>`)
  container.append(element)
  element.append(arButton)

  const MODEL_VIEWER:any = document.querySelector("model-viewer#modelViewerElement");
  if(MODEL_VIEWER){
    MODEL_VIEWER.src = url
    MODEL_VIEWER.modelCacheSize = 0
    MODEL_VIEWER.addEventListener("load",function(){
      arButton.removeClass("display-none")
      hideComponentLoader("modelViewerLoader")
    })
  }else{
    alert("Try again to load the model")
  }
  return MODEL_VIEWER
}

export function enableModelViewerForSceneViewer(url:string,parentContainerId:string) {
  showComponentLoader("canvasLoader")
  $("#modelViewerElement").remove()
  let container =  $("#"+parentContainerId) 
  if(!container){
    showToast("Try again",2000,"error")
    return
  }
  let element = $(`<model-viewer src="${url}" auto-rotate="false" tone-mapping="neutral" environment-image="legacy" shadow-intensity='1' disable-pan max-camera-orbit="Infinity 90deg auto" camera-orbit="auto auto 4m" shadow-softness='0.5'  id="modelViewerElement" ar ar-modes="scene-viewer quick-look" camera-controls class='scene-viewer'></model-viewer>`)
  let arButton = $(`<button slot="ar-button" class="button2 bg-color-primary zindex100 display-none white-card shadow-light font-md-small button-rounded top-middle marginTop5 heading3 display-flex-row no-wrap" id="ar-button"> <span class="center-aligned-column"> <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/icon/svg/cube_white.svg" width="25" /> </span><span class="center-aligned-column heading2 marginleft5 font-normal color-white text">View in your space</span>  </button>`)
  
  element.append(arButton)
  container.append(element)

  const MODEL_VIEWER:any = document.querySelector("model-viewer#modelViewerElement");
  if(MODEL_VIEWER){
    MODEL_VIEWER.src = url
    MODEL_VIEWER.modelCacheSize = 0
    MODEL_VIEWER.addEventListener("load",function(){
      arButton.removeClass("display-none")
      hideComponentLoader("modelViewerLoader")
    })
  }else{
    alert("Try again to load the model")
  }
  return MODEL_VIEWER
}


export function enableSceneViewer(url:string) {
  showComponentLoader("canvasLoader")
  let productName = applicationConfig.productName || ""
  updateLoaderProgress("canvasLoader",1,1,"Launching AR..")
    let modelUrl = `intent://arvr.google.com/scene-viewer/1.0?file=${url}&mode=ar_preferred&disable_occlusion=true&title=${productName}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://ovlapps.com/error;end;`
    $("#sceneViewer").attr("href", modelUrl);
    setTimeout(() => { 
        hideComponentLoader("canvasLoader")
        document.getElementById("sceneViewer").click() 
    }, 3000);
}



export async function viewInAr() {

  showComponentLoader("canvasLoader")
  // updateLoaderProgressWithInterval("canvasLoader","Creating model")
  updateLoaderProgress("canvasLoader",1,1,"Please wait")
  return new Promise(async (resolve,reject)=>{
    await generateModel(configuration.product,configuration,configuration.modules.concat(configuration.addons),true,null).then((url:string)=>{
      updateLoaderProgress("canvasLoader",1,1,"Please wait")
      // clearProgressBarInterval()
      hideComponentLoader("canvasLoader")
      resolve(url)
    }).catch(err=>{
      hideComponentLoader("canvasLoader")
      reject(err)
    })
  })
 
}


export function selectARViewer(url:string) {
   let key = url.substring(url.indexOf("public"))
    applicationConfig.awsConfig.getObject(key).then(data=>{
      let fileSize = data.ContentLength
      if(fileSize >= 3145728){
        enableModelViewer(url,"enableModelViewer")
      }else{
        enableSceneViewer(url)
      }
      hideComponentLoader("canvasLoader")
    }).catch(err=>{
      console.log(err)
      hideComponentLoader("canvasLoader")
    })
}

export async function generateModelLink() {
  logger?.info("customizein3d","AR -"+configuration?.product?.productName)
  showComponentLoader("canvasLoader")

  
  return new Promise((resolve,reject)=>{
    generateModel(configuration.product,configuration,configuration.modules.concat(configuration.addons),true,null).then((url:string)=>{
      let  data={
        moduleName:"product-viewer",
        shareItemId:[0],
        collectionNames:JSON.stringify({url:url}),
        clientId:configObj?.clientId,
        clientName:configObj?.clientName,
        tokenValidity: 1/24 // default 1 hr
      }
      getShareLink(data).then(data=>{
        let shareLink = window.location.origin + "/product-viewer?token=" + data.data.data.token
        resolve(shareLink)
      }).catch(err=>{
        console.log(err)
        reject(err)
      })
      hideComponentLoader("canvasLoader")
    }).catch(err=>{
      console.log(err)
      hideComponentLoader("canvasLoader")
    })
  })
}

export async function getModelLinkForSceneViewer() {
  return new Promise((resolve,reject)=>{
    generateModel(configuration.product,configuration,configuration.modules.concat(configuration.addons),true,null).then((url:string)=>{
      resolve(url)
    }).catch(err=>{
      console.log(err)
      hideComponentLoader("canvasLoader")
    })
  })
}


export async function generateModel(product:any,moduleConfiguration:Configuration,modules:Array<any>,isProductRender:boolean,debugOptions:any,isDefaltModel:boolean = false){
  moduleConfiguration.updateConfigHash()
  moduleConfiguration.updateModulesPositionRotation(group)

  await waitFor(200)

  let clientName = applicationConfig?.clientName
  let storefront_name = STORE?.currProduct?.storefront_name
  if(storefront_name){
    clientName = storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
  }

 
 
  const timestamp = Number(new Date())
  const requestId = `${clientName}${timestamp}`
  const configId = moduleConfiguration.getConfigId(moduleConfiguration.product.productName)
 


  let arConfig = new ARConfig(product,requestId,configId,moduleConfiguration.configName,moduleConfiguration.configHash,modules,moduleConfiguration.isProductRender,"CreateModel.py",renderSettingConfig,cameraFor2dRender)
  
  arConfig.configId = String(moduleConfiguration.configId)
  arConfig.configName = String("Default_"+moduleConfiguration.configId)
  arConfig.clientName = clientName
  arConfig.ClientName = clientName
  arConfig.isProductRender = !isProductRender

  if(debugOptions){
    arConfig.renderConfig.setDimensions(renderSettingConfig.getResolutionX(),renderSettingConfig.getResolutionY())
    arConfig.renderConfig.setPercentageResolution(Number(debugOptions.percentageResolution))
    arConfig.renderConfig.setNoOfSamples(Number(debugOptions.noOfSamples))
  }

  arConfig.additionalConfiguration = {
    currSofaLeg:moduleConfiguration.currSofaLeg
  }

  // let rotation = new Quaternion()
  // camera.getWorldQuaternion(rotation)
  // arConfig.camera.transform.rotation = camera.rotation

  
  // if(!arConfig.camera){
  //   arConfig.camera = new cameraClass("","2d")
  // }

  console.log(arConfig)
  
  return new Promise(async(resolve,reject)=>{
    // updateLoaderProgress("canvasLoader",0,1,"Creating model")
    await awsLambdaApiTrigger(arConfig,"createmodel").then(async data=>{
      // updateLoaderProgressWithInterval("canvasLoader","Creating model",180)
      // updateLoaderProgress("canvasLoader",3,4,"Creating model...")
      await applicationConfig.awsConfig.pollForGenerateModelMessages(requestId)
      clearProgressBarInterval()
      // updateLoaderProgress("canvasLoader",4,4,"Please wait...")
      await uploadGeneratedModelToS3(arConfig).then(async uploadRenderData=>{
        let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/models/${clientName}/${encodeURIComponent(arConfig.category)}/${encodeURIComponent(arConfig.subCategory)}/${encodeURIComponent(arConfig.productName)}/${arConfig.configHash}/${arConfig.configId}.glb`
        //Only if  the function is calling from configurator 
        if(configuration){
          await saveConfiguration(isDefaltModel,url)
        }
        moduleConfiguration.setIsConfigChanged(false)
        showToast("Saved successfully",2000)
        resolve(url)
      }).catch(err=>{
        console.log(err)                            
        reject(err)
      })
    }).catch(err=>{
      console.log(err)
      reject(err)
    })
  })


}


export function generateModelFromExporter() {
  return new Promise((resolve,reject)=>{
    showComponentLoader("canvasLoader")
    let url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/models/${applicationConfig?.clientName}/${configuration?.product.categoryName}/${configuration?.product.subCategoryName}/${configuration?.product.productName}.glb`
    resolve(url)
    // getGlbFileFromArrayBuffer(group).then(file=>{
    //   let fileName = String(Number(new Date())) + ".glb"
    //   let albumName = "public"
    //   let albumPhotosKey = encodeURIComponent(albumName) + "/models/" + encodeURIComponent(applicationConfig?.clientName) + "/" + encodeURIComponent(configuration?.product.productName || "DemoProduct") + "/";
    //   applicationConfig.awsConfig.uploadFile(null,"",albumPhotosKey,fileName,file).then(url=>{
       
    //       hideComponentLoader("canvasLoader")
    //       resolve(url)
    //     }).catch(err=>{
    //       hideComponentLoader("canvasLoader")
    //       reject(err)
    //   })
    // })
  })
}


export class WebXr{

  scene:Scene
  renderer:WebGLRenderer
  overlayContent:any
  

  constructor(scene:Scene,renderer:WebGLRenderer){
    this.scene = scene
    this.renderer = renderer
  }

  init(){
    this.overlayContent = document.getElementById('webxr-content')
    let config = {
      // requiredFeatures: ['hit-test'],
      optionalFeatures: ['depth-sensing'],
      // domOverlay: { root: this.overlayContent }
    }
    document.body.appendChild(ARButton.createButton(renderer));
    
    let controller = renderer.xr.getController(0);
    controller.addEventListener('select', this.onSelect);
    scene.add(controller)
    // controller1.addEventListener( 'selectstart', onSelectStart );
    // controller1.addEventListener( 'selectend', onSelectEnd );
    controller.addEventListener( 'connected', function ( event ) {
      console.log("Connected")
    } );

    // controller1.addEventListener( 'disconnected', function () {
    //   this.remove( this.children[ 0 ] );
    // } );

  }

  onSelect() {
      console.log("selected now")
      console.log(scene)
  }
  
}


export class ARConfig{
  requestId = ""

  ClientName = applicationConfig?.clientName
  clientName = applicationConfig?.clientName
  // L2_R1
  configId:string = ""

  configName:string = ""
  ConfigHash:string = ""
  configHash:string = ""
  productId:number = 0
  productName:string = ""
  category:string = ""
  subCategory:string = ""
  modules:Array<any> = null
  isProductRender:boolean = false
  fileName = ""

  additionalConfiguration:any = {}

  renderConfig =  null
  camera = null

  constructor(product:any,requestId:string,configId:string,configName:string,configHash:string,modules:Array<any>,isProductRender:boolean,fileName:string,renderConfig:any,camera:cameraClass){
    this.requestId = requestId
    this.configId = configId
    this.configName = configName
    this.configHash = configHash
    this.ConfigHash = configHash
    this.productId = product.product_id || product.productId
    this.productName = stringWithoutWhiteSpace(product.product_name || product.productName)
    this.category = stringWithoutWhiteSpace(product.category_name || product.categoryName)
    this.subCategory = stringWithoutWhiteSpace(product.sub_category_name || product.subCategoryName)
    this.modules = modules
    this.isProductRender = isProductRender
    this.fileName = fileName
    this.renderConfig = renderConfig
    this.camera = camera
  }
}
