import React, { useEffect, useState } from "react";
import { filterUIElements, hideComponentLoader } from "../../../../utils/UI_methods/global";
import { loadModuleImages } from "../../../../utils/customizein3d/UImethods";
import { isDeviceIpadPotrait } from "../../../../utils/customizein3d/modules/UI_methods";
import { getProductModuleUrl } from "../../../../utils/customizein3d/modules/helper";
import { kitchenModuleypesModules } from "../../../../utils/kitchenplanner/data";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getObjectByParameter, stringWithoutWhiteSpace } from "../../../../utils/methods";
import { addEventListenerOnImages } from "../../../../utils/moodboard/helper";
import HorizontalScroll from "../../ui-components/scrollbar/HorizontalScroll";
import ControlsTab from "../../ui-components/tabs/ControlsTab";
import Loader from "../main-ui/Loader";
import { STORE } from "../../../../utils/store/storeConfiguration";


const ProductModules: React.FC<{
  configObj: any;
  productId:any
  moduleRef:any
  action:(module:any)=>void
  moduleName:string
  dragStart:(event:any)=>void
  parentContainerId:string
  productModulesList?:Array<any>
  isAddonsOnly?:boolean
}> = (props) => {

  
  // const[modules,setModules]:any = useState([])
  const[productModules,setProductModules]:any = useState(props.productModulesList || [])
  const[otherProductModules,setOtherProductModules]:any = useState(props.productModulesList || [])

  const[currModuleType,setCurrModuleType] = useState("")
  const[product]:any = useState(getObjectByParameter(props.configObj.data.productsList,"product_id",props.productId))

  const[moduleImagesLoader] = useState("ModuleImagesLoader"+props.parentContainerId||"")

  const[currKitchenModuleType,setCurrKitchenModuleType] = useState("All")
  const[kitchenSubModuleTypes,setKitchenSubModuleTypes] = useState([])

  const[addonsCategories] = useState(["Sofa","Tables","Decor","Ottoman","Pillow","Chairs"])
  const[currAddonCategory,setCurrAddonCategory] = useState("Sofa")

  const[moduleTypeTabs] = useState([{tabName:"Sectional",label:"Sectional"},{tabName:"Standalone",label:"Standalone"}])
  const[tabItems,setTabItems] = useState([])
  const[currModuleTypeType,setCurrModuleTypeType] = useState(props.isAddonsOnly?"Standalone":"Sectional")

  const[isProductHasStandalone] = useState(!props.isAddonsOnly && (product?.product_name.includes("Diego") || product?.sub_category_name.includes("Recliner")))



  useEffect(()=>{
    if(props.moduleName === "kitchen"){
      setKitchenSubModuleTypes(kitchenModuleypesModules[currModuleType] || [])
    }
  },[currModuleType,props.moduleName])

 


  useEffect(()=>{
    if(props.moduleName.toLowerCase() != "customizein3d"){
      addEventListenerOnImages(".module-image")
    }
    if(productModules.length){
      let items = getArrayOfDistinctValues(productModules,"module_type")
      let tabList = []

      if(props.moduleName !== "kitchen"){
        items = items.filter(item=>item !== "Base" && item !== "Wall" && item !== "High")
       
        items = getSortedTabs(items)
      }else{
        items = items.filter(item=>item !== "Left" && item !== "Centre" && item !== "Corner")
      }
      for (const currItem of items) {
        tabList.push({tabName:currItem,label:currItem})
      }
      setTabItems(tabList)
    }else{
      setTabItems([])
    }
  },[productModules,product])

  function getSortedTabs(items:any){
    let subCategory = product.sub_category_name || product.subCategoryName 
    subCategory = stringWithoutWhiteSpace(subCategory.toLowerCase()) 
    items.sort((a,b)=>a>b?1:-1)
    if(subCategory === "lshape"){
      items.splice(0,0,"Left")
      let indexOfLeft = items.lastIndexOf("Left")
      items.splice(indexOfLeft,1)

      if(items.includes("DoubleSeater")){
        items = items.filter(currItem => currItem !== "DoubleSeater")
        items.push("DoubleSeater")
      }
      
      if(items.includes("ThreeSeater")){
        items = items.filter(currItem => currItem !== "ThreeSeater")
        items.push("ThreeSeater")
      }
    }
    if(subCategory === "setsofas"){
      items.splice(0,0,"SingleSeater")
      let indexOfLeft = items.lastIndexOf("SingleSeater")
      items.splice(indexOfLeft,1)
    }
    return items
  }
  

  useEffect(()=>{
    let filteredArray = []
    let filteredOtherModulesArray = []

    if(props.productModulesList){
      filteredArray = props.productModulesList
    }else{
      filteredArray = getFilteredArray(props.configObj.data.productModules,"product_id",props.productId)
    }
    if(filteredArray && filteredArray.length){
      if(currModuleTypeType === "Standalone"){
        filteredArray = props.configObj.data.productModules.filter(currModule => currModule.module_type === "Standalone" || currModule.is_addon)
        let productIdsList = getArrayOfDistinctValues(applicationConfig?.data?.productsList,"product_id")
        filteredArray = filteredArray.filter(currModule => productIdsList.includes(currModule.product_id))
        if(isProductHasStandalone){
          filteredArray = filteredArray.filter(currModule => currModule.product_id === props.productId)
        }
      }else{
        if(isProductHasStandalone){
          filteredArray = filteredArray.filter(currModule => currModule.module_type !== "Standalone" && !currModule.is_addon)
        }else{
          filteredArray = filteredArray.filter(currModule => currModule.module_type !== "Standalone")
        }
        
      }

      if(STORE?.getCurrCategory() === "Wardrobe"){
        filteredOtherModulesArray = props.configObj.data.productModules.filter(currModule => currModule.product_id !== props.productId && currModule.category_name === STORE?.getCurrCategory() && currModule.product_name !== "Wardrobe2")
      }


      setProductModules(filteredArray)
      setOtherProductModules(filteredOtherModulesArray)

      if(props.moduleName === "kitchen"){
        setCurrModuleType("Base")
      }else{
        setCurrModuleType(filteredArray[0]?.module_type)
      }
    }else{
      setProductModules([])
      setOtherProductModules([])
    }
   
  },[props.productId,props.moduleRef,currModuleTypeType])

  useEffect(()=>{
    if(productModules.length){
      loadModuleImages(moduleImagesLoader,productModules,product?.category_name,props.parentContainerId||"module_")
    }else{
      hideComponentLoader(moduleImagesLoader)
    }
  },[productModules,product])


  //For updating the tab content 
  useEffect(()=>{
    filterUIElements(".module-container","data-module-type",currModuleType,props.parentContainerId)
    setTimeout(() => {
      hideComponentLoader(moduleImagesLoader)
    }, 600);
  },[currModuleType])


 

  function HintText(){
    return(
      <div className="middle heading4 font-normal">
          No modules available
      </div>
     
    )
  }

  

  function kitchenModuleTypes() {
    return(
        <>
          <div className={currKitchenModuleType?.toLocaleLowerCase()==="all"?"marginleft10 marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} style={{padding:"5px 10px",borderRadius:"20px"}}>
              <div className='heading4 font-small text-center'  onClick={()=>setCurrKitchenModuleType("all")}>{"All"}</div>
          </div>
          {kitchenSubModuleTypes.map((subCategory:any,index:number)=>{
            return(
              <div className={currKitchenModuleType===subCategory?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrKitchenModuleType(subCategory)}>{subCategory}</div>
              </div>
            )
          })}
        </>
    )
  }

  
  function AddonsCategories() {
    return(
        <>
          {addonsCategories.map((category:any,index:number)=>{
            return(
              <div className={currAddonCategory===category?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrAddonCategory(category)}>{category}</div>
              </div>
            )
          })}
        </>
    )
  }

  function ModuleTypeTypes() {
    return(
        <>
          {moduleTypeTabs.map((currTab:any,i:number)=>{
            return(
              <div className={`marginleft10 pointer ${currModuleTypeType === currTab.tabName?"bg-color-warning color-white":"border bg-color-light  color-black"}`} key={i} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrModuleTypeType(currTab.tabName)}>{currTab.label}</div>
              </div>
            )
          })}
        </>
    )
  }

  function ModuleTypes() {
    return(
        <>
          {tabItems.map((currTab:any,i:number)=>{
            return(
              <div onClick={()=>setCurrModuleType(currTab.tabName)} className={`marginleft5 pointer ${currModuleType === currTab.tabName?"bg-color-warning color-white":"border bg-color-light  color-black"}`} key={i} style={{padding:"5px 10px",borderRadius:"5px",whiteSpace:"nowrap",minWidth:"6rem"}}>
                <div className='heading4 text-ellipsis font-small text-center pointer-none' >{currTab.label}</div>
              </div>
            )
          })}
        </>
    )
  }

  function OtherModules() {
    return(
      <div className="full-width" >
        <div className="heading3 text-center padding5 border-bottom color-danger border-top">More Options</div>
        <div className="display-flex-row" style={{alignContent:"baseline"}}>
            {otherProductModules?.map((module:any,index:number)=>{
              let key = getProductModuleUrl(props.configObj?.BASE_URL_ASSETS,props.configObj?.clientName,module.module_name,product?.category_name,module.sub_category_name)
              return(
                <div className={`module-container click-effect center-aligned-column ${isDeviceIpadPotrait()?" --is-potrait-mode":""}`} 
                  data-product-name={module.module_name}
                    data-product-category={module.category_name}
                    data-product-subcategory={module.sub_category_name || "Default"}
                    data-sub-module-type={module.sub_module_type || "Default"}
                    data-product-price={module.price || 0}
                    data-product-id={module.module_id}
                    data-image-type="module"
                    
                    onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                    draggable={props.moduleName === "kitchen"?"true":"false"}

                    id={(props.parentContainerId||"module_")+key} 
                    data-module-type={module.module_type} 
                    key={index} 
                    onClick={(event:any)=>{
                    if(props.moduleName === "moodboard"){
                      props.action(event)
                    }else{
                      props.action(module)
                    }
                }}>
                  <img 
                    
                    data-product-name={module.module_name}
                    data-product-category={module.category_name}
                    data-product-subcategory={module.sub_category_name || "Default"}
                    data-sub-module-type={module.sub_module_type || "Default"}
                    data-product-price={module.price || 0}
                    data-module-type={module.module_type} 
                    data-product-id={module.module_id}
                    data-image-type="module"
                    
                    onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                    className="module-image zindex1"
                    draggable="false"

                    src="assets/images/placeholderModules.png" 
                    alt="" style={{padding:"1rem",height:"8rem",objectFit:"contain"}} 
                  />
                  <div className="center-aligned-row text-center module-details pointer-none store-color-primary heading3"> {currModuleTypeType === "Standalone"?`${module.product_name}`:`${module.display_name}` }</div>
                  <div className="center-aligned-row text-center module-details pointer-none heading4 font-small">{module.module_description}</div>
                </div>
              )
            })}
      </div>
      </div>
    )
  }

 
  return (
    <div className="product-modules-wrapper full-width position-relative" id={`${props.parentContainerId}`}>
      <Loader
        loaderType="spinner"
        className="fade-background-loader --is-active zindex1"
        id={moduleImagesLoader}
        data={{}}  
      />
 
      <div className="full-height full-width product-module-container" id="productModulesWrapper">

        
      {currModuleTypeType === "Addons"?
          <div className="marginTop5 marginBottom5">
            <HorizontalScroll
              itemsClass="sub-categories-container"
              Component={AddonsCategories}   
              itemsList={kitchenSubModuleTypes}
              id={`AddKitchenModulesScrollBar`}     
            />
        </div>
        :null}


        {/* <ControlsTab
          configObj={props.configObj}
          tabItems={moduleTypeTabs}
          currSelectedValue={currModuleTypeType}
          action={setCurrModuleTypeType}
          theme="black"
        /> */}

        {isProductHasStandalone?
          <div className="marginTop5 full-width center-aligned-row">
            <ModuleTypeTypes
            />
          </div>
        :null}


       

        

        {productModules.length?
        <div className="full-width marginTop5">
         {tabItems.length <= 3?
            <ControlsTab
              configObj={props.configObj}
              tabItems={tabItems}
              currSelectedValue={currModuleType}
              action={setCurrModuleType}
              theme="red"
            />
          :
            <HorizontalScroll
              itemsClass="sub-categories-container"
              Component={ModuleTypes}   
              itemsList={tabItems}
              id={`modulesTypes`}     
            />
          }
        </div>
        :
          <HintText/>
        }


        {props.moduleName === "kitchen"?
        <div className="marginTop5 marginBottom5">
          <HorizontalScroll
            itemsClass="sub-categories-container"
            Component={kitchenModuleTypes}   
            itemsList={kitchenSubModuleTypes}
            id={`AddKitchenModulesScrollBar`}     
          />
        </div>
         
        :null}

        
        <div className="display-flex-row full-height  overflow-y-scroll" style={{alignContent:"baseline",paddingBottom:"12rem"}}>
              {productModules?.map((module:any,index:number)=>{
              let key = getProductModuleUrl(props.configObj?.BASE_URL_ASSETS,props.configObj?.clientName,module.module_name,product?.category_name,module.sub_category_name)
              return(
                <div className={`module-container click-effect center-aligned-column ${isDeviceIpadPotrait()?" --is-potrait-mode":""}`} 
                  data-product-name={module.module_name}
                    data-product-category={module.category_name}
                    data-product-subcategory={module.sub_category_name || "Default"}
                    data-sub-module-type={module.sub_module_type || "Default"}
                    data-product-price={module.price || 0}
                    data-product-id={module.module_id}
                    data-image-type="module"
                    
                    onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                    draggable={props.moduleName === "kitchen"?"true":"false"}

                    id={(props.parentContainerId||"module_")+key} 
                    data-module-type={module.module_type} 
                    key={index} 
                    onClick={(event:any)=>{
                    if(props.moduleName === "moodboard"){
                      props.action(event)
                    }else{
                      props.action(module)
                    }
                }}>
                  <img 
                    
                    data-product-name={module.module_name}
                    data-product-category={module.category_name}
                    data-product-subcategory={module.sub_category_name || "Default"}
                    data-sub-module-type={module.sub_module_type || "Default"}
                    data-product-price={module.price || 0}
                    data-module-type={module.module_type} 
                    data-product-id={module.module_id}
                    data-image-type="module"
                    
                    onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                    className="module-image zindex1"
                    draggable="false"

                    src="assets/images/placeholderModules.png" 
                    alt="" style={{padding:"1rem",height:"8rem",objectFit:"contain"}} 
                  />
                  <div className="center-aligned-row text-center module-details pointer-none store-color-primary heading3"> {currModuleTypeType === "Standalone"?`${module.product_name}`:`${module.display_name}` }</div>
                  <div className="center-aligned-row text-center module-details pointer-none heading4 font-small">{module.module_description}</div>
                </div>
              )
            })}

            {otherProductModules.length?
              <OtherModules/>
            :null}
       
        </div>
   
      </div>

   


    </div>
  );
};

export default ProductModules;
