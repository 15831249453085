import $ from "jquery";
import React from 'react';
import { Dimensions } from '../../../../../utils/customizein3d/Dimensions';
import { dimensions as productDimensions } from "../../../../../utils/customizein3d/modules/customizein3d";
import Button2 from '../../../ui-components/buttons/Button2';
import { dimensions } from "../../../../../utils/customizein3d/area3dmodel/area3dModel";

// import './Home.css';

const DimensionToggle: React.FC<{
    dimensions:Dimensions
}> = (props) => {


    function convertDim(event:any) {
        $(".dim-option").removeClass("--is-active")
        $(event.target).addClass("--is-active")
        props.dimensions.convertDimensions($(event.target).attr("data-unit-name"))
    }

    function clickHandle() {
        if(productDimensions){
            productDimensions?.toggleDimension()  
        }
        // if(props.dimensions){
        //     props.dimensions.toggleDimension()
        // }
        if(dimensions){
            dimensions.toggleDimension()
        }
    }


    return(
        <>
            <Button2
                configObj={{id:"dimensionToggle"}}
                action={()=>clickHandle()}
                label={"Dimensions"}
                fontAwesomeIcon={"far fa-clipboard color-primary"}
                iconPos="left"
                classNames={"shadow-medium width-auto button-small hide-in-panel-open"}
                svgUrl="assets/icon/svg/measure_danger.svg"
                isPreventDefault={true}
            />

            {/* <Button2
                configObj={{id:""}}
                action={showUnitOptions}
                label={currUnit === "feet"?"ft":currUnit === "inch"?"in":currUnit}
                fontAwesomeIcon={"fas fa-ruler-combined font-small color-danger"}
                iconPos="left"
                classNames={"shadow-medium button-small border-primary"}
            /> */}

            {/* <div className="dim-options display-flex-row">
                <div className="dim-option --is-active stop-propagation" onClick={convertDim} data-unit-name="feet">Ft&Inch</div>
                <div className="dim-option stop-propagation" onClick={convertDim} data-unit-name="inch">Inch</div>
                <div className="dim-option stop-propagation" onClick={convertDim} data-unit-name="cm">Cm</div>
            </div> */}
 
            {/* <svg id="dimLines" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="dimensionLineContainer">
                <line className="dimensionLine">
                </line>
                <line className="dimensionLine"></line>
                <line className="dimensionLine"></line>
                <line className="dimensionLine"></line>
                <line className="dimensionLine"></line>
            </svg> */}
        </>
    );
};
 
export default DimensionToggle;
