import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { getRandomString, stringWithoutWhiteSpace } from "../../../../utils/methods";
import { loadAndDisplayProductImage } from "../../../../utils/store/UI_methods";
import Loader from "../../shared-components/main-ui/Loader";

const ProductImage: React.FC<{
  product:any
  dragStart?:any
  classNames?:string
}> = (props) => {

  const imageRef = useRef(null)


  const[imageId]  = useState(`${stringWithoutWhiteSpace(props.product?.product_name || props.product?.productName)}_${getRandomString(10)}`)

  useEffect(()=>{
    if(imageRef){
      setTimeout(() => {
        $(imageRef.current).attr("src","")
        loadAndDisplayProductImage(imageRef.current,props.product)
      }, 200);
    }
  },[props.product,imageRef])


  return (
    <>
    {props.product?
      <>
      <img ref={imageRef} 
      data-product-category={props.product?.category_name || props.product?.categoryName} 
      data-category={props.product?.category_name || props.product?.categoryName} 
      data-subcategory={props.product?.sub_category_name || props.product?.subCategoryName} 
      data-product-subcategory={props.product?.sub_category_name || props.product?.subCategoryName} 
      data-productname={props.product?.product_name || props.product?.productName} 
      data-product-price={props.product?.price}
      data-product-id={props.product?.product_id}
      id={imageId} 
      onDragStart={props.dragStart}
      draggable={props.dragStart?"true":"false"}
      className={`product-image pointer-none ${props.classNames || ""}`} 
      />
      <Loader
        className={`--is-active fade-background-loader`}
        id={props.product?.product_name}
        data={{ isAnimated: true }}
        loaderType="dots"
      />
    </>
    :
    null
    }
    
    
    </>
    
  );
};

export default ProductImage;
