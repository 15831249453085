import React, { useState } from "react";
import { applicationConfig, logoutHandle } from "../../../../../../utils/methods";
import ActivityLogs from "../modules/leads/leadslist/header/ActivityLogs";

 
const UserOptions: React.FC<{
}> = (props) => {



  const[isOpen,setIsOpen] = useState(false)
  
 
  return (
    <div className='header-user-options white-card border crm-user-options' style={{width:"100%",padding:"20px 15px",maxWidth:"20rem"}}>
      

      <ActivityLogs isOpen={isOpen} setIsOpen={setIsOpen}/>


      <div className="user-info-container full-width center-aligned-row">
        <div className="padding5">
          <div className="center-aligned-row">
            <div className="bg-color-primary uppercase font-large color-white center-aligned-column white-card no-shadow" style={{width:"58px",height:"58px",borderRadius:"50%"}}>
              {applicationConfig?.user?.username.substring(0,2)}
            </div>
          </div>
          <div className="heading3 marginTop5 font-medium text-center capitalize ">{applicationConfig?.user?.username}</div>
          <div className="heading4 marginTop5 font-md-small text-center">{applicationConfig?.user?.email}</div>
        </div>
      </div>

      <div className="full-width border-bottom">
        <div className="padding5">

          {applicationConfig?.userType !== "Dispatcher"?
           <div onClick={()=>setIsOpen(true)} className='option marginTop5 display-flex-row'>
            <div><i className="fas fa-user-cog  color-dark font-normal"></i></div>
            <div className='marginleft10 heading4 font-normal color-black center-aligned-column'>My Activities</div>
          </div>
          :null}

         

          <div className='option marginTop5 display-flex-row'>
            <div><i className="fas fa-cog  color-dark font-normal"></i></div>
            <div className='marginleft10 heading4 font-normal color-black center-aligned-column'>Setting</div>
          </div>

          <div className='option marginTop5 display-flex-row' onClick={logoutHandle}>
            <div><i className="fas fa-power-off  color-dark font-normal"></i></div>
            <div className='marginleft10 heading4 font-normal color-black center-aligned-column'>Logout</div>
          </div>

        </div>
      </div>


      <div className="user-info-container full-width center-aligned-row">
        <div className="padding5">
          <div className="center-aligned-row">
            <img src="/assets/images/logos/estre.png" width={60} alt="" />
          </div>
          <div className="display-flex-row no-wrap marginTop5">
            <span className="center-aligned-column"><i className="far fa-copyright font-small color-dark"></i></span>
            <div className="heading4  marginleft5 font-md-small text-center capitalize "> 2023 - 1.0.0</div>
          </div>
        </div>
      </div>
      
      
    </div>
  );
};

export default UserOptions;
