import { IonAlert, IonSpinner } from '@ionic/react';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { checkFromCache, getAndSaveImageToCache } from '../../../../../../../utils/cache/cache';
import { controls, customizerConfig, enableFinalizeMode, projectConfiguration } from '../../../../../../../utils/customizein3d/area3dmodel/area3dModel';
import { updateCameraSettingViewData } from '../../../../../../../utils/customizein3d/area3dmodel/floorplan/createRender';
import { cameraFor2dRender, isViewRenderMode, resetCreateRenderParameters, set2dCameraForRender, setCameraFor2dRender, setIsViewRenderMode, update2dRenderImageInCache } from '../../../../../../../utils/customizein3d/area3dmodel/floorplan/render2d';
import { closeControlsPanelAndHideCreateRender } from '../../../../../../../utils/customizein3d/area3dmodel/UI_methods';
import { camera } from '../../../../../../../utils/customizer/ProjectConfiguration';
import { applicationConfig, getFilteredArray, share2DRender, waitFor } from '../../../../../../../utils/methods';
import { hideComponentLoader, showComponentLoader } from '../../../../../../../utils/UI_methods/global';
import Modal2 from '../../../../../../common/shared-components/Modals/Modal';
import GalleryAreasList from './GalleryAreasList';

const Render2d: React.FC<{
  allRendersList:Array<any>
  isCreatingRender:boolean
  currTab:string
}> = (props) => {

  let history = useHistory()

  const[isOpenImage,setIsOpenImage] = useState(false)
  const[rendersList2d,setRendersList2d] = useState([])

  const [currSelectedArea, setcurrSelectedArea] = useState<string>(null);
  const [currSelectedCamera, setCurrSelectedCamera] = useState<any>(null);
  const[showDeleteAlert,setShowDeleteAlert] = useState(false)
  const[isDeleteAll,setIsDeleteAll] = useState(false)
 

   useEffect(()=>{
    if(currSelectedArea){
      if(currSelectedArea.toLowerCase() =="all"){
        setRendersList2d([...props.allRendersList])
      }
      let renders = getFilteredArray(props.allRendersList,"areaName",currSelectedArea)
      renders = renders.sort((a,b)=>a.cameraId>b.cameraId?-1:1)
      renders = renders.splice(0,20)
      // console.log(renders)
      setRendersList2d(renders)
    }
   },[currSelectedArea,props.allRendersList])

   useEffect(()=>{
    // setcurrSelectedArea(projectConfiguration.areasList[0].area_name)
    applicationConfig.setFunctionRef("customizer",{setCurrSelectedAreaGallery:setcurrSelectedArea})
   },[])

  


   useEffect(()=>{
    setTimeout(() => {
      if(rendersList2d.length){
        displayImages(rendersList2d)
      }
    }, 500);
   },[rendersList2d])

   

  function displayImages(list:Array<any>) {
    for (const currCamera of list) {
      displayCameraImage(currCamera)
    }
   }

   async function displayCameraImage(currCamera:any,elementId:any = null){
      let imageUrlKey = `2DImages/DemoClient2/NewDemo/${currCamera.areaName}${currCamera.cameraId}/${currCamera.areaName}0000.png`

      let element = null
      if(elementId){
        element = $(`#${elementId}`)
      }else{
        element = $(`#RenderImage${currCamera.cameraName}`)
      }

      await checkFromCache(imageUrlKey)?.then((url: any) => {
        element?.attr("src", url);
      })
      .catch((err) => {
        update2dRenderImageInCache(currCamera).then(data=>{
          console.log(data)
          checkFromCache(imageUrlKey)?.then((url: any) => {
            element?.attr("src", url)
          })
        }).catch(err=>{
          console.log(imageUrlKey)
        })
      });
   }
 
  

  function viewImageHandle(currCamera:any) {
    setIsViewRenderMode(true)
    setIsOpenImage(true)
    setTimeout(() => {
      displayCameraImage(currCamera,"viewRenderImage")
    }, 1000);
  }

  async function loadViewHandle(currCamera:any) {
    hideComponentLoader("changeFinishLoader")
    showComponentLoader("canvasLoader")
    setIsViewRenderMode(true)
    set2dCameraForRender(currCamera)
    customizerConfig.setCurrentRenderingType("2d")
    resetCreateRenderParameters()
    controls.reset()
    await waitFor(100)
    updateCameraSettingViewData(customizerConfig.currentRenderingType)
    closeControlsPanelAndHideCreateRender()
    // await waitFor(250)
    enableFinalizeMode()
  }

  function deleteCurrSelectedHandle(cam:any) {
    setCurrSelectedCamera(cam)
    setShowDeleteAlert(true)
    setIsDeleteAll(false)
  }

  function deleteAllHandle() {
    setShowDeleteAlert(true)
    setIsDeleteAll(true)
  }

  function confirmDelete() {
    if(isDeleteAll){
      removeAllHandle()
    }else{
      removeCurrSelectedRender() 
    }
  }

  function removeCurrSelectedRender() {
    projectConfiguration.delete2dCamera(currSelectedCamera)
    applicationConfig.functions.customizer.setRefreshRenders(Number(new Date()))
  }

  function removeAllHandle() {
    projectConfiguration.removeArea2DCameras(currSelectedArea)
    applicationConfig.functions.customizer.setRefreshRenders(Number(new Date()))
  }

  function downloadRender(currCamera:any) {
    let src = $(`#RenderImage${currCamera.areaName}${currCamera.cameraId}`).attr("src")
    var link = document.createElement('a');
    link.setAttribute("target", "blank")
    link.href = src
    link.download = String(Number(new Date)) + '.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function createRenderAction(currCam:camera) {
    customizerConfig.setCurrentRenderingType("2d")
    let cam = new camera(currCam.areaName,"2d",currCam.cameraId,currCam)
    setIsViewRenderMode(true)
    setCameraFor2dRender(cam)
    applicationConfig.functions.customizer.setShowRenderModal(true)
  }

  function shareRenderHandle(currCamera:any) {
    let src = $(`#RenderImage${currCamera.areaName}${currCamera.cameraId}`).attr("src")
    share2DRender(currCamera?.cameraName,src)
  }


  return (
    <React.Fragment>
      {/* <MenuHeader
        label='Gallery'
        action={disableFinalizeMode}
      /> */}

        
    <IonAlert
        isOpen={showDeleteAlert}
        mode="ios"
        onDidDismiss={() => setShowDeleteAlert(false)}
        cssClass="my-custom-class"
        header={"Confirm!"}
        message={"Delete <strong>Render</strong>!!!"}
        buttons={[{
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              confirmDelete();
            },
          },
        ]}
      />


        <Modal2
          onDismiss={()=>setIsOpenImage(false)} 
          isOpen={isOpenImage}
          id=""
          heading='Image viewer'
          classNames='image-viewer-modal'
          FooterContent={()=>(<div></div>)}
          Content={()=>(
          <div className='full-height-width display-flex-row no-wrap'>
            <div className='full-width center-aligned-column full-height padding10'>
              <div className='border padding5 center-aligned-column full-height  position-relative'>
                <img id="viewRenderImage" className='full-height' src="" alt="" style={{width:"auto",objectFit:"contain"}} />
                
              </div>
            </div>
            <div className='width30 display-none padding10'>
              <div className='display-flex-row'>

              <button className="op-btn marginleft5 op-btn-primary-inverse op-btn">
                  <i className="fas fa-download font- color-danger" aria-hidden="true"></i> 
                  <span className="letter-spacing text marginleft10" >Download</span>
                </button>

                <button className="op-btn op-btn-primary op-btn marginleft10">
                  <i className="fas fa-share-alt font color-white" aria-hidden="true"></i> 
                  <span className="letter-spacing text color-white marginleft5" >Share</span>
                </button>
                
              </div>
            </div>
            
          </div>
          )}
        />

      {props.isCreatingRender?
        <div className='padding5'>
          <div className='heading1 color-danger padding5'>Rendering time <span id="renderingTime"></span></div>
        </div>
      :null}

        {/* <HorizontalScroll
          itemsClass="sub-categories-container-render"
          Component={AreasList}   
          itemsList={projectConfiguration.areasList}
          id={`rendersContainer2d`}     
        /> */}

        {!currSelectedArea?
          <GalleryAreasList
            allRendersList={props.allRendersList}
            setcurrSelectedArea={setcurrSelectedArea}
            currTab={props.currTab}
          />
        :
        <>

        <div className='full-width display-flex-row space-between'>
          <div className='display-flex-row padding10 pointer' onClick={()=>setcurrSelectedArea(null)}>
            <div> <i className='fas fa-chevron-left pointer-none color-danger font-'></i> </div>
            <div className='marginleft5 heading2 pointer-none font-md-small center-aligned-column'>Go back</div>
          </div>

          <div className='display-flex-row padding10 pointer' onClick={deleteAllHandle}>
          <div> <i className='fas fa-trash pointer-none color-danger font-'></i> </div>
          <div className='marginleft5 heading2 pointer-none font-md-small center-aligned-column'>Remove All</div>
          </div>


        </div>

        {/* <div className='display-flex-row padding10 pointer' onClick={()=>setcurrSelectedArea(null)}>
          <div> <i className='fas fa-chevron-left pointer-none color-danger font-'></i> </div>
          <div className='marginleft5 heading2 pointer-none font-md-small center-aligned-column'>Go back</div>
        </div> */}

      <div className='full-height-width display-flex-row overflow-y-scroll' style={{paddingBottom:"10rem",alignContent:"baseline"}}>
        {props.isCreatingRender && customizerConfig.currentRenderingType === "2d" && !isViewRenderMode?
        <div className='full-width pointer padding10'>
              <div className='render-image center-aligned-column padding5 border' style={{aspectRatio:"4/3"}}>
                <img  src="" width={70} alt="" />
              </div>
              <div className='details padding5'>
                <div className='display-flex-row no-wrap'>
                  <div className='loader'><IonSpinner name="crescent"/></div>
                  <div className='marginleft10'>
                    <div className='heading4 font-small'>Processing render...</div>
                    </div>
                </div>
              </div>
            </div>
        :null}
        {rendersList2d.length?rendersList2d?.map((currCamera:any,index:number)=>{
          // var d = new Date(Number(currCamera.updated_on))
          return(
            <div key={index} className='area-item position-relative full-width pointer padding10'>
              
              {props.isCreatingRender && currCamera.cameraId === cameraFor2dRender?.cameraId?
              <div className='bottom-left ion-padding zindex30'>
                <div className='display-flex-row padding10 no-wrap'>
                  <div className='center-aligned-column'><IonSpinner name="crescent"/></div>
                  <div className='center-aligned-column heading3 font-md-small marginleft5'>Updating...</div>
                </div>
              </div>
              :null}

              <div className='render-image position-relative center-aligned-column padding5 border' style={{aspectRatio:"4/3"}}>

                <div className=' top-left padding10'>
                  <div className='white-card no-shadow font-small heading2 center-aligned-column' style={{padding:"3px 7px"}}>{index+1}</div>
                </div>
                <img  id={`RenderImage${currCamera.areaName}${currCamera.cameraId}` } className="full-height" style={{objectFit:"cover"}}  src="" alt="" />
             
                <div className="middle">
                  <div className='minimap-actions'>

                    <div onClick={()=>loadViewHandle(currCamera)} className="customize-link border display-flex-row no-wrap">
                      <div className='cener-aligned-column pointer-none'> <i className='fas fa-cog color-white'></i> </div>
                      <div  className="color-white pointer-none center-aligned-column text marginleft5">Load view</div>
                    </div>

                    <div  onClick={()=>viewImageHandle(currCamera)} className="customize-link border marginTop5 display-flex-row no-wrap">
                      <div className='cener-aligned-column pointer-none'> <i className='fas fa-eye color-white'></i> </div>
                      <div className="color-white pointer-none center-aligned-column text marginleft5">View Render</div>
                    </div>


                  </div>
                </div>
             
             
              </div>

              <div className='top-right'>
                <div className='display-flex-row no-wrap padding5'>
                  <div className='padding5'onClick={()=>createRenderAction(currCamera)}>
                    <img src={`${applicationConfig.aws_public_url}assets/icon/svg/ar.svg`} width={20} className='pointer-none' alt="" />
                  </div>
                  <div className='padding5'onClick={()=>shareRenderHandle(currCamera)}>
                    <img src={`${applicationConfig.aws_public_url}assets/icon/svg/share.svg`} width={20} className='pointer-none' alt="" />
                  </div>
                  <div className='padding5' onClick={()=>downloadRender(currCamera)}>
                    <img src={`${applicationConfig.aws_public_url}assets/icon/svg/download.svg`} width={20} className='pointer-none' alt="" />
                  </div>
                  <div className='padding5' onClick={()=>deleteCurrSelectedHandle(currCamera)}>
                    <img src={`${applicationConfig.aws_public_url}assets/icon/svg/trash.svg`} width={20} className='pointer-none' alt="" />
                  </div>
                </div>
              </div>

              <div className='bottom-right ion-padding'>
                <div className='display-flex-row no-wrap bg-color-white no-shadow' style={{margin:"5px",padding:"0 2px"}}>
                  {/* <span className='heading2 font-small color-primary'>Render Time</span> */}
                  <span className='heading3 font-small color-black marginleft5'>{Math.floor(currCamera.renderTime/1000 || 0) || 0} Sec</span>
                </div>
              </div>

            </div>
          )
        }):null}
      </div>
      </>
        }

    </React.Fragment>
  );
};

export default Render2d;
