import React, { useEffect, useState } from "react";
import { getWindowHeight, getWindowWidth, hideComponentLoader, showComponentLoader, showToast } from "../../../../../../../utils/UI_methods/global";
import { confirmCrop, updateAspectRatio } from "../../../../../../../utils/cropper/cropper";
import { camera, customizerConfig,  undo } from "../../../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { updateCameraTransform, updateFov, updateNearValue } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/floorplanUI";
import { cameraFor2dRender, isViewRenderMode, pointerDownFov, pointerDownNear, renderSettingConfig } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/render2d";
import { takeSceneScreeShot } from "../../../../../../../utils/customizein3d/area3dmodel/floorplan/render3d";
import { applicationConfig, waitFor } from "../../../../../../../utils/methods";
import Cropper from "../../../../../../common/shared-components/Helpers/Cropper";
import Button2 from "../../../../../../common/ui-components/buttons/Button2";
import DropDown2 from "../../../../../../common/ui-components/filters/DropDown2";
import TakeRender from "../TakeRender";

const CameraSettings: React.FC<{
  // disableCameraSettingsMode:()=>void
  // setShowRenderModal:(val:boolean)=>void
  // setCurrSelectedStep:(val:string)=>void
  // currSelectedCamera:any
  isFullScreenMode:boolean
}> = (props) => {
 

  const[currselectedAspect,setCurrselectedAspect] = useState("4:3")
  const[isSettingAspectRatio,setisSettingAspectRatio] = useState(false)
 

  useEffect(()=>{
    renderSettingConfig?.setDimensions(getWindowWidth(),getWindowHeight())
    applicationConfig?.setFunctionRef("customizer",{setCurrselectedAspect:setCurrselectedAspect})
  },[])


  async function confirmAction2D() {
    showComponentLoader("changeFinishLoader")
    updateCameraTransform()
    await waitFor(200)
    confirmCrop()
    await waitFor(500)
    hideComponentLoader("changeFinishLoader")
    applicationConfig.functions.customizer.setShowRenderModal(true)
  }

  async function confirmAction3D() {
    showToast("Saved..",1000)
    await takeSceneScreeShot(applicationConfig.states.customizer.currSelectedCamera)
    updateCameraTransform()
    showComponentLoader("changeFinishLoader")
    await waitFor(500)
    hideComponentLoader("changeFinishLoader")
    // applicationConfig.functions.customizer.disableCameraSettingsMode()
  }

  function confirmCropHandle() {
    confirmCrop()
    setTimeout(() => {
      setisSettingAspectRatio(false)
    }, 200);
  }


  function updatedAspectRationHandle(val:string) {
    undo.add("aspect",{cropPoints:{...cameraFor2dRender.cropPoints}})
    updateAspectRatio(val)
    setCurrselectedAspect(val)
  }

 
  return (
    <>
    <TakeRender/>
    {!isSettingAspectRatio?
    <>

      <div id="cameraSettingsWrapper" className="center-aligned-row no-wrap full-width" style={{zIndex:1003}} >
        {/* <div className="heading1 font-small padding5 bottom-left" style={{bottom:"100%"}}>Camera settings</div> */}
        <div className={`display-flex-row no-wrap camera-settings-wrapper shadow-light position-relative  ${customizerConfig?.currentRenderingType === "3d"? " width50":" full-width"}`} style={{background:"rgba(255,255,255,0.3)",borderTop:"0px solid #000",justifyContent:"space-evenly"}}>
        <div className="display-flex-row no-wrap ion-margin-start">
         
         <div className={`center-aligned-column ${customizerConfig?.currentRenderingType==="3d"?" display-none":""}`}  style={{width:"160px"}}>
             <div className="display-flex-row no-wrap ion-margin-start">
               <DropDown2
                 classNames=""
                 values={["4:3","16:9","2:1","1:1"]}
                 currSelectedValue={currselectedAspect}
                 action={updatedAspectRationHandle}
                 label="Aspect"
                 />
           </div>
 
         </div>
       </div>
 
      
  
        <div className={`display-flex-row position-relative control no-wrap ${customizerConfig?.currentRenderingType==="3d"?" display-none":""}`}>
          <div className="heading3 center-aligned-column">Fov</div>
          <div className="range-slider- marginleft10 center-aligned-column" style={{width:"150px"}}>
              <input type="range" className="range-slider" onPointerDown={pointerDownFov} min={45} max={105} onChange={updateFov} id="rangeSliderFov"/>
          </div>
          <div className="center-aligned-column right-middle color-white marginleft10"><div id="rangeSliderFovVal" className="heading4">{camera?.fov}</div></div>
        </div>
  
  
        <div className={`display-flex-row position-relative control no-wrap ${customizerConfig?.currentRenderingType==="3d"?" display-none":""}`}>
          <div className="heading3 center-aligned-column">Cliping</div>
          <div className="range-slider- marginleft10 center-aligned-column" style={{width:"150px"}}>
              <input type="range" className="range-slider" onPointerDown={pointerDownNear} min={1} defaultValue={1} max={500} onChange={updateNearValue} id="rangeSliderNear"/>
          </div>
          <div className="center-aligned-column right-middle color-white marginleft10"><div id="rangeSliderNearVal" className="heading4">{camera?.near}</div></div>
        </div>

        <div className={`display-flex-row control no-wrap ${customizerConfig?.currentRenderingType==="2d"?" display-none":""}`}>
          <Button2
            configObj={applicationConfig}
            action={()=>{}}
            label={"Prev"}
            fontAwesomeIcon={"fas fa-chevron-left"}
            iconPos="left"
            classNames={"bg-color-primary  button-"}
          />

          <Button2
            configObj={applicationConfig}
            action={()=>{}}
            label={"Next"}
            fontAwesomeIcon={"fas fa-chevron-right"}
            iconPos="left"
            classNames={"bg-color-primary  button-"}
          />

        </div>


 

          <div id="finalizeRenderButton" className="center-aligned-column  full-height" style={{borderColor:"#000"}}>
            <div className="display-flex-row no-wrap">
              <Button2
                configObj={applicationConfig}
                action={()=>{
                  if(customizerConfig?.currentRenderingType === "3d"){
                    confirmAction3D()
                  }else{
                    confirmAction2D()
                  }
                }}
                label={isViewRenderMode && cameraFor2dRender.isRendered?"Update Render":"Take Render"}
                fontAwesomeIcon={"fas fa-camera"}
                iconPos="left"
                classNames={"bg-color-primary marginleft10 button-"}
              />
            </div>
          </div>
          
        </div>

       


      </div>

      
      </>
      :
      <>
      <Cropper/>

      <div id="cropSettingsWrapper" className="bottom-middle white-card camera-settings-wrapper shadow-light zindex101" style={{background:"rgba(255,255,255,0.6)",width:"500px"}}>
        <div className="display-flex-row space-around">

          <div className="display-flex-row no-wrap ion-margin-start">
            <div className="center-aligned-column marginleft10">
              <DropDown2
                classNames=""
                values={["4:3","16:9","2:1"]}
                currSelectedValue={currselectedAspect}
                action={updatedAspectRationHandle}
                label="Aspect Ratio"
                />
            </div>
          </div>

          <div className="">
            <div className="display-flex-row">
              <Button2
                configObj={applicationConfig}
                action={()=>setisSettingAspectRatio(false)}
                label={"Go Back"}
                fontAwesomeIcon={"fas fa-chevron-left color-danger"}
                iconPos="left"
                classNames={"button"}
              />

              <Button2
                configObj={applicationConfig}
                action={confirmCropHandle}
                label={"Confirm"}
                fontAwesomeIcon={"fas fa-check color-danger"}
                iconPos="left"
                classNames={"button bg-color-primary marginleft10"}
              />
            </div>
            </div>
        </div>
      </div>

      </>
      }

    </>
  );
};

export default CameraSettings;
 
