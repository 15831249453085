import { IonModal } from "@ionic/react";
import React, { useRef, useState } from "react";
import { layouts } from "../../../../../utils/customizein3d/modules/customizein3d";
import { SofaVariantsConfiguration } from "../../../../../utils/store/customization-options-data/sofa";
import { STORE, Variant } from "../../../../../utils/store/storeConfiguration";
import VariantSelector from "../../../../admin/products/store/product-customizer/common/VariantSelector";
import Button2 from "../../../ui-components/buttons/Button2";
import CloseButton from "../../../ui-components/buttons/CloseButton";
import SavedConfigsList from "./SavedConfigsList";
import { getUser } from "../../../../../utils/methods";
import $ from "jquery"
  
     
    
const Layouts: React.FC<{
}> = (props) => {

    const[isOpen,setisOpen] = useState(false)

    const eleRef = useRef()

    const[currSelectedVariant,setCurrSelectedVariant] = useState(new Variant(SofaVariantsConfiguration[0].name))

    function confirmSelection() {
        let variant = SofaVariantsConfiguration.find(currVariant => currVariant.name === currSelectedVariant.variantName)
        if(variant){
            layouts.create(variant.configuration)
        }
        setisOpen(false)
    }

    function Wrapper() {
        return(
            <div className="full-height-width no-wrap bg-color-white">
                <div className="header-content bg-color-light position-relative">
                    <span className="middle heading1 store-color-primary">Layouts</span>
                    <span className="right-middle"><CloseButton classNames="" action={()=>setisOpen(false)}/></span>
                    
                </div>
                <div className="body-content center-aligned-column padding-container">
                    <div className="display-flex-row product-customizer-wrapper customization-options">
                        {/* <VariantsList/> */}
                        <VariantSelector isSelectLayoutMode={true} confirmHandle={confirmSelection} variant={currSelectedVariant} product={STORE.currProduct} configuration={SofaVariantsConfiguration} onChange={setCurrSelectedVariant} isShowMakeYourOwn={false}/>
                    </div>
                </div>
                <div className="footer-content center-aligned-column">
                      <Button2
                        configObj={{id:""}}
                        action={()=>{confirmSelection()}}
                        label={"Confirm"}
                        fontAwesomeIcon={"fas fa-arrow-left color-primary"}
                        iconPos=""
                        classNames={"no-shadow store-bg-color-primary"}
                    />
                </div>
            </div>
        )
    }

    function open() {
        $(eleRef.current).css("left","0")
        setisOpen(true)
    }

    function close() {
        $(eleRef.current).css("left","-100%")
        setisOpen(false)
    }




    return (
        <>

        {/* <IonModal
            isOpen = {isOpen}
            onDidDismiss = {()=>setisOpen(false)}
            cssClass="medium-x-modal no-border"
            id=""
            >
        </IonModal> */}

        <div ref={eleRef} className="position-fixed white-card shadow-medium padding0 bg-color-white top-left" style={{zIndex:1000,height:"calc(100% - var(--main-header-height))",top:"calc(var(--main-header-height))",transition:"all 0.5s",left:"-100%",width:"16rem"}}>
            <SavedConfigsList isOpen={isOpen} closeHandle={()=>{close()}}/>
        </div>


        <Button2
            configObj={{id:""}}
            action={()=>{open()}}
            label={"Layouts"}
            fontAwesomeIcon={"fas fa-arrow-left color-primary"}
            iconPos="left"
            classNames={"no-border button-small width-auto shadow-medium bg-color-primary"}
            svgUrl="assets/icon/svg/layout-danger.svg"
        />

        </>
    );
};

export default Layouts;
    