import {
  IonPage
} from "@ionic/react";
import { apps, briefcase, colorPalette, images } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { getProjectAreaCategories } from "../../../../../services/api";
import { applicationConfig, getFilteredArray, logger } from "../../../../../utils/methods";
import { closeProductModulesWindow } from "../../../../../utils/moodboard/helper";
import {  createConfigruation, dragStart, dropImageMoodboard } from "../../../../../utils/moodboard/moodboard";
import Moodboardconfig from "../../../../../utils/moodboard/moodboardConfig";
import { getPageTourElements } from "../../../../../utils/siteTour/elements";
import { hideComponentLoader } from "../../../../../utils/UI_methods/global";
import CloseMenuCurved from "../../../../common/modules/customizein3d/common/CloseMenuCurved";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import ProductModules from "../../../../common/shared-components/product-modules/ProductModules";
import SiteTour from "../../../../common/ui-components/siteTour/SiteTour";
import View3DModel from "../../../../modelviewer/View3DModel";
import "../../../../style/admin/moodboard.css";
import Canvas from "./canvas/Canvas";
import CreateTemplateForm from "./canvas/CreateTemplateForm";
import MoodboardHeader from "./common/ModboardHeader";
import Controls from "./controls/Controls";
import DesktopTabs from "./controls/DesktopTabs";


const MoodBoard: React.FC<{
  configObj:any
  isLogin:boolean
}> = (props) => {


  const[currArea,setCurrArea] = useState<any>(null)
  const[currAreaId,setCurrAreaId] = useState<number>(0)
  const[projectAreaCategories,setProjectAreaCategories]:any = useState([])
  const[areasList,setareasList]:any = useState([])
  const[categoriesStatus,setCategoriesStatus]:any = useState({})
  const modelViewerRef:any = useRef()

  const[tourElementsList] = useState(getPageTourElements("Moodboard"))


  const [moodboardConfig] = useState(new Moodboardconfig())

  const [configuration,setConfiguration] = useState(null)

  useEffect(()=>{
    // set Project area categories
    if(props.configObj){
      let filteredArray = getFilteredArray(props.configObj?.data.projectAreaCategories,"project_id",props.configObj?.projectId)
      if(!filteredArray.length){
        filteredArray = getFilteredArray(props.configObj?.data.moodboardAreaCategories,"moodboard_id",props.configObj?.moodboardId)
      }
  
      setProjectAreaCategories(filteredArray)
      //set moodboard id if not exists
      let moodboardId = props.configObj?.getMoodboardId()
     
      let areas = getFilteredArray(props.configObj?.data.moodboardList,"moodboard_id",moodboardId)

      areas.sort((a,b)=>a.area_name<b.area_name?-1:1)
      moodboardConfig.setAreasList(areas)
      setCurrArea(areas[0].area_name)
      setareasList(areas)
      applicationConfig.setFunctionRef("moodboard",{updateCategoriesstatus:updateCategoriesstatus})
    }
    

  },[props.configObj])

  useEffect(()=>{
    if(currArea){
      let config = createConfigruation(props.configObj?.moodboardId,currArea)
      setConfiguration(config)
    }
  },[currArea])
 
  function setProductModelSrc(src:string){
    modelViewerRef.current?.setProductModelSrc(src)
  }

  function updateProjectAreaCategories() {
    setRefreshAreaCategories(true)
  }


  const[refreshAreaCategories,setRefreshAreaCategories] = useState(false)
  useEffect(()=>{
    if(refreshAreaCategories){
      getProjectAreaCategories().then(data=>{
        props.configObj.setProjectAreaCategories(data)
        let filteredArray = getFilteredArray(props.configObj?.projectAreaCategory,"project_id",props.configObj?.projectId)
        setProjectAreaCategories(filteredArray)
        setRefreshAreaCategories(false)
      }).catch(error=>{
        console.log(error)
      })
    }
  },[refreshAreaCategories])


  // function updateCurrArea(areaName:string){
  //   setCurrArea(areaName)
  // }

  function updateCategoriesstatus(confirmed:Array<any>,remaining:Array<any>,additional:Array<any>,areaName:string){
      setCategoriesStatus((prevState:any)=>{
        let obj = {...prevState}
        obj[areaName] = {
          confirmed:confirmed,
          remaining:remaining,
          additional:additional,
        }
        return obj 
      })
  }
 
  
  function updateCurrArea(areaName:string,areaId:number){
    setCurrArea(areaName)
    setCurrAreaId(areaId)
    props.configObj.areaName = areaName
    props.configObj.areaId = areaId

    logger?.info("editcollection","changearea",`Designing area: ${areaName}`)
  }

  
  let tabItems = [
    {
      tabName: "productsContainer",
      tabIcon: briefcase,
      tabLabel: "Products",
      tabId: "products",
      img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/SofaDesign.png",
    },
    {
      tabName: "finishContainer",
      tabIcon: colorPalette,
      tabLabel: "Finish",
      tabId: "finish",
      img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/mySofaLogo.png",
    },
    {
      tabName: "myImagesContainer",
      tabIcon: images,
      tabLabel: "My images",
      tabId: "myImages",
      img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/RoomArrangement.png",
    },
    {
      tabName: "layoutsContainer",
      tabIcon: apps,
      tabLabel: "Layouts",
      tabId: "layouts",
      img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/mySofaLogo.png",
    },

    {
      tabName: "templatesContainer",
      tabIcon: apps,
      tabLabel: "Templates",
      tabId: "templates",
      img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/mySofaLogo.png",
    },
    // {
    //   tabName: "colorSwatchContainer",
    //   tabIcon: apps,
    //   tabLabel: "ColorGroup",
    //   tabId: "colorSwatch",
    //   img: "https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/mySofaLogo.png",
    // },
  ];
    
 

 
  return (
    <IonPage className="background-white">
      {props.configObj && configuration?
      <>
        <SiteTour
          tourElementsList={tourElementsList}
          moduleName="moodboard"
        />
        <MoodboardHeader 
          configObj={props.configObj}
          currentConfigObject={props.configObj}
          updateCurrArea={updateCurrArea}
          areasList={areasList}
          categoriesStatus={categoriesStatus}
          updateProjectAreaCategories={updateProjectAreaCategories}
          currSelectedArea={currArea}
        />
        {/* Overflow visible for loader */}
        <div id="createMoodboardWrapper" className="moodboard-wrapper fill-available-height position-relative customize-canvas-controls-wrapper">
       
          {/* <ToggleLeftWrapper
            position="right"
          /> */}

          <Loader
            className="background-black-fade-medium"
            id="moodboardFullPageLoader"
            data={{}}
            loaderType="topProgressBar"
          />

          <DesktopTabs
            configObj={props.configObj}
            tabItems={tabItems}
          />

          
        <CreateTemplateForm
          configObj={props.configObj}
        />

          <div className="customize-controls-wrapper ion-padding-botto">
          {/* <DragControlsWrapperIcon/> */}

              <Controls
                  configObj={props.configObj}
                  setProductModelSrc={setProductModelSrc}
                  currArea={currArea}
                  tabItems={tabItems}
                />
            <div className="full-height-width background-white padding10 overflow-hidden" id="moodboardProductModules"> 
                 <div className="pointer click-effect border center-aligned-row full-width" 
                  style={{padding:"10px",marginBottom:"10px"}}
                  onClick={closeProductModulesWindow}
                  >
                      <span className="color-black heading letter-spacing">
                        <i className="fas fa-chevron-left color-black marginRight5" aria-hidden="true"></i>
                        Go Back
                      </span>
                  </div>
                <ProductModules
                    configObj={props.configObj}
                    productId={3375}
                    moduleRef={null}
                    action={(event:any)=>dropImageMoodboard(event)}
                    moduleName="moodboard"
                    dragStart={dragStart}
                    parentContainerId="moodboardProductModules"
                />
              </div>

              
          </div>

          <div className="light-background  canvas-wrapper customize-canvas-wrapper ">
          
            
            <div id="product3DmodelContainer" className="border">
              <View3DModel
                ref={modelViewerRef}
              />
            </div>

              <Canvas
                configObj={props.configObj}
                updateCurrArea={updateCurrArea}
                projectAreaCategories={projectAreaCategories}
                updateCategoriesstatus={updateCategoriesstatus}
                areasList={areasList}
                currArea={currArea}
                categoriesStatus={categoriesStatus}
                updateProjectAreaCategories={updateProjectAreaCategories}
                currentConfigObject={moodboardConfig}
              />

          </div>


        </div>
        </>
        :
        null}
    </IonPage>
  );
};

export default MoodBoard;
