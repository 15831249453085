import React from "react";
import { toggleDoors } from "../../../../../utils/customizein3d/modules/wardrobe";
 
const InternlStructure: React.FC<{
}> = (props) => {

     
    
  return (
    <>

    <div className="padding5" onClick={toggleDoors}>
        <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/images/wardrobe/ShelfLayout1.webp" alt="" width={100} />
    </div>

    
    </>
   
  );
};

export default InternlStructure;
