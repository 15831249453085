import React, { useState } from "react";
import { useHistory } from "react-router";
import ProductImage from "../../common/ui-components/elements/ProductImage";
import { getFormattedPrice, getRandomNumber } from "../../../utils/products/productviewer_shopify";
import { addItemToLocalStorage } from "../../../utils/cache/localstorage";
 

const ProductsList: React.FC<{
  productsList:any
  isHomePage?:boolean
}> = (props) => {

 

  let history = useHistory()


  function clickHandle(product:any) {
    addItemToLocalStorage("currProduct",product)
    // history.push(`/furnitures/${product.category_name}/${product.product_id}`)
    window.location.href = `/furnitures/${product.category_name}/${product.product_id}`
  }

 
  return (
    <div className="display-flex-row product-images-container marginTop10 full-height">
       

      {props.productsList?.map((product:any,index:number)=>{
        return(
          <div onClick={()=>clickHandle(product)} className={`category-details-wrapper padding5 image-small`} key={index}>
            <div className="">
              <div className="image product-image-container position-relative">
                <div className="top-right">
                  <i className="far fa-heart color-dark font-medium"></i>
                </div>

                <ProductImage product={product}/>
              </div>
              {props.isHomePage?
                <div className="padding10">
                  <div className="heading2 color-black font-medium">{product.product_name}</div>
                  <div className="heading1 rupee-sign marginTop5 font-normal">₹ {getRandomNumber(10000,25487)}.00</div>
                </div>
              :
                <div className="padding10 center-aligned-column">
                  <div className="heading2 text-center color-black font-medium">{product.product_name}</div>
                  <div className="heading4 text-center rupee-sign marginTop5 font-normal color-black">From ₹ {getRandomNumber(10000,25487)}.00</div>
                </div>
              }
              
              <div className="no-shadow marginleft5  display-flex-row no-wrap">
              </div>
            </div>
          </div>
        )
      })}
    </div> 
  );
};

export default ProductsList;
