import React, { useEffect, useState } from "react";
import { hideComponentLoader } from "../../../../../utils/UI_methods/global";
import { applicationConfig } from "../../../../../utils/methods";
import { Init, addModuleImageInCanvas, allowDrop, copyProduct, createConfigruation, dropImageMoodboard, removeProduct, undo } from "../../../../../utils/moodboard/moodboard";
import Moodboardconfig from "../../../../../utils/moodboard/moodboardConfig";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import ProductModules from "../../../../common/shared-components/product-modules/ProductModules";
import Button2 from "../../../../common/ui-components/buttons/Button2";
 
 

const Canvas: React.FC<{
    isOpen:any
    product:any
}> = (props) => {
 
  

    const[modulesList,setModulesList] = useState([])


    useEffect(() => {
        if(props.product && props.isOpen){
            setTimeout(() => {
                init()
            }, 700);
        }
    }, [props.isOpen,props.product]);

 

    async function init() {
        let list = applicationConfig?.data?.productModules.filter(currModule => currModule.category_name.includes(props.product.category_name) && currModule.module_id > 47 )
        setModulesList(list)
        createConfigruation("MoodboardForVariant","Default")   
        let moodboardConfig = new Moodboardconfig()
        Init(applicationConfig,{},moodboardConfig,"",true); 
        hideComponentLoader("addModulesLoader") 
    }
  
    return (
            <div className="full-height customize-canvas-controls-wrapper own-config-wrapper no-wrap">
                
                <Loader
                    id="addModulesLoader"
                    data={{message:"Please wait"}}
                    loaderType="dotsText"
                    className="--is-active"
                />
                <div className="customize-canvas-wrapper bg-color-ex-light position-relative canvas-wrapper">
                    <div className="moodboard-canvas-container margin0 middle" id="moodboardCanvasContainer" style={{width:"60%",height:"80%",transform:"translate(-50%,-50%)"}}>
                        <div id="mainMoodboardCanvasContainer"
                            onDrop={(event:any)=>dropImageMoodboard(event)}
                            onDragOver={allowDrop}
                            className="canvas-container full-height-width white-card shadow-light">
                        </div>

                        <div className="product-selection-box-container shadow-medium product-selection-box" id="productSelectionBox" style={{position:"absolute",top:"-100%",left:"-100%",zIndex:2}}>
                            <div className="top-middle rotation-icon" id="moduleRotateIcon"> <i className="fas fa-undo color-dark font-normal"></i> </div>
                        </div>

                        <div className="bottom-middle padding5" >
                            <div className="product-actions-container  display-flex-row">
                            <div className="white-card click-effect shadow-light display-flex-row copy-product" onClick={copyProduct} style={{padding:"5px 10px"}}>
                                <span><i className="fas fa-copy color-primary pointer-none font-md-small"></i></span>
                                <span className="heading3 pointer-none hide-in-mobile font-small marginleft5 center-aligned-column color-dark">Duplicate</span>
                            </div>

                            <div className="white-card click-effect marginleft5 shadow-light remove-product display-flex-row" onClick={()=>{removeProduct()}} style={{padding:"5px 10px"}}>
                                <span><i className="fas fa-trash color-danger pointer-none font-md-small"></i></span>
                                <span className="heading3 hide-in-mobile font-small pointer-none marginleft5 center-aligned-column color-dark">Delete</span>
                            </div>
                            </div>
                        </div>

                    </div>

                    


                    <div className="display-flex-row undo-product top-right" style={{right:"16px",top:"12px"}}>
                            <Button2
                                configObj={{id:""}}
                                action={undo}
                                label={`Undo`}
                                fontAwesomeIcon={"fas fa-undo color-danger"}
                                iconPos="left"
                                classNames={"text-center bg-color-warning-outline"}
                            />
                    </div>


                </div>
                <div className="controls-wrapper">
                    <div id="AddModule" className="full-height" style={{padding:"0"}}>
                        <div className="pointer bg-color-light position-relative border-top space-between padding10 border-bottom full-width" >
                            <span className="center-aligned-column heading3 font-normal text-center" id="">Add modules</span> 
                        </div>
                            <ProductModules
                                configObj={applicationConfig}
                                productId={props.product.product_id}
                                moduleRef={{}}
                                action={(event:any)=>addModuleImageInCanvas(event)}
                                moduleName="moodboard"
                                dragStart={()=>{}}
                                parentContainerId={"AddModule"}
                                productModulesList={modulesList}
                            />
                        </div>
                </div>
            </div>
    );
};

export default Canvas;
