import React, { useEffect, useState } from "react";
import { shadowEffectOnClick } from "../../../../../utils/UI_methods/ui_effects";
import { foregroundParts, tokenValidityList } from "../../../../../utils/data";
import {
  applicationConfig,
  categoryPartTypeFilteredArray,
  disposeVariables,
  getApplicationFiltersMaterials,
  getArrayOfDistinctValues,
  getClientFilteredMaterials,
  getFilteredArray,
  getObjectArrayOfDistictValues
} from "../../../../../utils/methods";
import { STORE } from "../../../../../utils/store/storeConfiguration";

const LinkDetails: React.FC<{
  shareItemId: number;
  moduleName: string;
  setSelectedCollections: (list: any) => void;
  setTokenValidity: (validity: any) => void;
  productCategory: string;
}> = (props) => {

  const [validityList] = useState(tokenValidityList);

  const [sharingOptions] = useState([
    {
      label: "Space Planner",
      value: true,
    },
    {
      label: "Finish",
      value: true,
    },
    {
      label: "Augumented Reality",
      value: true,
    },
    {
      label: "Addons",
      value: true,
    }
  ]);

  const [selectedCollections, setSelectedCollections] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);

  const [currSelectedValidity, setCurrSelectedValidity]: any = useState(1 / 24);

  useEffect(() => {
    props.setSelectedCollections(selectedCollections);
  }, [selectedCollections]);

  useEffect(() => {
    props.setTokenValidity(currSelectedValidity);
  }, [currSelectedValidity]);

  useEffect(() => {
    if(applicationConfig?.data?.objectMaterialTypes){
      let materials = applicationConfig?.data.materials || STORE.data.materialList
      materials = getClientFilteredMaterials(materials)
      let collections = []
      if(props.moduleName === "products"){
        collections = getCollectionsList(props.productCategory, materials)
        // collections = collections.filter(currMaterial => currMaterial.company_name === "Kuka" || currMaterial.company_name === "Stanley")
      }else{
        collections = getObjectArrayOfDistictValues(materials, "collection_name")  
      }
      let collectionsArray = getArrayOfDistinctValues(collections,"collection_name");
      setAllCollections(collections);
      setSelectedCollections(collectionsArray);
      //Collections for project's products

  
      if (props.moduleName === "") {
        materials = getApplicationFiltersMaterials("Visualizer",applicationConfig?.data.materials,applicationConfig?.data.applicationMaterials);
        let projectProducts = getFilteredArray(applicationConfig?.data.projectAreaProductsList,"project_id",props.shareItemId);
        let allCategories = getArrayOfDistinctValues(projectProducts,"category_name");
  
        let collectionsList = [];
        for (const category of allCategories) {
          let list = getCollectionsList(category, materials);
          collectionsList.push(...list);
        }
        let collections = getObjectArrayOfDistictValues(collectionsList,"collection_name");
        setAllCollections(collections);
        setSelectedCollections(collections);
      }
    }
    
  }, [props.shareItemId,props.moduleName,props.productCategory,]);

  function getCollectionsList(categoryName: any, materials: any) {
    let productParts = foregroundParts[categoryName];
    if (!productParts) {
      return [];
    }
    let allTextures = [];
    for (const part of productParts) {
      let textures = categoryPartTypeFilteredArray(materials,applicationConfig?.data.objectMaterialTypes,part,categoryName);
      allTextures.push(...textures);
    }

    return getObjectArrayOfDistictValues(allTextures, "collection_name");
  }

  function toggleCollections(collection: string, index: number) {
    if (isAllSelected) {
      setSelectedCollections([collection]);
      setIsAllSelected(false);
      return;
    }
    setSelectedCollections((prevValues) => {
      if (prevValues.includes(collection)) {
        prevValues.splice(prevValues.indexOf(collection), 1);
        return [...prevValues];
      }

      return [...prevValues, collection];
    });
  }

  function handleAllSelection() {
    setIsAllSelected(true);
    let collections = getArrayOfDistinctValues(
      allCollections,
      "collection_name"
    );
    setSelectedCollections(collections);
  }

  function ProductsForm() {
    return (
      <>
        <div
          className="color-danger heading3"
          style={{ paddingTop: "1rem", paddingLeft: 0 }}>
          Available collections for customization{" "}
          <span className="heading2">{""}</span>{" "}
        </div>
        <div className="areas-container">
          {allCollections.length ? (
            <div className="area-item">
              <div
                className="area-image shadow-light click-effect border "
                onClick={handleAllSelection}>
                {isAllSelected ? (
                  <div className="top-left check-container">
                    <div className="check"></div>
                  </div>
                ) : null}
                <div>
                  <div className="title color-black">ALL</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="center-aligned-row full-width border-dashed ion-padding ion-margin-top ion-margin-bottom">
              <div className="label">No Collections Available</div>
            </div>
          )}

          {allCollections.length
            ? allCollections.map((collection: any, index: any) => {
                let classNames = [];
                classNames.push("area-item");
                let classNamesAreaImage = ["area-image shadow-light click-effect border "];
                return (
                  <div className={classNames.join(" ")} key={index}>
                    <div onPointerDown={shadowEffectOnClick} className={classNamesAreaImage.join(" ")}
                      onClick={(event) =>
                        toggleCollections(collection.collection_name, index)
                      }>
                      {selectedCollections.includes(
                        collection.collection_name
                      ) && !isAllSelected ? (
                        <div className="top-left check-container">
                          <div className="check"></div>
                        </div>
                      ) : null}
                      <div>
                        <div className="title heading3 color-black">
                          {collection.collection_name}
                        </div>
                       
                      </div>
                      <div className="bottom-left full-width marginBottom5">
                        <div className="heading4 font-small color">
                          <i className="fas fa-info-circle marginRight5 font-ex-small color-primary"></i>
                            {collection.material_type} /{" "}
                            {collection.company_name}
                        </div>
                      </div>
                      
                    </div>
                  </div>
                );
              })
            : null}
            <div className="area-item " style={{height:"0.1px"}}></div>
            <div className="area-item "style={{height:"0.1px"}}></div>
            <div className="area-item "style={{height:"0.1px"}}></div>
            <div className="area-item "style={{height:"0.1px"}}></div>
        </div>
      </>
    );
  }


  return (
    <div className="create-project-areas-container position-relative flex1 padding10" style={{overflow:"hidden !important",height:"auto"}}>
      <div className="select-validity-container full-width ">
        <div className="heading3   color-danger">
          Grant access for duration:<span className="color-danger"></span>{" "}
        </div>
        <div className="display-flex-row">
          {validityList?.map((validity: any, index: number) => {
            let classNames = "op-btn op-btn-primary-inverse";
            if (index != 0) {
              classNames = classNames.concat(" marginleft5");
            }
            if (currSelectedValidity == validity.value) {
              classNames = classNames.concat(" --is-active");
            }
            return (
              <button
                key={index}
                className={classNames}
                onClick={() => setCurrSelectedValidity(validity.value)}>
                {validity.label}
              </button>
            );
          })}
        </div>
      </div>
{/* 
      <div className="select-validity-container marginTop10 full-width display-none">
        <div className="heading3 color-danger">
          Select customization options
        </div>

        <div className="padding5">
          <div className="display-flex-row marginTop5 no-wrap">
            <IonCheckbox slot="end" checked={props.configObj.allowSpacePlanner} onIonChange={(e:any)=>props.configObj.setAllowSpacePlanner(!props.configObj.allowSpacePlanner)} color="primary" mode='md'/>
            <div className="heading4 color-black marginleft10">Space Planner</div>
          </div>
          <div className="display-flex-row marginTop5 no-wrap">
            <IonCheckbox slot="end" checked={props.configObj.allowFinish} onIonChange={(e:any)=>props.configObj.setAllowFinish(!props.configObj.allowFinish)} color="primary" mode='md'/>
            <div className="heading4 color-black marginleft10">Change Finish</div>
          </div>
          <div className="display-flex-row marginTop5 no-wrap">
            <IonCheckbox slot="end" checked={props.configObj.allowAr} onIonChange={(e:any)=>props.configObj.setAllowAr(!props.configObj.allowAr)} color="primary" mode='md'/>
            <div className="heading4 color-black marginleft10">Allow Ar</div>
          </div>
          <div className="display-flex-row marginTop5 no-wrap">
            <IonCheckbox slot="end" checked={props.configObj.allowAddons} onIonChange={(e:any)=>props.configObj.setAllowAddons(!props.configObj.allowAddons)} color="primary" mode='md'/>
            <div className="heading4 color-black marginleft10">Addons</div>
          </div>
        </div>
        
      </div> */}

        <ProductsForm />
        {/* <CollectionsForm /> */}
    </div>
  );
};

export default LinkDetails;
