import { IonAlert, IonIcon, IonModal } from '@ionic/react';
import { expand } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { fullScreenBrowserToggle, getMobileOperatingSystem, updateCartCounter } from '../../../../utils/UI_methods/global';
import { rippleEffectOnClick, tapEffectOnClick } from '../../../../utils/UI_methods/ui_effects';
import { toggleFileOptions } from '../../../../utils/customizein3d/area3dmodel/UI_methods';
import { enableFloorplanMode, projectConfiguration } from '../../../../utils/customizein3d/area3dmodel/area3dModel';
import { generateModelFromExporter } from '../../../../utils/customizein3d/webxr';
import { downloadScene } from '../../../../utils/customizer/customizer';
import Inventory from "../../../admin/inventory/Inventory";
import "../../../style/common/header.css";
import Cart from '../Cart/Cart';
import { showProductCart } from '../../../../utils/store/product-cart';


const InnerPageHeader: React.FC<{
  configObj:any
  componentMiddle:any
  componentRight:any
  goBack:()=>void
  moduleName:string
}> = (props) => {

  const history = useHistory()

  const[isCartOpen,setIsCartOpen] = useState(false)
  const[isSettingsOpen,setIsSettingsOpen] = useState(false)
  const[isOpenInventory,setIsOpenInventory] = useState(false)

  const[cartCounter,setCartCounter] = useState(0)
  const[showGoBackAlert,setShowGoBackAlert] = useState(false)
  const[isShowCart,setIsShowCart] = useState(false)


  function clearCache(event:any) {
    localStorage.removeItem(`Customizer_3d_${projectConfiguration.projectName}_${projectConfiguration.projectId}`);
    window.location.href = ""
  }

  useEffect(()=>{
    
    updateCartCounter()
  },[])

 
  function File() {
    return(
      <div onClick={toggleFileOptions}  className='border-left hide-in-mobile border-right user-options-link'>
          <div className='display-flex-row padding10 pointer-none'>
          <div className=''>
            <div className='heading3 font-small'>File</div>
          </div>
          <div className='center-aligned-column marginleft5'> <i className='fas fa-chevron-down font-ex-small color-dark'></i> </div>
        </div>
      </div>
    )
  }

  function exportGlbHandle() {
    if(props.moduleName === "customizer"){
      downloadScene()
    }
    if(props.moduleName === "customizein3d"){
      generateModelFromExporter()
    }
  }

  function cartClickAction() {
    if(props.moduleName === "customizein3d"){
      setIsCartOpen(true)
    }
    if(props.moduleName === "productDetails"){
      showProductCart()
    }
  }

  return (
      <div className="main-header-wrapper white-card op-header-wrapper position-relative display-flex-row zindex100 no-wrap">
       <IonAlert
        isOpen={showGoBackAlert}
        mode="ios"
        onDidDismiss={() => setShowGoBackAlert(false)}
        cssClass=""
        header={"Confirm!"}
        message={"Configuration will be lost!!!"}
        buttons={[{
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "Confirm",
            handler: () => {
              props.goBack();
            },
          },
        ]}
      />
         <IonModal
          onDidDismiss={()=>setIsCartOpen(false)} 
          isOpen={isCartOpen}
          id=""
          cssClass='share-modal'
        >
           <Cart
            configObject={props.configObj}
            closeModal={()=>setIsCartOpen(false)}
          />
        </IonModal>
     

        <IonModal 
          onDidDismiss={()=>setIsOpenInventory(false)} 
          isOpen={isOpenInventory}
          backdropDismiss={false}
          id="inventoryModal"
          cssClass='project-action-modal'
        >
          <Inventory
          configObj={props.configObj}
          closeModal={setIsOpenInventory}
          currState="Home"
          product={null}
          finishId={0}
          />
        </IonModal>


        <div className="header-left-content">
          <div className="hide-in-mobile" onClick={()=>setShowGoBackAlert(true)}>
            <div className="brand">
              <img src="/assets/images/opus.png" alt="Opus"/>
              </div>
          </div>

          <div className="hide-in-desktop" onClick={()=>setShowGoBackAlert(true)}>
            <div className="brand">
              <img src="/assets/icon/icon.png" alt="Opus"/>
              </div>
          </div>

          {props.moduleName === "customizer"?
          <>
          <div onPointerDown={rippleEffectOnClick} id="projectsGoBack" className="border-left pointer click-effect  position-relative overflow-hidden heading3 border-right full-height center-aligned-column ion-padding-start ion-padding-end"
              onClick={()=>props.goBack()}>
            <span className='display-flex-row'><i className="fas fa-chevron-left center-aligned-column  color-danger"></i> <span className='heading3 hide-in-mobile'>Go back</span></span>
          </div>
          <File/>
          {/* <div id="customizerGoBack" className="border-left display-none heading3 border-right full-height center-aligned-column ion-padding-start ion-padding-end"
              onClick={disableKitchenPlannerMode}>
            <span><i className="fas fa-chevron-left color-danger"></i> Go back</span>
          </div> */}
          </>
          :
          <>
          <div onPointerDown={rippleEffectOnClick} id="productGoBack" className="border-left pointer click-effect position-relative overflow-hidden  border-right full-height display-flex-row no-wrap ion-padding-start ion-padding-end"
              onClick={()=>setShowGoBackAlert(true)}>
            <span className='center-aligned-column full-height'><i className="fas fa-chevron-left  color-danger"></i></span> <span className='hide-in-mobile center-aligned-column marginleft5 heading3'>Go back</span> 
          </div>
          </>
          }
        </div>


        

        <div className="header-middle-content middle">
          <props.componentMiddle/>
        </div>
       

        <div className="header-right-content position-relative">
       
            <props.componentRight/>
            {props.moduleName === "customizein3d" || props.moduleName === "productDetails"?
            <div onPointerDown={tapEffectOnClick} id="cartIcon" className="position-relative cart-icon click-effect white-card shadow-light center-aligned-column ion-margin-start" style={{width:"28px",height:"28px"}} onClick={cartClickAction}>
              <i className="fas fa-cart-plus color-primary font-normal"></i>
              <div className="badge cart-item-counter" id="cartItemCounterInner">{cartCounter}</div>
            </div>
            :null}

            {/* <Button2
              configObj={{}}
              action={shareClickHandle}
              label={"Share"}
              fontAwesomeIcon={"fas fa-share-alt color-primary"}
              iconPos="right"
              classNames={"shadow-light hide-in-mobile marginleft10 header-share-button font-m "}
            /> */}

            {getMobileOperatingSystem() !== "ios" && props.moduleName !== "customizer"?
            <div onPointerDown={tapEffectOnClick} className="position-relative cart-icon click-effect white-card shadow-light center-aligned-column ion-margin-start" style={{width:"28px",height:"28px"}} onClick={fullScreenBrowserToggle}>
              <IonIcon className="color-primary font-normal" icon={expand} />
            </div>
            :null}
          
           
        </div>

        {props.moduleName === "customizer"?
        <div className='header-user-options hide-in-mobile customizer-file-options white-card shadow-medium border' >
          <div className='option border-top pointer display-flex-row'>
            <div className='heading4 color-black font-small center-aligned-column' onClick={enableFloorplanMode}>Edit floorplan</div>
          </div>
          <div className='option border-top pointer display-flex-row' onClick={clearCache}>
            <div className='heading4 color-black font-small center-aligned-column'>Clear cache</div>
          </div>
          <div className='option border-top pointer display-flex-row' onClick={exportGlbHandle}>
            <div className='heading4 color-black font-small center-aligned-column'>Export Glb</div>
          </div>
        </div>
        
        :null}

        
       

      </div>
  );
};

export default InnerPageHeader;
