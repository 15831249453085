import React, { useEffect, useState } from "react";
import { checkFromCache } from "../../../utils/cache/cache";
import { getProductImageUrl } from "../../../utils/moodboard/helper";
import Button2 from "../../common/ui-components/buttons/Button2";
import "../../style/admin/product.css";
import ProductViewer from "./productviewer/ProductViewer";
import Modal from "../../common/shared-components/Modals/Modal";
import { productViewerInit } from "../../../utils/customizein3d/productViewer/productViewer";
import { waitFor } from "../../../utils/methods";
import { addItemToLocalStorage } from "../../../utils/cache/localstorage";
import { hideComponentLoader, showToast } from "../../../utils/UI_methods/global";
import { isTouchDevice } from "../../../utils/customizer/helper";
import ProductImage from "../../common/ui-components/elements/ProductImage";



const Products: React.FC<{
  configObj:any
  currProduct: any;
  setOpenProjectAction: (value: boolean) => void;
  viewProductin3DAction: (product: any) => void;
  gotoCustomizeIn3D: (product: any) => void;
}> = (props) => {

  useEffect(()=>{
    setTimeout(() => {
      displayImages()
    }, 500);
  },[props.currProduct])
  
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);


  async function displayImages() {
    let imageUrlKey = getProductImageUrl(
      props.configObj?.BASE_URL_ASSETS,
      props.configObj?.clientName,
      props.currProduct.product_name,
      props.currProduct.category_name,
      props.currProduct.sub_category_name
    );

    let image = document.querySelector("#productImageDetailsPage");
    await checkFromCache(imageUrlKey)
      ?.then((url: any) => {
        image?.setAttribute("src", url);
      })
      .catch((err) => {
        image?.setAttribute("src", "assets/images/placeholder.png");
      });
    // }
  }

  async function viewProductin3DAction() {
    setIsOpenViewProduct(true)
    await waitFor(500)
    productViewerInit(props.currProduct,"productViewerWrapper")
    hideComponentLoader("canvasLoader")
  }

  async function viewInArAction() {
    if(!isTouchDevice()){
      alert("Only supported on Android / Ios")
      return
    }
    viewProductin3DAction()
  }

  async function gotoCustomizeIn3D(product:any){
    addItemToLocalStorage("currProduct",product)
    // props.setCurrProductDetails(product.product_id)
    // setCustomizein3dConfiguration(product,false)
    // history.push("/product-3dcustomization");
    window.location.href = "/product-3dcustomization"
    // applicationConfig?.filters?.products?.setRecentConfigured(product)
  }

  return (
    <div className="full-height-width">

        <Modal
            isOpen = {isOpenViewProduct}
            Content = {()=>(
              <ProductViewer
                id="productViewerWrapper"
              />
            )}
            onDismiss = {()=>setIsOpenViewProduct(false)}
            heading={"Product Viewer"}
            classNames="medium-y-modal"
            id=""
            FooterContent={()=>(<div></div>)}
        />

        <div className="header-content bg-color-light heading1 center-aligned-column">
          <div className="display-flex-row no-wrap">
            <span className="heading1 color-black">{props.currProduct?.category_name} / </span>
            <span className="heading1 color-black"> {props.currProduct?.sub_category_name} / </span>
            <span className="heading1 color-danger">{props.currProduct?.product_name}</span>
          </div>
        </div>
        <div className="body-content center-aligned-column">
          <div className="full-width" style={{height:"70%"}}>
          <ProductImage product={props.currProduct} />
          </div>
        </div>
        <div className="footer-content">
          <div className="center-aligned-row  no-wrap ion-margin-top">
              <Button2
                configObj={{}}
                action={()=>{viewProductin3DAction()}}
                label={"View in 3D"}
                fontAwesomeIcon={"fas fa-cube color-white"}
                iconPos="left"
                classNames={"shadow-medium no-border margin0 bg-color-primary"}
                svgUrl="assets/icon/svg/cube_white.svg"
              />

              <Button2
                configObj={{}}
                action={()=>{viewInArAction()}}
                label={"View in Ar"}
                fontAwesomeIcon={"fas fa-cube color-warning"}
                iconPos="left"
                classNames={"shadow-medium no-border"}
                svgUrl="assets/icon/svg/ar.svg"
              />
            </div>
        </div>
 

    </div>
  );
};

export default Products;
