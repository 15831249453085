import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { stringWithoutWhiteSpace } from "../../../../utils/methods";
import { IonIcon } from "@ionic/react";
import { rippleEffectOnClick } from "../../../../utils/UI_methods/ui_effects";
  
  const ControlsTab: React.FC<{
    configObj:any
    tabItems:Array<any>
    currSelectedValue:string
    action:(value:any)=>void
    theme:string
    parentContainerId?:string
  }> = (props) => {

    const[fillBgColor,setFillBgColor] = useState("")

    const wrapperRef = useRef(null)

    useEffect(()=>{
     if(wrapperRef?.current && props.tabItems.length){
      setTimeout(() => {
        updateActiveElementWidth()
        updateActiveElementPos()
      }, 1000);
     }
    },[props.tabItems,wrapperRef])

    useEffect(()=>{
     if(props.currSelectedValue && wrapperRef?.current){
      setTimeout(() => {
        updateActiveElementWidth()
        updateActiveElementPos()
      }, 100);
     }
    },[props.currSelectedValue,props.tabItems,wrapperRef])

    function updateActiveElementWidth() {
      let wrapperWidth = $(wrapperRef.current).find(".controls-tab.--is-active").width()
      let fillBar = $(wrapperRef.current).find("#controlsTabFill")
      fillBar.css({"width":`${wrapperWidth}px`})
    }

    function updateActiveElementPos() {
      if(wrapperRef.current){
        let allTabs = wrapperRef.current.querySelectorAll(".controls-tab")
        let offsetX = 0
        if(allTabs.length > 1){
          for (const currTab of allTabs) {
              if($(currTab).hasClass("--is-active")){
                break;
              }
              offsetX += $(currTab).outerWidth(true)
          }
        }
        let fillBar = $(wrapperRef.current).find("#controlsTabFill")
        fillBar.css({"left":`${offsetX + 5}px`})
      }
     
    }


    let pos = 0


    function updateTabValue(event:any,val:string){
      props.action(val)
    }

    useEffect(()=>{
      if(props.theme === "red"){
        setFillBgColor("rgb(0,255,255)")
      }else{
        setFillBgColor("rgb(0,0,0)")
      }
    },[])
   
 
    return (
      <div ref={wrapperRef}  className="display-flex-row full-width position-relative controls-tab-wrapper" style={{padding:"0 0"}}>

      {/* <div className="center-aligned-column right-middle zindex30" style={{right:"0"}}> <i className="fas fa-chevron-right color-black"></i> </div>
      <div className="center-aligned-column left-middle zindex30" style={{left:"0"}}> <i className="fas fa-chevron-left color-danger"></i> </div> */}
      <div  className="controls-tab-container overflow-x-scroll full-width hide-scrollbar">
        {props.tabItems.length?props.tabItems.map((currTab:any,index:number)=>{
          let classNames = []
          classNames.push("controls-tab display-flex-row  no-wrap overflow-hidden position-relative")
          if(props.theme === "black"){
            classNames.push("controls-tab-black")
          }
          if(props.theme === "red"){
            classNames.push("controls-tab-red")
          }
          if(stringWithoutWhiteSpace(currTab.tabName)=== stringWithoutWhiteSpace(props.currSelectedValue)){
            classNames.push("--is-active")

            //100to convert into percentages 
            let index = 0
            for (let i = 0; i < props.tabItems.length; i++) {
              let tab = props.tabItems[i]
              if(tab.tabName === props.currSelectedValue){
                index = i
              }
            }
            if(index != 0){
              pos = 100 * (index / props.tabItems.length)
            }
          }
          return(
            <div style={{minWidth:`min-content`,width:"auto"}} className={classNames.join(" ")} key={index} onPointerDown={rippleEffectOnClick} onClick={(event) => updateTabValue(event,currTab.tabName)}>
              
              {currTab.ionIcon?
              <div className="center-aligned-column marginRight5 pointer-none"> <IonIcon className="center-aligned-column icon" icon={currTab.ionIcon}></IonIcon> </div>
              :null}

              {currTab.icon?
                <div className="center-aligned-column marginRight5 pointer-none"> <i className={`${currTab.icon} icon color-dark`}></i> </div>
              :null}

              <div className="pointer-none position-relative center-aligned-column" style={{pointerEvents:"none"}}>
                <div className="display-flex-row no-wrap">
                  <span className="text-index text padding0 center-aligned-column">{index+1}. </span>
                  <span className="text-ellipsis text" style={{maxWidth:"6rem"}}>{currTab.label}</span>
                </div>
                
                {currTab.count !== undefined?
                  <span id={`Count_${stringWithoutWhiteSpace(currTab.tabName)}`} className="bg-color-warning heading4 count-badge badge center-aligned-column padding0 top-right font-ex-small color-white" style={{right:"-1.3rem",top:"5px",borderRadius:"50%"}}>{currTab.count|| 0}</span>
                :
                null
                }
              </div>

            </div>
          )
        }):null}
      
      </div>

      <div className="controls-tab-active-bar">
        <div className="controls-bar-fill top-left" id="controlsTabFill"  style={{padding:"0",background:props.theme==="red"?"var(--op-color-danger)":"black"}}></div>
      </div>

      </div>
    );
  };
  
  export default ControlsTab;
  