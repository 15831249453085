import $ from "jquery"
import { getProductGalleryImageUrl, getProductImageUrl } from "../moodboard/helper"
import { getUrlPrefix } from "../customizer/customizer"
import { applicationConfig, stringWithoutWhiteSpace } from "../methods"
import { checkFromCache, getThumbnailsUrlKey, untarAndSaveToCache } from "../cache/cache"
import { STORE } from "./storeConfiguration"
import { getProductModuleUrl } from "../customizein3d/modules/helper"


let extensions = [".png",".webp",".jpeg",".jpg"]

export function ChangeProductCardSize(event:any) {
    $(".product-card-size-button").removeClass("--is-active")
    $(event.target).addClass("--is-active")

    $(".category-details-wrapper").removeClass("image-small")
    $(".category-details-wrapper").removeClass("image-medium")
    $(".category-details-wrapper").removeClass("image-large")

    const value = $(event.target).attr("data-value")
    $(".category-details-wrapper").addClass(value)
}


export async function loadAndDisplayProductImages(parentContainerId:string) {
    $(".product-image").attr("src","assets/images/placeholder.png")
    const parentContainer = document.getElementById(parentContainerId)
    let productsList = parentContainer?.querySelectorAll(".product-image") || [];
    for (let i = 0; i < productsList?.length; i++) {
      const productImageElement: any = productsList[i];
      const categoryName = productImageElement.getAttribute("data-category")
      const subCategoryName = productImageElement.getAttribute("data-subcategory")
      const productName = productImageElement.getAttribute("data-productname")
     
      let imageUrlKey = getProductImageUrl(applicationConfig?.BASE_URL_ASSETS,applicationConfig?.clientName,
        productName,categoryName,
        subCategoryName
      );
      let urlPrefix = getUrlPrefix(imageUrlKey);
      let tarFileKey = applicationConfig.awsConfig.getTarFileKey("products", {
        clientName: applicationConfig?.clientName,
        category: categoryName,
        subCategory: subCategoryName,
        productName: productName,
      });
      await untarAndSaveToCache(applicationConfig.awsConfig, urlPrefix, tarFileKey)
      displayImages(productImageElement,imageUrlKey);
    }
  }


  export async function loadAndDisplayProductImage(element:any,product:any) {
    // $(".product-image").attr("src","assets/images/placeholder.png")
    if(element){
      let clientName = product?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
      const categoryName = element.getAttribute("data-category")
      const subCategoryName = element.getAttribute("data-subcategory")
      const productName = element.getAttribute("data-productname")
      let imageUrlKey = getProductImageUrl(applicationConfig?.BASE_URL_ASSETS,clientName,
        productName,categoryName,
        subCategoryName
      );
      let urlPrefix = getUrlPrefix(imageUrlKey);
      let tarFileKey = applicationConfig.awsConfig.getTarFileKey("products", {
        clientName: clientName,
        category: categoryName,
        subCategory: subCategoryName,
        productName: productName,
      });
      await untarAndSaveToCache(applicationConfig.awsConfig, urlPrefix, tarFileKey)
      displayImages(element,imageUrlKey);
    }
  }

  export async function loadAndDisplayProductImageGalleryImage(imageContainerId:string,index:number) {
    // $(".product-image").attr("src","assets/images/placeholder.png")
    const productImageElement:any = document.getElementById(imageContainerId)
    if(productImageElement){
      const categoryName = productImageElement.getAttribute("data-category")
      const subCategoryName = productImageElement.getAttribute("data-subcategory")
      const productName = productImageElement.getAttribute("data-productname")
      let imageUrlKey = getProductGalleryImageUrl(applicationConfig?.clientName,
        productName,categoryName,
        subCategoryName,index
      );
      displayImages(productImageElement,imageUrlKey);
    }
  }


  export async function loadAndDisplayMaterialImage(imageContainerId:string) {
    // $(".product-image").attr("src","assets/images/placeholder.png")
    const ImageElement:any = document.getElementById(imageContainerId)
    if(ImageElement){
      const companyName = ImageElement.getAttribute("data-company")
      const collectionName = ImageElement.getAttribute("data-collection")
      const materialCode = ImageElement.getAttribute("data-material-code")
      let urlPrefix = applicationConfig.awsConfig.getTarFileKey("thumbnails",{companyName:companyName,collectionName:collectionName})
      let key = getThumbnailsUrlKey(companyName,collectionName,materialCode)
      await untarAndSaveToCache(applicationConfig.awsConfig,`thumbnails/OVL/${companyName}/${collectionName}`,urlPrefix)?.then(data=>{
        displayImages(ImageElement,key)
      }).catch(err=>{
        console.log(err)
      })
    }
  }

  export async function loadAndDisplayModuleImage(ImageElement:any) {
    // $(".product-image").attr("src","assets/images/placeholder.png")
    if(ImageElement){
      const categoryName = ImageElement.getAttribute("data-product-category")
      let awsConfig = applicationConfig.awsConfig
      let key = awsConfig.getTarFileKey("moduleImages",{clientName:applicationConfig?.defaultClientName,categoryName:categoryName})
      let urlprefix = `moduleImages/${applicationConfig?.defaultClientName}/${categoryName}`
      const productName = ImageElement.getAttribute("data-product-name")
      const subCategory = ImageElement.getAttribute("data-product-subcategory")
      untarAndSaveToCache(awsConfig,urlprefix,key)?.then(data=>{
        let key = getProductModuleUrl(applicationConfig?.BASE_URL_ASSETS,applicationConfig?.defaultClientName,productName,categoryName,subCategory)
        displayImages(ImageElement,key)
      }).catch(err=>{
      })

    }
  }

  


  //  export async function displayImages(imageElement:any,imageUrlKey:string) {
  //   await checkFromCache(imageUrlKey)
  //     ?.then((url: any) => {
  //       imageElement?.setAttribute("src", url);
  //       $(imageElement).parent().find(".component-loader").removeClass("--is-active");
  //     })
  //     .catch((err) => {
  //       $(imageElement).parent().find(".component-loader").removeClass("--is-active");
  //     });
  // }

  export function displayImages(imageElement:any, url:string) {
    const lastDotIndex = url.lastIndexOf('.');
    const defaultExtension = url.substring(lastDotIndex);
    return new Promise(async (resolve,reject)=>{
      for (let i = 0; i < extensions.length; i++) {
        let extension = extensions[i];
        let otherUrl = url?.replace(defaultExtension,extension);
        if(!otherUrl){
          reject(new Error("Invalid URL"));
          return; // Exiting the loop if URL is invalid
        }
        try {
            await checkFromCache(otherUrl)
            ?.then((url: any) => {
              imageElement?.setAttribute("src", url);
              $(imageElement).parent().find(".component-loader").removeClass("--is-active");
              resolve("Done")
            })
            .catch((err) => {
              $(imageElement).parent().find(".component-loader").removeClass("--is-active");
              resolve("Done")
            });
        } catch (error) {
          console.error(`File not found`);
          resolve("Done")
        }
      }
    });
  }


  export function openSideMenu() {
    $(".side-menu-wrapper").addClass("--is-active")
  }

  export function closeSideMenu() {
    $(".side-menu-wrapper").removeClass("--is-active")
  }

  export function getIconForOptions(currCategory:string) {
    if(currCategory === "Sofa"){
      return "fas fa-couch"
    }
    if(currCategory === "Cot"){
      return "fas fa-bed"
    }
  }

  export function getCustomVariantImageUrl(product:any,variantName:string) {
    return `https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/custom-variants/${product.category_name}/${product.product_name}/${variantName}.png`
  }

  export function getVariantImageUrl(currCategory:string,variant:any,currSize:string = "standard",config:any = null){
    let url = ""
    if(currCategory === "Sofa" || currCategory === "SofaBed" || currCategory === "UpholsteredBed" || currCategory === "Recliner"){
      let variantName = encodeURIComponent(config.name)
      url = `${STORE.BASE_URL_ASSETS}icons/variants/${currCategory}/${currSize}/${variantName}.svg`
      if(config?.options?.length){
        if(config?.name === variant.variantName){
          url = `${STORE.BASE_URL_ASSETS}icons/variants/${currCategory}/${currSize}/${variantName}_${variant.type || "LHS"}.svg`
        }else{
          url = `${STORE.BASE_URL_ASSETS}icons/variants/${currCategory}/${currSize}/${variantName}_${config.options[0]}.svg`
        }
      }
    }else{
      url = `${STORE.BASE_URL_ASSETS}icons/category/${currCategory}.svg`
    }
    return url
  }