import $ from "jquery";
import React, { useEffect, useState } from "react";
import { applicationConfig, waitFor } from "../../../../../utils/methods";
import { hideComponentLoader, showComponentLoader } from "../../../../../utils/UI_methods/global";
import Loader from "../../../../common/shared-components/main-ui/Loader";
import Rembg from "../../../../common/ui-components/forms/rembg/Rembg";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import { getRandomNumber } from "../../../../../utils/products/productviewer_shopify";
 

const MyImages: React.FC<{
  updateBackground:(url:string)=>void;
}> = (props) => {

  const [refreshImages, setRefreshImages] = useState(1);
  const [isOpenImageUpload, setIsOpenImageUpload] = useState(false);
  const [uploadImageLoaderId] = useState("uploadImageLaoder");

  const [myImages, setMyImages]: any = useState([]);



  // useEffect(()=>{
  //   setTimeout(() => {
  //     if(selectedFile){
  //       displayUploadedImage(selectedFile,"collectionUploadImg")
  //     }
  //   }, 200);
   
  // },[selectedFile])

  useEffect(() => {
      let albumName = "public";
      var albumPhotosKey = encodeURIComponent(albumName) + '/roomBackgrounds/uploads/' + applicationConfig?.clientName + "/" + applicationConfig?.user?.username + "/";
      applicationConfig.awsConfig.viewAlbumContents(albumPhotosKey)
        .then((photos: any) => {
          setMyImages([...photos.reverse()]);
        })
        .catch((err) => {
          console.log(err);
      });
  }, [refreshImages, applicationConfig]);


  const uploadImage = (imageDetails:any) => {
    showComponentLoader(uploadImageLoaderId);

    if (imageDetails.selectedFile) {
      let albumName = "public";
      var albumPhotosKey = encodeURIComponent(albumName) + "/roomBackgrounds/uploads/" +  applicationConfig?.clientName + "/" + applicationConfig?.user?.username + "/";
      applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey, imageDetails.selectedFile,null,true).then((data:any) => {
          postUploadActions()
        })
        .catch((err) => {
          console.log(err);
          // setSelectedFile(null)
          hideComponentLoader(uploadImageLoaderId);
        });
    } else {
      alert("No file selected");
      hideComponentLoader(uploadImageLoaderId);
    }
  };

  async function postUploadActions() {
    await waitFor(1500)
    setRefreshImages(getRandomNumber(1,520));
    hideComponentLoader(uploadImageLoaderId);
    // setSelectedFile(null)
  }

  function getImageUrl(key:string) {
    key = key.replace("public/","")
    return applicationConfig.aws_public_url+key
  }

  return (
    <React.Fragment>
    
      <Loader
        className="fade-background-loader"
        id={uploadImageLoaderId}
        loaderType="svg"
        data={{}}
      />

      <Rembg
        isOpen={isOpenImageUpload}
        setIsOpen={setIsOpenImageUpload}
        uploadImage={uploadImage}
        isFloorplan={false}
      />

         

        <div className="padding5 width-auto">  
          <Button2
              configObj={{id:""}}
              action={() => setIsOpenImageUpload(true)}
              label={"Upload Image"}
              fontAwesomeIcon={"fas fa-upload color-white"}
              iconPos="left"
              classNames={"shadow-medium button-small bg-color-primary"}
          />
        </div>

        <div className="full-width display-flex-row overflow-y-scroll">
                            {myImages.map((currBack:string,index:number)=>{
                                return(
                                  <div key={index} className="padding5 width50"  onClick={()=>{props.updateBackground(currBack)}}>
                                    <img src={getImageUrl(currBack)} alt="" className="full-width"/>
                                  </div>
                                )
                            })}
                    </div>

    
      


        

    </React.Fragment>
  );
};

export default MyImages;
