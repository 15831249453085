import React, { useEffect, useState } from "react";
import { hideComponentLoader } from "../../../../../utils/UI_methods/global";
import { configuration, releaseMemory, setCustomizein3dConfiguration } from "../../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig } from "../../../../../utils/methods";
import { getPageTourElements } from "../../../../../utils/siteTour/elements";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Canvas from "../../../../common/modules/customizein3d/canvas/Canvas";
import CameraSettings from "../../../../common/modules/customizein3d/canvas/Render/CameraSettings";
import Actions from "../../../../common/modules/customizein3d/controls/Actions";
import StartCustomerSession from "../customer/StartCustomerSession";
import ElevateControls from "./ElevateControls";
import StoreControls from "./StoreControls";
 

const CustomizeIn3D: React.FC<{
  product:any
  productConfiguration:any
  setIsOpenAdvanced:(val:boolean)=>void
  prevHandle:()=>void
  nextHandle:()=>void
  currSelectedTab:any
  isElevateMode:any
  changeProduct:(val:any)=>void
  isProductChanged:boolean
}> = (props) => {
 
  
    const[isSpacePlannerMode,setIsSpacePlannerMode] = useState(false)
    const[isFloorplanMode,setIsFloorplanMode] = useState(false)
    const[isTakeRenderMode,setIsTakeRenderMode] = useState(false)
    const[isViewProductMode,setIsViewProductMode] = useState(configuration?.isViewProductMode)
    const[modulesConfigurtion,setModulesConfiguration] = useState(configuration)
    const[showGoBackAlert,setShowGoBackAlert] = useState(false)
    const[isOpenStartSession,setIsOpenStartSession] = useState(false)

    const[tourElementsList] = useState(getPageTourElements("VariantCreator3D"))


    useEffect(() => {
      if(!props.isProductChanged){
        setTimeout(() => {
          init(props.product)
          setTimeout(() => {
            // if(getWindowWidth() > 480 && !isPotraitMode()){
            //   enableConfigureMode()
            // }
          }, 3000);
          hideComponentLoader("mainLoader")
        }, 300);
    
        applicationConfig.setFunctionRef("customizein3d",{setIsViewProductMode:setIsViewProductMode})
        applicationConfig.setFunctionRef("customizein3d",{toggleSpacePlannerValue:setIsSpacePlannerMode})
        applicationConfig.setFunctionRef("customizein3d",{setShowGoBackAlert:setShowGoBackAlert})
        applicationConfig.setFunctionRef("customizein3d",{setIsTakeRenderMode:setIsTakeRenderMode})
        applicationConfig.setFunctionRef("customizein3d",{setIsFloorplanMode:setIsFloorplanMode})
      }
          
    }, [props.product]);

  
    function init(product:any) {
      releaseMemory()
      let configuration = setCustomizein3dConfiguration(product,isViewProductMode)
      setModulesConfiguration(configuration)
      STORE.productConfiguration3D = configuration
    }
  
  
    return (
        <div className="full-height-width customize-canvas-controls-wrapper" id="mainCustomizein3DWrapper" style={{flexDirection:"row"}}>
         {/* <SiteTour
          tourElementsList={tourElementsList}
          moduleName="productConfigurator"
        /> */}
        <StartCustomerSession isOpen={isOpenStartSession} setIsOpen={setIsOpenStartSession} createCustomerCallback={()=>{}}/>
         
          <div className="customize-canvas-wrapper position-relative center-aligned-row white-card" style={{zIndex:1}}>
              {modulesConfigurtion?
              <Canvas
                modulesConfigurtion={modulesConfigurtion}
                isSpacePlannerMode={isSpacePlannerMode}
                isFloorplanMode={isFloorplanMode}
                currProduct={props.product}
                isViewProductMode={isViewProductMode}
                setIsSpacePlannerMode={setIsSpacePlannerMode}
                isElevateMode={props.isElevateMode}
              />
            :null}


              {isTakeRenderMode?
                <CameraSettings
                />
              :null}

              <Actions
                configObj={applicationConfig}
              />

            {/* <div className="right-middle">
              <Button2
                configObj={{id:""}}
                action={props.nextHandle}
                label={""}
                fontAwesomeIcon={"fas fa-bars color-warning"}
                iconPos="right"
                classNames={"no-shadow margin0 button-small"}
              />
            </div> */}
          </div>
          <div className="customize-controls-wrapper store-controls-wrapper  elevate-main-wrapper display-none full-height position-relative white-card" id="controlsWrapper" style={{zIndex:0}}>
            {props.isElevateMode && modulesConfigurtion?
            <ElevateControls
              modulesConfigurtion={modulesConfigurtion}
            />
            :
            <>
            {modulesConfigurtion?
              // <Controls
              //   product={props.product}
              //   modulesConfigurtion={modulesConfigurtion}
              //   productConfiguration={props.productConfiguration}
              //   setIsOpenAdvanced={props.setIsOpenAdvanced}
              //   setIsOpenSummary={props.setIsOpenSummary}
              //   nextHandle={props.nextHandle}
              //   prevHandle={props.prevHandle}
              //   currSelectedTab={props.currSelectedTab}
              //   isElevateMode={props.isElevateMode}
              // />
              <StoreControls
                product={props.product}
                modulesConfigurtion={modulesConfigurtion}
                productConfiguration={props.productConfiguration}
                isElevateMode={props.isElevateMode}
              />
            :null}
            </>
            
            }
            
          </div>
        </div>
    );
};

export default CustomizeIn3D;
