import $ from "jquery";
import React, { useState } from "react";
import { STORE, getCategoryPartsList } from "../../../../../utils/store/storeConfiguration";
import MaterialImage from "../../../../common/ui-components/elements/MaterialImage";
import { getObjectArrayOfDistictValues } from "../../../../../utils/methods";

 
const ModulesFinishDetails: React.FC<{
    configurations:any
    product:any
}> = (props) => {


    const[partsList] = useState(getCategoryPartsList(props.product?.category_name))
    const[configurations] = useState(props.configurations)
    const[category] = useState(props.product.category_name || props.product.categoryName)


   
    function openPartMappingHelper(){
        $("#partMappingHelper").click()
    }

    
    function getCodeFromPartName(partName:string) {
        let product = props.product
        partName = partName.toLowerCase()
        const partsList = getCategoryPartsList(product?.category_name)
        let result = partsList.find(currPart => currPart.partName.toLowerCase().includes(partName) || partName.includes(currPart.partName.toLowerCase()))
        if(result){
            return result.partCode
        }
        return "NA"
    }

    function getConfiguration(configurations:any,partName:string) {
        partName = partName.toLowerCase()
        return configurations.find(currPart => currPart.partName.toLowerCase().includes(partName) || partName.includes(currPart.partName.toLowerCase()))
    }
 
 

    function getFabricIconUrl(){
        let url = ""
        let category = props.product?.category_name
        if(category === "Sofa"){
            url = `https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/orders/fabricmapping/Sofa/Sofa.svg`
        }
        if(category === "UpholsteredBed"){
            url = `${STORE.BASE_URL_ASSETS}icons/category/UpholsteredBed.svg`
        }
        if(category === "ArmChair"){
            url = `${STORE.BASE_URL_ASSETS}icons/category/ArmChair.svg`
        }
        if(category === "Recliner"){
            url = `${STORE.BASE_URL_ASSETS}icons/category/Recliner.svg`
        }
        if(category === "SofaBed"){
            url = `${STORE.BASE_URL_ASSETS}icons/category/SofaBed.svg`
        }
        return url
    }

    function SofaConfig() {
        return(
        <table>
                <tbody>
                {partsList.map((currPart:any,index:number)=>{
                    let result = getConfiguration(configurations,currPart.partName)
                    return(
                        <tr key={index} className={`${!result?" display-none":""}`}>
                            <td onClick={openPartMappingHelper}>A{index+1}</td>
                            <td>{result?.collectionName || "Default"}</td>
                            <td className="">{result?.materialCode || "Default"}</td>
                            <td>
                            <span>
                                <MaterialImage dimensions={{width:"4rem",height:"4rem"}} material={result}/>
                            </span>
                            </td>
                        </tr>
                        
                    )
                })}
            </tbody>
        </table>
        )
    }

    function WardrobeConfig() {
        let configs = getObjectArrayOfDistictValues(props.configurations,"material_code")
        return(
        <table>
                <tbody>
                {configs.map((currPart:any,index:number)=>{
                    return(
                        <tr key={index}>
                            {/* <td onClick={openPartMappingHelper}>{currPart?.partName || "Default"}</td> */}
                            <td>{currPart?.collectionName || "Default"}</td>
                            <td className="">{currPart?.materialCode || "Default"}</td>
                            <td>
                            <span>
                                <MaterialImage dimensions={{width:"2rem",height:"2rem"}} material={currPart}/>
                            </span>
                            </td>
                        </tr>
                        
                    )
                })}
            </tbody>
        </table>
        )
    }

 
  return (
    <div className="display-flex-row no-wrap">
            <div className="">
                <div className="Pricing"></div>
                {category === "Sofa" ?
                    <SofaConfig/>
                :props.configurations.length?
                    <WardrobeConfig/>
            :"NA"
                }
                
            </div>
    </div>
  );
};

export default ModulesFinishDetails;
