import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../UI_methods/global";
import { applicationConfig, stringWithoutWhiteSpace, waitFor } from "../../methods";
import { SofaVariantsConfiguration } from "../../store/customization-options-data/sofa";
import { Variant } from "../../store/storeConfiguration";
import { hideConfigOptionsWindow } from "./UI_methods";
import { appendModel, configuration, group, resetModulesGroup, scene } from "./customizein3d";


export class Layouts{
    variant:Variant

    constructor(){

    }
}

export class SofaLayout extends Layouts{

    super(){

    }

    async create(moduleNames:Array<string>){
        let product = configuration.product  
        let isValidConfig = true
        let modulesList = []
        for (const moduleName of moduleNames) {
            let module = applicationConfig?.data?.productModules.find(currModule => currModule.product_name === product.productName && currModule.module_name.toLowerCase().includes(moduleName.toLowerCase()))
            if(!module){
                isValidConfig = false
                showGlobalToast("Config does't exists",2000,"error")
                return
            }
            modulesList.push(module)
        }
        configuration.reset()
        resetModulesGroup()
        await waitFor(1000)
        // scene.remove(group)
        showComponentLoader("mainLoaderSpinner")
        for (let i = 0; i < modulesList.length; i++) {
            const module = modulesList[i];
            await appendModel(module,false)
            await waitFor(200)
            if(i === modulesList.length - 1){
                hideComponentLoader("mainLoaderSpinner")
            }
        }
        
    }

    async loadDefault(){
        let product = configuration.product 
        let allVariants = SofaVariantsConfiguration.filter(currVariant => currVariant.categories.includes(stringWithoutWhiteSpace(product?.subCategoryName)))
        // for (const currVariant of allVariants) {
        //     let moduleNames = currVariant.configuration
        //     let module = applicationConfig?.data?.productModules.find(currModule => currModule.product_name === product.productName && currModule.module_name.toLowerCase().includes(moduleName.toLowerCase()))
        //     if(module){
        //         modulesList.push(module)
        //     }
            
        // }
        this.create(allVariants[0].configuration)
        hideConfigOptionsWindow()
    }

}


 
 