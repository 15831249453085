import axios from "axios";
// import FormData from "form-data";
// var Form = require('form-data');
import FormData from 'form-data';
import AwsConfig from "../utils/aws-sdk/AwsConfig";
import { projectConfiguration } from "../utils/customizein3d/area3dmodel/area3dModel";
import { applicationConfig, getCategoryMaterialTypesArray, getLoggedInUserName, getToken, getUser, logger, stringWithoutWhiteSpace, waitFor } from "../utils/methods";
import { hideComponentLoader, showComponentLoader } from "../utils/UI_methods/global";
import http from "./http-common";
import { ProjectForm } from "../utils/projects/projectForm";
import { DeviceUUID } from "device-uuid";
import { getItemFromLocalStorage } from "../utils/cache/localstorage";


var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

// const BASE_PATH = "http://13.233.90.128:3000/"
// export const BASE_PATH = "http://localhost:3100/"
export const BASE_PATH = "https://ogtv5ck91f.execute-api.ap-south-1.amazonaws.com/"

var clientId = null
var applicationId = 2
var sharedProductId = null  
var sharedProjectId = null
var shareCollectionId = null

var authenticationData = {
  Username : 'opusapiaccessuser',
  Password : 'Opus@2022',
};
var poolData = { UserPoolId : 'ap-south-1_PXMqy1SF3',
  ClientId : 'sqvo96dld4i27it6kuf5aodd4'
};
export var AWS_AUTH_TOKEN = ""


export function API_INIT(data:any) {
  clientId = data.clientId
  if(data.moduleName==="projects"){
    sharedProjectId = data.shareItemId || null
  }
  if(data.moduleName==="products"){
    sharedProductId = data.shareItemId || null
  }
  if(data.moduleName==="collections"){
    shareCollectionId = data.shareItemId || null
  }
}


export function setShareProductId(id:number) {
  sharedProductId = id
}

export function setClientId(id:number) {
  clientId = id
}

export async function getTextures(){
    const response = await fetch(`${BASE_PATH}api/productmaterials?clientId=${clientId}&applicationId=${applicationId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}

export async function getApplicationMaterials(){
  const response = await fetch(`${BASE_PATH}api/applicationMaterials`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getProjectMaterials(){
  const response = await fetch(`${BASE_PATH}api/projectMaterials?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getTarFile(){
  return axios
        .get(
          `https://elevateardemo.s3.ap-south-1.amazonaws.com/opusdata/sofa.tar`,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization":AWS_AUTH_TOKEN,
              "Access-Control-Allow-Origin":"*"
            },
          }
        )

}



export const uploadFloorPlan = (projectId:any,SELECTED_FILE:any) =>{
    let formData = new FormData();

    let blob = SELECTED_FILE.slice(0, SELECTED_FILE.size, 'image/png'); 
    SELECTED_FILE = new File([blob], `${projectId}_floorplan.png`, {type: 'image/png'});

    formData.append("file", SELECTED_FILE);
    return http.post(`${BASE_PATH}api/uploadFloorPlan/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
}


export const uploadRenderCamera = (formData:any) =>{

  return http.post(`${BASE_PATH}api/uploadRenderCamera`, formData, {
    headers: {
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
      "Authorization":AWS_AUTH_TOKEN
    },
  })
}

export const uploadShellForRendering = (formData:any) =>{

  return http.post(`${BASE_PATH}api/uploadShellForRendering`, formData, {
    headers: {
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
      "Authorization":AWS_AUTH_TOKEN
    },
  })
}



export async function getMoodboardList(){
  let queryString = `clientId=${clientId}`
  if(shareCollectionId){
    queryString = `clientId=${clientId}&&collectionId=${shareCollectionId}`
  }
  const response = await fetch(`${BASE_PATH}api/getMoodboards?${queryString}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export const uploadProductImage = async(projectId:any,SELECTED_FILE:any) =>{
    let formData:any = new FormData();

    // let blob = SELECTED_FILE.slice(0, SELECTED_FILE.size, 'image/png'); 
    // SELECTED_FILE = new File([blob], `${projectId}_floorplan.png`, {type: 'image/png'});

    formData.append("file", SELECTED_FILE);
    // return axios.post(`${BASE_PATH}api/uploadProductImage/`,formData, {
    //   headers: {
    //     'Content-Type': "multipart/form-data"
    //   },
    // })
    return axios.post(`${BASE_PATH}api/uploadProductImage/`, formData)
    // const concat = require("concat-stream")
    
    // fd.append("hello", "world")
    // formData.append("file", SELECTED_FILE)
    // return axios.post(`${BASE_PATH}api/uploadProductImage/`, formData, {
    //   headers:{
    //     ...axios.defaults.headers,
    //     ...Headers
    //   }
    // })


    // const response  = await fetch(`${BASE_PATH}api/uploadProductImage/`,{
    //   method:"POST",
    //   body:formData,
    //   headers:{
    //     'Content-Type': 'multipart/form-data'
    //   }
    // })
    // const jsonResponse = await response.json();
    // if(true){
    //     return Promise.resolve(jsonResponse)
    // }else{
    //     return Promise.reject(jsonResponse.message)
    // }
}

export const saveProjectAreas = (projectId:number,areas:any) => {
    let data = {
        projectId:projectId,
        areas: JSON.stringify(areas)
      };
  
      return axios
        .post(
          `${BASE_PATH}api/saveProjectAreas`,
          {
            data: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization":AWS_AUTH_TOKEN
            },
          }
        )
}

export const updateProjectMaterialRelationship = (projectId:number,ids:any) => {
    let data = {
      clientId:applicationConfig.clientId,
      projectId:projectId,
      ids: ids
    };

    return axios
      .post(
        `${BASE_PATH}api/updateProjectMaterialRelationship`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}

export function updateVisualizerConfig(data:any) {
  
  return   axios
  .post(
    `${BASE_PATH}api/updateCurrentConfig`,
    {
      data: data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization":AWS_AUTH_TOKEN
      },
    }
  )
}


export function saveProductConfiguration(data:any) {
  
  return   axios
  .post(
    `${BASE_PATH}api/saveProductConfiguration`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization":AWS_AUTH_TOKEN
      },
    }
  )
}

export function deleteProduct(productId:any) {
  
  return   axios
  .post(
    `${BASE_PATH}api/deleteProduct`,
    {
      productId:productId
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization":AWS_AUTH_TOKEN
      },
    }
  )
}




export async function createAndSaveRender(data:any,delay:number,index:number) {

  let endpoints = [
    "https://vi1o2s436l.execute-api.ap-south-1.amazonaws.com/api/createrender",
    "https://falz0zc82m.execute-api.ap-south-1.amazonaws.com/api/createrender2",
    "https://z76xvqlti6.execute-api.ap-south-1.amazonaws.com/api/createrender3",
    "https://6gkvu2bc5j.execute-api.ap-south-1.amazonaws.com/api/createrender4",

    "https://vi1o2s436l.execute-api.ap-south-1.amazonaws.com/api/createrender",
    "https://falz0zc82m.execute-api.ap-south-1.amazonaws.com/api/createrender2",
    "https://z76xvqlti6.execute-api.ap-south-1.amazonaws.com/api/createrender3",
    "https://6gkvu2bc5j.execute-api.ap-south-1.amazonaws.com/api/createrender4"
  ]

  // let endpoint = "https://vi1o2s436l.execute-api.ap-south-1.amazonaws.com/api/createrender"
  // ${BASE_PATH}api/createRender
  let endpoint = endpoints[index]
  await waitFor(delay)
  return axios
  .post(
    `${endpoint}`,
    {
      configuration: data,
    },
    {
      headers: {
        "Content-Type": "application/json"
      },
    }
  )
}



export async function createShell(data:any) {

  let endpoint ="https://vi1o2s436l.execute-api.ap-south-1.amazonaws.com/api/createrender"
  return new Promise((resolve,reject)=>{
      resolve(
        axios
        .post(
          `${endpoint}`,
          {
            configuration: data,
          },
          {
            headers: {
              "Content-Type": "application/json"
            },
          }
        )
      )
  })
}




export async function awsLambdaApiTrigger(data:any,endpoint:string) {
  let api = `https://vi1o2s436l.execute-api.ap-south-1.amazonaws.com/api/${endpoint}`

       return axios
        .post(
          `${api}`,
          {
            configuration: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization":AWS_AUTH_TOKEN
            },
          }
        )
    
  }

export function uploadRenderToS3(data:any) {

  // ${BASE_PATH}api/createRender
  return   axios
  .post(
    `${BASE_PATH}api/uploadRender`,
    {
      configuration:data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization":AWS_AUTH_TOKEN
      },
    }
  )
}



export function downloadProductModuelesInEfs(product:any,moduleNamesArray:Array<any>) {
  let data = {
    ...product,
    clientName:applicationConfig?.clientName,
    modules:moduleNamesArray,
    subCategoryName:product.subCategory
  }

  console.log(data)
  // ${BASE_PATH}api/createRender
  return   axios
  .post(
    `${BASE_PATH}api/downloadProductModules`,
    {
      data:data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization":AWS_AUTH_TOKEN
      },
    }
  )
}


export function uploadGeneratedModelToS3(data:any) {

  return   axios
  .post(
    `${BASE_PATH}api/uploadModel`,
    {
      configuration:data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization":AWS_AUTH_TOKEN
      },
    }
  )
}

export function moveSplitRendersToDestination(data:any) {

  return   axios
  .post(
    `${BASE_PATH}api/moveRendersToDestination`,
    {
      configuration:data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization":AWS_AUTH_TOKEN
      },
    }
  )
}

export const saveMoodboard = (clientId:number,moodboardId:number,projectId:number,areas:Array<any>,name:string,sagment:string) => {
    let data = {
      clientId:clientId,
      projectId:projectId,
      moodboardId:moodboardId,
      areas:JSON.stringify(areas),
      name:name,
      sagment:sagment
    };

    return axios
      .post(
        `${BASE_PATH}api/saveMoodboard`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
"Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}

export const saveProjectAreaCategories = (projectId:number,areaCategories:any) => {
    let data = {
        projectId:projectId,
        areaCategories: JSON.stringify(areaCategories)
      };
  
      return axios
        .post(
          `${BASE_PATH}api/saveProjectAreasCategories`,
          {
            data: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization":AWS_AUTH_TOKEN
            },
          }
        )
}

export const saveMoodboardAreaCategories = (moodboardId:number,areaCategories:any) => {
  let data = {
      clientId:applicationConfig?.clientId,
      moodboardId:moodboardId,
      areaCategories: JSON.stringify(areaCategories)
    };

    return axios
      .post(
        `${BASE_PATH}api/saveMoodboardAreasCategories`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}

export const saveProject = (projectDetails:ProjectForm,clientId:number) => {
    let data = {
        ...projectDetails,
        clientId:clientId
      };
  
      return axios
        .post(
          `${BASE_PATH}api/saveProject`,
          {
            data: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
"Authorization":AWS_AUTH_TOKEN
            },
          }
        )
}

export const addProduct = (proudctData:any) => {
  let data = proudctData
    return axios
      .post(
        `${BASE_PATH}api/addProduct`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}

export const updateProduct = (proudctData:any) => {
  let data = proudctData
    return axios
      .post(
        `${BASE_PATH}api/updateProduct`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}


export const addMaterial = (materialData:any) => {
    let data = {...materialData,clientId:applicationConfig.clientId}
    return axios
      .post(
        `${BASE_PATH}api/addMaterial`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}
export const createCollection = (colldata:any) => {
  let data = colldata
    return axios
      .post(
        `${BASE_PATH}api/createCollection`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
"Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}

export const createCompany = (comData:any) => {
  let data = comData
    return axios
      .post(
        `${BASE_PATH}api/createCompany`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}
export const deleteProject = (projectId:number) => {

    return axios
      .post(
        `${BASE_PATH}api/deleteProject`,
        {
          projectId: projectId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}

export const deleteCollections = (collectionId:number) => {
    return axios
      .post(
        `${BASE_PATH}api/deleteMoodboard`,
        {
          moodboardId: collectionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}


export const updateProject = (projectForm:ProjectForm) => {
    return axios
      .post(
        `${BASE_PATH}api/updateProject`,
        {
          data: projectForm,
        },
        {
          headers: {
            "Content-Type": "application/json",
"Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}

export const updateProductModelAvailability = (ids:Array<any>) => {
  let data = {
      productIdArray:ids,
    };

    return axios
      .post(
        `${BASE_PATH}api/updateModelAvailableFlag`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}

export async function updateModelAvailability() {

  //#is_model_available, checkIsModelAvailable, IsModelAvailable
  showComponentLoader("mainLoader")
  await getProductIdsArrayWithModelAvailable().then((ids:any)=>{
    console.log(ids)
    updateProductModelAvailability(ids).then(data=>{
      console.log(data)
    }).catch(err=>{
      console.log(err)
    })
  })
  hideComponentLoader("mainLoader")

 
  
}

export function getProductIdsArrayWithModelAvailable() {
  let productsList = applicationConfig?.data?.productsList
  let ids = []
  return new Promise(async (resolve,reject)=>{
    for (let i = 0; i < productsList.length; i++) {
      const product = productsList[i];
      let tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:applicationConfig?.clientName,productName:stringWithoutWhiteSpace(product.product_name),categoryName:stringWithoutWhiteSpace(product.category_name),subCategoryName:stringWithoutWhiteSpace(product.sub_category_name)})
      await applicationConfig.awsConfig.getObject(tarfilekey).then(async (data:any)=>{
        ids.push(product.product_id)
        if(i === productsList.length-1){
          resolve(ids)
        }
      }).catch((error:any)=>{
        if(i === productsList.length-1){
          resolve(ids)
        }
      })
    }
  })
}




export function saveMoodBoardConfig(data:any) {
  return axios.post(
    `${BASE_PATH}api/updateMoodboardConfig`,
    {
      data: data,
    },
    {
      headers: {
        "Content-Type": "application/json",
"Authorization":AWS_AUTH_TOKEN
      },
    }
  )
}





export const saveCurrentConfig = (data:any) => {

  axios
  .post(
    `${BASE_PATH}api/signin`,
    {
      config: data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization":AWS_AUTH_TOKEN
      },
    }
  )
  .then((response:any) => {
  })
  .catch((error:any) => {
  });
};


export const signin = (username:string,password:string) => {

  return axios
  .post(
    `${BASE_PATH}api/signin`,
    {
      username: username,
      password: password,
      deviceId:new DeviceUUID().get()
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
   
};


export async function getShareLink(data:any){
  return axios
    .post(
      `${BASE_PATH}api/getShareLink`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}


export function logout(){
  const user = getUser()
  const username = user?.username
  return axios
    .post(
      `${BASE_PATH}api/logout`,
      {
        username: username
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}




export async function getAllAreas(){
    const response = await fetch(`${BASE_PATH}api/areas/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}
export async function getProductImages(){
    const response = await fetch(`${BASE_PATH}api/getProductImages/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}

export async function getAreacategoryRelationship(){
    const response = await fetch(`${BASE_PATH}api/areaCategoryRelationship/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}


export async function getProducts(){
    const response = await fetch(`${BASE_PATH}api/products/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}


export async function getProductAttributes(){
    const response = await fetch(`${BASE_PATH}api/getProductAttributes/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}

 //Async makes this function to return a promise
 export async function getObjectMaterialTypes(){
    //Await makes this function to wait for a promise
    const response = await fetch(`${BASE_PATH}api/customizablematerials/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
    
}


export async function getColorOptions(){
    const response = await fetch(`${BASE_PATH}api/coloroptions`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}


export async function getProjects(){
    let queryString = `clientId=${clientId}`
    if(sharedProjectId){
      queryString = `clientId=${clientId}&&projectId=${sharedProjectId}`
    }
    const response = await fetch(`${BASE_PATH}api/projects?${queryString}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}

export async function getProductModules(){
  const response = await fetch(`${BASE_PATH}api/getModules?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getAllModules(){
  const response = await fetch(`${BASE_PATH}api/modulesList`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getCategoryModules(){
  const response = await fetch(`${BASE_PATH}api/getCategoryModules?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getModulesPrice(){
  const response = await fetch(`${BASE_PATH}api/getModulesPrice?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getModulesTypes(){
  const response = await fetch(`${BASE_PATH}api/getModuleTypes?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}


export async function getProjectAreaCategories(){
  const response = await fetch(`${BASE_PATH}api/getProjectAreaCategories?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getMoodboardAreaCategories(){
  const response = await fetch(`${BASE_PATH}api/getMoodboardAreaCategories?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}


export async function getAllCategories(){
  const response = await fetch(`${BASE_PATH}api/categories/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getAllSubCategories(){
  const response = await fetch(`${BASE_PATH}api/subCategories/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}



export async function getAllProjectAreas(){
    const response = await fetch(`${BASE_PATH}api/projectareas?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}

export async function getAllMaterialTypes(){
  const response = await fetch(`${BASE_PATH}api/materialTypes`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getAllCollections(){

  const response = await fetch(`${BASE_PATH}api/collections`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getCategoryMaterialTypes(){
    const response = await fetch(`${BASE_PATH}api/categoryMaterialtypes/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
         //using this to convert the object into format where each uniue object contains array of material types
         let categoryMaterials:any = {}
         let objectsSet = new Set()
         //Get the Distinct Values of objects 
         for (let value of jsonResponse.data) {
             objectsSet.add(value.category_name)
         }
         //Convert set into array
         let objectsArray = Array.from(objectsSet)

         // for each object create corrosponding material array
     
         for (let index = 0; index < objectsArray.length; index++) {
             let objectName:any = objectsArray[index]
             let materialTypeArray = getCategoryMaterialTypesArray(objectsArray[index],jsonResponse.data)
             categoryMaterials[objectName] = materialTypeArray
         }
        return Promise.resolve(categoryMaterials)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}



export async function getAreaProducts(projectId:number,areaId:number){
  
    const response = await fetch(`${BASE_PATH}api/areaproducts?projectId=${projectId}&areaId=${areaId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}


export async function getAllProjectAreaProducts(){
    const response = await fetch(`${BASE_PATH}api/areaproducts?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}

export async function getAllCustomizableObjects(){
    const response = await fetch(`${BASE_PATH}api/customizableobjects/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
      return Promise.reject(jsonResponse.message)
    }
}


export async function getForegroundSavedConfig(projectId:number = 0,productId:number = 0){
  if(!productId) {
    let currProject = getItemFromLocalStorage("currProject")
    projectId = currProject?.project_id || 0
  }
    const response = await fetch(`${BASE_PATH}api/getForegroundSavedConfig?projectId=${projectId}&&productId=${productId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}

export async function getSofaLegs(productId:number){
    const response = await fetch(`${BASE_PATH}api/getSofaLegs?productId=${productId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}

export async function getProductSavedConfigs(){
  const response = await fetch(`${BASE_PATH}api/getProductConfigrations?clientId=${clientId}`,{
    headers:{
      "Authorization":AWS_AUTH_TOKEN
    }
  });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getMoodBoardSavedConfig(){
    const response = await fetch(`${BASE_PATH}api/getMoodBoardSavedConfig?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}


export async function getProductsList(){
  let queryString = `clientId=${clientId}`
  if(sharedProductId){
    queryString = `clientId=${clientId}&&productId=${sharedProductId}`
  }
  const response = await fetch(`${BASE_PATH}api/productslist?${queryString}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getAllProductsList(){
  const response = await fetch(`${BASE_PATH}api/productslist?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}


export async function getAllViews(){
    const response = await fetch(`${BASE_PATH}api/getAllViews/`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
    const jsonResponse = await response.json();
    if(response.status==200 || response.status==404){
        return Promise.resolve(jsonResponse.data)
    }else{
        return Promise.reject(jsonResponse.message)
    }
}


export async function tokenVerification(token:string){
  let api = "verifyToken"
  if(token.length===32){
    api = "verifyShareToken"
  } 
  const response = await fetch(`${BASE_PATH}api/${api}?token=${token}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(jsonResponse.error){
    return Promise.reject(jsonResponse.error)
  }
  if(response.status==200){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.error)
  }
}




export async function apiGatwayAuthentication() {

  return new Promise((resolve,reject)=>{
    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    var userData = {
      Username : 'opusapiaccessuser',
      Pool : userPool
    };
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result:any) {
          // var accessToken = result.getAccessToken().getJwtToken();
          AWS_AUTH_TOKEN = result.idToken.jwtToken;
          // console.log(AWS_AUTH_TOKEN)
          resolve("Authenticated")
      },

      onFailure: function(err) {
          console.log(err);
          logout()
          reject("Error")
      },

    });
  })
  
}

export const saveUploadedFileDetails = (formData:any) => {
  let data = formData
    return axios
      .post(
        `${BASE_PATH}api/saveUploadedFileDetails`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}


export async function getUploadedFilesDetails(){
  const response = await fetch(`${BASE_PATH}api/getUploadedFilesDetails?clientId=${clientId}`,{
    headers:{
      "Authorization":AWS_AUTH_TOKEN
    }
  });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getLogs(){
  const response = await fetch(`${BASE_PATH}api/getlogs`,{
    headers:{
      "Authorization":AWS_AUTH_TOKEN
    }
  });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
    // logger?.resetLogs()
    return Promise.resolve(jsonResponse.data)
  }else{
    // logger?.resetLogs()
    return Promise.resolve(jsonResponse.message)
  }
}

export function saveLogs(data:any) {
  return axios.post(
    `${BASE_PATH}api/createlogs`,
    {
      data: data,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization":AWS_AUTH_TOKEN
      },
    }
  )
}


export const createSession = (sessionId:string,ipAddress:string) => {
  let win:any = window
  let username = getLoggedInUserName()
  let orientationType = window.screen?.orientation?.type || "Not Available"
  let data = {
      sessionId:sessionId,
      loginSessionId: "",
      username: username,
      deviceName: win?.platform?.name,
      ipAddress:ipAddress,
      deviceDecscription: win?.platform?.description,
      deviceOs: JSON.stringify(win?.platform?.os || ""),
      screen:JSON.stringify({width:window.screen.availWidth,height:window.screen.availHeight,orientation:orientationType})
    };
    return axios
      .post(
        `${BASE_PATH}api/createSession`,
        {
          data: data,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
}


export async function getIpAddress(){
  const response = await fetch(`https://api.db-ip.com/v2/free/self`,{
  });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse)
  }else{
      return Promise.reject(jsonResponse)
  }
}


export const updateProductTransform = (areaName:string) => {
    let productsList = projectConfiguration.getAreaObject(areaName).areaConfiguration
    return axios
      .post(
        `${BASE_PATH}api/updateProductTransform`,
        {
          data: JSON.stringify({
            projectId:applicationConfig.projectId,
            areaId:applicationConfig.areaId,
            productsList:productsList
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization":AWS_AUTH_TOKEN
          },
        }
      )
}


export const saveSharedProductConfiguration = (configuration:any) => {
  return axios
    .post(
      `${BASE_PATH}api/saveSharedProductConfiguration`,
      {
        configuration:configuration,
        token:getToken()
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}




export const updateAreaFloorplan = () => {
  return axios
    .post(
      `${BASE_PATH}api/updateAreaFloorplan`,
      {
        data: JSON.stringify({
          projectId:applicationConfig.projectId,
          projectConfiguration:projectConfiguration.getProjectConfiguration()
        }),
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}


export async function getUsersList(){
  const response = await fetch(`${BASE_PATH}api/getUsers?clientId=${clientId}`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}


export async function getClientsList(){
  const response = await fetch(`${BASE_PATH}api/getClients`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}

export async function getMaterialCompanies(){
  const response = await fetch(`${BASE_PATH}api/company`,{
      headers:{
        "Authorization":AWS_AUTH_TOKEN
      }
    });
  const jsonResponse = await response.json();
  if(response.status==200 || response.status==404){
      return Promise.resolve(jsonResponse.data)
  }else{
      return Promise.reject(jsonResponse.message)
  }
}


export const createUser = (userDetails:any) => {
  return axios
    .post(`${BASE_PATH}api/register`,
      {
        ...userDetails,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}

export const updateUser = (userDetails:any) => {
  return axios
    .post(`${BASE_PATH}api/updateUser`,
      {
        ...userDetails,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}

export const deleteUser = (userDetails:any) => {
  return axios
    .post(`${BASE_PATH}api/deleteUser`,
      {
        ...userDetails,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}

export const updateClient = (userDetails:any) => {
  return axios
    .post(`${BASE_PATH}api/updateClient`,
      {
        ...userDetails,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}

export const deleteClient = (userDetails:any) => {
  return axios
    .post(`${BASE_PATH}api/deleteClient`,
      {
        ...userDetails,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}

export const createClient = (userDetails:any) => {
  return axios
    .post(`${BASE_PATH}api/createClient`,
      {
        ...userDetails,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization":AWS_AUTH_TOKEN
        },
      }
    )
}

