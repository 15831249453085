import { Euler, Object3D, Quaternion, Vector3 } from "three";
import { addItemToLocalStorage, getItemFromLocalStorage } from "../cache/localstorage";
import { ProductInstance, TextureInfo, Transform } from "../customizer/ProjectConfiguration";
import { applicationConfig, getFilteredArray, getObjectByParameter, stringWithoutWhiteSpace } from "../methods";
import { Dimensions, WardrobeConfiguration } from "../store/storeConfiguration";
import { projectConfiguration } from "./area3dmodel/area3dModel";
import { getObjectDimensionPosition } from "./modules/helper";


export class Configuration{
  modules: Array<Module> = [];
  addons: Array<any> = [];
  isGroupRotationVertical: boolean = false;
  groupRotation: number = 0;
  //When detaching in kitchne planner
  lastDeletedModule:any

  isDefaultConfigAvailable:boolean = false

  isEnableModuleResizing:boolean = applicationConfig.clientName !== "DashSquare"?true:false




  product:ProductInstance
  isViewProductMode:boolean = false
  isLshapeWithSingleSeater:boolean = false

  allowSpacePlanner:boolean = true
  allowFinish:boolean = true
  allowAr:boolean = true
  allowAddons:boolean = true

  configName:string = "Default"
  configId:number = 0

  configHash:string = ""
  isConfigChanged:boolean = true
  
  isProductRender:boolean = false
  isCreatingRender:boolean = false

  currSofaLeg:string = ""

  currMode:string = "3d"


  productConfiguration:any = null
  
  constructor(productId:number = 0){
    this.modules = []
    this.addons = []
    this.groupRotation = 0

    this.configHash = this.getConfigHash()
    // this.product = new ProductInstance(product)

    let productModules = getFilteredArray(applicationConfig?.data?.productModules,"product_id",productId)
    if(!productModules?.length){
      this.isViewProductMode = true
    }
  }

  reset(){
    this.modules = []
    this.addons = []
    this.groupRotation = 0
  }

  setProduct(product:any){
    if(!projectConfiguration){
      this.product = new ProductInstance(product)
      this.isDefaultConfigAvailable = getObjectByParameter(applicationConfig?.data?.savedProductConfiguration,"product_id",product.product_id || product.productId) ? true : false
    }
  }

  getConfigHash(){
    return String(Number(new Date()))
  }

  updateConfigHash(){
    this.configHash = this.getConfigHash()
    this.updateLocalStorage()
  }

  setIsConfigChanged(val:boolean){
    this.isConfigChanged = val
    this.updateLocalStorage()
  }


  getModulesList(){
    return this.modules
  }
  getAddonsList(){
    return this.addons
  }
  addModule(module:any,modelObject:any){
    let dim = null
    if(modelObject){
      dim = getObjectDimensionPosition(null,modelObject)?.dimensions
    }
    if(dim){
      module.dim_X = dim.dimX
      module.dim_Y = dim.dimY
      module.dim_Z = dim.dimZ
    }
    let instance = new Module(module,modelObject?.uuid)
    // let rotation = new Quaternion()
    // modelObject.getWorldQuaternion(rotation)
    if(modelObject){
      let worldPosition = new Vector3()
      modelObject?.getWorldPosition(worldPosition)
      instance.transform.position = modelObject?.position
      instance.transform.rotation = modelObject?.rotation
      modelObject.userData.configuration = instance
    }
    this.modules.push(instance)
    this.updateLocalStorage()
    return instance
  }

  updateGroupRotation(){
    if(this.groupRotation===270){
      this.groupRotation = 0
      return
    }
    this.groupRotation = this.groupRotation + 90
    this.updateLocalStorage()

  }


  addAddon(module:any,object:any){
    let instance = new Module(module,object?.uuid,true)
    this.addons.push(instance)
    instance.transform.position = object?.position
    instance.transform.rotation = object?.rotation
    this.updateLocalStorage()

    return instance
  }

  getLastModelModuleType(){
    // let object = this.modules[this.getModulesLength()-1] 
    let object:any = this.getLastModel()
    return object?.moduleType || null
  }

  getAddedType(moduleObject:any){
    let currModuleInfo = this.getModuleFromObjectId(moduleObject.uuid) || this.getAddonFromObjectId(moduleObject.uuid)
    return currModuleInfo?.isAddon || false
  }


  removeModule(moduleObject:any){
    this.removeModuleFromConfig(moduleObject)
    this.updateLocalStorage()

  }

  setLastDeletedModule(moduleObject:any){
    let currModuleInfo = this.getModuleInfoObjectfromModelObject(moduleObject)
    this.lastDeletedModule = currModuleInfo
  }

  removeModuleFromConfig(moduleObject:any){
    this.modules = this.modules.filter(currModule => currModule.moduleObjectId !== moduleObject.uuid)
    this.addons = this.addons.filter(currModule => currModule.moduleObjectId !== moduleObject.uuid)
    this.updateLocalStorage()
  }

  removeAddonFromConfig(moduleObject:any){
    this.addons = this.addons.filter(currModule => currModule.moduleObjectId !== moduleObject.uuid)
    this.updateLocalStorage()
  }


  getModuleFromObjectId(uuid:any){
    return this.modules.find(currModule => currModule.moduleObjectId === uuid)
  }

  getAddonFromObjectId(uuid:any){
    return this.addons.find(currModule => currModule.moduleObjectId === uuid)
  }


  getModuleTypefromModelObject(moduleObject:any){
    return this.modules.find(currModule => currModule.moduleObjectId === moduleObject.uuid).moduleType
  }


  getModulesInfoList(){
    let modules = []
    for (let index = 0; index < this.modules.length; index++) {
        let data:any = this.modules[index]
        modules.push(data.module)
    }
    return modules
  }

  getModuleInfoObjectfromModelObject(moduleObject:any){
    return this.modules.concat(this.addons).find(currModule => currModule.moduleObjectId === moduleObject.uuid)
  }

  getModuleInfoIndexfromModelObject(moduleObject:any){
    let moduleIndex:any = -1
    for (let index = 0; index < this.modules.length; index++) {
      let currObject:any = this.modules[index]
      if(moduleObject.uuid===currObject.moduleObjectId){
        moduleIndex = index
        break
      }
    }
    for (let index = 0; index < this.addons.length; index++) {
      let currObject:any = this.addons[index]
      if(moduleObject.uuid===currObject.moduleObjectId){
        moduleIndex = index
        break
      }
    }
    return moduleIndex
  }

  getAddonsInfoObjectfromModelObject(moduleObject:any){
    return this.addons.find(currModule => currModule.moduleObjectId === moduleObject.uuid)
  }

  getLastModel(){
    return this.modules[this.getModulesLength()-1]
  }

  getSecondLastModel(){
    if(this.modules.length === 2){
      return this.modules[0]
    }
    return this.modules[this.getModulesLength()-2]
  }

  getFirstModel(){
    return this.modules[0]
  }

  getLastModelObject(){
    let object:any = this.getLastModel()
    return object
  }

  getFirstModelObject(){
    let object:any = this.getFirstModel()
    return object
  }

  replaceFirstModule(module:any,object:Object3D){
    let instance = new Module(module,object.uuid)
    object.userData.configuration = instance
    instance.transform.position = object.position
    instance.transform.rotation = object.rotation
    this.modules.splice(0,1,instance)
    this.updateLocalStorage()
  }

  replaceRightModule(module:any,oldModel:Object3D,newModel:any){
    this.removeModuleFromConfig(oldModel)
    this.addModule(module,newModel)
    this.updateLocalStorage()
  }

  getRightModule(){
    return this.modules.find(currModule => currModule.moduleType === "Right")
  }

  getPrevModuleObject(index:number){
    let object:any = this.modules[index-1]
    return object.object
  }

  getPrevModuleFromModule(moduleObject:any){
    let modelIndex = 0
    for (let index = 0; index < this.modules.length; index++) {
      let currObject:any = this.modules[index]
      if(moduleObject.uuid===currObject.moduleObjectId){
        modelIndex = index
        break
      }
    }
    if(modelIndex){
      return this.modules[modelIndex-1]
    }

    return this.getLastModelObject()
    //else return last element object 
  }

  getPrevAddon(moduleObject:any){
    let modelIndex = 0
    for (let index = 0; index < this.addons.length; index++) {
      let currObject:any = this.addons[index]
      if(moduleObject.uuid===currObject.moduleObjectId){
        modelIndex = index
        break
      }
    }
    return this.addons[modelIndex] || null

  }

  getLastAddon(){
    let lastIndex = 0
    if(this.addons.length){
      lastIndex = this.addons.length - 1
    }
    return this.addons[lastIndex] || null
  }


  getNumberOfCornerModulesTillLength(moduleObject:any){
    let lastIndex = 0
    for (let index = 0; index < this.modules.length; index++) {
      let currObject:any = this.modules[index]
      if(moduleObject.uuid===currObject.moduleObjectId){
        lastIndex = index
        break
      }
    }
    if(lastIndex){
      let allModules = this.modules
      return this.getNumberOfCornerModules(allModules.slice(0,lastIndex))
    }
    return this.getNumberOfCornerModules(this.modules)
  }

  getNumberOfConsoleModulesTillLength(moduleObject:any){
    let lastIndex = 0
    for (let index = 0; index < this.modules.length; index++) {
      let currObject:any = this.modules[index]
      if(moduleObject.uuid===currObject.moduleObjectId){
        lastIndex = index
        break
      }
    }
    if(lastIndex){
      let allModules = this.modules
      return this.getNumberOfConsoleModules(allModules.slice(0,lastIndex))
    }
    return this.getNumberOfConsoleModules(this.modules)
  }

  getModuleIndexFromObject(modelObject:any){
    let modelIndex = 0
    for (let index = 0; index < this.modules.length; index++) {
      let currObject:any = this.modules[index]
      if(modelObject?.uuid===currObject.moduleObjectId){
        modelIndex = index
        break
      }
    }
    return modelIndex
  }


  getNumberOfCornerModules(modules:any){
    let array = modules.filter((module:any)=>module.moduleType==="Corner")
    return array.length
  }

  getNumberOfConsoleModules(modules:any){
    let array = modules.filter((module:any)=>module.moduleName.includes("Console"))
    return array.length
  }

  getModulesLength(){
    return this.modules.length
  }

  getConfigId(productName:string){
    let configId = productName
    let allModules = this.modules.concat(this.addons)
    for (let index = 0; index < allModules.length; index++) {
      let currObject:any = allModules[index]
      let moduleName = currObject.moduleName
      let prefix = moduleName.substring(0,1)
      let suffix = ""
      if(moduleName.search(/[0-9]/i) != -1){
        suffix = moduleName.substr(moduleName.search(/[0-9]/i))
      }else{
        suffix = moduleName.substr(1).match(/[A-Z]/g) || ""
      }
      configId = configId + "_" + prefix + suffix
    }
    return configId
  }

  makeLastModuleAsFirst(){
    let lastModule = this.modules[this.modules.length-1]
    let otherModules = this.modules.slice(0,this.modules.length-1)
    this.modules = [lastModule,...otherModules]
    this.updateLocalStorage()
  }

  addModuleAtPosition(module:any,index:number){
    if(module.isAddedAsAddon){
      this.addons.splice(index,0,module)
    }else{
      this.modules.splice(index,0,module)
    }
    this.updateLocalStorage()
  }

  updateFinish(modelObject:any,partName:string,config:any,categoryName:string="",moduleName:string=""){
  
    for (let index = 0; index < this.modules.length; index++) {
      const configObj = this.modules[index];
      if(configObj.moduleObjectId===modelObject?.uuid){
        let textureInfo = new TextureInfo(config)
        if(categoryName === "Sofa"){
          textureInfo.uvScale = 40
        }
        textureInfo.partName = partName
        configObj.configuration = configObj.configuration.filter(currConfig=> currConfig.partName !== partName)
        configObj.configuration.push(textureInfo)
      }
    }
    for (let index = 0; index < this.addons.length; index++) {
      const configObj = this.addons[index];
      if(configObj.moduleObjectId===modelObject?.uuid){
        let textureInfo = new TextureInfo(config) 
        if(categoryName === "Sofa"){
          textureInfo.uvScale = 40
        }
        textureInfo.partName = partName
        configObj.configuration = configObj.configuration.filter(currConfig=> currConfig.partName !== partName)
        configObj.configuration.push(textureInfo)
      }
    }

    // if(this.isViewProductMode){
    //   let object = getAddedProductFromScene()
    //   if(object){
    //     let originalPartName = getOriginalPartName(this.product.categoryName,partName)
    //     this.product.configuration[originalPartName] = config
    //   }
    // }
    this.updateLocalStorage()
  }

  createConfigFromSavedConfig(sharedConfig:any){
    let modulesList = sharedConfig?.modules || []
    let addonsList = sharedConfig?.addons || []
    if(modulesList?.length){
      for (const currModule of modulesList) {
        let instance = this.addModule(currModule,null)
        let transform = currModule.transform
        instance.transform.position = new Vector3(transform.position.x,transform.position.y,transform.position.z)
        instance.transform.rotation = new Euler(transform.rotation._x,transform.rotation._y,transform.rotation._z)
      }
    }
    if(addonsList?.length){
      for (const currModule of addonsList) {
        let instance = this.addAddon(currModule,null)
        let transform = currModule.transform
        instance.transform.position = new Vector3(transform.position.x,transform.position.y,transform.position.z)
        instance.transform.rotation = new Euler(transform.rotation._x,transform.rotation._y,transform.rotation._z)
      }
    }
     
    this.allowSpacePlanner = sharedConfig.allowSpacePlanner
    this.allowAr = sharedConfig.allowAr
    this.allowFinish = sharedConfig.allowFinish
    this.allowAddons = sharedConfig.allowAddons
    this.isViewProductMode = sharedConfig.isViewProductMode
    this.groupRotation = sharedConfig.groupRotation
    this.configHash = sharedConfig.configHash
    this.isConfigChanged = sharedConfig.isConfigChanged
    this.configId = sharedConfig.configId
    this.configName = sharedConfig.configName
    this.product.configuration = sharedConfig.product?.configuration || {}
    this.updateLocalStorage()
  }

  updateConfigId(id){
    this.configId = id
    this.updateLocalStorage()
  }

  getConfiguration(){
    return this.modules.concat(this.addons)
  }

  setAllowSpacePlanner(value:any){
    this.allowSpacePlanner = value
  }
  setAllowFinish(value:any){
    this.allowFinish = value
  }
  setAllowAr(value:any){
    this.allowAr = value
  }
  setAllowAddons(value:any){
    this.allowAddons = value
  }

 

  updateLocalStorage(){
    if(this.product){
      addItemToLocalStorage(`product_configurator_${this.product.productName}_${this.product.productId}`,this)
    }
  }

  getLocalStorageConfiguration(){
    return getItemFromLocalStorage(`product_configurator_${this.product.productName}_${this.product.productId}`)
  }

  updateModulesPositionRotation(group:any){
    group.children.forEach(modelObject => {
      let instance = modelObject.userData.configuration
      let worldPosition = new Vector3()
      let worldRotation = new Quaternion()
      var euler = new Euler();
      modelObject.getWorldPosition(worldPosition)
      modelObject.getWorldQuaternion(worldRotation)
      instance.transform.position = worldPosition
      instance.transform.rotation = euler.setFromQuaternion(worldRotation, 'XYZ');
      this.updateLocalStorage()
    });
      
  }

  setIsProductRender(val:boolean){
    this.isProductRender = val
  }

  setIsLshapeWithSingleSeater(){
    let modules = ["SingleSeater","DoubleSeater","ThreeSeater"]
    let productSubCategory = this.product?.subCategoryName
    if(productSubCategory){
      let productModules = applicationConfig.data.productModules
      let singleseater = productModules.filter(currModule => modules.includes(currModule.module_type) && currModule.product_id === this.product.productId)
      let isCategoryEligible = stringWithoutWhiteSpace(productSubCategory.toLowerCase()) === "lshape" || stringWithoutWhiteSpace(productSubCategory.toLowerCase()) === "recliner" 
      if(isCategoryEligible && singleseater){
        this.isLshapeWithSingleSeater = true
      }

    }
  }
  
}
 

export class Module{
  normal:Vector3 = new Vector3(0,0,1)
  moduleName:string
  categoryName:string
  subCategoryName:string
  productName:string
  moduleType:string
  subModuleType:string
  displayName:string
  moduleId:number
  moduleObjectId:string
  module:any
  transform:Transform =  new Transform()
  configuration:any = []

  isAddon:boolean = false
  isAddedAsAddon:boolean = false
  isWallMounted:boolean = false
  isAttachedToWall:boolean = false

  allowDuplication:boolean = false
  allowMovement:boolean = false
  allowRotation:boolean = false
  allowDelete:boolean = false

  dimX:number = 0
  dimY:number = 0
  dimZ:number = 0

  dimensions:Dimensions = new Dimensions()

  additionalConfiguration:any = {}

  constructor(module:any,moduleObjectId:string,isAddedAsAddon:boolean = false){
    this.moduleName = module.module_name || module.moduleName || "Default"
    this.moduleType = module.module_type || module.moduleType || "Default"
    this.subModuleType = module.sub_module_type || module.subModuleType || "Default"
    this.moduleId = module.sub_module_id || module.moduleId || "Default"
    this.displayName = module.display_name || module.displayName || "Default"
    this.categoryName = module.category_name || module.categoryName
    this.subCategoryName = module.sub_category_name || module.subCategoryName
    this.productName = module.product_name || module.productName || module.module_name
    this.isAddon = module.is_addon || module.isAddon
    this.isAddedAsAddon = isAddedAsAddon

    this.dimX = module.dim_X || null
    this.dimY = module.dim_Y || null
    this.dimZ = module.dim_Z || null


    this.configuration = module.configuration || []

    this.allowDuplication = module.allow_duplication===undefined?module.allowDuplication:module.allow_duplication
    this.allowMovement = module.allow_movement===undefined?module.allowMovement:module.allow_movement 
    this.allowRotation = module.allow_rotation===undefined?module.allowRotation:module.allow_rotation  
    this.allowDelete = module.allow_delete===undefined?module.allowDelete:module.allow_delete  

    this.moduleObjectId = moduleObjectId

    if(this.categoryName === "Wardrobe"){
      this.additionalConfiguration = new WardrobeConfiguration()
    }
  }
  updateNormal(normal:Vector3){
    this.normal = normal
  }
}
