import { applicationConfig } from "../methods"
import { ProductViewer360 } from "../products/productviewer_shopify"

export var productViewer360 = null
export var productDetails:ProductDetails = null

export function initProductDetails(product:string,is360ImagesAvailable:boolean) {
    productDetails = new ProductDetails(product,is360ImagesAvailable)
}

export class ProductDetails{
    is360ImagesAvailable:boolean = true
    product:any = null

    constructor(product:any,is360ImagesAvailable:boolean){
        this.is360ImagesAvailable = is360ImagesAvailable
        this.product = product
        if(is360ImagesAvailable){
            setTimeout(() => {
                productViewer360  = new ProductViewer360("canvas-container","customization-options",product.product_name)
                productViewer360.init()  
            }, 500);
        
        }
    }
}