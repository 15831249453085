import { IonAlert } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import HorizontalScroll from "../../../common/ui-components/scrollbar/HorizontalScroll";
import { applicationConfig, getObjectByParameter } from "../../../../utils/methods";
import { hideImage, updateScrollPosition } from "../../../../utils/UI_methods/global";
import FormStatus from "./FormStatus";
import Hint from "./Hint";
import { ProjectForm } from "../../../../utils/projects/projectForm";
 

const AreaCategoryForm: React.FC<{
    selectedAreas:any;
    currStep:string
    selectedCategories:any
    setSelectedCategories:(selectedCategories:any)=>void
    updateCurrentState:(state:string)=>void
    projectForm:ProjectForm
}> = (props) => {
  
    const [categories, setCategories]:any = useState(null);
    const[currSelectedArea,setCurrselectedArea]:any = useState('')
    const[selectedCategories,setSelectedCategories]:any = useState(props.selectedCategories)
    
    const [showAlert, setShowAlert] = useState(false);
    const [alertArea, setAlertArea] = useState("")

    const [scrollBarId] = useState("createProjectAreasScrollbar");
    const[isAllAreasCompleted,setIsAllAreasCompleted] = useState(false)
    const[areaVisitedMapping] = useState({})

    useEffect(() => {
        if(props.selectedAreas){
          setCurrselectedArea(props.selectedAreas[0].area_name)
          updateListWithPreSelectedCategories()
        }
    }, [props.currStep]);

    useEffect(()=>{
      let arr = []
      for (const areaCategory of applicationConfig?.data?.areaCategoriesRelationship) {
          if(areaCategory.area_name===currSelectedArea){
              arr.push((areaCategory))
          }
      }
      setCategories(arr)
      setTimeout(() => {
        updateScrollPosition(scrollBarId)
      }, 500);

      areaVisitedMapping[currSelectedArea] = true

    },[currSelectedArea])

    function updateListWithPreSelectedCategories() {
      for (const area of props.selectedAreas) {
        const areaName = area.area_name
        let categories = applicationConfig?.data?.areaCategoriesRelationship.filter(currValue => currValue.area_name === areaName && currValue.is_pre_selected)
        if(categories.length){
          for (const category of categories) {
            updateSelectedCategories(null,category.category_name,areaName,category.category_id,category.area_id)  
          }
        }
      }
    }


    

    useEffect(()=>{
      setIsAllAreasCompleted(isAllAreasVisited())
    },[currSelectedArea])

    function isAllAreasVisited() {
      let isAllAreasVisited = true
      for (const area of props.selectedAreas) {
        // let categories = selectedCategories[area.area_name]
        let isVisited = areaVisitedMapping[area.area_name]
        if(!isVisited){
          isAllAreasVisited = false
          break
        }
      }
      return isAllAreasVisited
    }




  
      const updateSelectedCategories = (event:any,categoryName: string,areaName:string,categoryId:number,areaId:number) => {
        //For every selected Areas

        setSelectedCategories((prevState: any) => {
            //Initially when no category is selected
            if(!prevState){
              return{[areaName]:[{areaId:areaId,categoryName:categoryName,categoryId:categoryId}]}
            }

            let categories = prevState[areaName]
            if(categories){
              for (const [i,category] of categories.entries()) {
                //check if category already exits in array remove and return 
                if (category.categoryName === categoryName) {
                  prevState[areaName].splice(i, 1);
                  // prevState[areaName] = categories
                  return {...prevState};
                }
              }
              //else push new cateogry 
              categories.push({areaId:areaId,categoryName:categoryName,categoryId:categoryId})
              return{[areaName]:categories,...prevState}
            }else{
              return{[areaName]:[{areaId:areaId,categoryName:categoryName,categoryId:categoryId}],...prevState}
            }
          }
        );

    
    
      };
    

      const ProductCategories:React.FC<{
        categories:any
      }> = (props) => {
        return(
            <div className="create-project-areas-container create-project-product-categories-container">
            <div className="areas-container">
              {selectedCategories[currSelectedArea]?.map((category:any,index:any)=>{
                return (
                  <div className={"area-item"} key={index} >
                    {/* <div className="area-image white-card shadow-light" onClick={(e)=>updateSelectedCategories(e,category.categoryName,currSelectedArea,category.categoryId,category.areaId)}>
                        <div className="top-left check-container">
                          <div className="check"></div>
                        </div>
                        <div className="title">{category.categoryName}</div>
                    </div> */}
                    <div className="area-image white-card shadow-light" onClick={(e)=>updateSelectedCategories(e,category.categoryName,currSelectedArea,category.categoryId,category.areaId)}>
                          <div className="">
                            <img src={`${applicationConfig.aws_public_url}assets/images/icons/productIcons/${category.categoryName}.png`} onError={hideImage} alt="" style={{width:"3rem",aspectRatio:"1/1",opacity:"0.7"}} />
                            <div className="heading4 color-black">{category.categoryName}</div>
                          </div>
                          <div className="top-left check-container">
                            <div className="check"></div>
                          </div>
                      </div>
                  </div>
                )
              })}
              {props.categories?.map((category:any,index:any)=>{
                return (
                  <>
                  {!getObjectByParameter(selectedCategories[currSelectedArea],"categoryName",category.category_name)?
                    <div className={"area-item"} key={index} >
                      <div className="area-image white-card shadow-light" onClick={(e)=>updateSelectedCategories(e,category.category_name,category.area_name,category.category_id,category.area_id)}>
                          <div className="">
                            <img src={`${applicationConfig.aws_public_url}assets/images/icons/productIcons/${category.category_name}.png`} onError={hideImage} alt="" style={{width:"3rem",aspectRatio:"1/1",opacity:"0.7"}} />
                            <div className="heading4 color-black">{category.category_name}</div>
                          </div>
                      </div>
                    </div>
                  :null}
                  </>
                  
                )
              })}
                
            </div>
          </div>
        )
      }

  
    const getCategoryCount = (areaName:string) => {
      if(selectedCategories[areaName]){
        return selectedCategories[areaName].length
      }
      return 0
    }


    function checkIfAllAreaCompleted(){
      let allCategories:any  = $(".category-count")
      for (const category of allCategories) {
        let value:string = $(category).attr("data-count")!
        if(parseInt(value)===0){
          let areaName = $(category).attr("data-area-name")!   
          setShowAlert(true)
          setAlertArea(areaName)
          return false  
        }
      }

      return true
    }
      
  const Areas = () => {
    return(
        <React.Fragment>
          {props.selectedAreas?.map((area:any,index:number)=>{
              let totalCategories =  getCategoryCount(area.area_name)
              let classNames=[]
              classNames.push("area heading3   ")
              if(area.area_name===currSelectedArea){
                  classNames.push("--is-active")
              }
              return(
                  <div className={classNames.join(" ")} onClick={()=>{
                    setCurrselectedArea(area.area_name)
                  }} key={index}>{area.area_name} <span className="category-count" data-area-name={area.area_name} data-count={totalCategories}>({totalCategories})</span> </div>
              )
          })}

        </React.Fragment>
    )
}

function gotoNextArea(){


  let currArea = currSelectedArea
  let areaList = props.selectedAreas
  let nextArea = null
  let currAreaIndex:number = 0
  let totalItems:number = areaList.length
  let nextAreaIndex:number = 0
  areaList.forEach((area:any,index:number) => {
    if(area.area_name===currArea){
      currAreaIndex = index
      return
    }
  });
  nextAreaIndex = (currAreaIndex + 1) % totalItems 
  nextArea = areaList[nextAreaIndex]
  setCurrselectedArea(nextArea.area_name)
}
 

function gotoPrevArea(){
  let currArea = currSelectedArea
  let areaList = props.selectedAreas
  let prevArea = null
  let currAreaIndex:number = 0
  let totalItems:number = areaList.length
  let prevAreaIndex:number = 0
  areaList.forEach((area:any,index:number) => {
    if(area.area_name===currArea){
      currAreaIndex = index
      return
    }
  });
  prevAreaIndex = (currAreaIndex - 1) % totalItems 

  if(prevAreaIndex == -1){
    prevAreaIndex = totalItems -1
  }
  prevArea = areaList[prevAreaIndex]
  setCurrselectedArea(prevArea.area_name)
}



const Icons = () => {
  return(
    <React.Fragment>
      <div className="right-middle ion-margin circle-icon-container" onClick={gotoNextArea}>
      <i className="fa fa-chevron-right color-black" aria-hidden="true"></i>
      </div>

      <div className="left-middle ion-margin circle-icon-container" onClick={gotoPrevArea}>
        <i className="fa fa-chevron-left color-black" aria-hidden="true"></i>
      </div>
    </React.Fragment>
  )
}


const gotoNextStep = () => {
  if(checkIfAllAreaCompleted()){
    props.setSelectedCategories(selectedCategories)
    // props.updateCurrentState("Step4")
    props.updateCurrentState("Summary")
  }

}
   
  return (
    <React.Fragment>

        {/* <Hint
          id="areaCat"
          heading="Areas saved"
          subHeading="Select product categories from the categories list"
          buttonText="Start Selecting Categories"
          closeAction={()=>{props.projectForm.isShowProductsHint = false}}
          isShow={props.projectForm.isShowProductsHint}
        /> */}

      <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={'Alert!!!'}
          // subHeader={'Subtitle'}
          message={`Select products for ${alertArea}`}
          buttons={['OK']}
          mode="ios"
        />


       <div className="modal-header heading1">
                Select Products
              </div>
        <div className="modal-body" id="cerateProjectAreas">
          <FormStatus
            count={3}
          />
            {/* <Areas/> */}
            <HorizontalScroll
                itemsClass="product-area-container"
                Component={Areas}   
                itemsList={props.selectedAreas}
                id={scrollBarId}     
            />
            <ProductCategories
              categories = {categories}
            />
            <Icons/>

      </div>
      <div className="modal-footer" style={{justifyContent:"space-between"}}>
        <button className="op-btn op-btn-light" onClick={()=>props.updateCurrentState("Step2")}> <i className="fas fa-arrow-left color-black"></i> Prev</button>
        {isAllAreasCompleted?
        <button className="op-btn op-btn-primary" onClick={gotoNextStep}>Summary</button>
        :
        <button className="op-btn op-btn-primary" onClick={gotoNextArea} >Next <i className="fas fa-arrow-right color-white"></i></button>
        }
      </div>

     
    </React.Fragment>
        
  );
};

export default AreaCategoryForm;
