import $ from "jquery";
import React, { useEffect, useState } from "react";
import { applicationConfig, getFilteredArray, getObjectByParameter, stringWithoutWhiteSpace } from "../../../../utils/methods";
import { createProductForm } from "../../../../utils/products/createProductForm";
import { filterCategoryForStore } from "../../../../utils/products/createproduct";

const ProductDetailsForm: React.FC<{
  productDetails:createProductForm
  isUpdateMode:boolean
}> = (props) => {


    const[productTags,setProductTags] = useState(props.productDetails.productTags)

    const[subCategories,setSubCategories] = useState([])
    const[storeFronts,setStoreFronts] = useState(applicationConfig?.user?.storeFronts)
    const[categories] = useState(applicationConfig?.data?.categories)

    useEffect(()=>{
      props.productDetails.productTags = productTags
      if(props.isUpdateMode && props.productDetails){
        // updateCategory(props.productDetails.categoryId)

        let subCat = getFilteredArray(applicationConfig?.data.subCategories,"category_id",String(props.productDetails.categoryId))
        setSubCategories(subCat)
        setTimeout(() => {
          // $("#subCategory").val(props.productDetails.subCategoryId)
          $("#category").attr("default-value",props.productDetails.categoryId)
    
        }, 1000);
      }
    },[productTags,props.productDetails])

    useEffect(()=>{
      if(props.isUpdateMode){
        setTimeout(() => {
          $("#subCategory").attr("default-value",props.productDetails.subCategoryId)
        }, 1500);
      }
    },[props.isUpdateMode,subCategories])


    function updateCategory(categoryId:number) {
      let categoryName = getObjectByParameter(categories,"id",categoryId)?.category_name
      if(categoryName === "Sofa" || categoryName === "Recliner" || categoryName === "SofaBed"){
        $("#sofaCupHolder").fadeIn(0)
      }else{
        $("#sofaCupHolder").fadeOut(0)
      }
      let subCat = getFilteredArray(applicationConfig?.data.subCategories,"category_id",String(categoryId))
      setSubCategories(subCat)
      setCategoryId(categoryId)
    }

    function setProductName(value:string) {
      props.productDetails.productName = value
      props.productDetails.sku = stringWithoutWhiteSpace(props.productDetails.productName)
      $("#productSku").val(stringWithoutWhiteSpace(value))
    }

    function setStoreFrontId(id:number) {
      props.productDetails.storeFrontId = id
    }


    
    function setDescription(value:string) {
      props.productDetails.description = value
    }

    function setCategoryId(value:number) {
      if(value){
        let category = getObjectByParameter(applicationConfig?.data.categories,"id",String(value))
        props.productDetails.categoryId = value
        props.productDetails.categoryName = category.category_name

        props.productDetails.subCategoryId = 77
        props.productDetails.subCategory = "All"
      }
    }

    function setSubCategoryId(value:number) {
      if(value){
        let subCategory = getObjectByParameter(applicationConfig?.data.subCategories,"id",String(value))
        props.productDetails.subCategoryId = value
        props.productDetails.subCategory = subCategory.sub_category_name
      }
      
    }


    function addTag(event:any) {
      if(event.key === "Enter"){
        const value:any = $(event.target).val() || ""
        if(value?.length){
          setProductTags((prevValues:any)=>{
            return [...prevValues,value]
          })
          $(event.target).val("")
        }
      }
    }

    function removeTag(tagName:string) {
          setProductTags((prevValues:any)=>{
            return prevValues.filter(currValue => currValue !== tagName)
          })
    }

    
    function updateTiling(event:any) {
      props.productDetails.tilingScale3d  = Number(event.target.value)
    }

    console.log(props.productDetails)

  return (
    <React.Fragment>
      <div className="padding10">

      <div className="custom-row">

          <div className="custom-col-50">
            <div className="form-label-input-container">
              <div className="label-container">
                <div className="label">
                  Product Name <span className="mandatory">*</span>
                </div>
              </div>
              <div className="input-container">
                <input
                  className="input input-mandatory"
                  type="text"
                  defaultValue={props.productDetails.productName}
                  onChange={(e)=>setProductName(e.target.value)}
                  name="ProductName"
                  id="productName"
                  required
                />
              </div>
            </div>
          </div>

          <div className="custom-col-50">
            <div className="form-label-input-container">
              <div className="label-container">
                <div className="label">
                  SKU <span className="mandatory">*</span>
                </div>
              </div>
              <div className="input-container">
                <input
                  className="input disable input-mandatory"
                  defaultValue={props.productDetails.sku}
                  type="text"
                  name="sku"
                  id="productSku"
                  required
                />
              </div>
            </div>
          </div>

      </div>


        

       

        <div className="custom-row">
          <div className="custom-col-50">
            <div className="form-label-input-container">
              <div className="label-container">
                <div className="label">
                  Category <span className="mandatory">*</span>
                </div>
              </div>
              <div className="input-container">
                <select defaultValue={props.productDetails.categoryId} className={false?"input input-mandatory disable":"input input-mandatory"} name="" id="category" onChange={(e)=>updateCategory(Number(e.target.value))}>
                    <option value={0}>Select Category</option>
                    {categories.map((category:any,index:number)=>{
                        return(
                            <option value={category.id} key={index}>{category.category_name}</option>
                        )
                    })}
                </select>
              </div>
            </div>
          </div>
          <div className="custom-col-50">

            <div className="form-label-input-container">
              <div className="label-container">
                <div className="label">
                  Sub Category<span className="mandatory">*</span>
                </div>
                <div className="hint"></div>
              </div>
              <div className="input-container">
                <select onChange={(e)=>setSubCategoryId(Number(e.target.value))} defaultValue={props.productDetails.subCategoryId} className={false?"input input-mandatory disable":"input input-mandatory"} name="" id="subCategory">
                  <option value={0}>Select Sub Category</option>
                  {subCategories.map((subCategory:any,index:number)=>{
                      return(
                          <option value={subCategory.id} key={index}>{subCategory.sub_category_name}</option>
                      )
                  })}
                </select>
              </div>
            </div>

            <div className="form-label-input-container display-none" id="sofaCupHolder" style={{display:"none"}}>
              <div className="label-container">
                <div className="label">
                  Can have up holder?
                </div>
              </div>
              <div className="input-container">
                <div className="padding5">
                    <input type="radio"  name="canHaveCupHolder" value="1" />
                    <label className="marginleft5 heading3 font-normal">Yes</label>
                    <input className="ion-margin-start" type="radio" checked={true} name="canHaveCupHolder" value="0"/>
                    <label className="marginleft5 heading3 font-normal">No</label>
                </div>
              </div>
            </div>


          </div>
        </div>

        


        
        <div className="form-label-input-container">
          <div className="label-container">
            <div className="label">Store Front</div>
          </div>
          <div className="input-container">
                <select onChange={(e)=>setStoreFrontId(Number(e.target.value))} defaultValue={props.productDetails.storeFrontId} className={false?"input input-mandatory":"input input-mandatory"} name="" id="subCategory">
                  <option value={0}>Select Store Category</option>
                  {storeFronts.map((storeFront:any,index:number)=>{
                      return(
                          <option value={storeFront.storefront_id} key={index}>{storeFront.storefront_name}</option>
                      )
                  })}
                </select>
          </div>
        </div>

        
        <div className="form-label-input-container">
          <div className="label-container">
            <div className="label">Description</div>
          </div>
          <div className="input-container">
            <textarea
              name=""
              defaultValue={props.productDetails.description}
              onChange={(e)=>setDescription(String(e.target.value))}
              rows={3}
              id="description"
              className="input full-width"
              style={{ height: "auto" }}
            />
          </div>
        </div>

        <div className="custom-row">
          <div className="custom-col-50">
            {/* <div className="form-label-input-container">
              <div className="label-container">
                <div className="label">
                  Leather material available?
                </div>
              </div>
              <div className="input-container">
                <div className="padding5">
                    <input type="radio"  name="isLeatherAvailable" value="1" />
                    <label className="marginleft5 heading3 font-normal">Yes</label>
                    <input className="ion-margin-start" type="radio" checked={true} name="isLeatherAvailable" value="0"/>
                    <label className="marginleft5 heading3 font-normal">No</label>
                </div>
              </div>
            </div> */}

            <div className="form-label-input-container">
              <div className="label-container">
                <div className="label">
                  Tiling Scale
                </div>
              </div>
              <div className="input-container">
                <div className="padding5">
                    <input type="number" className="input" defaultValue={props.productDetails.tilingScale3d} onChange={updateTiling} />
                </div>
              </div>
            </div>


          </div>


          <div className="custom-col-50">
            <div className="form-label-input-container ion-margin-start">
              <div className="label-container">
                <div className="label">
                  Template available?
                </div>
              </div>
              <div className="input-container">
                <div className="padding5">
                    <input type="radio" name="isTemplateAvailable" value="1" />
                    <label className="marginleft5 heading3 font-normal">Yes</label>
                    <input className="ion-margin-start" type="radio" checked={true} name="isTemplateAvailable" value="0"/>
                    <label className="marginleft5 heading3 font-normal">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
            <div className="form-label-input-container">
              <div className="label-container">
                <div className="label">
                  Tags
                </div>
              </div>
              <div className="input-container">
                <input
                  className="input"
                  type="text"
                  name="sku"
                  id="productTags"
                  placeholder="Sofa, 1 Seater, LShape"
                  onKeyDown={addTag}
                  required
                />
              </div>
            </div>
            <div className="tags-container padding5 display-flex-row">
              {productTags?.map((currTag:string,index:number)=>{
                return(
                  <div key={index} className="bg-color-light border marginRight5 padding5 white-card no-shadow">
                    <span className="font-small heading3">{currTag}</span>
                    <span onClick={()=>removeTag(currTag)} className="font-small"><i className="fas fa-times color-black font-ex-small marginleft5"></i></span>
                  </div>
                )
              })}
            </div>
          </div>


      </div>
    </React.Fragment>
  );
};

export default ProductDetailsForm;
