import CameraControls from "camera-controls"
import $ from "jquery"
import { CircleGeometry, Mesh, MeshBasicMaterial, Vector3 } from "three"
import { getWindowWidth, hideComponentLoader } from "../../UI_methods/global"
import { camera } from "../../customizer/ProjectConfiguration"
import { applicationConfig, waitFor } from "../../methods"
import { rendererDimensions } from "../common"
import { set3dControls } from "../controls"
import { GRID, hideGrid, showGrid } from "../enviornment"
import { resetTruckValue } from "./actions"
import { hideAreaLabel, showAreaLabel } from "./annotations"
import { AREA_MODEL_ORIGIN, CURR_AREA_GROUP, CURR_AREA_NAME, controls, customizerConfig, deselectProduct, perspectiveCamera, projectConfiguration, removeAllBoxHelpers, resetRenderer, scene, camera as sceneCamera, updateAreaCustomizer, updateCameraControls, updateSettingPositionCameraView } from "./area3dModel"
import { cameraForSettingPosition, cameraSettingViewData } from "./floorplan/createRender"
import { getAnchorElement, mouseDownAnchor, removeAllAnchors, toggleCeilingVisibility, updateAnchorPosition } from "./floorplan/floorplanUI"
import { cameraFor2dRender, rotateCameraIconFromCameraControls, setCameraFor2dRender, setHeightSliderValues } from "./floorplan/render2d"
import { onPointerDownCone, onPointerMoveCone, onPointerUpCone } from "./floorplan/render3d_new"
import { All_WALLS, areaWallsBoundingBoxMapping, getAreaFromPoint, hideWallsBoxHelpers, showWallsBoxHelpers } from "./walls"
import { getObjectDimensionPosition } from "../modules/helper"

export var isInteriorVisualization = false

var moveCircle = null
const vector = new Vector3()

var EPS = 0.005

  
export async function enableInteriorVirtualization(setPositionWithControls:Boolean) {
    removeAllAnchors()
    if(CURR_AREA_NAME.toLowerCase() !== "all"){
        updateAreaCustomizer("all")
    }
    applicationConfig.functions.customizer.setIsInteriorVisualization(true)
    $(".hide-on-walkthrough-mode").addClass("display-none")
    hideAreaLabel()
    hideWallsBoxHelpers()
    enableInteriorControls()

    if(cameraFor2dRender){
        await setPositionFromSavedCamera()
    }else{
        await setPositionFromControlsForInteriorVirualization(CURR_AREA_NAME)
        create2dCamera()
    }
    if(CURR_AREA_NAME === "all" || CURR_AREA_NAME === projectConfiguration.projectName){
        toggleCeilingVisibility(true)
    }
    deselectProduct()
    moveCircle.visible = true   
    cameraForSettingPosition.zoom = getWindowWidth() > 1600 ? 18 : 12
    isInteriorVisualization = true 
    createAnchorElement(projectConfiguration.projectName)
    updateAnchorPosition(projectConfiguration.projectName,controls.getPosition())
    applicationConfig.functions.customizer.setIsRefreshHeaderMiddle(Number(new Date()))
    hideGrid()
    updateSettingPositionCameraView()
    // setCameraHeightRangeSliderValues()
    setHeightSliderValues(customizerConfig.cameraMinHeight,customizerConfig.cameraMaxHeight,controls.getPosition().y)
    rotateCameraIconFromCameraControls(getAnchorElement(projectConfiguration.projectName),controls.azimuthAngle)
    removeAllBoxHelpers()
    GRID.visible = true
    setTimeout(() => {
        hideComponentLoader("changeFinishLoader")
    }, 3000);
    // console.log(FLOOR)
}

function create2dCamera() {
    let detectedArea = getAreaFromPoint(controls.getPosition())
    let cam = new camera(detectedArea,"2d")
    setCameraFor2dRender(cam)
    upadateInteriorCameraConfig()
}

export function upadateInteriorCameraConfig() {
    cameraFor2dRender.transform.updateTransform(controls.getPosition(),controls.camera.quaternion,controls.camera.scale,0)
    cameraFor2dRender.setRotation(controls.polarAngle,controls.azimuthAngle) 
}


export function createAnchorElementOld(cameraId:string) {
    let parentContainer = document.getElementById("area3DSceneWrapper")
    let anchorElement = $("<div></div>").addClass("anchor-icon-container padding0 top-middle zindex1001").
    attr("id",`areaAnchor${cameraId}`)
    .appendTo($(parentContainer))
    $("<div></div>").addClass("anchor-icon")
    .html(`<i class='fas fa-circle pulse-anchor pointer-none font-ex-small icon'></i>`)
    .appendTo(anchorElement)
    return anchorElement
}

export function createAnchorElement(cameraId:string) {
   
    let parentContainer = document.getElementById("area3DSceneWrapper")
    let anchorElement = $("<div></div>").addClass("anchor-icon-container  walkthrough-camera-icon top-middle zindex100").
    attr("id",`areaAnchor${cameraId}`)
    .appendTo($(parentContainer))

    $("<div></div>").addClass("anchor-icon")
    .html(`<i class='fas fa-circle pulse-anchor pointer-none icon'></i>`)
    .mousedown((event)=>{
        mouseDownAnchor(event)
        $(".interior-anchor-container").addClass("display-none")
    })
    .appendTo(anchorElement)
    
    $("<i></i>").addClass("fas fa-caret-up pointer-none anchor-cone top-right")
    .appendTo(anchorElement)

    $("<i></i>").addClass("fas fa-arrows-alt-v padding5 alt rotate-icon font-normal top-right")
    .on("mousemove",onPointerMoveCone)
    .mousedown((event)=>{
        onPointerDownCone(event)
    })
    .mouseup((event)=>{
        onPointerUpCone(event)
    })
    .appendTo(anchorElement)

    return anchorElement
}



export async function setPositionFromControlsForInteriorVirualization(areaName:string,isTween:boolean = true) {
    let position = areaWallsBoundingBoxMapping[areaName || "all"].boundingBox.center 
    let posY    = (customizerConfig.cameraMinHeight + customizerConfig.cameraMaxHeight) / 2
    // console.log(posY,(customizerConfig.cameraMinHeight + customizerConfig.cameraMaxHeight) / 2)


    await controls.setLookAt(position.x,posY,position.z,position.x,posY,position.z + EPS,isTween)
    areaName = getAreaFromPoint(position)

    setTimeout(() => {
        $("#walkthroughCurrAreaName").text(areaName)
    }, 1200);
}


export async function setPositionFromSavedCamera() {
    let position =  cameraFor2dRender.transform.position 
    // let posY =  areaWallsBoundingBoxMapping["all"].boundingBox.center.y
    await controls.setLookAt(position.x,position.y,position.z,position.x,position.y,position.z + EPS,true)
    controls.azimuthAngle = cameraFor2dRender.azimuthAngle
    controls.polarAngle = cameraFor2dRender.polarAngle
    let areaName = getAreaFromPoint(position)
    $("#walkthroughCurrAreaName").text(areaName)
}

export function enableInteriorControls() {
    sceneCamera.fov = 75
    controls.enableZoom = false
    controls.enablePan = false
    // controls.rotateSpeed *= -1
    controls.maxPolarAngle = Math.PI
    // controls.minPolarAngle =  Math.PI / 2
    // if(customizerConfig.currentRenderingType === "3d"){
    //     controls.minPolarAngle = controls.maxPolarAngle =  Math.PI / 2
    // }

    controls.minDistance = controls.maxDistance = 1;
    controls.azimuthRotateSpeed = -0.3;
    controls.polarRotateSpeed   = -0.3;
    controls.mouseButtons.wheel = CameraControls.ACTION.NONE

    controls.camera.near = 0.01
    controls.camera.updateProjectionMatrix()
}

export function enableFloorplanControls() {
    controls.enableZoom = false
    controls.mouseButtons.wheel = CameraControls.ACTION.NONE
    controls.camera.updateProjectionMatrix()
}

export function disableFloorplanControls() {
    set3dControls(controls)
}

export  async function disableInteriorVirtualization() {
    isInteriorVisualization = false
    resetRenderer()

    $(".hide-on-walkthrough-mode").removeClass("display-none")
    disableInteriorControls()
    // disableSpacePlanner()
    updateCameraControls(CURR_AREA_GROUP,CURR_AREA_NAME || "all",false)
    set3dControls(controls)


    toggleCeilingVisibility(false)
    showAreaLabel()
    moveCircle.visible = false   
    applicationConfig.functions.customizer.setIsInteriorVisualization(false)
    showWallsBoxHelpers()
    removeAllAnchors()
    showGrid()
    resetTruckValue()
    GRID.visible = false
}

export function disableInteriorControls() {
    controls.enableZoom = true    
    controls.enablePan = true    
}

export function releaseMemoryInteriorVirtualization(){
    isInteriorVisualization = false
}

export function addMoveCircle() {
    const geometry = new CircleGeometry( 0.1,32);
    const material = new MeshBasicMaterial( { 
        color: 0xffffff,
        opacity:0.8,
        transparent:true 
    } );
    const outerCircleMaterial = new MeshBasicMaterial( { 
        color: 0x0083b7,
        opacity:0.8,
        transparent:true 
    } );
    moveCircle = new Mesh( geometry, material );
    // outerCircle = new Mesh( geometry, outerCircleMaterial );
    // outerCircle.scale.set(1.2, 1.2, 1.2);
    moveCircle.rotation.x -= Math.PI/2;
    moveCircle.visible = false
    // moveCircle.add( outerCircle );
    scene.add( moveCircle );
}

export function updateMoveCirclePosition(position:any) {
    if(isInteriorVisualization){
        moveCircle.visible = true   
        moveCircle.position.set(position.x,AREA_MODEL_ORIGIN.y+0.1,position.z)
    }
}

export function hideMoveCircle() {
    moveCircle.visible = false
}


export async function moveCamera(position:any) {
    let finalPosition = moveCircle.position
    //For mobile view
    if(!moveCircle.visible){
        finalPosition = position
    }

    updatePosition(position)

    updateAnchorPosition(projectConfiguration.projectName,position)

    await waitFor(500)
    let areaName = getAreaFromPoint(perspectiveCamera.position)
    $("#walkthroughCurrAreaName").text(areaName)
    // controls.setPosition(position.x,posY,position.z)
}

async function updatePosition(position:Vector3) {
 
    let newTarget = new Vector3(position.x,controls.getPosition().y,position.z)
    let oldTarget = new Vector3(controls.getPosition().x,controls.getPosition().y,controls.getPosition().z)

    let directionVector = new Vector3().subVectors(newTarget,oldTarget) 
    directionVector.normalize().round()

    const posY = controls.getPosition().y
    await controls.setLookAt(position.x,posY,position.z,position.x + (directionVector.x * EPS),posY,position.z + (directionVector.z * EPS),true)
}

 
export async function updateInteriorCameraView(renderedCamera:any,position:any,isUpdateTarget:boolean = true) {
    // camera.position.set(position.x,position.y,position.z)
    // if(isInteriorVisualization){
    //     isCameraTween = isInteriorVisualization?true:false
    // }
    if(isUpdateTarget){
        // let posY = controls.getPosition().y
        await controls.moveTo(position.x,position.y,position.z,false)
        cameraFor2dRender?.transform.updateTransform(controls.getPosition(),cameraFor2dRender?.transform.rotation,cameraFor2dRender?.transform.scale,0)
    }
    if(renderedCamera){
        sceneCamera.near = Number(renderedCamera.near) 
        sceneCamera.fov = renderedCamera.fov
    }
}


export function toggleMoveCircleVisibility(isVisible:boolean){
    if(!isInteriorVisualization){
        moveCircle.visible = false
    }else{
        moveCircle.visible = isVisible
    }
}

export function updateInteriorCameraFromAreaName(areaName:string) {

    // let configuration = projectConfiguration.projectConfiguration
    // let areaCamera = projectConfiguration.getCameraFromAreaName(areaName) || configuration[areaName]
    let position = projectConfiguration.projectConfiguration[areaName]?.anchor
    updateInteriorCameraView(null,position)
    // controls.target.set(position.x+0.000000000001,position.y,position.z) 
    // 
}

export function updateInteriorAreaLabelsPosition(areasList:any) {
    for (const area of areasList) {
        let data = areaWallsBoundingBoxMapping[area.area_name]
      if(data){
        data = areaWallsBoundingBoxMapping[area.area_name].boundingBox.center

        vector?.set(data.x,data.y,data.z)
        vector?.project(cameraForSettingPosition)
        vector.x = Math.round((0.5 + vector?.x / 2) * (rendererDimensions.width  * cameraSettingViewData.width))
        vector.y = Math.round((0.5 - vector?.y / 2) * (rendererDimensions.height  * cameraSettingViewData.height))
        vector.y = vector.y +  (rendererDimensions.height * (1 - cameraSettingViewData.height)) + $(".main-header-wrapper").height()
        
        vector.x = vector.x +  (rendererDimensions.width * (cameraSettingViewData.left))
        vector.y = vector.y -  (rendererDimensions.height * (cameraSettingViewData.bottom))
        
        $(`#interiorViewanchor${area.area_name}`)?.css({"top":`${vector?.y}px`,"left":`${vector?.x}px`}) 
        // $(`#areaLabel${area.area_name}`)?.removeClass("--is-hide") 
      }else{
        $(`#interiorViewanchor${area.area_name}`)?.addClass("--is-hide") 
      }
    }
}


export function gotoInteriorArea(areaName:string) {
    updateInteriorCameraFromAreaName(areaName)   
    updateAnchorPosition(projectConfiguration.projectName,controls.getPosition())
    rotateCameraIconFromCameraControls(getAnchorElement(projectConfiguration.projectName),controls.azimuthAngle)
}

