import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import CartProductSummary from "../cart/CartProductSummary";

 

const CartProductConfig: React.FC<{
  item:any
  product:any
  buttonClass?:string
}> = (props) => {

 
  const[configuration] = useState(JSON.parse(props.item.configuration))
  const[isOpen,setIsOpen] = useState(false)
  

 
  return (
    <div className="config-details">

      <IonModal
        isOpen = {isOpen}
        onDidDismiss = {()=>setIsOpen(false)}
        cssClass="medium-y-modal no-border"
        id=""
      >
      <CartProductSummary product={props.product} productConfiguration={configuration} closeHandle={()=>{setIsOpen(false)}}/>
      </IonModal>

      <div className="">
        <Button2
          configObj={{id:""}}
          action={()=>{setIsOpen(true)}}
          label={"Summary"}
          fontAwesomeIcon={"far fa-clipboard color-warning"}
          iconPos="left"
          classNames={"no-shadow button-small hide-button-text-in-mobile text-center bg-color-light" + props.buttonClass}
        />
      </div>

    </div>
  );
};

export default CartProductConfig;
