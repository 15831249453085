import React, { useEffect, useState } from "react";
import { getProductModules } from "../../../../../services/api";
import { applicationConfig, getObjectByParameter, getUser } from "../../../../../utils/methods";
import { getFormattedPrice, getRandomNumber } from "../../../../../utils/products/productviewer_shopify";
import ModuleImage from "../../../../common/ui-components/elements/ModuleImage";
import CustomVariantMeasurements from "../product-customizer/measurements/CustomVariantMeasurements";
import AddonsDetails from "./AddonsDetails";
import CustomInstructions from "./CustomInstructionsList";
import ModulesFinishDetails from "./ModulesFinishDetails";
import ProductVariantMeasurements from "../product-customizer/measurements/ProductVariantMeasurements";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import SofaPartImage from "../variant-creator-3d/SofaPartImage";
import OtherDimensions from "./OtherDimensions";
import LegDetails from "./LegDetails";
import ProductImage from "../../../../common/ui-components/elements/ProductImage";
 
const ProductConfigSummary: React.FC<{
    configuration:any
    product:any
    isShowModulesList?:boolean
    configuration3D?:any
}> = (props) => {
 

    const[isCustomVariant] = useState(props.configuration?.isCustomVariant)
    const[isCustomOrder] = useState(props.configuration?.isCustomOrder)
    const[customVariants] = useState(props.configuration?.customModulesList)
    const[currCategory] = useState(props.product?.category_name)
    const[isDataLoaded,setIsDataLoaded] = useState(false)
    const[readOnly] = useState(getUser()?.isShareMode)

    useEffect(()=>{
        loadApiData()
    },[])


    async function loadApiData() {
        if(isDataLoaded){
            return
        }
        let promiseArray = [getProductModules()]
        Promise.all(promiseArray)
        .then(function (apidata) {
            applicationConfig.data.productModules = apidata[0]
            setIsDataLoaded(true)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

 

    function ModulesList() {
        let modulesList = getModulesList()
        return(
            <div className="marginTop10">
                <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>Module</th>
                            <th>Description</th>
                            <th className="display-flex-row no-wrap">Configuration <SofaPartImage product={props.product}/> </th>
                            <th>Price</th>
                        </tr>
                    </thead>
                <tbody>
                    {modulesList?.map((currModule:any,i:number)=>{
                        let module = getObjectByParameter(applicationConfig?.data?.productModules,"module_name",currModule.moduleName)
                        return(
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td className="center-aligned-column" style={{maxWidth:"12rem"}}> {currCategory === "Sofa" ? <ModuleImage module={module}/>:<ProductImage classNames="no-border" product={currModule}/>} </td>
                                <td className="heading2 text-no-wrap">{module?.display_name || currModule?.moduleName}</td>
                                <td>{module?.module_description}</td>
                                <td> <ModulesFinishDetails product={props.product} configurations={currModule?.configuration} /> </td>
                                <td>{getFormattedPrice(getRandomNumber(5000,15000))}</td>
                            </tr>
                        )
                    })}
                </tbody>
                </table>
            </div>
            </div>
        )
    }

    function StorageOption() {
        let storage = props.configuration.storage
        return(
            <div className="marginTop10">
                <div className="marginTop10">
                <div className="Pricing"></div>
                <table className="table store-quotation-table">
                <tbody>
                    <tr>
                        <td>{storage.name} - {storage.type} {storage.size}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        )
    }


    function getModulesList(){
        let modulesList = []
        if(props.configuration3D){
            modulesList = props.configuration3D.modules || []
            if(props.configuration3D.isLshapeWithSingleSeater){
                let addonsList = [...props.configuration3D.addons]
                if(addonsList.length){
                    let singleSeaters = addonsList.filter(currAddon => (currAddon.moduleName === "SingleSeater" || currAddon.moduleName === "DoubleSeater" || currAddon.moduleName === "ThreeSeater") && currAddon.productName === props.product.product_name)
                    if(singleSeaters.length){
                        modulesList = [...modulesList,...singleSeaters]
                    }
                }
            }
        }else{
            modulesList = props.configuration.customModulesList
        }
        return modulesList
    }


    
  function HeaderDetails() {
    return(
        <div className="marginTop10">
            <div className="marginTop10">
            <div className="Pricing"></div>
            <table className="table store-quotation-table border-top">
                <tbody>
                    <tr>
                        <td className="">Product Name</td>
                        <td className="">{STORE.getProductName(props.product)}</td>
                        <td className="">Qualiy of product</td>
                        <td className="capitalize">{props.configuration.quality}</td>
                    </tr>
                    <tr>
                        <td className="">Product Category</td>
                        <td className="">{props.product?.category_name}</td>
                        <td className="">Price</td>
                        <td className="capitalize">{getFormattedPrice(props.configuration.price)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        </div> 
    )
    }
  
 
  return (
    <>
    {isDataLoaded?
        <>
     <div className="full-width">

        {!isCustomOrder?
        <div className="pricing-details  white-card padding10 shadow-medium full-width overflow-x-scroll">
            <div className="heading1">Modules List</div>
            <div className=""><ModulesList/></div>
        </div>
        :null}

        {isCustomOrder?
        <div className="pricing-details  white-card padding10 shadow-medium ">
            <div className=""><HeaderDetails/></div>
        </div>
        :null}

        {/* <div className="pricing-details  marginTop10 white-card padding10 shadow-medium overflow-hidden">
            <div className="heading1">Fabric Details</div>
            <FabricDetails 
            configuration={props.configuration} 
            product={props.product}
            configuration3D={props.configuration3D}
            />
        </div> */}

        {currCategory === "Sofa"?
            <div className="pricing-details  marginTop10 white-card padding10 shadow-medium">
                <div className="heading1">Top view of product / Space Configuration</div>
                {!isCustomOrder?
                <CustomVariantMeasurements product={props.product} productConfiguration={props.configuration} isReadOnly={readOnly} configuration3D={props.configuration3D}/>
                :
                <ProductVariantMeasurements product={props.product} productConfiguration={props.configuration} isReadOnly={readOnly}/>
                }
            </div>
        :null}

        

        {currCategory === "Sofa" && props.product.customizable_legs ?
            <div className="pricing-details  marginTop10 white-card padding10 shadow-medium">
                <div className="heading1">Leg Details</div>
                <div className=""><LegDetails configuration3D={props.configuration3D} configuration={props.configuration} isReadOnly={readOnly}/></div>
            </div>
        :null}

        {currCategory === "Sofa" && props.product?.sub_category_name !== "Chair" && applicationConfig.clientName === "SiddhiSofas"?
            <div className="pricing-details  marginTop10 white-card padding10 shadow-medium">
                <div className="heading1">Dimensions</div>
                <div className=""><OtherDimensions configuration3D={props.configuration3D} configuration={props.configuration} isReadOnly={readOnly}/></div>
            </div>
        :null}

        {currCategory === "UpholsteredBed"?
            <div className="pricing-details  marginTop10">
                <div className="heading1">Storage Option</div>
                <div className=""><StorageOption/></div>
            </div>
        :null}



        
  
        {props.configuration3D?.addons?
            <div className="pricing-details  marginTop10 white-card padding10 shadow-medium full-width overflow-x-scroll">
                <div className="heading1">Add-ons related to this product</div>
                <div className=""><AddonsDetails configuration={props.configuration3D} product={props.product}/></div>
            </div>
        :null}

        <div className="pricing-details  marginTop10 white-card padding10 shadow-medium">
            <div className="heading1">Special instruction / Additional cusomization if any</div>
            <div className=""><CustomInstructions configuration={props.configuration}/></div>
        </div>
      

     </div>   


</>
    :null}
     </>
  );
};

export default ProductConfigSummary;
