import $ from "jquery";
import { BoxHelper, Object3D, Raycaster } from "three";
import { Vector3 } from "three/src/math/Vector3";
import { showComponentLoader, showToast } from "../UI_methods/global";
import { selectGroupKitchenPlanner } from "../kitchenplanner/groups";
import { applicationConfig, compareArrays, disposeVariables, getMaterialTypes, stringWithoutWhiteSpace } from "../methods";
import { updateActionPanelInformation } from "./UImethods";
import { isProductRotating } from "./area3dmodel/actions";
import { IS_CUSTOMIZABLE_MODE, IS_PRODUCT_LOCKED, IS_SPACE_PLANNER_MODE, composer, customizerConfig, deselectProduct, floorplanner, getClicked3DPoint, getIntersectsObjectsInAreaModel, isFloorPlanMode, isKitchenPlannerMode, kitchenPlanner, labelRenderer, measurementTool, renderer, resetCurrSelectedMeshes, selectProduct, wallColorCustomization } from "./area3dmodel/area3dModel";
import { isDraggingProduct } from "./area3dmodel/dragging";
import { isSelectWallForElevationView } from "./area3dmodel/elevationView";
import { isSelectWallsForArea, wallsClickInteraction, wallsClickWallColorCustomization, wallsHoverInteraction } from "./area3dmodel/floorplan/floorplanUI";
import { getOriginalCategoryName, getOriginalPartName, getOriginalProductName, getProductFromAreaModel } from "./area3dmodel/helper";
import { isInteriorVisualization, moveCamera, updateMoveCirclePosition } from "./area3dmodel/interior_visualization";
import { CURR_ISOLATED_PRODUCT, isIsolationView } from "./area3dmodel/isolationView";
import { addProductMultipleSelect } from "./area3dmodel/multiselect";
import { isProductConfigureMode } from "./area3dmodel/productConfigure";
import { getAllMeshesFromGroup, hideControlsPanel, loadAndApplyTextureToMesh, updateModelBoundingBox } from "./common";
import { disableLoading, getIntersectsObjects, getSelectedObjectType, group, undo } from "./modules/customizein3d";
import { createBoxBelowModel, getObjectByObjectId, getObjectDimensionPosition, getParent, updateOtherModelPositions } from "./modules/helper";
import { setFinishForUndo } from "./modules/undo";
import { CURR_SELECTED_MODULE, removeBoxHelper } from "./modules/raycasting";


export var CURR_CLICKED_MESH = null
var startTime:any,endTime:any

export var raycaster = new Raycaster();
export var mouseVector = new Vector3();

export var CURR_SELECTED_PRODUCT = undefined

export var hoverBoxHelper = null

var intersectsArray = []
var clickedObj:any = {}
var detectedObject:Object3D
var clicked3dPoints:Vector3

export function addRaycastingListenerToImage(dragControlsImages:any,controls:any) {
  dragControlsImages.addEventListener("dragstart",function(event:any) {
    controls.enablePan = false
    startTime  = new Date()
  })
  dragControlsImages.addEventListener("dragend",function(event:any) {
    controls.enablePan = true
    endTime  = new Date()
  })
}


  export function applyRaycastingInAreaModel(event:any,raycaster:any,customizerConfig:any,objectMaterialTypes:any,composer:any,x:any,y:any,scene:any,element:any,camera:any,IS_CUSTOMIZABLE_MODE:boolean,openMaterialWindow:(val:any,partName:string,productName:string)=>void,selectionType:string = "single") {
    let groupForRaycasting = scene

    if(isSelectWallsForArea || isSelectWallForElevationView){
      wallsClickInteraction(raycaster,x,y,scene,element,camera)
      return
    }


    if(wallColorCustomization.enabled){
      wallsClickWallColorCustomization(raycaster,x,y,scene,element,camera)
      return
    }

    if(measurementTool.enabled){
      measurementTool.click(x,y,camera)
      return
    }

    if(customizerConfig.isFinalizeMode){
      return
    }

    if(isIsolationView){
      groupForRaycasting = CURR_ISOLATED_PRODUCT
    }
  

    let intersectsArray:any = getIntersectsObjectsInAreaModel(groupForRaycasting,element,x, y,mouseVector,raycaster,camera,"");
    let clickedObj = getClickedObjectInArea(intersectsArray)

  
    if(clickedObj?.object){
      selectProductFromRaycasting(event,clickedObj?.object)
    }

    if(customizerConfig.isMultipleSelectionMode){
      addProductMultipleSelect(getProductFromAreaModel(clickedObj?.object))
      return
    }
    //If model is selected already
    // if(CURR_SELECTED_PRODUCT?.name === getProductFromAreaModel(clickedObj?.object?.parent)?.name && IS_CUSTOMIZABLE_MODE){
    //   if(IS_SPACE_PLANNER_MODE){
    //     return
    //   }
    //   return
    // }


    if(!clickedObj && !isProductConfigureMode){
      deselectProduct()
       hideControlsPanel(function() {
        composer.onWindowResize(renderer,camera,labelRenderer,IS_SPACE_PLANNER_MODE)
      })
      if(isKitchenPlannerMode){
        kitchenPlanner.multiselect.removeItemsFromGroup()
      }
    }

    $(".add-products-button").fadeIn(0)
  }

  function selectProductFromRaycasting(event:any,object:Object3D) {
        if(isProductConfigureMode){
          CURR_SELECTED_PRODUCT = object.parent
        }

        if(isKitchenPlannerMode && event.ctrlKey){
          if(CURR_SELECTED_PRODUCT){
            kitchenPlanner.multiselect.addItemToGroup(CURR_SELECTED_PRODUCT)
          }
          kitchenPlanner.multiselect.addItemToGroup(getProductFromAreaModel(object))
          return
        }
        
        CURR_SELECTED_PRODUCT = getProductFromAreaModel(object)

        if(CURR_SELECTED_PRODUCT?.userData.isModulesGroup){
          selectGroupKitchenPlanner(CURR_SELECTED_PRODUCT)
          return
        }

      

        if(CURR_SELECTED_PRODUCT?.visible){
          if(IS_CUSTOMIZABLE_MODE){
            hideControlsPanel(()=>{})
            resetCurrSelectedMeshes()
          }
          selectProduct()
          return
        }
  }


  export function sceneHoverInteraction(raycaster:any,x:any,y:any,scene:any,element:any,camera:any) {
    if(isSelectWallsForArea || isSelectWallForElevationView){
      wallsHoverInteraction(raycaster,x,y,floorplanner.group,element,camera)
      return
    }

    if(customizerConfig.isMultipleSelectionMode || isProductConfigureMode || isIsolationView || isKitchenPlannerMode || isProductRotating){
      removeHoverBoxHelper(scene)
      return
    }

    if(measurementTool.enabled){
      measurementTool.sceneHoverInteraction(x,y,camera)
      return
    }

    if(isDraggingProduct || customizerConfig.isFinalizeMode){
      removeHoverBoxHelper(scene)
      return
    }
    
    //update position of move icon
    if(isInteriorVisualization){
      // clickedObj = getFloorMesh(intersectsArray)
      // if(clickedObj?.object){
        clicked3dPoints = getClicked3DPoint(x,y,mouseVector)
        if(clicked3dPoints){
          updateMoveCirclePosition(clicked3dPoints)
        }else{
          console.log("Not found")
        }
      // }else{
      //   hideMoveCircle()
      // }
    }else{
      intersectsArray = getIntersectsObjectsInAreaModel(scene,element,x, y,mouseVector,raycaster,camera,"")
      clickedObj = getClickedObjectInArea(intersectsArray)
      if(clickedObj?.object){
        // detectedObject = getProductFromAreaModel(clickedObj?.object)
        detectedObject = getDetectedObject(clickedObj)
        if(detectedObject && !isFloorPlanMode && detectedObject.visible && !detectedObject.name.toLowerCase().includes("wall")){
          addHoverBoxHelper(scene,detectedObject)
        }else{
          removeHoverBoxHelper(scene)
        }
      }else{
        removeHoverBoxHelper(scene)
      }
    }
  }

  export function getDetectedObject(clickedObj:any) {
    if(isProductConfigureMode){
      detectedObject = clickedObj?.object.parent
    }
    else if(isKitchenPlannerMode){
      detectedObject = clickedObj?.object
    }
    else{
      detectedObject = getProductFromAreaModel(clickedObj?.object)
    }
    return detectedObject
  }

  export function updateInteriorCameraPosition(raycaster:any,x:any,y:any,scene:any,element:any,camera:any) {
    intersectsArray = getIntersectsObjectsInAreaModel(scene,element,x, y,mouseVector,raycaster,camera,"")
    clickedObj = getClickedObjectInArea(intersectsArray)

    // If clicked any product
    if(clickedObj?.object){
      return
    }else{
      //Check if floor is clicked
      clickedObj = getFloorMesh(intersectsArray)
      if(clickedObj?.object){
        if(clickedObj?.object.name.toLowerCase().includes("floor")){
          //For mobile there is no hover event
          clicked3dPoints = getClicked3DPoint(x,y,mouseVector)
          moveCamera(clicked3dPoints)
        }
      }
    }
    
  }


  export function addHoverBoxHelper(scene:any,object:any) {
    removeHoverBoxHelper(scene)
    if(object.visible){
      hoverBoxHelper = new BoxHelper( object, 0x0058a3)
      scene.add(hoverBoxHelper)
    }
  }

  export function removeHoverBoxHelper(scene:any) {
    if(hoverBoxHelper){
      scene?.remove(hoverBoxHelper)
    }
  }


  export function hoverInteration(raycaster:any,composer:any,x:any,y:any,scene:any,element:any,camera:any,IS_CUSTOMIZABLE_MODE:boolean,isDropFinish:boolean = false) {
    
    let intersectsArray:any = getIntersectsObjectsInAreaModel(scene,element,x, y,mouseVector,raycaster,camera,"");
    let clickedObj = getClickedObjectInArea(intersectsArray)

    if(CURR_SELECTED_PRODUCT?.name === clickedObj?.object.parent.name && IS_CUSTOMIZABLE_MODE && (IS_PRODUCT_LOCKED || isDropFinish)){
      // if(IS_CUSTOMIZABLE_MODE){
      if(clickedObj?.object){
        // let partName = clickedObj?.object?.name
        // let productName = clickedObj?.object?.parent.name
        // let categoryName = CURR_SELECTED_PRODUCT.userData.categoryName
        // disableMultiselectionMode()
        composer.outlinePass.selectedObjects = []
        composer.outlinePass.selectedObjects = [clickedObj?.object]
      }
      composer.render()
      return
    }
  }


  export function setCurrSelectedProduct(object:any) {
    // export function setCurrSelectedProduct(object:any,productName:string,categoryName:string,subCategoryName:string) {
    CURR_SELECTED_PRODUCT = object
    // CURR_SELECTED_PRODUCT.userData.productName = productName
    // CURR_SELECTED_PRODUCT.userData.categoryName = categoryName
    // CURR_SELECTED_PRODUCT.userData.subCategoryName = subCategoryName
  }

  export function getProductNameFromAreaModel(object:any) {
    if(object?.parent?.name === "area3dmodel"){
      return object.name
    }
    if(!object.parent){
      return null
    }
    return getProductNameFromAreaModel(object.parent)
  }


  export function selectMultipleParts(clickedMesh:any) {
    let currSelectedMeshes = composer.outlinePass.selectedObjects
    //If no mesh is selected initially
    if(!currSelectedMeshes.length){
      composer.setObjectsForOutline([clickedMesh])
      return
    }
    //compare first mesh and selected mesh category and material types 
    let firstSelectedElement = currSelectedMeshes[0]
    let firstSelectedElementCategory = getOriginalCategoryName(customizerConfig,getOriginalProductName(customizerConfig,currSelectedMeshes[0].parent.name))
    let currObjectNameCategory = getOriginalCategoryName(customizerConfig,getOriginalProductName(customizerConfig,clickedMesh.parent.name))
    //Compare categories
    if(firstSelectedElementCategory===currObjectNameCategory){
      let firstSelectedElementMaterialTypes = getMaterialTypes(applicationConfig?.data.objectMaterialTypes,firstSelectedElementCategory,getOriginalPartName(firstSelectedElementCategory,firstSelectedElement.name))
      let currSelectedElementMaterialTypes = getMaterialTypes(applicationConfig?.data.objectMaterialTypes,firstSelectedElementCategory,getOriginalPartName(firstSelectedElementCategory,clickedMesh.name))
      //Compare material types
      if(compareArrays(firstSelectedElementMaterialTypes,currSelectedElementMaterialTypes)){
        if(composer.outlinePass.selectedObjects.find(currMesh=>currMesh.name === clickedMesh.name)){
          //If mesh is already selected..remove from list
          let updatedMeshes = composer.outlinePass.selectedObjects.filter(currMesh=>currMesh.name != clickedMesh.name)
          composer.setObjectsForOutline(updatedMeshes)
          disposeVariables([updatedMeshes,currSelectedMeshes])
          return
        }else{
          composer.setObjectsForOutline([...currSelectedMeshes,clickedMesh])
        }
      }else{
        alert("Material types mismatch")
      }
    }else{
      alert("Category mismatch")
    }
  }

  export function resetCurrSelectedProduct() {
    CURR_SELECTED_PRODUCT = null
  }

  export function getMeshOfSameMaterialTypes(meshes:Array<any>,clickedObj:any,objectMaterialTypes:any,categoryName:string) {
    let filteredMeshes = []
    let categoryMaterialTypes: any = [];
    let partName = clickedObj?.name

    
    partName = getOriginalPartName(categoryName,partName)
    categoryMaterialTypes = getMaterialTypes(objectMaterialTypes,categoryName,partName)
    
    meshes?.forEach(object=>{
      if(object.isMesh){
        let currPartName = getOriginalPartName(categoryName,object.name)
  
        let otherElementMaterialTypes = getMaterialTypes(objectMaterialTypes,categoryName,currPartName)
        if(compareArrays(categoryMaterialTypes,otherElementMaterialTypes)){
            filteredMeshes.push(object)
        }
      }
    })
    return filteredMeshes
  }
  



  export async function dropMaterial(x:any,y:any,texture:any,configuration:any,element:any,camera:any,scene:any,moduleName:string,productName:string,MODULE_NAME:string) {
    showComponentLoader("changeFinishLoader")

    let intersectsArray:any = getIntersectsObjects(scene,element,x, y,mouseVector,raycaster,camera,moduleName);
    let clickedObj = getClickedObject(intersectsArray.filter( (object:any)=> object.object.type != "Line"))  // To prevent dimensions
    let object = getParent(clickedObj?.object) 
    
    if(object?.type==="Object3D"){
      // createBoxBelowModel(scene,boundingBox,object,SELECTED_OBJECT_OUTLINE_NAME)
    
      //get module 
      let allMeshes = getAllMeshesFromGroup(object)
      setFinishForUndo(undo,allMeshes,null)
      loadAndApplyTextureToMesh(object,texture,clickedObj?.object,productName,MODULE_NAME,"Sofa",configuration).then(data=>{
        disableLoading()
      })
      // loadAndApplyTexture(module.module,module.object,texture).then(data=>{
      //   disableLoading()
      // })
    }
    setTimeout(() => {
      disableLoading()
    }, 3000);
  }
  

  
export function updateObjectDimensions(dimensionName:string,action:string,scene:any,SELECTED_OBJECT_OUTLINE_NAME:string,boundingBox:any,configuration:any,axis:any) {
    let delta = 0
    let objectType = getSelectedObjectType(CURR_SELECTED_MODULE)


    if(objectType==="3dmodel")delta=0.04;
    let currLength = CURR_SELECTED_MODULE.scale.y 
    let currBreadth = CURR_SELECTED_MODULE.scale.x 
    let currDepth = CURR_SELECTED_MODULE.scale.z 


    const increaseWidthInCM = 1

    // const inchToMeter = 0.0254;
    // const increaseWidthByInches = 1;
    // const increaseWidthByMeters = increaseWidthByInches * inchToMeter;
    const increaseWidthByMeters = increaseWidthInCM / 100

    let dimData = getObjectDimensionPosition(null,CURR_SELECTED_MODULE).dimensions
    let dimX = dimData.dimX
    // let dimY = dimData.dimY * 100
    // let dimZ = dimData.dimZ * 100


    let modulesList = configuration.modules
    let modelsList = []
    // Increase the model's width
    switch(dimensionName){
      case "length":
        if(objectType==="3dmodel"){
          showToast("Only update breadth",2000)
          return
        }
        if(action==="plus"){
          CURR_SELECTED_MODULE.scale.set(currBreadth,currLength + delta ,currDepth)
        }else{
          CURR_SELECTED_MODULE.scale.set(currBreadth,currLength - delta ,currDepth)
        }
      break;
  
      case "breadth":
        if(action==="plus"){
          // let result = CURR_SELECTED_MODULE.scale.x + increaseWidthByMeters * 2;
          let result = dimX + (2.54 / 100);
          // console.log(result)
          CURR_SELECTED_MODULE.scale.set((result / dimX) * CURR_SELECTED_MODULE.scale.x ,currLength,currDepth)
          // CURR_SELECTED_MODULE.matrix.makeScale(result,currLength,currDepth)
          // console.log(CURR_SELECTED_MODULE.name,CURR_SELECTED_MODULE.scale,result,increaseWidthByMeters)
        }else{
          let result = CURR_SELECTED_MODULE.scale.x - increaseWidthByMeters * 2;
          CURR_SELECTED_MODULE.scale.set(result ,currLength,currDepth)
        }
        updateOtherModelPositions(group,configuration,boundingBox,axis,configuration.product.subCategoryName,false,false)
      break;

      case "height":
 
        // modulesList.forEach(module => {
        //   let moduleObject = getObjectByObjectId(scene,module.moduleObjectId) 
        //   modelsList.push(moduleObject)
        // });
        if(action==="plus"){
          let result = CURR_SELECTED_MODULE.scale.y + increaseWidthByMeters;
          CURR_SELECTED_MODULE.scale.set(currBreadth,result,currDepth)
        }else{
          let result = CURR_SELECTED_MODULE.scale.y - increaseWidthByMeters;
          CURR_SELECTED_MODULE.scale.set(currBreadth,result,currDepth)
        }
      break;

      case "depth":

        if(action==="plus"){
          let result = CURR_SELECTED_MODULE.scale.z + increaseWidthByMeters * 4;
          CURR_SELECTED_MODULE.scale.set(currBreadth,currLength,result)
        }else{
          let result = CURR_SELECTED_MODULE.scale.z - increaseWidthByMeters * 4;
          CURR_SELECTED_MODULE.scale.set(currBreadth,currLength,result)
        }
      break;
  
    }

    updateActionPanelInformation(CURR_SELECTED_MODULE,boundingBox)
    let moduleConfig = CURR_SELECTED_MODULE?.userData.configuration
    if(moduleConfig){
      let transform = moduleConfig.transform
      if(transform){
        transform.scale = CURR_SELECTED_MODULE.scale
      }
    }
    removeBoxHelper()
  }
  
  export function  getClickedObject(intersects:Array<any>) {
      var res = intersects?.filter(function(res) {
      return res && res?.object 
      && res?.object?.name.length
      && res?.object?.visible
      && !res?.object?.name?.toLowerCase().includes("wall")
      // let obj:any = res.object
      })[0];
      if(!res){
          return null
      }
      return res
  }

    
  export function getClickedObjectInArea(intersects:Array<any>) {
    var res = intersects?.filter(function(res) {
      return res && res?.object 
      && !res?.object?.name?.toLowerCase().includes("wall") 
      && !res?.object?.name?.toLowerCase().includes("floor") 
      && !res?.object?.name?.toLowerCase().includes("wallpaint") 
      && !res?.object?.name?.toLowerCase().includes("door") 
      && !res?.object?.type?.includes("BoxHelper") 
      && !res?.object?.type?.includes("GridHelper") 
      && !res?.object?.name?.includes("mesh") 
      // && (!res?.object?.name?.toLowerCase().includes("untitled") && res?.object?.parent.name?.toLowerCase().includes("frame")) 
      && !res?.object?.name?.toLowerCase().includes("ceiling") 
      && res?.object?.parent?.name!="dragging"
      && res?.object?.parent?.name.length
    })[0];
    if(!res){
      return null
    }
    return res
}    

export function getFloorMesh(intersects:Array<any>) {
  var res = intersects?.filter(function(res) {
    return res && res?.object 
    && res?.object?.name?.toLowerCase().includes("floor") 
  })[0];
  if(!res){
    return null
  }
  return res
}    