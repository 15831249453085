import $ from "jquery"
import { AdditiveBlending, Box3, BoxGeometry, Color, FrontSide, Group, Mesh, MeshBasicMaterial, ShaderMaterial, Vector3 } from "three"
import { getHeaderHeight, showToast } from "../../../UI_methods/global"
import { applicationConfig, getArrayOfDistinctValuesFromArray, getFilteredArray, stringWithoutWhiteSpace } from "../../../methods"
import { rendererDimensions } from "../../common"
import { getObjectBoxFromSelectedMeshes, getObjectByName, getObjectDimensionPosition } from "../../modules/helper"
import { mouseVector, raycaster, removeHoverBoxHelper } from "../../raycasting"
import { updateUndoVisibility } from "../UI_methods"
import { IS_SPACE_PLANNER_MODE, area3DSceneWrapper, areaModel, camera, canvasDimensions, composer, controls, customizerConfig, floorplanner, getIntersectsObjectsInAreaModel, isFloorPlanMode, labelRenderer, maskForCeiling, orthoCamera, perspectiveCamera, projectConfiguration, renderer, scene, undo, wallColorCustomization } from "../area3dModel"
import { setWallColorMode, isSelectWallForElevationView, confirmWallForElevationView } from "../elevationView"
import { isInteriorVisualization, updateInteriorCameraView } from "../interior_visualization"
import { All_WALLS, getAreaFromPoint, setInitialWallColor, updateNorthWall } from "../walls"
import { cameraForSettingPosition, cameraSettingViewData } from "./createRender"
import { EPS, cameraFor2dRender } from "./render2d"
import { floor } from "../../enviornment"


export var isPointerDownOnAnchor = false
export var isNorthWallSelectionMode = false

export var currSelectedAreaForEdit = ""
let x,y = 0

var intersectsArray = []
var clickedObj:any = {}
const vector = new Vector3()

let currSelectedAreaElement = null
let currSelectedAreaAnchor = null
let currselectedArea = null
var posX,posY,posZ = 0
var detectedObject:Mesh = null
let hoveredWallMaterial:any
export var currSelectedWallForElevationView = null
const selectedWallOpacity = 0.8

export var isSelectWallsForArea = false
export var isEnableAnchorDrag = false

export var isCameraSetting = false

var clickedWallColor = "0x0058a3"
var hoveredWallColor = "0x33bbff"
var defaultWallColor = "0xdddddd"
var updateAreaWallsList = []

var vec = new Vector3(); // create once and reuse
var initialPositionForUndo = new Vector3(); // create once and reuse

export function mouseDownAnchor(event:any) {
    currSelectedAreaAnchor = $(event.target).parent()
    y = event.pageY
    x = event.pageX
    isPointerDownOnAnchor = true

    if(customizerConfig.isFinalizeMode || customizerConfig.isFloorplanMode){
        $("#initialMessgaeWindow").addClass("display-none")
        removePulseAnimation()
        currSelectedAreaAnchor.addClass("pulse-anchor")
        setInitialPosition()
        if(cameraFor2dRender?.azimuthAngle){
            controls.rotateTo(cameraFor2dRender?.azimuthAngle,cameraFor2dRender.polarAngle,false)
        }
    
        currselectedArea = stringWithoutWhiteSpace(currSelectedAreaAnchor.find(".anchor-tooltip").text()) 
        applicationConfig.setCurrArea(currselectedArea)
    }
    
}


export function setMousePoints(event:any) {
    y = event.pageY
    x = event.pageX 
}

export function mouseMoveAnchor(event:any) {
    if(isPointerDownOnAnchor){
        let isUpdatePosition = updateAnchorElementPosition(event)
        if(customizerConfig.currentRenderingType === "3d" && customizerConfig.isFinalizeMode){
            applicationConfig.functions.customizer.setIsAddCameraMode(true)
        }
        if(isUpdatePosition){
            updateSceneCameraFromAnchorValues(currselectedArea)
        }
    }
}

export function mouseUpAnchor(event:any) {
    if(isPointerDownOnAnchor){
        isPointerDownOnAnchor = false
        if(customizerConfig.isFinalizeMode || customizerConfig.isFloorplanMode){
            applicationConfig.functions.customizer.setCurrAreaFloorplan(currselectedArea)
            applicationConfig.setCurrArea(currselectedArea)
            $(".add-anchor-walls-wrapper").css("pointer-events","all")
            showGuideBox()
            updateGuideState("confirmAnchor")
            //Add position in undo only if added to config 
            let cam = projectConfiguration.cameras.find(currCamera=>currCamera.cameraId === cameraFor2dRender.cameraId)
            if((customizerConfig.currentRenderingType === "3d" && cam) || customizerConfig.currentRenderingType === "2d"){
                undo.add("position",{prevPosition:initialPositionForUndo,cameraId:cameraFor2dRender.cameraId})
            }
        }
    }
}


export function setCurrSelectedAnchor(camera:any) {
    currSelectedAreaAnchor = camera
}

export function setInitialPosition() {
    setRaycaster(x,y)
    posX = raycaster.ray.origin.x
    posZ = raycaster.ray.origin.z

    let posY = controls.getPosition().y
    let pos = new Vector3(posX,posY,posZ)
    initialPositionForUndo = cameraFor2dRender.transform.position
    controls.setLookAt(pos.x,pos.y,pos.z,pos.x,pos.y,pos.z + EPS,false)
}

export function showCameraPropertiesPanel() {
    $("#cameraSettingsContainer").addClass("--is-active")
    composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
}

export function hideCameraPropertiesPanel() {
    // $("#cameraSettingsContainer").empty()
    $("#cameraSettingsContainer").removeClass("--is-active")
    composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
}

export function enableAnchorDrag() {
    isEnableAnchorDrag = true
    // addPulseAnimationToAllAnchors()
}

export function addPulseAnimationToAllAnchors() {
    let configuration = projectConfiguration.projectConfiguration
        for (const areaKey in configuration) {
        addPulseAnimation(areaKey)
    }
}


export function hideAnchorConfirmButton(areaName:string) {
    getAnchorElement(areaName).find(".anchor-confirm-button").removeClass("--is-active")
}
export function showAnchorConfirmButton(areaName:string,type:string) {
    let confirmButton = getAnchorElement(areaName).find(".anchor-confirm-button")
    confirmButton.addClass("--is-active")
}

export function updateAnchorElementPosition(event:any) {
    // y = event.pageY + area3DSceneWrapper.getBoundingClientRect().top + window.scrollY
    // x = event.pageX + area3DSceneWrapper.getBoundingClientRect().left + window.scrollX
   
    if(x < (canvasDimensions.width * cameraSettingViewData.width)-10){
        y = event.pageY
        x = event.pageX
        currSelectedAreaAnchor.css("top",`${y}px`) 
        currSelectedAreaAnchor.css("left",`${x}px`)    
        return true
    }
    return false
}

export function addAnchor(areaName:any,position:Vector3) {
    getAnchorIcon(areaName)
    if(isFloorPlanMode){
        updateAnchorPosition(areaName,position)
    }
    isSelectWallsForArea = false
}



export function getAnchorIcon(areaName:any) {
   
    const parentContainer = document.getElementById("area3DSceneWrapper")

    const anchorElement = $("<div></div>").addClass("anchor-icon-container hide-in-north-wall-selection floorplan-anchor-icon top-middle zindex30").
    attr("id",`areaAnchor${areaName}`)
    .appendTo($(parentContainer))
  
    $("<div></div>").addClass("anchor-icon")
    .html(`<i class='fas fa-map-marker-alt pointer-none font-medium icon'></i>`)
    .appendTo(anchorElement)



    const label = $("<div></div>").addClass("heading2 color-black white-card floorplan-tooltip shadow-light anchor-tooltip font-small middle")
    .text(`${String(areaName)}`)
    .appendTo(anchorElement)

    const buttonContainer = $("<div></div>").addClass("heading2 padding5 color-black white-card floorplan-edit-button shadow-light font-ex-small top-right")
    .html(`<i class='fas fa-edit pointer-none color-black font-small icon'></i>`)
    .appendTo(label)

    buttonContainer.click(function() {
        setCurrAreaFloorplan(areaName)
        applicationConfig.functions.floorplan.updateWallHandle(areaName)
    })
}

export function setCurrAreaFloorplan(areaName:string) {
    currSelectedAreaForEdit = areaName
}


export function getAnchorElement(areaName:any) {
    areaName = String(areaName)
    if(isInteriorVisualization){
        return $(`#areaAnchor${stringWithoutWhiteSpace(projectConfiguration.projectName)}`)
    }
    return $(`#areaAnchor${stringWithoutWhiteSpace(String(areaName)) || stringWithoutWhiteSpace(projectConfiguration.projectName)}`)
}
export function setCurrSelectedAreaElement(areaName:string) {
    currSelectedAreaElement = $(`#areaDetailsWrapper${areaName}`)
}


export function confirmWalls(areaName:string = "") {


    let wallsNames = []
    let selectedMesh = []
    //Group to get anchors position
    // const wallsGroup = new Group()
    // floorplanner.group.add(wallsGroup)
    if(All_WALLS.length){
		All_WALLS.forEach(currWall=>{
            if(currWall.userData.isClicked){
                currWall.material.color.setHex(hoveredWallColor)
                currWall.userData.isSelected = true
                // wallsGroup.add(currWall.clone())
                selectedMesh.push(currWall)
                wallsNames.push(currWall.name)
            }
		})
	}
    wallsNames = getArrayOfDistinctValuesFromArray(wallsNames)
   
    let center = getObjectBoxFromSelectedMeshes(selectedMesh).center

    if(!wallsNames.length){
        alert("No wall selected")
        return
    }

    if(isNorthWallSelectionMode){
        projectConfiguration.setNorthWall(wallsNames[0])
        updateNorthWall()
        disableNorthWallSelectionMode()
        postConfirmWallsActions()
        return 
    }

    if(positionAnchorToWallsCenter(center,areaName)){
        projectConfiguration.updateAreaWalls(areaName,wallsNames)
        let mapping = {...applicationConfig.states.customizer.areaDetailsMapping}
        mapping[areaName].walls = true
        applicationConfig.functions.customizer.setAreaDetailsMapping(mapping)
    }else{
        if(All_WALLS.length){
            All_WALLS.forEach(currWall=>{
                if(currWall.userData.isClicked){
                    currWall.material.color.setHex(0xdddddd)
                    currWall.userData.isSelected = false
                }
            })
        }  
        showToast("Anchor is not correct Please select again",2000,"error")
    }
    postConfirmWallsActions()
    // wallsGroup.parent.remove(wallsGroup)
    selectedMesh = []
}

function postConfirmWallsActions() {
    hideConfirmButton()
  
    disableSelectWalls()
    hideGuideBox()
    removePulseAnimation()
    // hideAnchorConfirmButton(currselectedArea)
    isSelectWallsForArea = false
    isEnableAnchorDrag = false
    updateAreaWallsList = []

    removeAreaHighlight()
    hideWallSelectionHint()  
}


function positionAnchorToWallsCenter(center:Vector3,areaName:string) {
    let areaModelPosition = getObjectDimensionPosition(null,floorplanner.group).positions
    console.log(center,getObjectDimensionPosition(null,floorplanner.group).center)
    if(center.y > areaModelPosition.min.y && center.y < areaModelPosition.max.y){
        addAnchor(areaName,center)
        projectConfiguration.updateAreaAnchor(areaName,center)
        return true  
    }
    return false
}




export async function updateSceneCameraFromAnchorValues(areaName:string="") {
    y = y  
    x = x  
    setRaycaster(x,y)
    posX = raycaster.ray.origin.x
    posZ = raycaster.ray.origin.z

    let pos = new Vector3(posX,controls.getPosition().y,posZ)


    await updateInteriorCameraView(cameraFor2dRender,pos)

    if(customizerConfig.currentRenderingType === "2d" && customizerConfig.isFinalizeMode){
        updateAreaNameWithClippingValue()
        cameraFor2dRender.transform.updateTransform(controls.getPosition(),perspectiveCamera.quaternion.clone(),perspectiveCamera.scale,0)
    }
}
 

export function setRaycaster(x:number, y:number) {

    let currCamera = false ? orthoCamera : cameraForSettingPosition
    let width = false ? rendererDimensions.width : rendererDimensions.width  * cameraSettingViewData.width
    let height = false ? rendererDimensions.height :  rendererDimensions.height* cameraSettingViewData.height

    if(isInteriorVisualization){
        y = y -  (rendererDimensions.height * (1 - cameraSettingViewData.height))
    }
  

    y = y - ((area3DSceneWrapper.getBoundingClientRect().top) + window.scrollY)
    x = x - ((area3DSceneWrapper.getBoundingClientRect().left) + window.scrollX)  

    x = (x / width) * 2 - 1
    y = -(y / height) * 2 + 1

    mouseVector?.set(x, y, 0.5)
    raycaster?.setFromCamera(mouseVector, currCamera)
    return raycaster?.intersectObject(scene,true) 
  }

export function resetAnchor() {
    // currSelectedAreaAnchor.remove()
    x = 0
    y = 0
    // currSelectedAreaElement.find(".add-anchor-button").removeClass("display-none")
    $("#areadetailsConfirmButton").removeClass("--is-active")
}

export function resetWalls() {
    setInitialWallColor()
    removeHoverBoxHelper(scene)
    hideConfirmButton()
    disableSelectWalls()
}

export function resetCurrSelectedWallForElevationView() {
    currSelectedWallForElevationView = null
}


export function setCurrSelectedWallForElevationView(wall:any) {
    currSelectedWallForElevationView = wall
}

export function enableNextArea(areaName:any) {
    $(`#areaDetailsWrapper${areaName}`).removeClass("disable")
}

export function enableSelectWalls(areaName:string) {
    currselectedArea = areaName
    // showToast("Click on the wall to select",2000)
    isSelectWallsForArea = true
    showConfirmButton()
    // startWallsGlowing()
    $(".area-details-confirm-button").addClass("--is-active")
    $(".hide-on-wall-select").addClass("disable")

    setInitialWallColor()
    // applicationConfig.functions.customizer.setIsWallSelection(true)
}

export function disableSelectWalls() {
    isSelectWallsForArea = false
    removePulseAnimation()
    hideConfirmButton()
    if(All_WALLS.length){
		All_WALLS.forEach(currWall=>{
            currWall.userData.isClicked = false
            resetWallColor(currWall)
		})
	}
    $(".area-details-confirm-button").removeClass("--is-active")
    $(".hide-on-wall-select").removeClass("disable")
    // applicationConfig.functions.customizer.setIsWallSelection(false)
}




export function wallsHoverInteraction(raycaster:any,x:any,y:any,scene:any,element:any,camera:any) {
    intersectsArray = getIntersectsObjectsInAreaModel(scene,element,x, y,mouseVector,raycaster,camera,"")
    // console.log(intersectsArray)
    clickedObj = getDetectedWall(intersectsArray)
    // console.log(clickedObj)
    if(clickedObj?.object){
        resetWallColor(detectedObject)
        detectedObject = clickedObj?.object
        // detectedObject.visible = false
        hoveredWallMaterial = detectedObject.material
        if(detectedObject){
            hoveredWallMaterial.color.setHex(clickedWallColor)
            // addHoverBoxHelper(scene,detectedObject)
        }else{
            resetWallColor(detectedObject)
            // removeHoverBoxHelper(scene)
        }
    }else{
        if(detectedObject){
            resetWallColor(detectedObject)
        }
    }
}


export function resetWallColor(mesh:any){
    if(mesh){
        mesh.material?.color.setHex(0xdddddd)
        if(mesh.userData.isSelected && !isSelectWallsForArea && !isSelectWallForElevationView){
            mesh.material?.color.setHex(hoveredWallColor)
        } 
        if(mesh.userData.isClicked){
            mesh.material?.color.setHex(clickedWallColor)
        }
    }
   
}

export function wallsClickWallColorCustomization(raycaster:any,x:any,y:any,scene:any,element:any,camera:any) {
    intersectsArray = getIntersectsObjectsInAreaModel(scene,element,x, y,mouseVector,raycaster,camera,"")
    clickedObj = getDetectedWall(intersectsArray)
    if(clickedObj?.object){
        let detectedObject = clickedObj?.object
        if(detectedObject){
            wallColorCustomization.addWall(detectedObject)
        }
    }
}

export function wallsClickInteraction(raycaster:any,x:any,y:any,scene:any,element:any,camera:any) {
    if(isSelectWallForElevationView){
        resetWalls()
    }
    intersectsArray = getIntersectsObjectsInAreaModel(scene,element,x, y,mouseVector,raycaster,camera,"")
    clickedObj = getDetectedWall(intersectsArray)
    if(clickedObj?.object){
        let detectedObject = clickedObj?.object
        if(detectedObject){
            if(isNorthWallSelectionMode){
                updateAreaWallsList = []
                setInitialWallColor()
            }
            let material:any = detectedObject.material 
            //If alreay clicked, deselect
            if(detectedObject.userData.isClicked){
                material.color.setHex(0xdddddd)
                //For deleteing selected wall
                detectedObject.userData.isSelected = false
                detectedObject.userData.isClicked = false
                return
            }
            material.color.setHex(clickedWallColor)
            material.opacity = 1
            detectedObject.userData.isClicked = true
            updateAreaWallsList.push(detectedObject.name)
            currSelectedWallForElevationView = detectedObject

            if(currSelectedWallForElevationView && setWallColorMode){
                confirmWallForElevationView()
            }

            // showAnchorConfirmButton(currselectedArea,"walls")
            // updateGuideState("confirmWalls")
            // detectedObject.material.opacity = 0.4
            // let boxHelper = addBoxHelperInWall(detectedObject)
            // detectedObject.userData.boxHelper = boxHelper
        }
    }
}

    
export function getDetectedWall(intersects:Array<any>) {
    var res = intersects?.filter(function(res) {
      return res && res?.object 
      && res?.object?.name?.includes("Wall_") 
    //   && !res?.object?.name?.includes("Floor_") 
      && res?.object?.parent?.name.length
    })[0];
    if(!res){
      return null
    }
    return res
}  

function showConfirmButton() {
    $("#areadetailsConfirmButton").addClass("--is-active") 
}
function hideConfirmButton() {
    $("#areadetailsConfirmButton").removeClass("--is-active") 
}

 
export function removeAllAnchors() {
    $(".anchor-icon-container").remove()
}

export function updateAllAreaAnchorsPosition() {
    if(customizerConfig.currentRenderingType === "3d" && customizerConfig.isFinalizeMode){
        let camerasList = getFilteredArray(projectConfiguration.cameras,"renderType","3d")
        for (const currCam of camerasList) {
            updateAnchorPosition(currCam.cameraId,currCam.transform.position)
        }
    }
    if(customizerConfig.currentRenderingType === "2d" && customizerConfig.isFinalizeMode){
        if(cameraFor2dRender){
            updateAnchorPosition(applicationConfig.projectName,cameraFor2dRender.transform.position)
        }
    }
    if(isFloorPlanMode){
        let configuration = projectConfiguration.projectConfiguration
        for (const areaKey in configuration) {
            let area = configuration[areaKey]
            if(area.walls.length){
                updateAnchorPosition(areaKey,area.anchor)
            }
        }
    }
}

export function toggleCeilingVisibility(isVisible:boolean){
    if(isVisible){
        perspectiveCamera.layers.enable(maskForCeiling)
    }else{
        perspectiveCamera.layers.disable(maskForCeiling)
    }
}



export function highlightSelectedAreaWalls(areaName:string) {
    let configuration = projectConfiguration.projectConfiguration
    let walls = configuration[areaName].walls
    for (const wallName of walls) {
        let wall = getObjectByName(scene,wallName)
        if(wall){
            wall.material.color.setHex(clickedWallColor)
            wall.material.opacity = 1
            wall.userData.isSelected = true
            wall.userData.isClicked = true
            updateAreaWallsList.push(wallName)
        }
    }
}

export function deselectAreaWalls(areaName:string) {
    let configuration = projectConfiguration.projectConfiguration
    let walls = configuration[areaName].walls
    for (const wallName of walls) {
        let wall = getObjectByName(scene,wallName)
        if(wall){
            wall.userData.isSelected = false
            wall.userData.isClicked = false
        }
    }
}



export function cancelWallSelection() {
    
    for (const wallName of updateAreaWallsList) {
        let wall = getObjectByName(scene,wallName)
        if(wall){
            wall.material.color.setHex(hoveredWallColor)
            wall.material.opacity = 1
            wall.userData.isSelected = false
            wall.userData.isClicked = false
        }
    }
    updateAreaWallsList = []
}


export function updateAnchorPosition(areaName:string,position:Vector3) {
    let element = getAnchorElement(areaName)
    let currCamera = getCameraForSettingPosition()
    let dimensions = getViewportWidthHeight()

  

    if(element){
        updateScreenPositionFromWorldPosition(currCamera,position,dimensions.width,dimensions.height)
        setScreenPositionOfElement(element,vector)
        if(!position.x && !position.y && !position.z){
            element.addClass("display-none")
            return
        }
    }
}

export function setScreenPositionOfElement(element:any,position:Vector3) {
    if(element){
        let top = Math.abs((area3DSceneWrapper.getBoundingClientRect().top) + position?.y - element.height()/2)
        let left = Math.abs((area3DSceneWrapper.getBoundingClientRect().left) + position?.x - element.width()/2)
        if(isInteriorVisualization && !customizerConfig.isFinalizeMode){
            left = left + (renderer?.domElement.width * cameraSettingViewData.left)
            top = top + (renderer?.domElement.height - (renderer?.domElement.height * cameraSettingViewData.height)) - (renderer?.domElement.height * cameraSettingViewData.bottom)
        }
        $(element).css("top",`${top}px`) 
        $(element).css("left",`${left}px`) 
        element.removeClass("display-none")
    } 
}

export function getCameraForSettingPosition() {
    if(isFloorPlanMode){
        return orthoCamera
    }
    return cameraForSettingPosition

}

export function updateScreenPositionFromWorldPosition(currCamera:any,worldPosition:Vector3,width:number,height:number){
    vector?.set(worldPosition.x,worldPosition.y,worldPosition.z)
    vector?.project(currCamera)

    vector.x = Math.round(((vector?.x + 1) / 2) * width)
    vector.y = Math.round((-(vector?.y - 1) / 2) * height)
}

export function getViewportWidthHeight() {
    let width,height = 0
    if(isFloorPlanMode){
       width =  renderer?.domElement.width
       height = renderer?.domElement.height
    }else{
        width = renderer?.domElement.width * cameraSettingViewData.width
        height = renderer?.domElement.height * cameraSettingViewData.height
    }
    return {
        width:width,
        height:height
    }

}


export function highlightSelectedWalls() {
    let walls = []
    let configuration = projectConfiguration.projectConfiguration
    for (const areaKey in configuration) {
        let area = configuration[areaKey]
        if(area.walls.length){
            walls.push(...walls,...area.walls)
        }
    }
    if(All_WALLS.length){
		All_WALLS.forEach(currWall=>{
            if(walls.includes(currWall.name)){
                currWall.material.color.setHex(hoveredWallColor)
                currWall.userData.isSelected = true
            }else{
                currWall.material.color.setHex(defaultWallColor)
            }
		})
	}  
}

export function hideAllAreaAnchors() {
    $(".anchor-icon-container").css("visibility","hidden")
}
export function showAllAreaAnchors() {
    $(".anchor-icon-container").css("visibility","visible")
}


export function hideGuideBox() {
    return
    $(".user-guide-box").removeClass("--is-active")
}
export function showGuideBox() {
    $(".user-guide-box").addClass("--is-active")
}

export function updateGuideState(state:string) {
    if(applicationConfig.functions.customizer.setCurrStateFloorplan){
        applicationConfig.functions.customizer.setCurrStateFloorplan(state)
    }
}

export function addPulseAnimation(areaName:string) {
    // $(".anchor-icon-container").removeClass("pulse-anchor")
    setCurrAreaName(areaName)
    if(customizerConfig.currentRenderingType === "3d"){
        removePulseAnimation()
    }
    getAnchorElement(areaName).addClass("pulse-anchor")
}

export function setPulseAnimation(anchor:any) {
    if(customizerConfig.currentRenderingType === "3d"){
        removePulseAnimation()
    }
    anchor.addClass("pulse-anchor")
}



export function removePulseAnimation() {
    $(".anchor-icon-container").removeClass("pulse-anchor")
}

export function showWallSelectionHint() {
    $(".wall-selection-hint").addClass("--is-active")
}

export function hideWallSelectionHint() {
    $(".wall-selection-hint").removeClass("--is-active")
}

export function setCurrAreaName(areaName:string) {
    currselectedArea = areaName
}

export function getGlowMaterial() {
    let uniforms:any = { 
        "c":   { type: "f", value: 1.0 },
        "p":   { type: "f", value: 0.7 },
        glowColor: { type: "c", value: new Color(0xffff00) },
        viewVector: { type: "v3", value: camera.position }
    }
    return new ShaderMaterial( 
    {
        uniforms:uniforms,
        vertexShader:   document.getElementById( 'vertexShader'   ).textContent,
        fragmentShader: document.getElementById( 'fragmentShader' ).textContent,
        side: FrontSide,
        blending: AdditiveBlending,
        transparent: true
    }   );
}

export function pulsateNextArea(areaDetailsMapping:any,setCurrArea:(val:string)=>void) {
    removeAreaHighlight()
    if(!areaDetailsMapping){
        return
    }

    if(isAllAreasCompleted(areaDetailsMapping)){
        $("#areaDetailsUpdateButton").addClass("pulse-box")
        $("#areaDetailsUpdateButton").removeClass("display-none")
        return
    }

    let areas = projectConfiguration.areasList 
    let index = 0
    for (let i = 0; i < areas.length; i++) {
        const area = areas[i]
        if(!areaDetailsMapping[area.area_name]?.walls){
            index = i
            break
        }
        index = i
    }
    if(index === areas.length-1){
        $("#areaDetailsUpdateButton").addClass("pulse-box")
        $("#areaDetailsUpdateButton").removeClass("display-none")
        return
    }
    // $(`#areaDetailsWrapper${areas[index].area_name}`).addClass("pulse-box")
    setCurrArea(areas[index].area_name)
}

export function removeAreaHighlight() {
    $(".area-details-wrapper").removeClass("pulse-box")
}


export function addPulseAnimationInArea(areaName:string) {
    $(".area-view-wrapper").removeClass("pulse-box")
    $(`#areaViewWrapper${areaName}`).addClass("pulse-box")
}



  export function updateFov(event:any,fov:any = null) {
    if(!fov){
        fov = event.target.value
    }
    camera.fov = fov
    camera.updateProjectionMatrix()
    cameraFor2dRender.updateFov(Number(fov))
    projectConfiguration.updateCameraTransform(applicationConfig.areaName,camera)
    $("#rangeSliderFovVal").text(String(camera.fov))
  }

  var posWithNearValue = new Vector3()
  var cameraWorldDirection = new Vector3()

  export function updateNearValue(event:any,near:any = null) {
    if(!near){
        near = (event.target.value / 100)
    }
    camera.near = near
    camera.updateProjectionMatrix()
    cameraFor2dRender.updateNear(near)
    // projectConfiguration.updateCameraNear(applicationConfig.areaName,camera)
    perspectiveCamera.getWorldDirection( cameraWorldDirection );
    updateAreaNameWithClippingValue()
    $("#rangeSliderNearVal").text(String(camera.near))
  }

  export function updateAreaNameWithClippingValue() {
    let position = perspectiveCamera.position
    let detectedArea = getAreaFromPoint(position.add(cameraWorldDirection.multiplyScalar(perspectiveCamera.near)))
    cameraFor2dRender.updateArea(detectedArea)
  }

  export function updateCameaAspect(value:any) {
    camera.aspect = value
   
    camera.updateProjectionMatrix()
    projectConfiguration.updateCameraNear(applicationConfig.areaName,camera)
  }

  export function updateCameraTransform(areaName:string = currselectedArea) {
    if(cameraFor2dRender && customizerConfig.isFinalizeMode){
        projectConfiguration.updateCameraValues(camera,cameraFor2dRender)
        // cameraFor2dRender.updateSceneCamera(perspectiveCamera.clone())
        cameraFor2dRender.setRotation(controls.polarAngle,controls.azimuthAngle)
        projectConfiguration.updateLocalStorage()  
    }
    projectConfiguration.updateLocalStorage()
  }

export function isAllAreasCompleted(areaDetailsMapping:any) {
    let isCompleted = true
    let areas = projectConfiguration.areasList 

    for (let i = 0; i < areas.length; i++) {
        const area = areas[i]
        if(!projectConfiguration.projectConfiguration[area.area_name]?.walls.length && area.area_name !== "Other"){
            isCompleted = false
            break
        }
    }

    return isCompleted
}

export function removeAreaAnchor(areaName:string) {
    getAnchorElement(areaName).remove()   
}


export function undoHandleFloorplan() {
    let item = undo.getLastItem()
    if(!item){
      showToast("Nothing to undo",2000,"error")
      return
    }
    let action = item.action
    let data = item.data

    let areaName =  data.areaName

    switch (action) {

        case "add":

        
            //update UI
            let mapping = {...applicationConfig.states.customizer.areaDetailsMapping}
            mapping[areaName].walls = false
            applicationConfig.functions.customizer.setAreaDetailsMapping(mapping)
            
            //Remove anchor
            removeAreaAnchor(areaName)

            deselectAreaWalls(areaName)

            //Update config
            projectConfiguration.updateAreaWalls(areaName,[])
            projectConfiguration.getAreaObject(areaName).updateAnchor(new Vector3(0,0,0))


            //reset Walls colors
            highlightSelectedWalls()

            showToast("Anchor removed",2000)

        break;

        case "update":

            //update anchor position
            updateAnchorPosition(areaName,data.position)

            //update walls 
            deselectAreaWalls(areaName)

            projectConfiguration.updateAreaWalls(areaName,data.walls)
            projectConfiguration.updateAreaAnchor(areaName,data.position)

            highlightSelectedWalls()

            showToast("Updated",2000)

        
        break;

    
        default:
        break;
    }
      
    undo.removeLastItem()
    updateUndoVisibility()
    projectConfiguration.updateLocalStorage()
}


export function enableNorthWallSelectionMode() {
    isSelectWallsForArea = true
    setInitialWallColor()
    isNorthWallSelectionMode = true
    $("#northWallConfirmButton").removeClass("display-none")
    $(".hide-in-north-wall-selection").addClass("display-none")
    highlightSelectedNorthWall()
    showToast("Selected successfully",2000)
}

export function disableNorthWallSelectionMode() {
    isNorthWallSelectionMode = false
    disableSelectWalls()
    $("#northWallConfirmButton").addClass("display-none")
    $(".hide-in-north-wall-selection").removeClass("display-none")
    updateAreaWallsList = []
}

function highlightSelectedNorthWall() {
    let northWall = projectConfiguration.northWall
    if(northWall.length){
        if(All_WALLS.length){
            All_WALLS.forEach(currWall=>{
                if(currWall.name === northWall){
                    currWall.material.color.setHex(clickedWallColor)
                    currWall.material.opacity = 1
                    currWall.userData.isSelected = true
                    currWall.userData.isClicked = true
                }
            })
        }
    }
    
}

export function releseMemoryFloorplan() {
    isPointerDownOnAnchor = false
    intersectsArray = []
    clickedObj = {}
  
    currSelectedAreaElement = null
    currSelectedAreaAnchor = null
    currselectedArea = null

    detectedObject = null
    isSelectWallsForArea = false
    isEnableAnchorDrag = false
    updateAreaWallsList = []
}