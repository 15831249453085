import React from 'react';
import { useClearCache } from 'react-clear-cache';
import { applicationConfig, hideWelcomeScreen } from "../../../../utils/methods";
import Loader from '../../shared-components/main-ui/Loader';
 
const Welcome: React.FC<{
  }> = (props) => {



    function gotoCustomizein3d() {
      if(applicationConfig.history && applicationConfig.shareItemId){
        // applicationConfig.setProductId(applicationConfig.shareItemId)
        if(applicationConfig?.data?.productsList?.length){
          let categoryName = applicationConfig?.data?.productsList.find(product => product.product_id === applicationConfig.shareItemId)?.category_name
          applicationConfig.history.push("/products/"+categoryName)
          hideWelcomeScreen()
        }
      }else{
        hideWelcomeScreen()
      }
    }
   

  return (

    <div   className='top-left welcome-screen display-none full-height-width' id="welcomeScreenShare">
    <div className='full-height-width'>

      <div className='top-middle' style={{top:"3rem"}}>
        <img src="assets/images/opus.png" alt="" />
      </div>
      <div className='middle full-width ion-padding'>
        <div className='product-details  padding5'>
          <div className='color-black font-ex-large text-center heading1'>Welcome to Opus Product Configurator</div>
          {/* <img src="" className='' id="sharedItemImage" alt="" /> */}
        </div>
        <div className='color-black font-normal marginTop10 center-aligned-column heading1' id="shareItemText"></div>
      </div>

      <div id="getStartedButton" onClick={gotoCustomizein3d} className='bottom-middle ion-padding' style={{bottom:"3rem"}}>
        <div className='white-card text shadow-medium bg-color-primary color-white heading2 click-effect letter-spacing1' style={{padding:"10px 20px"}}>Please wait ...</div>
      </div>

      <div className='bottom-middle full-width padding10'>
        <div className='color-danger full-width font-normal text-center center-aligned-column heading2' id="shareTokenExpiryTime">Note: This Link has will be active for 6 hours.</div>
      </div>

    </div>
  </div>
  );
};

export default Welcome;
