import $ from "jquery";
import React, { useEffect, useState } from "react";
import { applicationConfig, getSubDomainName, getUser, goback } from "../../../../../utils/methods";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ShareLink from "../../../../common/ui-components/links/sharelink/ShareLink";
import CustomerConfirmation from "./CustomerConfirmation";
import ChangeProduct from "./ChangeProduct";
import { startPageTour } from "../../../../../utils/siteTour/tour";
import { fullScreenBrowserToggle, getWindowWidth } from "../../../../../utils/UI_methods/global";
import { generateModelFromExporter } from "../../../../../utils/customizein3d/webxr";
import { IonModal } from "@ionic/react";
import ProductDetails from "../../ProductDetails";
import { STORE } from "../../../../../utils/store/storeConfiguration";
 
const Header: React.FC<{
    product:any
    isElevateMode:boolean
    changeProduct:(val:any)=>void
}> = (props) => {

    const [isOpenProductDetails,setIsOpenProductDetails] = useState<any>(null);

    const[logoSrc,setLogoSrc] = useState("assets/images/opus.png")
    const[subDomain] = useState(window.location.hostname.split('.')[0])
    const[isWhiteLabeledClient,setIsWhiteLabeledClient] = useState(false)


  
    useEffect(()=>{
      if(subDomain === "ssh" || subDomain === "thelevelfurniture" || applicationConfig?.user?.isAllowedWhitelabeling){
        setLogoSrc(`https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/clientLogos/${applicationConfig?.clientName}.png`)
        setIsWhiteLabeledClient(true)
      }
    },[subDomain])


    function showRequestQuotation() {
        $("#requestQuotationWindow").fadeIn()
    }

    function goBackHandle() {
        if(applicationConfig.sharedItem){
            window.history.back()
        }else{
            let categoryName = props.product.category_name || "Sofa"
            window.location.href = `/store/products/${categoryName}`
        }
    }
    
  return (
    <div className="main-header-wrapper border-bottom customize-3d-header heading2 bg-color-white white-card position-relative" style={{borderTop:"var(--op-color-primary)"}}>
        <IonModal
          isOpen={isOpenProductDetails} 
          cssClass={"small-y-modal"}
          onDidDismiss={setIsOpenProductDetails}
        >
            <ProductDetails
                configObj={applicationConfig}
                currProduct={props.product}
                setOpenProjectAction={setIsOpenProductDetails}
                viewProductin3DAction={()=>{}}
                gotoCustomizeIn3D={()=>{}}
            />
        </IonModal>


    <CustomerConfirmation/>
    <div className="left-middle">
        <div className="display-flex-row">
            <img src={logoSrc} alt=""  className="padding5" style={{width:"7rem",objectFit:"contain"}} />
            
            <div className="center-aligned-column border-left hide-button-text-in-mobile  border-right padding10" onClick={goBackHandle}>
                <div className="display-flex-row">
                    <span><i className="fas fa-chevron-left color-danger"></i></span>
                    <span className="heading3 marginleft5 text">Go back</span>
                </div>
            </div>

            <ChangeProduct
                changeProduct={props.changeProduct}
            />
{/* 
            <div className='option border-top pointer display-flex-row' onClick={generateModelFromExporter}>
            <div className='heading4 color-black font-small center-aligned-column'>Export Glb</div>
          </div> */}
           
        </div>
    </div>
    <div className="right-middle">

        <div className="display-flex-row no-wrap">

            {!getUser()?.isShareMode && getSubDomainName() !== "elevate"?
                <ShareLink
                shareItemId={props.product?.product_id}
                loaderId = {"loaderId"}
                moduleName="products"
                textClass="color-black"
                isShareButton={true}
                Content={()=>(
                    <Button2
                        configObj={{id:""}}
                        action={()=>{}}
                        label={"Share"}
                        fontAwesomeIcon={"fas fa-share color-primary"}
                        iconPos="right"
                        classNames={"no-shadow button-small bg-color-primary-outline"}
                    />
                )}
            />
            :null}

        <div className="center-aligned-column zindex1 padding5" style={{width:`${props.isElevateMode?"7rem":"4rem"}`}}>
            {props.isElevateMode?
                <div className="center-aligned-column pointer" onClick={startPageTour}>
                    <i className="fas fa-question-circle color-black font-medium"></i>
                </div>
            :
                <Button2
                    configObj={{id:""}}
                    action={fullScreenBrowserToggle}
                    label={""}
                    fontAwesomeIcon={"fas fa-expand-arrows-alt color-black"}
                    iconPos="left"
                    classNames={"no-shadow button-small marginleft5"}
                />
            }
        </div>



        </div>

    

    

    
    
          
    </div>
    
    <div className="middle font-large color-black store-color-primary heading1">
         
        <div className="display-flex-row no-wrap">
            <>
            {getWindowWidth()<480?
            <div className="color-black heading1 font-small center-aligned-column text-no-wrap" >{props.product.product_name}</div>
            :
            <div className="color-black heading1 font-medium center-aligned-column text-no-wrap" >Customizing - {props.product.product_name}</div>
            }
            </>

            <div className="center-aligned-column marginleft5" onClick={()=>{setIsOpenProductDetails(true)}}> <i className="fas fa-info-circle color-black"></i> </div>
            
        </div>
    </div>

    </div>
  );
};

export default Header;
