import { customizerConfig } from "../customizein3d/area3dmodel/area3dModel";
import { renderSettingConfig } from "../customizein3d/area3dmodel/floorplan/render2d";
import { configuration } from "../customizein3d/modules/customizein3d";
import { ProjectConfiguration } from "../customizer/ProjectConfiguration";
import { getWindowHeight, getWindowWidth } from "../UI_methods/global";


export class RenderConfig {
    aspectRatio:any = 4/3
    splitLimit:number = 8
    isMerge = false
    rendering:any = {
        resolution:{
            resolution_x:getWindowWidth(),
            resolution_y:getWindowHeight(),
        },
        percentage_resolution:25,
        no_of_samples:10,
        denoiser:true,
        render_min_x:0,
        render_max_x:1
    }
    requestId:string   
    rendersCount: number;
    

    constructor() {
        
    }

    setDimensions(dimX:number,dimY:number){
        this.rendering.resolution.resolution_x = Math.round(dimX) 
        this.rendering.resolution.resolution_y = Math.round(dimY) 
        this.aspectRatio = dimX / dimY
        // this.rendering.resolution.resolution_y = Math.round(dim / this.aspectRatio) 
    }

    getResolutionX(){
        return this.rendering.resolution.resolution_x
    }

    getResolutionY(){
        return this.rendering.resolution.resolution_y
    }

    getAspectRatio(){
        return Math.round(this.rendering.resolution.resolution_x / this.rendering.resolution.resolution_y * 100) / 100  
    }

    // setConfigName(val:string){
    //     this.configName = val
    // }

    setPercentageResolution(value:number){
        this.rendering.percentage_resolution = value
    }

    setNoOfSamples(value:number){
        this.rendering.no_of_samples = value
    }

    setIsMergeTrue(){
        this.isMerge = true
    }

  
    setRenderCount(count:number){
        this.rendersCount = count
    }
    setRequestId(id:string){
        this.requestId = id
    }

    setSplitLimit(limit:number){
        this.splitLimit = limit
    }

    setRenderMinMax(renderMinX:any,renderMaxX:any){
        this.rendering.render_min_x = renderMinX
        this.rendering.render_max_x = renderMaxX
    }

}

export class customizerRenderConfig{
    fileName = "360Renderer.py"
    projectRenderConfig:any
    // renderConfig:RenderConfig = renderSettingConfig
    renderConfig:RenderConfig = renderSettingConfig
    createBlend = true
    renderType:string = customizerConfig?.currentRenderingType || "2d"
    rendersCount: number;
    requestId:string  
    
    isProductRender = true
    

    constructor(projectConfiguration:ProjectConfiguration,cameraObject:any = null){
        this.projectRenderConfig = {...projectConfiguration}

        if(cameraObject){
            this.projectRenderConfig.cameras = [cameraObject]
        }else{
            this.projectRenderConfig.cameras = []
        }
        this.projectRenderConfig.projectConfiguration = {}
        this.projectRenderConfig.areasList = []
    }

    setCreateBlend(val:boolean){
        this.createBlend = val
    }

    removeAreasConfig(){
        this.projectRenderConfig.projectConfiguration = null  
    }


    setProjectConfigForConfigurator(){
        if(window.location.pathname.includes("product")){
            let product = configuration.product
            let productName = configuration.product.productName
            let productInstanceName = productName+"_1"

            this.projectRenderConfig.configId = configuration.configId
            this.projectRenderConfig.configName = configuration.configName
            this.projectRenderConfig.projectConfiguration = {
                "Default":{
                    areaName:"Default",
                    areaConfiguration:{
                        [productName]:{
                            [productInstanceName]:{
                                moduleConfiguration:configuration,
                                productName:productName,
                                productInstanceName:productInstanceName,
                                categoryName:product.categoryName,
                                subCategoryName:product.subCategoryName,
                            }
                        }
                    }
                }
            }
        }
        
    }


    addAllAreasConfiguration(projectConfiguration:any){
        this.projectRenderConfig.projectConfiguration = {...projectConfiguration?.projectConfiguration} || []
    }


    // getProductNameWithoutVariant(productName:any){
    //     let array = productName.split("")
    //     let lastIndex = array.length - 1
    //     if(numbers_array.includes(array[lastIndex]) && array[lastIndex - 1] === "_"){
    //         array.pop()
    //         array.pop()
    //         return array.join("")
    //     }
    //     return productName

    // }


    // getProductModel(productName:string){
    //     let result = null
    //     areaModel.children.forEach(object => {
    //         if(object.name.toLowerCase().includes(productName.toLowerCase()) || productName.toLowerCase().includes(object.name.toLowerCase())){
    //             result = object
    //             return
    //         }
    //     });
    //     return result
    // }
    
}