import { IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { applicationConfig, getArrayOfDistinctValues } from "../../../../utils/methods";
import ProductsList from "../ProductsList";
import MainHeader from "../common/MainHeader";
import ProductFilterHeader from "../common/ProductFilterHeader";
import SubHeader from "../common/SubHeader";
import ConfiguratorLinks from "./ConfiguratorLinks";
import Footer from "./Footer";
import Plateforms from "./Plateforms";
import TopCategories from "./TopCategories";
import DemoMessage from "./DemoMessage";
import Features from "./Features";
 

const ElevateHomepage: React.FC<{
  configObj:any
  isLogin:boolean
}> = (props) => {


  const[categories,setCatgories] = useState([])

  useEffect(()=>{
    if(props.configObj && props.isLogin){
      setCatgories(getArrayOfDistinctValues(applicationConfig?.data?.productsList,"category_name"))
    }
  },[])

 


  function TopProducts() {
    return(
      <>
        <div className="sort-items-container section">
          <ProductFilterHeader isHomePage={true} heading="Shop Our Best Sellers" filterOptions={[]}/>
          <ProductsList productsList={applicationConfig?.data?.productsList}/>
        </div>
      </>
    )
  }

  function Categories() {
    return(
      <div className="full-width section">
        <div className="ion-padding center-aligned-column" >
          <div className="heading1 font-ex-large">Explore The Range</div>
        </div>
        <TopCategories/>
      </div>
    )
  }

  function Deals() {
    return(
      <div className="full-width section">
        <div className="ion-padding center-aligned-column" >
          <div className="heading1 font-ex-large">Discover Exclusive Discounts</div>
        </div>
        <div className="display-flex-row">
          <div className="width50 padding10">
            <img className="full-width" src="https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/images/sale/1.png" alt="" />
          </div>
          <div className="width50 padding10">
            <img  className="full-width" src="https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/images/sale/3.png" alt="" />
          </div>
        </div>
      </div>
    )
  }



  function Banner() {
    return(
      <div className="full-width">
        <Carousel infiniteLoop={true} showThumbs={false}   autoPlay={true}>
            <div>
                <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/images/banner/Banner3.png" />
            </div>
            <div>
                <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/elevate/images/banner/Banner3.png" />
            </div>
        </Carousel>
      </div>
    )
  }
 
  return (
    <IonPage>
      {props.configObj && props.isLogin?
      <div className="full-height-width bg-color-white elevate-main-wrapper overflow-y-scroll">
        <DemoMessage/>
        <MainHeader/>
        <SubHeader/>
        <Banner/>
        <Features/>
        <div className="main-content-wrapper elevate-homepage position-relative bg-color-white">
            <div className="main-cards-filters-wrapper no-wrap padding-container full-height-width" >
              <Categories/>
              <ConfiguratorLinks/>
              <TopProducts/>
              <Deals/>
              {/* <Plateforms/> */}
            </div>
            <Footer/>
        </div>
      </div>
      :null}
    </IonPage>
  );
};

export default ElevateHomepage;
