import { IonModal } from "@ionic/react";
import React, { useRef, useState } from "react";
import { applicationConfig, getUser, waitFor } from "../../../../../utils/methods";
import Modal from "../../../../common/shared-components/Modals/Modal";
import ProductImages from "../../../projects/projectActions/moodboard/controls/ProductImages";
import { addItemToLocalStorage } from "../../../../../utils/cache/localstorage";
import { configuration, resetConfiguration, setCustomizein3dConfiguration } from "../../../../../utils/customizein3d/modules/customizein3d";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import { hideComponentLoader, showComponentLoader } from "../../../../../utils/UI_methods/global";
import { getForegroundSavedConfig } from "../../../../../services/api";
import Home from "../../productActions/Home";
import $ from "jquery"
import Button2 from "../../../../common/ui-components/buttons/Button2";
 
const ChangeProduct: React.FC<{
    changeProduct:(val:any)=>void
}> = (props) => {

    const[isOpenProduct,setisOpenProduct] = useState(false)
    const [isOpenOptions, setIsOpenOptions] = useState(false);
    const [currProduct,setCurrProduct] = useState<any>(null);

    const ref = useRef(null)


    async function  changeProduct(product:any) {
        addItemToLocalStorage("currProduct",product)
        STORE.currProduct = product
        setCurrProduct(product)
        addItemToLocalStorage("sharedItem",null)
        // setIsOpenOptions(true)
        $(ref.current).fadeIn()
    }

    function close() {
        $(ref.current).fadeOut()
    }

    async function loadApiData(product:any) {
        let promiseArray = [getForegroundSavedConfig(0,product.product_id)]
        Promise.all(promiseArray)
        .then(function (apidata) {
            applicationConfig.data.customizerSavedConfigs = apidata[0]
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function customizein3dAction() {
        showComponentLoader("mainLoaderSpinner")
        setIsOpenOptions(false)
        window.location.href = "/customizein3d"
    }
 
    return (
        <>

        {!getUser()?.isShareMode?
            <div onClick={()=>{setisOpenProduct(true)}} className="center-aligned-column  pointer border-left padding10">
            <div className="display-flex-row">
                <span><i className="fas fa-th color-primary"></i></span>
                <span className="heading3 marginleft5 hide-in-mobile">All Products</span>
            </div>
            </div>
        :null}


         <IonModal
            isOpen = {isOpenProduct}
            onDidDismiss = {()=>setisOpenProduct(false)}
            cssClass="medium-y-modal no-border"
            >
                 <div className="customizein3d-change-product moodboard-wrapper full-height-width">
                    {isOpenProduct?
                        <>
                        <div className="full-height-width">
                            <div className="header-content position-relative border-bottom">
                            
                                <div className="right-middle">
                                    <Button2
                                        configObj={{}}
                                        action={()=>{setisOpenProduct(false)}}
                                        label={""}
                                        fontAwesomeIcon={"fas fa-times color-danger"}
                                        iconPos="left"
                                        classNames={"shadow-light"}
                                    />
                                </div>

                                <div className="marginleft10 left-middle heading1 center-aligned-column">Select Product</div>

                            </div>
                            <div className="body-content-no-footer position-relative">
                                <ProductImages
                                    setProductModelSrc={()=>{}}
                                    configObj={applicationConfig}
                                    currArea={""}
                                    action={(product)=>{changeProduct(product)}}
                                    moduleName="customizein3d"
                                    dragStart={()=>{}}
                                    parentContainerId="changeConfiguratorProduct"
                                />
                            </div>
                        </div>

                      
                        <div ref={ref} className="full-height-width padding0 top-left zindex100" style={{display:"none"}}>
                            {currProduct?
                            <div className="full-height-width bg-color-white">
                                <div className="header-content position-relative border-bottom">

                                    <div className="left-middle">
                                    <Button2
                                        configObj={{}}
                                        action={close}
                                        label={"All products"}
                                        fontAwesomeIcon={"fas fa-chevron-left color-danger"}
                                        iconPos="left"
                                        classNames={"shadow-light button-small"}
                                    />
                                    </div>

                                    <div className="marginleft10 middle heading2 center-aligned-column">{currProduct?.product_name}</div>

                                </div>

                                <div className="body-content-no-footer">
                                    <Home
                                        configObj={applicationConfig}
                                        currProductId={currProduct?.product_id}
                                        setOpenProjectAction={()=>setIsOpenOptions(false)}
                                        currProduct={currProduct}
                                        viewIn360Action={customizein3dAction}
                                        gotoCustomizeIn3D={customizein3dAction}
                                        quickCustomizeAction={()=>{}}
                                    /> 
                                </div>

                            </div>
                           
                            :null}
                           
                        </div>
                        </>
                    :null}
                </div>
            </IonModal>
        
        </>
       
    );
};

export default ChangeProduct;
