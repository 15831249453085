import { IonSpinner } from "@ionic/react";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Customer, STORE } from "../../../../../../utils/store/storeConfiguration";
import Input from "../../../../../common/ui-components/input/Input";

 

const CustomerMobileForm: React.FC<{
  setIsCustomerFound:(val:boolean)=>void
  formData:any
  setFoundCustomer:(val:Customer)=>void
  foundCustomer:Customer
  isSetMobileNumber:boolean
}> = (props) => {

  const[alertId] = useState("customerFormAlert")

  const[customersList,setCustomersList] = useState([])
  const[filteredCustomers,setFilteredCustomer] = useState([])
  const[errors,setErrors] = useState([])

 

  useEffect(()=>{
    // STORE.storeApi.getCustomersList().then((data:any)=>{
    //   if(!data.error){
    //     if(data.data?.data?.length){
    //       setCustomersList(data.data.data)
    //     }   
    //   }
    // })
  },[])

  useEffect(()=>{
    setTimeout(() => {
      const input = document.querySelector("#customerPhone");
      const windowObj:any = window
      if (input) {
        windowObj.intlTelInput(input, {
          initialCountry: "in",
          separateDialCode: true,
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
        });
      }
    }, 500);
  },[])


  function onchangeMobile(event:any) {
    const value = event.target.value
    props.formData.mobile = value
    if(value.length > 5){
      setFilteredCustomer(customersList.filter(customer=>customer.mobile.includes(value)))
      $("#mobileSearchLoader").removeClass("visibility-hidden")
    }else{
      setFilteredCustomer([])
    }
    setTimeout(() => {
      $("#mobileSearchLoader").addClass("visibility-hidden")
    }, 2000);
  }


  // function submitMobileHandle() {
  //   const mobile:any = $("#customerPhone").val()
  //   if(!mobile.length){
  //     showAlert(alertId,"Please enter mobile number","error")
  //     return
  //   }
  //   props.formData.mobile = mobile
  //   props.setIsSetMobileNumber(true)
  // }
 

  function setCustomerHandle(customer:Customer) {
    props.setFoundCustomer(customer)
    // props.formData.mobile = customer.mobile
    // props.formData.customerName = customer.customerName
    props.setIsCustomerFound(true)
    // setTimeout(() => {
    //   $("#customerName").val(customer.customerName)
      $("#customerPhone").val(customer.mobile)
    // }, 500);
    setFilteredCustomer([])
  }


 
    
  return (
    <div className="position-relative">
    <div className="heading2 text-center ion-padding">Enter customer mobile number</div>
    <div className="center-aligned-row position-relative">
      <Input
        type="number"
        id="customerPhone"
        label=""
        isMandatory={true}
        onChange={onchangeMobile}
        value={props.foundCustomer?.mobile}
        className={props.foundCustomer || props.isSetMobileNumber?" disable":""}
        placeholder="Mobile number"
      />
    
      <div id="mobileSearchLoader" className="right-middle visibility-hidden center-aligned-column padding10" style={{marginTop:"5px"}}>
        <IonSpinner name="crescent" />
      </div>
      <div className="top-middle" style={{padding:"0",top:"100%",zIndex:999,width:"300px"}}>
        <div className="full-width customers-list bg-color-white">
          {filteredCustomers.map((customer:any,index:number)=>{
            return(
              <div className={`pointer padding10 ${index>1?" display-none":" "}`} onClick={()=>{setCustomerHandle(new Customer(customer))}} key={index}>
                <div className={`heading3 font-normal  `}>{customer.customer_name}</div>
                <div className={`heading3 marginTop5 color-dark font-md-small`}>+91 {customer.mobile}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>

    {/* <div className="padding5 center-aligned-column ion-margin-top">
      <Button2
        configObj={{id:""}}
        action={submitMobileHandle}
        label={"Continue"}
        fontAwesomeIcon={"fas fa-chevron-right color-white"}
        iconPos=""
        classNames={"no-shadow margin0 button-large cart-button cart-button-warning"}
      />
    </div> */}
    
  </div>
  );
};

export default CustomerMobileForm;
