import AWS from "aws-sdk"
import { applicationConfig, getObjectArrayOfDistictValues, stringWithoutWhiteSpace } from "../methods";
import { updateLoaderPercentage, updateLoaderProgress } from "../UI_methods/global";
import axios from "axios";

const BUCKET_NAME = "opusassets"

AWS.config.region = "ap-south-1"
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "ap-south-1:110f8f2a-ddc2-4a7b-b2d2-723e922fb326",
});

var s3:AWS.S3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {Bucket: BUCKET_NAME}
});

var distinctSqsMessages = new Set()
var sqsMessages = []

AWS.config.update({region: "ap-south-1"})

var sqs = new AWS.SQS(
  {
    apiVersion: '2012-11-05',
    accessKeyId:"AKIAS77YT2HNDZDFUQ5R",
    secretAccessKey:"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"
  });

var  queueURL = "https://sqs.ap-south-1.amazonaws.com/206142951898/blenderRender";
var queueURLRembg = "https://sqs.ap-south-1.amazonaws.com/206142951898/rembg"
var queueURLCreateTar = "https://sqs.ap-south-1.amazonaws.com/206142951898/createTar"
var queueURLCreateModel = "https://sqs.ap-south-1.amazonaws.com/206142951898/createmodel"


var params = {
  AttributeNames: [
     "SentTimestamp"
  ],
  MaxNumberOfMessages: 10,
  MessageAttributeNames: [
     "All"
  ],
  QueueUrl: queueURL,
  VisibilityTimeout: 20,
  WaitTimeSeconds: 0
 };

 

export default class AwsConfig{
   
    constructor(){
        // this.bucketName = bucketName
    }



    getTarFileKey(assetType:string,assetInfo:any){
        switch(assetType){
            case "thumbnails":
                return `thumbnails/OVL/${assetInfo.companyName}/${assetInfo.collectionName}/${assetInfo.collectionName}.tar.gz`
            break

            case "models":
                return `models/${assetInfo.clientName}/${assetInfo.productName}/${assetInfo.productName}.tar.gz`
            break

            case "productModels":
              return `productModels/${assetInfo.clientName}/${assetInfo.categoryName}/${assetInfo.subCategoryName}/${assetInfo.productName}.tar.gz`
            break

            case "products":
              return `productImages/${assetInfo.clientName}/${stringWithoutWhiteSpace(assetInfo.category)}/${stringWithoutWhiteSpace(assetInfo.subCategory)}/${stringWithoutWhiteSpace(assetInfo.productName)}/${stringWithoutWhiteSpace(assetInfo.productName)}.tar.gz`
            break

            case "textures":
              return assetInfo?.url?.replace(".png",".tar.gz") || null
            break

            case "moduleImages":
              return `moduleImages/moduleImages.tar.gz`

              case "addons":
                return `addons/${assetInfo?.clientName}/${assetInfo?.categoryName}/${assetInfo?.categoryName}.tar.gz`
              
            case "commonTexture":
              return `textures/${assetInfo?.companyName}/${assetInfo?.collectionName}/${assetInfo?.collectionName}Common/${assetInfo?.collectionName}Common.tar.gz`

            case "sprites":
              let index = assetInfo.urlPrefix.lastIndexOf("/") 
              let fileName = assetInfo.urlPrefix.substring(index)
              return `${assetInfo.urlPrefix}${fileName}.tar.gz`;
              
            case "renders":
              return `sprites/${assetInfo.clientName}/${stringWithoutWhiteSpace(assetInfo.projectName)}/ViewRenders/ViewRenders.tar.gz`
  
            case "360Images":
              return `360Images/${assetInfo.clientName}/${assetInfo.projectName}/${assetInfo.areaName}.tar.gz`

            case "AreaModel":
              return `models/${assetInfo.clientName}/AreaModels/${assetInfo.projectName}/${assetInfo.areaName}/${assetInfo.areaName}.tar.gz`
            
            case "ProjectShell":
              return `models/${assetInfo.clientName}/AreaModels/${assetInfo.projectName}/${assetInfo.projectName}_Shell.tar.gz`
              
            default:
                return ""
        }
    }

    getFileKey(assetType:string,assetInfo:any){
        switch(assetType){
            case "thumbnails":
                return `thumbnails/OVL/${assetInfo.companyName}/${assetInfo.collectionName}/${assetInfo.collectionName}.png`
            break

            case "models":
                return `models/${assetInfo.productName}/${assetInfo.productName}.glb`
            break

            default:
                return ""
        }
    }

    async getObject(key:string){
        let objectParameter = {
            Bucket: BUCKET_NAME,
            Key: key,
            ResponseCacheControl:"no-cache"
        }
        return  await s3.getObject(objectParameter).on('httpDownloadProgress', function (evt) {
          if(key.includes("model") || key.includes("thumbnails") || key.includes("ViewRenders") || key.includes("360Images") || key.includes("AreaModel")){
            // let factor = 1
            // if(key.includes("model")){
            //   factor = 2
            //   updateLoaderPercentage("canvasLoader",evt.loaded,evt.total)
            // }
            updateLoaderProgress("texturesLoader",evt.loaded,evt.total,"Downloading..")
            updateLoaderProgress("minimapLoader",evt.loaded,evt.total,"Downloading..")
            updateLoaderProgress("walkthroughLoader",evt.loaded,evt.total,"Downloading..")
          }
        }).promise();
    }

    async getObjectAttributes(key:string){
      let objectParameter = {
          Bucket: BUCKET_NAME,
          Key: key
      }
      return  await s3.getObject(objectParameter).promise()
    }

    uploadFile(blob:any,moduleName:string,albumPhotosKey:string,fileName:string,file:any=null) {
        return new Promise((resolve,reject)=>{
            let baseUrl = "https://opusassets.s3.ap-south-1.amazonaws.com/"
    
            // let albumName = "uploads"
            // var albumPhotosKey = encodeURIComponent(albumName) + "/share/" + encodeURIComponent(moduleName.toLowerCase()) + "/";
            if(!file){
              file = this.getFileFromCanvasBlob(blob,fileName)
            }
            var photoKey = albumPhotosKey + fileName;
            // Use S3 ManagedUpload class as it supports multipart uploads
            var upload = new AWS.S3.ManagedUpload({
              params: {
                Bucket: BUCKET_NAME,
                Key: photoKey,
                Body: file,   
                ACL:'public-read'
              }
            });
            var promise = upload.promise();
            promise.then(
              function(data) {
                resolve(baseUrl+photoKey)
              },
              function(err:any) {
                  console.log(err)
                  reject(err)
              }
            );
        })
      
      }

      uploadImageToS3(albumPhotosKey:string,file:any,customFileName=null,isPublic:boolean = false) {
        return new Promise((resolve,reject)=>{
            // let file = this.getFileFromCanvasBlob(blob,fileName)
            let fileName = file.name
            if(customFileName){
              fileName = customFileName
            }
            var photoKey = albumPhotosKey + fileName;
            let param:any = {
              Bucket: BUCKET_NAME,
              Key: photoKey,
              Body: file,
              CacheControl:"no-cache"
            }
            if(isPublic){
              param.ACL = "public-read"
            }
            // Use S3 ManagedUpload class as it supports multipart uploads
            var upload = new AWS.S3.ManagedUpload({
              params: param
            });
            var promise = upload.promise();
            promise.then(
              function(data) {
                resolve(data)
              },
              function(err:any) {
                  console.log(err)
                  reject(err)
              }
            );
        })
      }


// downloadAnduploadDefaultModel(albumPhotosKey, fileUrl, customFileName = null, isPublic = false) {
//     return new Promise((resolve, reject) => {
//         // Download the file from the URL
//         axios({
//             method: 'get',
//             url: fileUrl,
//             responseType: 'arraybuffer'
//         }).then(response => {
//             const fileData = Buffer.from(response.data, 'binary');
//             const contentType = response.headers['content-type'];

//             let fileName = fileUrl.split('/').pop(); // Default file name from URL
//             if (customFileName) {
//                 fileName = customFileName;
//             }

//             const photoKey = albumPhotosKey + fileName;

//             const params = {
//                 Bucket: BUCKET_NAME,
//                 Key: photoKey,
//                 Body: fileData,
//                 ContentType: contentType // Set the content type based on the file being downloaded
//             };

//             if (isPublic) {
//                 params.ACL = "public-read";
//             }

//             // Use S3 ManagedUpload class as it supports multipart uploads
//             const upload = new AWS.S3.ManagedUpload({
//                 params: params
//             });

//             const promise = upload.promise();
//             promise.then(
//                 function(data) {
//                     resolve(data);
//                 },
//                 function(err) {
//                     console.log(err);
//                     reject(err);
//                 }
//             );
//         }).catch(error => {
//             console.log('Error downloading the file:', error);
//             reject(error);
//         });
//     });
// }


      
    uploadRembgImage(fileName:string,file:any=null) {
      return new Promise((resolve,reject)=>{

        let albumName = "withbg"
        var albumPhotosKey = encodeURIComponent(albumName) + "/";
        var photoKey = albumPhotosKey + fileName;
        // Use S3 ManagedUpload class as it supports multipart uploads
        var upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: "rembgbucket",
            Key: photoKey,
            Body: file,
          }
        });
        var promise = upload.promise();
        promise.then(
          function(data) {
            resolve(photoKey)
          },
          function(err:any) {
              console.log(err)
              reject(err)
          }
        );
      })
    }
      // async viewAlbumContents(albumPhotosKey:string){
      //   return new Promise((resolve,reject)=>{
      //     s3.listObjectsV2({ Prefix: albumPhotosKey, Bucket: BUCKET_NAME}, function(err, data) {
      //         if (err) {
      //             reject('There was an error viewing your album: ' + err.message);
      //             return;
      //         }
      //         let photos = [];
      //         data.Contents.forEach(function(photo) {
      //             var photoKey = photo.Key;
      //             photos.push(photoKey);
      //         });
      //         resolve(photos);
      //     });

          
          
      //   })
      // }


viewAlbumContents(albumPhotosKey:string) {
    return new Promise((resolve, reject) => {
        let allKeys = [];
        let isTruncated = true;
        let continuationToken = null;

        const listObjects = () => {
            let params = {
                Bucket: BUCKET_NAME,
                Prefix: albumPhotosKey,
                Delimiter: Date.now().toString()
            };
            // s3.listObjectsV2(params, (err, data) => {
            //     if (err) {
            //         reject('There was an error viewing your album: ' + err.message);
            //         return;
            //     }

            //     data.Contents.forEach((photo) => {
            //         allKeys.push(photo.Key);
            //     });

            //     isTruncated = data.IsTruncated;
            //     continuationToken = data.NextContinuationToken;

            //     if (isTruncated) {
            //         listObjects();
            //     } else {
            //         resolve(allKeys);
            //     }
            // });

            s3.listObjectsV2(params, (err, data) => {
              if (err) {
                resolve([]);
              } else {
                const images = data.Contents.map(item => {
                  return item.Key;
                });
                resolve(images);
              }
            });

        };

        listObjects();
    });
}

 

 
 
 

    
      getFileFromCanvasBlob(dataUrl:any,fileName:string){
        let bytes = ""
        if(dataUrl.split(',')[0].indexOf('base64') >= 0){
            bytes = atob(dataUrl.split(',')[1])
        }else{
            bytes = window.unescape(dataUrl.split(',')[1])
        }
        var mime = dataUrl.split(',')[0].split(':')[1].split(';')[0];
        var max = bytes.length;
        var ia = new Uint8Array(max);
        for (var i = 0; i < max; i++) {
          ia[i] = bytes.charCodeAt(i);
        }
        return new File([ia], fileName, { type: mime });
      }
      

    getBlobObjectUrl(arrayBufferView:any){
        let blob = new Blob( [ arrayBufferView ], { type: "image/jpeg" } );
        let urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL( blob );
    }

    blobToFile(theBlob:any,fileName:string){
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    removeMessage(message:any,url:string){
      sqs.deleteMessage({
        QueueUrl : url,
        ReceiptHandle : message?.ReceiptHandle
      }, function(err, data) {
          err && console.log(err);
      });
    }
  

    deleteObjectFromS3 = async (objectKey) => {
      const params = {
        Bucket: BUCKET_NAME,
        Key: objectKey,
      };
      try {
        return await s3.deleteObject(params).promise();
      } catch (error) {
        console.error('Error deleting object from S3:', error);
        throw error;
      }
    };

    async pollForMessages(requestId:string,noOfRenders:number,pollingLimit:number = 100){
      const removeMessage = this.removeMessage
      const addToSqs = this.addToSqs

      return new Promise((resolve,reject)=>{

        let countSet = new Set()

        let getMessages = setInterval(async () => {
          sqs.receiveMessage(params, async function(err, data) {
            if (err) {
              console.log("Receive Error", err);
            } else if (data.Messages) {

              if(sqsMessages.length <= pollingLimit){
                data.Messages.forEach((message:any)=>{
                  addToSqs(message)
                })
              }

              sqsMessages.forEach((message:any)=>{
                let info = JSON.parse(message?.Body)
                // let info = message
                if(info?.requestPayload?.configuration?.renderConfig.requestId === requestId){
                  countSet.add(info?.requestPayload?.configuration?.renderConfig.rendersCount)
                  removeMessage(message,queueURL)
                  if(countSet.size === noOfRenders){
                    clearInterval(getMessages)
                    resolve("Completed")
                  }
                }
              })
            }
          });
        }, 200);
      })
    }

    addToSqs(message:any){
      distinctSqsMessages.add(message.MessageId)
      sqsMessages.push(message)
      if(!Array.from(distinctSqsMessages).includes(message.MessageId)){
        sqsMessages.push(message)
      }
    }


    async removeAllMessages(){
      const removeMessage = this.removeMessage
      sqsMessages = []
      distinctSqsMessages = new Set()

      return new Promise((resolve,reject)=>{
        let getMessages = setInterval(async () => {
          sqs.receiveMessage(params, async function(err, data) {
            if (err) {
              console.log("Receive Error", err);
            } else if (data.Messages) {
              if(!data.Messages.length){
                clearInterval(getMessages)
                resolve("Deleted all")
              }
              data.Messages.forEach((message:any)=>{
                  removeMessage(message,queueURL)
              })
            }
          });
        }, 200);
      })

     
      
     
    }


    async pollForGenerateModelMessages(requestId:string){
      const removeMessage = this.removeMessage
      params = {
        AttributeNames: [
           "SentTimestamp"
        ],
        MaxNumberOfMessages: 10,
        MessageAttributeNames: [
           "All"
        ],
        QueueUrl: queueURLCreateModel,
        VisibilityTimeout: 20,
        WaitTimeSeconds: 0
       };
      return new Promise((resolve,reject)=>{
        let getMessages = setInterval(async () => {
          sqs.receiveMessage(params, async function(err, data) {
            if (err) {
              console.log("Receive Error", err);
            } else if (data.Messages) {
              data.Messages.forEach((message:any)=>{
                let info = JSON.parse(message?.Body)
                if(info?.requestPayload?.configuration?.requestId === requestId){
                  removeMessage(message,queueURLCreateModel)
                  clearInterval(getMessages)
                  resolve("Completed")
                }
              })
            }
          });
        }, 200);
      })
    }

    async pollForRembgMessages(requestId:string){
    params = {
        AttributeNames: [
           "SentTimestamp"
        ],
        MaxNumberOfMessages: 10,
        MessageAttributeNames: [
           "All"
        ],
        QueueUrl: queueURLRembg,
        VisibilityTimeout: 20,
        WaitTimeSeconds: 0
       };
      const removeMessage = this.removeMessage
      return new Promise((resolve,reject)=>{
        let getMessages = setInterval(async () => {
          sqs.receiveMessage(params, async function(err, data) {
            if (err) {
              console.log("Receive Error", err);
            } else if (data.Messages) {
              data.Messages.forEach((message:any)=>{
                let info = JSON.parse(message?.Body)
                if(info?.requestPayload?.configuration?.requestId === requestId){
                  removeMessage(message,queueURLRembg)
                  clearInterval(getMessages)
                  resolve("Completed")
                }
              })
            }
          });
        }, 200);
      })
    }


    async pollForCreateTarMessages(requestId:string){
      params = {
          AttributeNames: [
             "SentTimestamp"
          ],
          MaxNumberOfMessages: 10,
          MessageAttributeNames: [
             "All"
          ],
          QueueUrl: queueURLCreateTar,
          VisibilityTimeout: 20,
          WaitTimeSeconds: 0
         };
        const removeMessage = this.removeMessage
        return new Promise((resolve,reject)=>{
          let getMessages = setInterval(async () => {
            sqs.receiveMessage(params, async function(err, data) {
              if (err) {
                console.log("Receive Error", err);
              } else if (data.Messages) {
                data.Messages.forEach((message:any)=>{
                  let info = JSON.parse(message?.Body)
                  if(info?.requestPayload?.configuration?.requestId === requestId){
                    removeMessage(message,queueURLCreateTar)
                    clearInterval(getMessages)
                    resolve("Completed")
                  }
                })
              }
            });
          }, 200);
        })
      }


      async copyObject(sourceKey:string,destinationKey:string){
        var copySource = encodeURIComponent(`${BUCKET_NAME}/${sourceKey}`);
        var copyParams = {
            Bucket: BUCKET_NAME,
            ACL: 'public-read',
            CopySource: copySource,
            Key: `${destinationKey}`
        };
        var s3 = new AWS.S3();
        return s3.copyObject(copyParams, function (copyErr, copyData) {
            if (copyErr) {
                console.log(copyErr);
                // reject(copyErr);
            } else {
                console.log("Done")
                
                // resolve(baseUrl + photoKey);
            }
        });
      }
}


 