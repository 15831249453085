import React from "react";
import { resizeObject } from "../../../../../utils/customizein3d/modules/customizein3d";
import Button2 from "../../../../common/ui-components/buttons/Button2";
 

const ResizeModuleOptions: React.FC<{
}> = (props) => {


  return (
    <div className="">
        <div className="size-options-wrapper marginTop5">
          <div className="center-aligned-row">
            <div className="wrapper size-options-container">

              <div className="size-change-form">

                <div className="display-flex-row">

                  <Button2
                    configObj={{}}
                    action={() => resizeObject("breadth", "minus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-minus color-black"}
                    iconPos="left"
                    classNames={"shadow-light button-ex-small"}
                  />
                  
                  <div className="center-aligned-row space-around" style={{minWidth:"8rem"}}>
                    <div className="label">Breadth:</div>
                    <div id="breadthValue" className="color-danger center-aligned-column marginleft5">
                      20'
                    </div>
                  </div>
                 
                  <Button2
                    configObj={{}}
                    action={() => resizeObject("breadth", "plus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-plus color-black"}
                    iconPos="left"
                    classNames={"shadow-light button-ex-small"}
                  />

                </div>

                <div className="display-flex-row ion-margin-top">

                  <Button2
                    configObj={{}}
                    action={() => resizeObject("depth", "minus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-minus color-black"}
                    iconPos="left"
                    classNames={"shadow-light button-ex-small"}
                  />

                  <div className="center-aligned-row space-around" style={{minWidth:"8rem"}}>
                    <div className="label">Depth:</div>
                    <div id="depthValue" className="color-danger center-aligned-column marginleft5">
                      20'
                    </div>
                  </div>

                  <Button2
                    configObj={{}}
                    action={() => resizeObject("depth", "plus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-plus color-black"}
                    iconPos="left"
                    classNames={"shadow-light button-ex-small"}
                  />

                  </div>

                <div className="display-flex-row ion-margin-top">

                  <Button2
                    configObj={{}}
                    action={() => resizeObject("height", "minus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-minus color-black"}
                    iconPos="left"
                    classNames={"shadow-light button-ex-small"}
                  />
                  
                  <div className="center-aligned-row space-around" style={{minWidth:"8rem"}}>
                    <div className="label">Height:</div>
                    <div id="heightValue" className="color-danger center-aligned-column marginleft5">
                      20'
                    </div>
                  </div>
                 
                  <Button2
                    configObj={{}}
                    action={() => resizeObject("height", "plus")}
                    label={""}
                    fontAwesomeIcon={"fas fa-plus color-black"}
                    iconPos="left"
                    classNames={"shadow-light button-ex-small"}
                  />

                </div>
               

              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ResizeModuleOptions;
