import React, { useEffect, useState } from "react";

  import { closeSummary, hideComponentLoader, showComponentLoader } from "../../../../utils/UI_methods/global";
import {
  displayImageFromIndexDb, getFileKey,
} from "../../../../utils/cache/indexdb";
import { printSummary } from "../../../../utils/customizein3d/area3dmodel/helper";
import { updateRoomLayoutValues } from "../../../../utils/customizein3d/modules/UI_methods";
import { confirmConfigurationFromCustomer } from "../../../../utils/customizein3d/modules/helper";
import { convertHtmlToPdf } from "../../../../utils/html2canvas/html2pdf";
import { applicationConfig,  getModulePrice, getUserType, logger, shareProject, waitFor } from "../../../../utils/methods";
import Button2 from "../../ui-components/buttons/Button2";
import ShareLink from "../../ui-components/links/sharelink/ShareLink";
import Loader from "../main-ui/Loader";
import { getFormattedPrice } from "../../../../utils/products/productviewer_shopify";
 
  const ConfigCustomizein3D: React.FC<{
    configObj: any;
    configuration:any
  }> = (props) => {

    const[loaderId] = useState("productImageLoader")

    const[totalPrice,setTotalPrice] = useState(0)


    useEffect(() => {

      setTimeout(() => {
          let fileKey = getFileKey("customizein3d",{productName:props.configObj.productName,mode:"normalMode"})
          displayImageFromIndexDb(`shareProductImageNormalMode`,"customizein3d",fileKey)
          updateRoomLayoutValues()
          setPrice()
        // }
    }, 200);
    }, [props.configObj]);


    async function DownloadAsPdf() {
      showComponentLoader(loaderId)
      await waitFor(300)
      await convertHtmlToPdf("customizein3d",props.configObj,props.configuration,props.configObj.productName || "Summary")
      hideComponentLoader(loaderId)
      logger?.info("customizein3d","D-sum")
    }

    
    async function shareAPI() {
      showComponentLoader(loaderId)
      await shareProject(props.configObj,"customizein3d")
      hideComponentLoader(loaderId)
      logger?.info("customizein3d","share-sum")
    }


    function setPrice() {
      props?.configuration?.forEach((config:any)=>{
        let finish = ''
        let collectionName = ''
        for (const key in config.configuration) {
            finish = `${config.configuration[key].companyName} / ${config.configuration[key].collectionName} / ${config.configuration[key].materialCode}`
            collectionName = config.configuration[key].collectionName
        }
        let price = getModulePrice(props.configObj.data?.productModulesPrice,collectionName,config.moduleId) || 0
        setTotalPrice(oldValues=>{
          return oldValues+price
        })
      }
    )}

    function CustomerWarning() {
      return(
        <div className="white-card shadow-light border ion-padding display-none ion-margin-bottom" id="confirmationWarningWrapper">
              <div className="heading2 color-black text-center">After confirmation configuration will be shared with the vendor</div>
              <div className="heading2 color-danger marginTop10 text-center font-small">Note: Link no longer will be active after confirm</div>
              <div className="display-flex-row space-around marginTop10 no-wrap">
                <Button2
                  configObj={applicationConfig}
                  action={closeSummary}
                  label={"Not now"}
                  fontAwesomeIcon={"fas fa-times color-danger"}
                  iconPos="left"
                  classNames={"shadow-light button-large"}
                />

                <Button2
                  configObj={applicationConfig}
                  action={confirmConfigurationFromCustomer}
                  label={"Confirm & send"}
                  fontAwesomeIcon={"fas fa-check color-success"}
                  iconPos="left"
                  classNames={"shadow-light bg-color-primary button-large"}
                />
              </div>
            </div>
      )
    }


    return (
      <React.Fragment>
         
        <div className="summary-wrapper overflow-y-scroll">

          {props.configuration?
          <div className="summary-details-wrapper" id="productSummary">
          <Loader
              className="fade-background-loader"
              id={loaderId}
              data={{}}
              loaderType="dots"
            />
            
            <div className="screenshots-wrapper display-flex-row no-wrap full-width">

              <div className="product-image  padding10 border">
                <img src="" className="full-width" id="shareProductImageNormalMode" alt="" />
              </div>
            </div>

              <div className="display-flex-row color-black padding10">
                <img src="https://opusassets.s3.ap-south-1.amazonaws.com/public/images/icons/SofaDesign.png" alt="" className="tab-icon-image" width="30" height="30"/>
                <span className="heading1 letter-spacing marginleft5 font-medium center-aligned-column">Modules List</span>
              </div>

            {props?.configuration?.map((config:any,index:number)=>{
              let finish = ''
              let collectionName = ''
              for (const key in config.configuration) {
                  finish = `${config.configuration[key].companyName} / ${config.configuration[key].collectionName} / ${config.configuration[key].materialCode}`
                  collectionName = config.configuration[key].collectionName
              }
              let price = 0
              return(
                <div className="display-flex-row no-wrap space-between padding10 border-bottom" key={index}>
                  <div className="product-details display-flex-row no-wrap">
                    <div className="other-details">
                      <div className="product-name letter-spacing heading1">{config.displayName}</div>
                      <div className="config color-dark">
                        <span>Width: 952 Cm, </span>
                        <span className="marginleft5">Height: 952 Cm, </span>
                        <span className="marginleft5">Depth: 952 Cm</span>
                        <div><span className="heading3">Configuration :</span> {finish}</div>
                      </div>
                      <div className="font-small category-name ion-margin-top ion-margin-bottom letter-spacing color-white bg-color-black rupee-sign" style={{padding:"0.2rem 1rem",width:"max-content"}}>₹ {price}</div>
                    </div>
                  </div>
                  <div className="price hide-in-mobile rupee-sign heading1"> {getFormattedPrice(price)}</div>
                </div>
              )
            })}
          </div>
        
        :null}
        

        <div className="summary-actions-wrapper">

        {getUserType().toLowerCase() === "customer"?
            <CustomerWarning/>
          :null}


          <div className="ion-margin-bottom">
            <div className="font-large heading1 letter-spacing color-black marginBottom5">
              Total Price
              </div>
            <div><span className="heading2 font-large marginRight5 rupee-sign">₹</span> <span className="heading1 letter-spacing rupee-sign" style={{fontSize:"3rem"}} id="summaryTotalPrice">{getFormattedPrice(totalPrice)}</span>  </div>
          </div>
         
          {getUserType().toLowerCase() !== "customer"?
          <>
          <div className={`display-flex-row`}>
            <Button2
              configObj={applicationConfig}
              action={shareAPI}
              label={"Share"}
              fontAwesomeIcon={"fas fa-share-alt color-primary"}
              iconPos="left"
              classNames={"shadow-medium no-border button-large marginTop10 bg-color-primary"}
            />

            <Button2
              configObj={applicationConfig}
              action={printSummary}
              label={"Print"}
              fontAwesomeIcon={"fas fa-print color-primary"}
              iconPos="left"
              classNames={"shadow-medium no-border button-large marginTop10 bg-color-primary-inverse"}
            />
          </div>

          <div className="display-flex-row">
            <div className="marginTop10">
              <Button2
                configObj={applicationConfig}
                action={DownloadAsPdf}
                label={"Download Pdf"}
                fontAwesomeIcon={"fas fa-download color-primary"}
                iconPos="left"
                classNames={"shadow-medium no-border button-large bg-color-primary-inverse"}
              />
            </div>

            <div className="position-relative marginTop10">
              <ShareLink
                shareItemId={props.configObj?.product?.productId}
                loaderId = {loaderId}
                moduleName="products"
                textClass="color-black"
                isShareButton={true}
              />
            </div>
          </div>

          
          </>
          :

          <div className={`ion-margin-top display-flex-row`}>
            <button className="op-btn op-btn-primary op-btn-lg" onClick={shareAPI}>
              <i className="fas fa-share-alt font-large color-white" aria-hidden="true"></i> 
              <span className="letter-spacing text color-white marginleft5" >Share</span>
            </button>
            <button className="op-btn op-btn-primary-inverse marginleft10 op-btn-lg" onClick={DownloadAsPdf}>
              <i className="fas fa-download font-large color-dark" aria-hidden="true"></i> 
              <span className="letter-spacing text marginleft5" >Download Pdf</span>
            </button>
          </div>
          }

        </div>

      </div>
     
    </React.Fragment>
    );
  };

  export default ConfigCustomizein3D;