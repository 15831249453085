import { IonAlert } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { deleteProduct } from "../../../../services/api";
import { productViewerInit } from "../../../../utils/customizein3d/productViewer/productViewer";
import { applicationConfig, getObjectByParameter, waitFor } from "../../../../utils/methods";
import Modal from "../../../common/shared-components/Modals/Modal";
import Button2 from "../../../common/ui-components/buttons/Button2";
import ProductImage from "../../../common/ui-components/elements/ProductImage";
import ShareLink from "../../../common/ui-components/links/sharelink/ShareLink";
import Toast from "../../../common/ui-components/notification/Toast";
import ProductViewer from "../productviewer/ProductViewer";
import EditLink from "../../../common/ui-components/links/EditLink";

const Home: React.FC<{
  configObj: any;
  currProductId: number;
  currProduct: any;
  setOpenProjectAction: (value: boolean) => void;
  viewIn360Action: (product: any) => void;
  quickCustomizeAction: () => void;
  gotoCustomizeIn3D: (product: any) => void;
}> = (props) => {
  const [currProduct, setcurrProduct] = useState<any>({});
  const [loaderId] = useState<any>("sharelinkLoader");
  const [isOpenViewProduct, setIsOpenViewProduct] = useState(false);
  
  const[showDeleteAlert,setShowDeleteAlert] = useState(false)

  const [actions] = useState([
    // {
    //   name: "customizeFinish",
    //   label: "Quick Customize",
    //   icon: "fas fa-eye",
    //   svg:"https://opusassets.s3.ap-south-1.amazonaws.com/public/estre/assets/icons/common/customize_sofa.svg",
    //   action:props.quickCustomizeAction,
    // },
    {
      name: "viewin3d",
      label: "Quick 3D",
      icon: "fas fa-eye",
      svg:applicationConfig.aws_public_url+"assets/icon/svg/viewin3d.svg",
      action:viewProductin3DAction,
    },
    {
      name: "customizeFinish",
      label: "Customize in 3D",
      iconClassNames: ["color-danger"],
      svg:applicationConfig.aws_public_url+"assets/icon/svg/customize_in_3d.svg",
      icon: "fas fa-cogs",
      action:props.gotoCustomizeIn3D,
    },
  ]);

  useEffect(() => {
    if (props.currProductId) {
      let obj = getObjectByParameter(props.configObj?.data.productsList,"product_id",props.currProductId);
      setcurrProduct(obj);
    }

  }, [props.currProductId,props.configObj]);

  
 

  function deleteProductAction() {
    deleteProduct(props.currProduct.product_id || props.currProduct.productId).then(data=>{
      console.log(data)
      window.location.href = "/product-categories"
    }).catch(err=>{
      console.log(err)
    })
  }


  function viewIn360Actions() {
    props.viewIn360Action(props.currProduct)
  }



  async function viewProductin3DAction() {
    setIsOpenViewProduct(true)
    await waitFor(500)
    productViewerInit(props.currProduct,"productViewerWrapper")
  }

  
  return (
    <React.Fragment>
        <Toast/>

        <Modal
            isOpen = {isOpenViewProduct}
            Content = {()=>(
              <ProductViewer
                id="productViewerWrapper"
              />
            )}
            onDismiss = {()=>setIsOpenViewProduct(false)}
            heading={currProduct?.product_name}
            classNames="vertical-modal"
            id=""
            FooterContent={()=>(<></>)}
        />

        <IonAlert
          isOpen={showDeleteAlert}
          mode="ios"
          onDidDismiss={() => setShowDeleteAlert(false)}
          cssClass="my-custom-class"
          header={"Confirm!"}
          message={"Delete <strong>Product</strong>!!!"}
          buttons={[{
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: "Confirm",
              handler: () => {
                deleteProductAction();
              },
            },
          ]}
        />

        <div className="project-action-container position-relative  product-action-container">
         
        <div className="background-white ion-padding full-height-width">
          <div className="top-middle zindex30 padding10  display-flex-row" >
          {applicationConfig?.isMasterLogin()?
          <EditLink
            configObj={props.configObj}
            editItemId={currProduct?.product_id}
            editItemName = {currProduct?.product_name}
          />
          :null}
            
          </div>
              {applicationConfig?.userType === "admin"?
                <div className="top-right zindex100 padding10">
                  <ShareLink
                    shareItemId={props.currProductId}
                    loaderId = {"loaderId"}
                    moduleName="products"
                    textClass="color-black"
                    isShareButton={false}
                  />
                </div>
              :null}

              {applicationConfig?.isMasterLogin()?
                <div className="top-left zindex100 padding10">
                  <Button2
                    configObj={{}}
                    action={() => setShowDeleteAlert(true)}
                    label={""}
                    fontAwesomeIcon={"fas fa-trash color-danger"}
                    iconPos="left"
                    classNames={"shadow-none button-md-small bg-color-light"}
                  />
                </div>
              :null}

              <div className="" style={{height:"70%"}}>
                <div className="display-flex-row no-wrap full-height">
                  <div className="full-height center-aligned-column">
                    <i className="fas font-large color-dark fa-chevron-left"></i>
                  </div>
                  <div className="product-image-container center-aligned-row full-width position-relative ion-padding">
                    <ProductImage product={currProduct} />
                  </div>
                  <div className="full-height center-aligned-column">
                    <i className="fas font-large color-dark fa-chevron-right"></i>
                  </div>
                </div>
              </div>

              <div className="" style={{height:"30%"}}>
                <div className="center-aligned-row no-wrap">
                  {actions.map((currAction:any,index:number)=>{
                    return(
                      <div style={{width:"10rem",height:"10rem"}} onClick={()=>{currAction.action()}} key={index} className="white-card pointer ion-margin-end center-aligned-column border shadow-light">
                        <div className="padding5 ">
                          <div className="center-aligned-row"><img src={currAction.svg} alt="" style={{width:"4rem"}} /></div>
                          <div className="heading3 text-center font-md-small store-color-primary">{currAction.label}</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
