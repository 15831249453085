import React, { useEffect, useState } from "react";
import { applicationConfig, getArrayOfDistinctValues, getObjectByParameter } from "../../../../../utils/methods";
import Materials from "../../../shared-components/Materials/Materials";
// import SummaryFooter from "./SummaryFooter";
import { Sprites } from "../../../../../utils/customizein3d/kanvas/sprites";
import { sprites, updateTexture } from "../../../../../utils/customizein3d/modules/customizein3d";
import HorizontalScroll from "../../../ui-components/scrollbar/HorizontalScroll";
import ColorSwatch from "./ColorSwatch";
 

const SpritesMaterials: React.FC<{
  spritesConfig:Sprites
}> = (props) => {

  const[currPart,setCurrPart] = useState("")

  const[products] = useState(props.spritesConfig.productsList)
  const[partsList,setPartsList] = useState([])

  const [allMaterials]: any = useState(applicationConfig?.data?.materials);
  const [allProductMaterials,setAllProductMaterials]: any = useState([]);
  // const[currCategory,setCurrCategory] = useState(props.spritesConfig.productsList[0].categoryName)
  const[currCategory,setCurrCategory] = useState("")
  
  useEffect(()=>{
    props.spritesConfig.setCurrCategory = setCurrCategory
  },[props.spritesConfig])

  useEffect(()=>{
    let collections = getObjectByParameter(props.spritesConfig.productsList,"categoryName",currCategory)?.collections
    if(collections){
      let collectionArray = getArrayOfDistinctValues(collections,"collectionName")
      let materials =  allMaterials.filter(currMaterial => collectionArray.includes(currMaterial.collection_name))
      setAllProductMaterials(materials)
    }else{
      setAllProductMaterials([])
    }
    let parts = []
    let productConfigs = getFilteredConfigs(currCategory)
    for (const currConfig of productConfigs) {
      parts.push(currConfig.partName)
    }
    if(parts.length){
      setCurrPart(parts[0])
      if(parts.length === 1){
        setPartsList(parts)
      }else{
        setPartsList(["All",...parts])
      }
    }else{
      setCurrPart("")
      setPartsList([])
    }
    sprites.setCurrSelectedSprite(currCategory)
  },[currCategory])


 


  function selectProduct(value:string) {
    setCurrCategory(value)
    sprites.setCurrSelectedSprite(value)
  }

  function getFilteredConfigs(category:string) {
    let allConfigs = props.spritesConfig.foregroundProducts
    return allConfigs.filter(currconfig => currconfig.categoryName === category)
  }


  

    
  function AddonsCategories() {
    return(
        <>
          {partsList.map((part:any,index:number)=>{
            return(
              <div className={currPart===part?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
              
                <div className='heading4 font-small text-center' onClick={()=>setCurrPart(part)}>{part}</div>
              </div>
            )
          })}
        </>
    )
  }


  function getFilteredProducts() {
    return products.filter(currProduct => currProduct.categoryName !== "Floor")
  }

  function Categories() {
    return(
        <>
          {getFilteredProducts().map((product:any,index:number)=>{
            let category = product.categoryName
            return(
              <div  onClick={()=>selectProduct(category)} className={category===currCategory?"marginleft5 marginTop5":"marginleft5 marginTop5 border"} key={index} style={{border:`${category===currCategory?"1px solid #000":"none"}`, padding:"0.7rem 1rem",borderRadius:"5px",whiteSpace:"nowrap"}}>
                  <div className="icon center-aligned-column pointer-none">
                    <img src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/images/icons/productIcons/${category}.png`} alt="" style={{width:"3rem",aspectRatio:"1"}}/>
                  </div>
                <div className='heading3 marginTop5 font-ex-small color-black pointer-none text-center' style={{marginTop:"-0.3rem"}}>{category}</div>
              </div>
            )
          })}
        </>
    )
  }
 
  return (
      <div id="spritesMaterialsWindow" className="full-height-width show-on-sprites bg-color-white top-left padding0 visibility-hidden zindex30">

        <div className="display-flex-row full-width position-relative marginTop5 space-between" style={{height:"6rem"}}>
          
          {/* <div className="width50">
            <span className="heading3 color-black">Select product</span>
          </div> */}

          {/* <div className="full-width marginTop5">
            <DropDown
              columns={1}
              classNames="full-width storefront-dropdwon dropdown-lg"
              values={getArrayOfDistinctValues(products,"categoryName")}
              currSelectedValue={currCategory}
              action={selectProduct}
              label="Select Product"
            />
          </div> */}

            <div className="full-width">
              <HorizontalScroll
                itemsClass="sub-categories-container"
                Component={Categories}   
                itemsList={partsList}
                id={`categoryiesSpritesScrollBar`}     
              />
            </div>

          </div>

          <div className="full-width padding5" style={{height:"calc(100% - 6rem)"}}>

            {currPart && partsList.length > 1?
            <div className="full-width marginBottom5" style={{marginTop:"-0.25rem"}}>
              <HorizontalScroll
                itemsClass="sub-categories-container"
                Component={AddonsCategories}   
                itemsList={partsList}
                id={`AddAddonsScrollBar`}     
              />
            </div>
            :null}

            {sprites.colorApplicableCategory.includes(currCategory)?
              <ColorSwatch/>
              // <div className="center-aligned-column ion-padding">
              //   <input type="color" onChange={(event)=>{sprites.updateColor(event)}} id="colorPicker" value="#ff0000"></input>
              // </div>

            :
              <div className="full-width marginTop10 position-relative">
              {allProductMaterials.length?
                <Materials
                  materials={allProductMaterials}
                  action={updateTexture}
                  moduleName="customizein3d"
                />
              :
                <div className="middle heading4">Materials Not Available</div>
              }
            </div>
            }
            
          </div>

          <div className="footer-content">
            
            {/* <SummaryFooter
              fabricateConfig={props.spritesConfig}
            /> */}

          </div>

      
      </div>
  );
};

export default SpritesMaterials;
