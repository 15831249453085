import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import '../../theme/variables.css';
import '../style/admin/product.css';
import '../style/animations.css';
import '../style/controls/controls.css';
import '../style/cssvariables.css';
import '../style/ionic.css';
import '../style/layout.css';
import '../style/measurement-tool/measurement.css';
import '../style/style.css';
import '../style/ui-components/borders.css';
import '../style/ui-components/buttons.css';
import '../style/ui-components/design.css';
import '../style/ui-components/form.css';
import '../style/ui-components/tables.css';

import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import {
  API_INIT,
  apiGatwayAuthentication,
  createSession,
  getIpAddress,
  getMoodBoardSavedConfig,
  logout,
  tokenVerification
} from '../../services/api';
import PrivateRoute from '../../utils/PrivateRoute';
import PublicRoute from '../../utils/PublicRoute';
import { displaySharedItemImage, hideComponentLoader, showComponentLoader } from '../../utils/UI_methods/global';
import Authorization from '../../utils/authorization/authorization';
import { Logging } from '../../utils/logging/logs';
import {
  generateSessionId,
  getCollectionsFilteredTextures,
  getToken,
  getUser,
  getUserType,
  globalMethodsInit,
  removeUserSession,
  showTokenExpiryScreen,
  showWelcomeScreen,
  updateUserSession
} from '../../utils/methods';
import { applicationConfig } from '../../utils/moodboard/applicationConfig';
import { Store } from '../../utils/store/storeConfiguration';
import Dashboard from '../admin/dashboard/Dashboard';
import Designs from '../admin/designs/Designs';
import Finish from '../admin/finish/Finish';
import Logs from '../admin/logs/Logs';
import Moodboards from '../admin/moodboards/Moodboards';
import ProductCategories from '../admin/products/ProductCategories';
import Products from '../admin/products/Products';
import Projects from '../admin/projects/Projects';
import Customizer from '../admin/projects/projectActions/customizer/Customizer';
import MoodBoard from '../admin/projects/projectActions/moodboard/MoodBoard';
import VirtualTour from '../admin/projects/projectActions/virtualtour/VirtualTour';
import Users from '../admin/users/Users';
import CustomizeIn3D from '../common/modules/customizein3d/CustomizeIn3D';
import Error from '../common/modules/error/Error';
import Login from '../login';
import View3DModel from '../modelviewer/View3DModel';
import Share from '../share/Share';

/* Core CSS required for Ionic components to work properly */
/* Basic CSS for apps built with Ionic */
/* Optional CSS utils that can be commented out */

const OpusController: React.FC = () => {
  

  const [clientName,setClientName] = useState<string>("");
  const [CLIENT_ID,setClientId] = useState<number>(0);


  const [BASE_PATH] = useState<string>("http://13.233.90.128/model-viewer/model-viewer/");
  // const [BASE_PATH_API] = useState<string>("http://localhost:3100/");
  const [BASE_PATH_API] = useState<string>("https://ogtv5ck91f.execute-api.ap-south-1.amazonaws.com/");

  const [textures, setTextures]:Array<any> = useState([]);
  const [objectMaterialTypes, setobjectMaterialTypes]:Array<any> = useState([]);
  const [colorOptions, setColorOptions]:Array<any> = useState([]);
  const [projects, setProjects]:Array<any> = useState([]);
  const [customizableObjects, setCustomizableobjects]:Array<any> = useState([]);
  const [allViews, setAllViews]:Array<any> = useState([]);
  const [projectAreasList, setProjectAreasList]:Array<any> = useState([]);
  const [categoryMaterialTypes, setcategoryMaterialTypes]:Array<any> = useState([]);
  const [foregroundSavedConfiguration, setForegroundSavedConfiguration]:Array<any> = useState([]);
  const [areaProducts, setAreaProducts]:Array<any> = useState([]);
  const [productAttributes, setProductAttributes]:Array<any> = useState([]);
  const [projectAreaCategories, setProjectAreaCategories]:Array<any> = useState([]);
  const [allCategories, setAllCategories]:Array<any> = useState([]);
  const [allSubCategories, setAllSubCategories]:Array<any> = useState([]);
  const [productsList, setProductsList]:Array<any> = useState([]);
  const [moodBoardSavedConfigs, setMoodBoardSavedConfigs]:Array<any> = useState([]);
  const [productModules, setProductModules]:Array<any> = useState([]);
  const [productModulesPrice, setProductModulesPrice]:Array<any> = useState([]);
  const [productModuleTypes, setProductModuleTypes]:Array<any> = useState([]);
  const [moodboardList, setMoodboardList]:Array<any> = useState([]);
  const [areasList, setAreasList]:Array<any> = useState([]);
  const [allMaterialTypes, setAllMaterialTypes]:Array<any> = useState([]);
  const [allCollections, setAllCollections]:Array<any> = useState([]);
  const [applicationMaterials, setApplicationMaterials]:Array<any> = useState([]);
  const [projectMaterials, setProjectMaterials]:Array<any> = useState([]);
  const [categoryModules, setCategoryModules]:Array<any> = useState([]);
  const [uploadedFileDetails, setUploadedFileDetails]:Array<any> = useState([]);
  const [moodboardAreaCategories,setMoodboardAreaCategories]:Array<any> = useState([]);
  const [shareItemId,setShareItemId]:Array<any> = useState([]);
  const [shareItemConfig,setShareItemConfig]:Array<any> = useState(null);
  const [usersList,setUsersList]:Array<any> = useState(null);
  const [clientsList,setClientsList]:Array<any> = useState(null);
  const [materialCompaniesList,setMaterialCompaniesList]:Array<any> = useState(null);
  const [modulesList,setModulesList]:Array<any> = useState([]);
  const [savedProductConfiguration,setSavedProductConfiguration]:Array<any> = useState([]);
  const [areaCategoriesRelationship,setAreaCategoriesRelationship]:Array<any> = useState([]);


  const [isLoadedPageConfiguration,setIsLoadedPageConfiguration]:Array<any> = useState(false);


  //Authentication and authorization
  const [isLogin, setIsLogin] = useState<boolean>(false);
  // const[token,setToken]:any = useState(null)
  

  //config objects
  const[applicationConfigObject,setApplicationConfigObject] = useState<applicationConfig>(null)
  const[storeConfig,setStoreConfig] = useState<any>(null)

  const[mainLoaderId] = useState<string>("mainLoader")



  //If the user is admin set sharetoken for one time use// set from the share module 
  const[shareToken,setShareToken] = useState(null)
  const[isShareMode,setIsShareMode] = useState(false)

  const[isLoadedLogs,setIsLoadedLogs] = useState(false)


  var logs = null

  useEffect(()=>{
    let user = getUser()

   
    //Set is share mode false 
    if(user){
      if(user.userType === "admin"){
        updateUserSession(false,null)
      }
    }
    
  },[])




  useEffect(() => {
    if(!applicationConfigObject){
      return
    }
    let isDataLoaded = false

    // createLoader("Authenticating..");
    showComponentLoader(mainLoaderId)
    // updateLoaderProgress(mainLoaderId,1,1,"Fetching data..")
  
    let token:string = shareToken || getToken()!



    if(token && !isDataLoaded){
      tokenVerification(token).then(async (data)=>{
          await apiGatwayAuthentication()
        
        //Check if user is online
        // if(!isError){
          let clientId = data?.clientId
          setClientId(data?.clientId)  
          setClientName(data?.clientName)
          applicationConfigObject.clientId = clientId
          applicationConfigObject.clientName = data?.clientName
          API_INIT({clientId:clientId,shareItemId:data?.shareItemId,moduleName:data?.moduleName})

          let userType = data?.userType
          let moduleName = data?.moduleName || null
          let shareItemId = data?.shareItemId || null
          let shareItemData = data?.configuration || null
          setShareItemId(shareItemId)
          setShareItemConfig(shareItemData)
          let authorization = new Authorization(userType,moduleName,shareItemId)
          let promiseArray = authorization.getData()


          let href = window.location.href
          if(shareItemId && !href.includes("/customize") && !href.includes("/walkthrough")){
            showWelcomeScreen(data?.expiryTime)
          }

          // applicationConfigObject.crm.data.fetch()

          promiseArray = promiseArray.map(promise => promise?.catch(error => error))

          Promise.all(promiseArray)
          .then(function (apidata) {
            if(!isDataLoaded){
              let textures = apidata[0]
              //If shared link collection names 
              if(data?.collectionNames?.length){
                textures = getCollectionsFilteredTextures(textures,data?.collectionNames)
              } 
              setTextures(textures);
              setobjectMaterialTypes(apidata[1]);
              setColorOptions(apidata[2]);
              setProjects(apidata[3]);
              setCustomizableobjects(apidata[4])
              setAllViews(apidata[5])
              setProjectAreasList(apidata[6])
              setcategoryMaterialTypes(apidata[7])
              setForegroundSavedConfiguration(apidata[8])
              setAreaProducts(apidata[9])
              setProductAttributes(apidata[10])
              setProjectAreaCategories(apidata[11])  
              setAllCategories(apidata[12])
              setAllSubCategories  (apidata[13])
              setProductsList(apidata[14])
              setMoodBoardSavedConfigs(apidata[15])
              setProductModules(apidata[16])
              setProductModulesPrice(apidata[17])
              setMoodboardList(apidata[18])
              setAreasList(apidata[19])
              setAllMaterialTypes(apidata[20])
              setProductModuleTypes(apidata[21])
              setAllCollections(apidata[22])
              setApplicationMaterials(apidata[23])
              setProjectMaterials(apidata[24])
              setCategoryModules(apidata[25])
              setUploadedFileDetails(apidata[26])
              setMoodboardAreaCategories(apidata[28])
              setUsersList(apidata[29])
              setClientsList(apidata[30])
              setMaterialCompaniesList(apidata[31])
              setModulesList(apidata[32])
              setSavedProductConfiguration(apidata[33])
              setAreaCategoriesRelationship(apidata[34])
              setIsLogin(true)
              hideComponentLoader(mainLoaderId)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        // }
      
      }).catch(err=>{
        console.log(err)
        hideComponentLoader(mainLoaderId)
          handleLogout()
      })
    }else{
        hideComponentLoader(mainLoaderId)
        handleLogout()
    }
    return () => {
      isDataLoaded = true
    }
  // },[isLogin,shareToken]);
},[applicationConfigObject,shareToken]);




  function handleLogout() {
    if(getUserType() === "customer"){
      showTokenExpiryScreen()
    }else{
      logout()
      .then((response:any) => {
        removeUserSession()
      })
      .catch((error:any) => {
        console.log(error)
      });
    }
  }



  const[refreshMoodBoardConfig,setRefreshMoodBoardConfig] = useState(false)

  useEffect(()=>{
    if(refreshMoodBoardConfig){
      getMoodBoardSavedConfig().then(data=>{
        setMoodBoardSavedConfigs(data)
        setRefreshMoodBoardConfig(false)
      }).catch(error=>{
        console.log(error)
      })
    }
  },[refreshMoodBoardConfig])





  function updateMoodBoardConfig() {
    // setRefreshMoodBoardConfig(true)
  }

  useEffect(()=>{
    let configObj:any = new applicationConfig(CLIENT_ID,"",0,0,'','',[],{})
    let store:any = new Store()
    configObj?.setProjectAreaCategories(projectAreaCategories)
    configObj?.setBasePathAssets(BASE_PATH)
    configObj.setUser(getUser())
    setStoreConfig(store)
    setApplicationConfigObject(configObj)
  },[])

  
  useEffect(()=>{
    // let configObj:any = new applicationConfig(CLIENT_ID,clientName,0,0,'','',[],{})

    //Create config object for the application which will contain the data and other 

    if(applicationConfigObject){
      let data = {
        categories:allCategories,
        subCategories:allSubCategories,
        productsList:productsList,
        allProductsList:productsList,
        modulesList:modulesList,
        materials:textures,
        moodBoardSavedConfigs:moodBoardSavedConfigs,
        customizerSavedConfigs:foregroundSavedConfiguration,
        updateMoodBoardConfig:updateMoodBoardConfig,
        productModules:productModules,
        objectMaterialTypes:objectMaterialTypes,
        categoryMaterialTypes:categoryMaterialTypes,
        productModulesPrice:productModulesPrice,
        moodboardList:moodboardList,
        areasList:areasList,
        projects:projects,
        projectAreasList:projectAreasList,
        projectAreaCategories:projectAreaCategories,
        projectAreaProductsList:areaProducts,
        allMaterialTypes:allMaterialTypes,
        productModuleTypes:productModuleTypes,
        allViews:allViews,
        allCollections:allCollections,
        applicationMaterials:applicationMaterials,
        projectMaterials:projectMaterials,
        productAttributes:productAttributes,
        customizableObjects:customizableObjects,
        colorOptions:colorOptions,
        categoryModules:categoryModules,
        uploadedFileDetails:uploadedFileDetails,
        moodboardAreaCategories:moodboardAreaCategories,
        usersList:usersList,
        clientsList:clientsList,
        materialCompaniesList:materialCompaniesList,
        savedProductConfiguration:savedProductConfiguration,
        areaCategoriesRelationship:areaCategoriesRelationship
      }

      let functions = {
        setCurrMoodboardId:setCurrMoodboardId,
        setCurrProjectDetails:setCurrProjectDetails,
      }
      applicationConfigObject?.setIsShareMode(isShareMode)
      // applicationConfigObject?.setFunctions(functions)
      applicationConfigObject?.setData(data)
      applicationConfigObject?.setShareItemId(shareItemId)
      applicationConfigObject?.setShareItemConfiguration(shareItemConfig)
      setLogs()
      
      globalMethodsInit(applicationConfigObject,logs)

      if(shareItemId && productsList?.length){
        displaySharedItemImage(shareItemId)
      }
    }
  },[CLIENT_ID,clientName,allMaterialTypes,projectAreaCategories,projectAreasList,
    allCategories,allSubCategories,productsList,textures,productModules,productModulesPrice,
    moodboardList,areasList,productModuleTypes,allViews,allCollections,applicationMaterials,projectMaterials,
    categoryMaterialTypes,productAttributes,customizableObjects,colorOptions,categoryModules,foregroundSavedConfiguration,
    uploadedFileDetails,isShareMode,isLoadedLogs,moodboardAreaCategories,usersList,clientsList,
    savedProductConfiguration,materialCompaniesList,modulesList,isLogin,applicationConfigObject,shareItemId,
    shareItemConfig,areaCategoriesRelationship])
 


    function setLogs() {
      if(!isLoadedLogs){
        let sessionId = generateSessionId(32)
        logs = new Logging("testuser",sessionId)
        getIpAddress().then(ipInfo=>{
          saveSessionToDatabse(sessionId,ipInfo.ipAddress)
        }).catch(err=>{
          console.log(err)
          saveSessionToDatabse(sessionId,"")
        })
        globalMethodsInit(applicationConfigObject,logs)
      }
      setIsLoadedLogs(true)
    }

    function saveSessionToDatabse(sessionId:string,ipAddress:string) {
        createSession(sessionId,ipAddress).then(data=>{
          const insertId = data.data.data.id
          applicationConfigObject.setSessionId(insertId)
          logs.setSessionDatabaseId(insertId)
          logs.info("login","session",`START SessionId: ${sessionId}`)
        }).catch(err=>{
          console.log(err)
          logs.error("login","session",`START unable to create session:`)
        })
    }


  function setCurrProjectDetails(projectId:number,projectName:string){
    // Here moodboard config object is globel object
    //Update projectid, name and project areas in configuration
    let projectAreas = projectAreasList.filter((area:any)=>area.project_id===projectId)
    applicationConfigObject?.setProjectId(projectId)
    applicationConfigObject?.setProjectName(projectName)
    applicationConfigObject?.setAreas(projectAreas)
    applicationConfigObject?.setAllProducts(areaProducts)
  }

  function setCurrMoodboardId(moodboardId:any) {
    applicationConfigObject?.setMoodboardId(moodboardId)
  }

  function setCurrProductDetails(productId:number){
    //Update projectid, name and project areas in configuration
    applicationConfigObject.setProductId(productId)
  }


  function setLoginTrue(){
    // setIsLogin(true)
  }

  return (

    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>

          <PrivateRoute
            path="/customize"
            component={() => (
              <React.Fragment>
                <Customizer
                  configObj = {applicationConfigObject}
                  setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                  isLoadedPageConfiguration={isLoadedPageConfiguration}
                  isLogin={isLogin}
                />
              </React.Fragment>
            )}
            exact={true}
          />

          <PublicRoute path="/login" component={()=>(<Login
            setLoginTrue = {setLoginTrue}
            // setToken={setToken}
          />)} exact={true} />
{/* 
          <PrivateRoute 
              path="/"
              component={()=>(
                <Dashboard
                  configObj={applicationConfigObject}
                  projects={projects}
                  allAreas={projectAreasList}
                  allViews={allViews}
                  allProducts={areaProducts}
                  setCurrProductDetails={setCurrProductDetails}
                  applicationConfigObject={applicationConfigObject}
                />
              )}
              exact={true}
          /> */}

          <PrivateRoute 
              path="/"
              component={()=>(
                <Projects
                  projects={projects}
                  allAreas={projectAreasList}
                  allViews={allViews}
                  BASE_PATH_API={BASE_PATH_API}
                  CLIENT_ID={CLIENT_ID}
                  setCurrProjectDetails={setCurrProjectDetails}
                  allCategories={allCategories}
                  moodBoardSavedconfigs = {moodBoardSavedConfigs}
                  configObj={applicationConfigObject}
                  setCurrMoodboardId={setCurrMoodboardId}
                />
              )}
              exact={true}
          />


          <PrivateRoute 
              path="/projects"
              component={()=>(
                <Projects
                  projects={projects}
                  allAreas={projectAreasList}
                  allViews={allViews}
                  BASE_PATH_API={BASE_PATH_API}
                  CLIENT_ID={CLIENT_ID}
                  setCurrProjectDetails={setCurrProjectDetails}
                  allCategories={allCategories}
                  moodBoardSavedconfigs = {moodBoardSavedConfigs}
                  configObj={applicationConfigObject}
                  setCurrMoodboardId={setCurrMoodboardId}
                />
              )}
              exact={true}
          />

          <PrivateRoute 
              path="/designs"
              component={()=>(
                <Designs
                  projects={projects}
                  BASE_PATH_API={BASE_PATH_API}
                />
              )}
              exact={true}
          />

          <PrivateRoute 
              path="/products/:category"
              component={()=>(
                <Products
                  setCurrProductDetails={setCurrProductDetails}
                  applicationConfig={applicationConfigObject}
                />
              )}
              exact={true}
          />
 

            <PrivateRoute 
              path="/product-categories"
              component={()=>(
                <ProductCategories
                  applicationConfig={applicationConfigObject}
                />
              )}
              exact={true}
            />

          <PrivateRoute 
              path="/collections"
              component={()=>(
                <Moodboards
                  configObj = {applicationConfigObject}
                  setCurrProjectDetails={setCurrProjectDetails}
                  setCurrMoodboardId={setCurrMoodboardId}
                />
              )}
              exact={true}
          />

          <PrivateRoute
            path='/edit-moodboard'
            component={()=>(
              <MoodBoard
                configObj={applicationConfigObject}
                isLogin={isLogin}
              />
            )}
            exact={true}
          />

          <PrivateRoute
            path="/3dwalkthrough"
            component={()=>(
              <VirtualTour
                allAreas={projectAreasList}
                configObj={applicationConfigObject}
              />
            )}
            exact={true}
          />

          {/* <PrivateRoute
            path="/3dcustomization"
            component={()=>(
              <CustomizeIn3D
                controlCurrState="Products"
              />
            )}
            exact={true}
          /> */}

          <PrivateRoute
            path="/product-3dcustomization"
            component={()=>(
              <CustomizeIn3D
                configObj={applicationConfigObject}
                setIsLoadedPageConfiguration={setIsLoadedPageConfiguration}
                isLoadedPageConfiguration={isLoadedPageConfiguration}
                isLogin={isLogin}
              />
            )}
            exact={true}
          />

          {/* <PrivateRoute
            path="/product-viewer"
            component={()=>(
              <ProductViewer
                configObj={applicationConfigObject}
              />
            )}
            exact={true}
          /> */}

          
          <PrivateRoute
            path="/finish"
            component={()=>(
              <Finish
                configObj={applicationConfigObject}
              />
            )}
            exact={true}
          />

          {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}
          <Redirect to="/login" />
 
           <Route
              path="/share/:token"
              component={()=>(
                <Share
                  configObj = {applicationConfigObject}
                  setLoginTrue={setLoginTrue}
                  setShareToken={setShareToken}
                  setIsShareMode={setIsShareMode}
                />
              )} 
              exact={false}
            />

            <Route
              path="/product-viewer"
              component={()=>(
                <View3DModel
                  ref={applicationConfigObject}
                />
              )} 
            />

          <PrivateRoute
            path="/error"
            component={()=>(
              <Error
                configObj={applicationConfigObject}
              />
            )}
            exact={true}
          />

          <PrivateRoute
            path="/logs"
            component={()=>(
              <Logs
                configObj={applicationConfigObject}
                isLogin={isLogin}
              />
            )}
            exact={true}
          />

          <PrivateRoute
            path="/users"
            component={()=>(
              <Users
                configObj={applicationConfigObject}
              />
            )}
            exact={true}
          />

        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default OpusController;
