// import * as THREE from "three"
import $ from "jquery"
import { AmbientLight, Box3, Clock, Color, DirectionalLight, Group, LoadingManager, MathUtils, Object3D, PerspectiveCamera, Plane, PlaneGeometry, PMREMGenerator, PointLight, Raycaster, Scene, TextureLoader, Vector3 } from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { checkFromCache, getAndSaveImageToCache, untarAndSaveToCache } from "../../cache/cache"
import { getUrlPrefix, resetAreaRenderFlag } from "../../customizer/customizer"
import { applicationConfig, categoryFilteredArray, getMaterialTypes, hideWelcomeScreen, logger, stringWithoutWhiteSpace, waitFor } from "../../methods"
import { hideCanvasControls, showCanvasControls } from "../../UI_methods/canvas"
import { clearProgressBarInterval, getCanvasContainerWidthheight, hideComponentLoader, setCanvasContainerAspectRatio, showAndHideLoader, showComponentLoader, showToast, updateLoaderProgress, updateLoaderProgressWithInterval } from "../../UI_methods/global"
import { detachModule, draggableGroup, fitRendererIntoElement, renderMultipleViews, setModelBoundingBox, setRendererDimensions } from "../common"
import { addCamera, addCameraControls, addDracoLoader, createLabelRenderer, createWebGlRenderer, set3dControls, setSpacePlannerControls, updateSceneCameraAspectRatio, updateSceneCameraFov } from "../controls"
import { addGridHelper, addGround, addPointLightsToScene, generatedEnvironmentMap, GRID, loadGeneratedEnvironmentMap, showGrid } from "../enviornment"
import { getMaterialUpdated, getObjectByName, getObjectDimensionPosition } from "../modules/helper"
import { Composer } from "../postProcessing"
import { CURR_SELECTED_PRODUCT, hoverInteration, resetCurrSelectedProduct } from "../raycasting"
import { tweenCameraPosition } from "../tween"
import { resetRotationSlider, resetTruckValue } from "./actions"
import { cancelControlsCheckAnimation, createProductAnnotations, hideDistanceAnnotations, showDistanceAnnotations, updateAnnotationsAnimation, updateDistanceCheckPointsPosition, updateFloorDimensionsPosition, updateSelectedProductAnnotation } from "./annotations"
import { addBoxHelper, dropProductCustomizer, removeBoxHelper } from "./dragging"
import { addActiveClassToTexture, addAreaProducts, addProjectProducts, createEventListeners, getAreaModelOrigin, getOriginalPartName, getOriginalProductName, getProductsGroup, hideElements, loadModelToTheScene, prepareModel, setInitialWallsHiding, setMaskForCeiling, setWallProductBoundaries, updateMaterialForSelectedObjects } from "./helper"
import { addActiveClassOnAnnotation, changeViewMode, closeControlsPanelAndHideProducts, disableRotationMode, displayAnnotations, hideAnnotations, hideFloorplanModeElements, hideFloorplanOptionWindow, hideLoading, hideProductActions, hideProductsAnnotationsButton, hideRotationSlider, removeActiveClassFromAnnotations, resetUIMethods, showFloorplanModeElements, showFloorplanOptionWindow, showProductActions, showRotationSlider, updateUndoVisibility } from "./UI_methods"
import { All_WALLS, areaWallsBoundingBoxMapping, areaWallsGroupsMapping, createAreaWallsBoundingBoxMapping, disableWallsHiding, enableWallsHiding, getAreaFromWallsBoundingBox, hideWallsBoxHelpers, initialWallsSetup, isAutoWallsHiding, makeWallsTransparent, removeWallsElementOfShapeFloorplan, resetWallProductParameters, rotateNorthWallIcon, setInitialWallColor, showAllWalls, showWallsBoxHelpers, updateAreaLabelVisibility, updateWallsHiding, WALLS_HIDING } from "./walls"

import Stats from 'three/examples/jsm/libs/stats.module'
import CustomizerConfig, { Undo } from "../../customizer/CustomizerConfig"
import { ProjectConfiguration } from "../../customizer/ProjectConfiguration"
import { KitchenPlanner } from "../../kitchenplanner/kitchenPlanner"
import { hideKitchen2dModeElements, showKitchen2dModeElements } from "../../kitchenplanner/UI_methods"
import { startPageTour } from "../../siteTour/tour"
import { Dimensions } from "../Dimensions"
import { Floorplan } from "../floorplanner/floorplan"
import { MeasurementTool } from "../measurement-tool/measurementTool"
import { addDragControlsInProduct, createDragControls, disableDragging } from "./dragControls"
import { cameraForSettingPosition, createCameraForRender, updateCameraSettingViewData, VIEWS_FOR_2D, VIEWS_FOR_3D, VIEWS_FOR_WALKTHROUGH } from "./floorplan/createRender"
import { disableSelectWalls, getAnchorElement, hideWallSelectionHint, releseMemoryFloorplan, removeAllAnchors, toggleCeilingVisibility, updateAllAreaAnchorsPosition, updateCameraTransform } from "./floorplan/floorplanUI"
import { rotateCameraIconFromCameraControls, setCameraMinMaxValues, setOrthographicCameraPosition } from "./floorplan/render2d"
import { addMoveCircle, disableFloorplanControls, disableInteriorVirtualization, enableFloorplanControls, enableInteriorControls, enableInteriorVirtualization, isInteriorVisualization, releaseMemoryInteriorVirtualization, setPositionFromControlsForInteriorVirualization, toggleMoveCircleVisibility } from "./interior_visualization"
import { isIsolationView } from "./isolationView"
import { CURR_SELECTED_PRODUCT_GROUP, hideOtherItems, hideProductConfigureButton, isProductConfigureMode, productConfigurationSpacePlannerActions, showProductConfigureButton, updateControlsForModules, updateModulesFinish } from "./productConfigure"
import { postFloorplanEditActions } from "./shell"
import { isTraceFloorplanMode, TraceFloorplan } from "./traceFloorplan"
import { isTakeRenderMode } from "../modules/render2d"
import { setInitialSnappedWall } from "../../kitchenplanner/helper"
import { Dragging } from "./custom-dragging"
import { setWallColorMode } from "./elevationView"
import { WallColorCustomization } from "./wallColor"

// import CameraControls from 'camera-controls';


export var isViewShell = false

//group = group for 3d models
var group:any,PMREMGeneratorObject:any
export var perspectiveCamera:any,
boundingBox:any,width=0,
height=0,dracoLoader:any,target:any

export var composer:Composer
export var groundGroup:any
export var textureLoader=undefined
export var orthoCamera:any

//Drag controls for individual 3d models

export var customizerConfig:CustomizerConfig = undefined
export var renderer:any
export var labelRenderer:any
export var areaModel:any = new Group()

export var area3DSceneWrapper:any

export var mouse = {x: 0, y: 0};

export var isMultipleCanvasSelectionMode:boolean = false
   
export var FLOOR = null
export var AREA_FLOOR = null



var groundGeometry:any = undefined

export var AREA_MODEL_ORIGIN:Vector3


var manager = new LoadingManager()
var fontLoader = new FontLoader();

 
var raycaster = new Raycaster()
var currSelectedMaterialTypes:string
var animationFrameId = undefined

export var IS_SPACE_PLANNER_MODE = false

export var IS_SHELL_MODE = true


// export var configForSaveMaterials = null
export var productLoader = null
export var scene = null
export var camera = new PerspectiveCamera()
export var orbitControls = null
export var controls = null
export var transformObject3d = null
export var dragControlsImages = null
export var IS_SHOW_CLICK_HINT = true
// export var configForSaveMaterials = null
export var IS_CUSTOMIZABLE_MODE = false
export var IS_PRODUCT_SELECTED = false
export var IS_PRODUCT_LOCKED = false
export var IS_SHOW_DISTANCE = false
export var isControlsDragEnded = true
export var CURR_AREA_GROUP = new Group()
export var scale = 1
export var CURR_SELECTED_AREA = "all"
export var shellForSettingCameraPosition = null

const clock = new Clock()

export var MIN_DISTANCE = 1 * scale
export var MAX_DISTANCE = 30 * scale


export var PARTS_MAPPING = {}

export var CURR_SELECTED_MESHES = []

export var projectConfiguration:ProjectConfiguration = null

const GROUND_GROUP_NAME = "groundGroup"
const GROUND_OUTLINE_NAME = "groundoutline"
export const GROUND_NAME = "Floor"
var GROUND_WIDTH = 5 * scale, GROUND_HEIGHT = 5 * scale
const GROUND_DIMENSIONS_GROUP_NAME = "groundDimensions" 
export const DIMENSIONS_NAME = "dimensions"

export var canvas = null
export var distanceLinesGroup = null

export var IS_VERTICAL_DRAGGING = false

export var PRODUCTS_LIST = []

export var planeForRaycasting = null
var stats = null

export var isFloorPlanMode = false
export var isKitchenPlannerMode = false

export var CURR_AREA_NAME = "all"

// export var perspectiveCameraForRender = null
export var groupForCameraForRender = new Object3D()

// export var renderSettingConfig = null
export var firstPersonControls = null
export var pointerLockControls = null
export var canvasDimensions = {width:2,height:2}
var isLoaded = false

export var virtaulPlanesGroup = new Object3D()
export var maskForCeiling = 1 
export var maskForHiddenElementsInFloorplan = 2

export var undo = null

export var kitchenPlanner = null
export var measurementTool:MeasurementTool = null

export var clampModulesKitchenPlanner = null

export var groupForRotatingProduct = new Group()


export var dimensions:Dimensions = null
export var floorplanner:Floorplan = null
export var traceFloorplan:TraceFloorplan = null
export var dragging:Dragging = new Dragging()

export var product3DModelsList = [] 


export var wallColorCustomization = new WallColorCustomization()

export async function area3dModelInit(moduleConfigObject:any,projectConfig:ProjectConfiguration) {
    // releaseMemoryCustomizer()
    area3DSceneWrapper = document.getElementById("area3DSceneWrapper")
    canvasDimensions = getCanvasContainerWidthheight("customizer")

    if(isLoaded ){
      return
    }

    projectConfiguration = projectConfig
    customizerConfig = moduleConfigObject

    // console.log(applicationConfig,customizerConfig,projectConfiguration)


    boundingBox = new Box3()
    target = new Vector3()
    textureLoader = new TextureLoader()
    fontLoader = new FontLoader()
    // areaModel = new Group()
    raycaster = new Raycaster()
    manager = new LoadingManager()


    productLoader = new GLTFLoader(manager)
    addDracoLoader(productLoader,dracoLoader)
  
  
    width = canvasDimensions.width
    height = canvasDimensions.height
    scene = new Scene()
    scene.background = new Color(0xffffff)

    groundGroup = new Group();
    groundGroup.name = GROUND_GROUP_NAME

    distanceLinesGroup = new Group();
    distanceLinesGroup.visible = false
    scene.add(distanceLinesGroup)

    // addRectLights(scene)

    scene.add(virtaulPlanesGroup)
    
    renderer = createWebGlRenderer(renderer,width,height)
    labelRenderer = createLabelRenderer(width,height)

    
    area3DSceneWrapper.appendChild( labelRenderer.domElement )
    area3DSceneWrapper.appendChild(renderer.domElement)


    perspectiveCamera = addCamera({width:width,height:height,near:1.01},"perspective")
    perspectiveCamera.layers.disable(maskForCeiling)

    createCameraForRender(perspectiveCamera,width,height)
    cameraForSettingPosition.layers.disable(maskForCeiling)


    camera = perspectiveCamera
    orthoCamera = addCamera({width:width,height:height},"orthographic")
    orthoCamera.layers.disable(maskForCeiling)


    stats = Stats();
    area3DSceneWrapper.appendChild( stats.dom );

    addMoveCircle()
  

    PMREMGeneratorObject = new PMREMGenerator(renderer)
    PMREMGeneratorObject.compileCubemapShader()
    loadGeneratedEnvironmentMap(scene,renderer,PMREMGeneratorObject)
    scene.add(group)
    draggableGroup.renderOrder = 6
    scene.add(draggableGroup)

    composer = new Composer(renderer,scene,camera)

    createDragControls()
    
    
    canvas = renderer.domElement

    createControls()

    // applicationConfig.functions.customizein3d.setDimensionsObject(dimensions)
    floorplanner = new Floorplan(scene,areaModel,renderer,orthoCamera,controls,area3DSceneWrapper,raycaster,5,5,"customizer")
    if(projectConfiguration.floorplanConfiguration?.vertices?.length){
      floorplanner.parseConfiguration(projectConfiguration.floorplanConfiguration.vertices)
    }
    projectConfiguration.floorplanConfiguration = floorplanner.configuration

    traceFloorplan = new TraceFloorplan()

    dimensions = new Dimensions(scene,renderer,perspectiveCamera,orthoCamera,CURR_AREA_GROUP,IS_SPACE_PLANNER_MODE,1)

    undo = new Undo("360")
    await addProjectToScene()
    setRendererDimensions(renderer)

    kitchenPlanner = new KitchenPlanner(areaModel,productLoader)
    measurementTool = new MeasurementTool(scene,area3DSceneWrapper,raycaster,renderer,camera,areaModel,planeForRaycasting)

    // transformObject3d = new TransformObject3D(scene,controls,renderer,camera)
    orthoCamera.layers.disable(maskForHiddenElementsInFloorplan)
    perspectiveCamera.layers.enable(maskForHiddenElementsInFloorplan)
    
    scene.name = "area3dmodel"
    scene.add(areaModel)
    CURR_AREA_GROUP = floorplanner.group

    scene.add(groupForRotatingProduct)

    // addSkyBackground()
    hideWelcomeScreen()
    setTimeout(() => {
      setCanvasContainerAspectRatio(16,10,1,0,"customizer")
      fitRendererIntoElement(renderer,camera)
      createEventListeners(area3DSceneWrapper,camera,renderer)
    }, 1500)
    isLoaded = true
  }

  export function addSkyBackground() {
    const skyColor = 0xeeeeee;
    scene.background = new Color(skyColor);
  }

  export function setGroupForRotatingProduct(group:Group) {
    groupForRotatingProduct = group
  }


  export function setProjectConfigObject(projectConfig:ProjectConfiguration) {
    projectConfiguration = projectConfig
   
  }

  export function updateCanvasDimensions() {
    canvasDimensions = getCanvasContainerWidthheight("customizer")
  }

  export function createControls() {
      // let group = getProductsGroup(customizerConfig,areaModel)
      // boundingBox.setFromObject(group)
      // boundingBox.getCenter(target)

      controls = addCameraControls(controls,camera,renderer,{minDistance:MIN_DISTANCE,maxDistance:MAX_DISTANCE})
      orbitControls = controls

      

      animate()
      controls.addEventListener( 'controlstart', function ( event:any ) {
        hideDistanceAnnotations()
        hideProductsAnnotationsButton()
        isControlsDragEnded = false
      })

      controls.addEventListener( 'control', function ( event:any ) {
        rotateNorthWallIcon()
        updateAllAreaAnchorsPosition()
        const anchorElement = isInteriorVisualization?getAnchorElement(projectConfiguration.projectName):null
        if(customizerConfig.isFinalizeMode || isInteriorVisualization){
          rotateCameraIconFromCameraControls(anchorElement,controls.azimuthAngle)
        }
       
        if(!customizerConfig.isFinalizeMode && isAutoWallsHiding){
          updateWallsHiding()
        }
        if(isKitchenPlannerMode){
          kitchenPlanner.updateDragIconPosition()
        }
        measurementTool.hide()
        updateAreaLabelVisibility()
      })
    
      controls.addEventListener( 'controlend', function ( event:any ) {
        isControlsDragEnded = true
 
        updateCameraTransform()
      })
  }



  export function setControlsOrbitPoint() {
    let group = getProductsGroup(customizerConfig)
    boundingBox.setFromObject(group)
    boundingBox.getCenter(target)
    controls.setOrbitPoint(target.x,target.y,target.z)
    updateCameraControls(areaModel,"all")
  }






  function getLocalUrl(projectName:string,areaName:string) {
    return `assets/models/LivingRoom.glb`
  }

  export async function addProjectToScene() {
    return new Promise(async  (resolve,reject)=>{
      let key:string = `models/${applicationConfig?.clientName}/AreaModels/${projectConfiguration.projectName}_${projectConfiguration.projectId}/${projectConfiguration.projectName}_Shell.glb`

      if(projectConfiguration.isFloorplanCreatedFromShape && floorplanner.configuration.vertices?.length){
        floorplanner.createFloorplanFromVertices(floorplanner.configuration.vertices)
        floorplanner.createFloorFromVertices()
        await postShellLoadActions(floorplanner.group,"all")
        resolve("Done")
        return 
      }
      let urlPrefix = getUrlPrefix(key);
      let tarFileKey = `models/${applicationConfig?.clientName}/AreaModels/${projectConfiguration.projectName}_${projectConfiguration.projectId}/${projectConfiguration.projectName}_Shell.tar.gz`
      await untarAndSaveToCache(applicationConfig.awsConfig,urlPrefix,tarFileKey)
      // const isFound = await getAndSaveImageToCache(key)
      // console.log(isFound)
      // if(!isFound){
      //   traceFloorplan.enable()
      //   hideLoading()
      //   resolve("Done")
      //   return 
      // }

      updateLoaderProgress("canvasLoader",1,100,"Please wait...")
      checkFromCache(key)?.then(async (url:any)=>{

        await loadModelToTheScene(productLoader,url).then(async (gltf:any)=>{
          let shell = gltf.scene
          await postShellLoadActions(shell,"all")
          startPageTour()
          resolve("Done")
        }).catch(err=>{
          hideComponentLoader("changeFinishLoader")
          reject(err)
        })
      }).catch(err=>{
        hideComponentLoader("changeFinishLoader")
      console.log("Shell not found")
        reject(err)
      })
    })
  }


  export function updateSettingPositionCameraView() {
    cameraForSettingPosition.position.set(AREA_MODEL_ORIGIN.x,AREA_MODEL_ORIGIN.y+5,AREA_MODEL_ORIGIN.z)
    cameraForSettingPosition.lookAt(AREA_MODEL_ORIGIN.x,AREA_MODEL_ORIGIN.y,AREA_MODEL_ORIGIN.z)
    cameraForSettingPosition.updateProjectionMatrix()
  }

 

export function setDefaultCurrAreaGroup() {
  CURR_AREA_GROUP = floorplanner.group
  dimensions.updateCurrObject(CURR_AREA_GROUP)
}

export function postShellFoundActions() {
  setAreaModelOrigin()
  projectConfiguration.isDefinedFloorplan = true
  floorplanner.removeFloorplanListeners()
  addGridHelper(scene,{size:1000*scale,division:500,scale:scale,position:AREA_MODEL_ORIGIN})
  setCameraMinMaxValues()
  setInitialWallsHiding()
  updateSettingPositionCameraView()
  showGrid()
}


export async function postShellLoadActions(gltf:any,areaName:string) {
    detachModule(floorplanner.group)
    floorplanner.group = gltf
    floorplanner.group.name = "floorplan"
    scene.add(floorplanner.group)
    setDefaultCurrAreaGroup()


    // addPointLightsToScene(scene,floorplanner.group)



    clearProgressBarInterval()
    updateLoaderProgress("canvasLoader",100,100,"Applying finish...")
    

    await waitFor(500)

    updateLoaderProgress("canvasLoader",100,100,"Please wait...")
    hideElements(scene)

    setMaskForCeiling()
 
    await prepareModel(floorplanner.group,areaName)
    createProductAnnotations()
    updateUndoVisibility()

    updateAnnotationsAnimation()
    showComponentLoader("addProductLoader")
    updateLoaderProgressWithInterval("addProductLoader","Loading model..")
    if(areaName === "all"){
        createAreaWallsBoundingBoxMapping()
        initialWallsSetup(floorplanner.group)
        createAreaWallsBoundingBoxMapping()
        

        makeWallsTransparent(All_WALLS,areaWallsBoundingBoxMapping["all"].boundingBox.center)
        addFloor(areaWallsBoundingBoxMapping["all"].boundingBox)
        addProjectProducts()
        if(!projectConfiguration.areAreasDefined && !isTraceFloorplanMode){
          await waitFor(500)
          showFloorplanOptionWindow()
        }
    }else{
      addAreaProducts(areaName)  
    }
    postShellFoundActions()
    setControlsOrbitPoint()
    await waitFor(1000)
    clearProgressBarInterval()
    hideLoading()
}



export function setAreaModelOrigin() {
  AREA_MODEL_ORIGIN = getAreaModelOrigin()
  planeForRaycasting = new Plane(new Vector3(0, 1, 0), - AREA_MODEL_ORIGIN.y)
  GRID?.position.copy(AREA_MODEL_ORIGIN)
}

  
  export function addFloor(data:any) {
    // FLOOR = floorplanner.group.children
    // return
    let deltaForWidthAndHeight = 0
    // let group = getProductsGroup(customizerConfig,areaModel)
    GROUND_WIDTH = data.dimensions.dimX + deltaForWidthAndHeight
    GROUND_HEIGHT = data.dimensions.dimZ + deltaForWidthAndHeight
    groundGeometry = new PlaneGeometry( GROUND_WIDTH, GROUND_HEIGHT )
    let positions = {
      x:data.positions.min.x + GROUND_WIDTH / 2 - deltaForWidthAndHeight / 2,
      y:data.positions.min.y,
      z:data.positions.min.z + GROUND_HEIGHT / 2 - deltaForWidthAndHeight / 2
    }
    FLOOR = addGround(scene,groundGeometry,groundGroup,GROUND_NAME,GROUND_OUTLINE_NAME,boundingBox,fontLoader,GROUND_DIMENSIONS_GROUP_NAME,positions,false)
    setModelBoundingBox(FLOOR)
    FLOOR.visible = false
    FLOOR.name = "hidden_floor"
  }

  export function addAreaFloor(areaName:string) {
    const data = areaWallsBoundingBoxMapping[areaName].boundingBox
    let deltaForWidthAndHeight = 0
    GROUND_WIDTH = data.dimensions.dimX + deltaForWidthAndHeight
    GROUND_HEIGHT = data.dimensions.dimZ + deltaForWidthAndHeight
    groundGeometry = new PlaneGeometry( GROUND_WIDTH, GROUND_HEIGHT )
    let positions = {
      x:data.positions.min.x + GROUND_WIDTH / 2 - deltaForWidthAndHeight / 2,
      y:data.positions.min.y,
      z:data.positions.min.z + GROUND_HEIGHT / 2 - deltaForWidthAndHeight / 2
    }
    AREA_FLOOR = addGround(scene,groundGeometry,groundGroup,GROUND_NAME,GROUND_OUTLINE_NAME,boundingBox,fontLoader,GROUND_DIMENSIONS_GROUP_NAME,positions,false)
  }

  

  export function getIntersectsObjectsInAreaModel(scene:any,area3DSceneWrapper:any,x:number, y:number,mouseVector:any,raycaster:any,camera:any,moduleName:string) {
  
    y = y - area3DSceneWrapper.getBoundingClientRect().top + window.scrollY
    x = x - area3DSceneWrapper.getBoundingClientRect().left + window.scrollX
  
    x = (x / area3DSceneWrapper.offsetWidth!) * 2 - 1
    y = -(y / area3DSceneWrapper.offsetHeight!) * 2 + 1
  
    mouseVector?.set(x, y, 0.5)
    raycaster?.setFromCamera(mouseVector, camera)
 
    return raycaster?.intersectObject(scene,true) 
   

  }



  export function getClicked3DPoint(x:number, y:number,mouseVector:any) {

    y = y - area3DSceneWrapper.getBoundingClientRect().top + window.scrollY
    x = x - area3DSceneWrapper.getBoundingClientRect().left + window.scrollX
  
    x = (x / area3DSceneWrapper.offsetWidth!) * 2 - 1
    y = -(y / area3DSceneWrapper.offsetHeight!) * 2 + 1

    mouseVector?.set(x, y,0.5)
    raycaster.setFromCamera(mouseVector, camera);
    // var intersects = raycaster?.intersectObject(scene,true)
    // var intersects = raycaster.ray.intersectPlane(planeForRaycasting, intersects);
    return raycaster.ray.intersectPlane(planeForRaycasting, target);
    // if (intersects.length > 0)
    //     return intersects[0].point;
  };



  
 

  export function disableMultiselectionMode() {
    isMultipleCanvasSelectionMode = false
  }

  export function enableMultiselectionMode() {
    isMultipleCanvasSelectionMode = true
  }


  export function openMaterialWindow(object:any=null,partName:string,productName:string) {

    // object = CURR_SELECTED_PRODUCT
    let categoryName = CURR_SELECTED_PRODUCT.userData.categoryName
    partName = getOriginalPartName(categoryName,partName)
    let productdetails = `BedRoom1.View1.${categoryName}.${productName}.${partName}`

    
    let materialTypesList = getMaterialTypes(applicationConfig?.data.objectMaterialTypes,categoryName,partName) 
   
    if((!materialTypesList.length)){
      deselectCanvas()
      return
    }
    if(currSelectedMaterialTypes===JSON.stringify(materialTypesList)){
      return
    }
    productName = stringWithoutWhiteSpace(getOriginalProductName(customizerConfig,productName))
    if(partName && productName && categoryName){
      applicationConfig.functions.customizer.openMaterialWindowFromCanvas(productdetails)
      currSelectedMaterialTypes = JSON.stringify(materialTypesList) 
      setTimeout(() => {
        addActiveClassToTexture(customizerConfig,productName,partName)
      }, 500)
    }else{
      applicationConfig.functions.customizer.closeMaterialWindowFromCanvas()
    }
  }


  export function enableCustomzationMode() {
    IS_CUSTOMIZABLE_MODE = true
  }


  export function disableCustomizationMode() {
    IS_CUSTOMIZABLE_MODE = false
  }

  export function resetCurrMaterialTypes() {
    currSelectedMaterialTypes = ""
  }

  export async function selectProduct() {
    if(isFloorPlanMode || customizerConfig.isMultipleSelectionMode || setWallColorMode){
      return
    }
    if(CURR_SELECTED_PRODUCT.userData.configuration){

      // setGroupForRotatingProduct(null)

      let allowMovement = CURR_SELECTED_PRODUCT.userData.configuration.allowMovement
      let allowRotate = CURR_SELECTED_PRODUCT.userData.configuration.allowRotation

      dimensions.setCurrSelectedObject(CURR_SELECTED_PRODUCT)

      $(".customization-cancel-button").addClass("--is-active")
      CURR_SELECTED_PRODUCT.userData.isProductSelected = true

      IS_PRODUCT_SELECTED = true
      hideCanvasControls()
      resetCurrMaterialTypes()
      applicationConfig.functions.customizer.closeMaterialWindowFromCanvas()

      addBoxHelper(CURR_SELECTED_PRODUCT)
      showProductActions()
      resetRotationSlider()
  
      removeActiveClassFromAnnotations()
      updateSelectedProductAnnotation()
      CURR_SELECTED_AREA = getAreaFromWallsBoundingBox(CURR_SELECTED_PRODUCT) 

      if(!isIsolationView){
        if(!isProductConfigureMode || allowMovement){
          addDragControlsInProduct(CURR_SELECTED_PRODUCT)
        }
      }
      
      displayAnnotations()
      if(CURR_SELECTED_PRODUCT.userData.isProductModular){
        if(!isProductConfigureMode){
          showProductConfigureButton()
        }
      }else{
        hideProductConfigureButton()
      }

      if(!isProductConfigureMode || allowRotate){
        showRotationSlider()
      }

      if(isKitchenPlannerMode){
        applicationConfig.functions.kitchenPlanner.setCurrSelectedModuleGroup(null)
      }

      if(CURR_SELECTED_PRODUCT.userData.isWallMounted && CURR_SELECTED_PRODUCT.userData.isAttachedToWall){
        setWallProductBoundaries(CURR_SELECTED_PRODUCT)
        if(!CURR_SELECTED_PRODUCT.userData.snappedWall){
          setInitialSnappedWall(CURR_SELECTED_PRODUCT)
        }
      }

      setGroupForRotatingProduct(CURR_SELECTED_PRODUCT.userData.groupForRotation)
      $(".add-products-button").fadeIn(0)
    }
  }

 

  export async function deselectProduct() {
    $(".customization-cancel-button").removeClass("--is-active")
    disableRotationMode()
    kitchenPlanner?.setIsReplaceMode(false)
    customizerConfig.setIsProductConfigurationMode(false)
    try {
      if(CURR_SELECTED_PRODUCT){

        CURR_SELECTED_PRODUCT.userData.isProductSelected = false
        dimensions.setCurrSelectedObject(CURR_AREA_GROUP)

     
        IS_PRODUCT_SELECTED = false
        deselectCanvas()
       
        hideAnnotations()
 
        //To dont select the product part when not in customization mode
        $("#categoryHeading").text("")
        showCanvasControls()
        IS_CUSTOMIZABLE_MODE = false
        isMultipleCanvasSelectionMode = false
        // checkIsSavedConfig()
        removeActiveClassFromAnnotations()
        disableProductLock()
        hideProductActions()
        removeBoxHelper()
        hideRotationSlider()
        disableVerticalDragging()

        resetWallProductParameters(true)
        disableDragging()
        composer.removeOutlinePass()
        CURR_SELECTED_AREA = null
        if(!isProductConfigureMode){
          $(".hide-on-configure").removeClass("display-none")
          resetCurrSelectedProduct()
        }
        hideProductConfigureButton()
        if(isKitchenPlannerMode){
          kitchenPlanner.deselectActions()
        }

      // transformObject3d.transformControls.detach()

      }
      // hideControlsPanel(function() {
      //   fitRendererIntoElement(IS_SPACE_PLANNER_MODE,renderer,camera,1)
      //   $(".add-products-button").fadeIn(0)
      //   hideCreateRender()
      // }
      // )
      // transformControls.detach()
    } catch (error) {
      console.log(error)
    }
    
  }


  export function disableClickHint(event:any) {
    event?.stopPropagation()
    event?.preventDefault()
    IS_SHOW_CLICK_HINT = false
  }


 export function deselectCanvas() {
      applicationConfig.functions.customizer.closeMaterialWindowFromCanvas()
      composer.setObjectsForOutline(CURR_SELECTED_PRODUCT)
      currSelectedMaterialTypes = ""
      removeBoxHelper()
  }

  export function initialCamera() {
    boundingBox.setFromObject(areaModel)
    boundingBox.getCenter(target)
    tweenCameraPosition(camera,controls,target,camera)
  }

  export async function toggleSpacePlannerMode() {
    if(IS_SPACE_PLANNER_MODE){
      disableSpacePlanner()
      logger?.info("customizer","spaceplanner",`Space planner ends` )
      return
    }
    logger?.info("customizer","spaceplanner",`Space planner starts` )
    enableSpacePlanner()
    return
  }

 

  export async function disableSpacePlanner(tweenTime = 1000) {
    // deselectProduct()
    IS_SPACE_PLANNER_MODE = false
    dimensions.setIsSpacePlannerMode(false)
    floorplanner.setIsSpacePlannerMode(false)
    showComponentLoader("changeFinishLoader")
   

    hideDistanceAnnotations()
    controls.reset()

    camera = switchCameraArea(controls,perspectiveCamera)
    set3dControls(controls)
    // hideObject(scene,GROUND_DIMENSIONS_GROUP_NAME)
    if(WALLS_HIDING){
      showWallsBoxHelpers()
    }
    
    if(isProductConfigureMode){
      updateControlsForModules(CURR_SELECTED_PRODUCT_GROUP)
      hideOtherItems(CURR_SELECTED_PRODUCT_GROUP)
      $("#moduelsDragIcon").addClass("display-none")
    }else{
      updateCameraControls(CURR_AREA_GROUP,CURR_AREA_NAME || "all")
    }
    if(isKitchenPlannerMode){
      hideKitchen2dModeElements()
    }
    setInitialWallsHiding()


    applicationConfig.functions.customizer.setIsSpacePlannerMode(false)

    if(CURR_AREA_NAME === "all"){
      showFloor()
    }


    hideComponentLoader("changeFinishLoader")
  }


  export async function enableSpacePlanner(tweenTime = 1000) {
    showComponentLoader("changeFinishLoader")
    deselectProduct()

    boundingBox?.setFromObject(CURR_AREA_GROUP || areaModel)
    boundingBox?.getCenter(target)
   

    if(areaWallsBoundingBoxMapping && controls.camera.isPerspectiveCamera){
      let box = areaWallsBoundingBoxMapping[CURR_AREA_NAME].boundingBox
      let fitToBoxDistance = controls.getDistanceToFitBox(box.dimensions.dimX,box.dimensions.dimY,box.dimensions.dimZ,true)
      await controls.setPosition(target.x,target.y + fitToBoxDistance ,target.z,false)
    }

    if(isTraceFloorplanMode){
      traceFloorplan.setCameraPosition(new Vector3(0,0,0))
    }

    camera = switchCameraArea(controls,orthoCamera)
    updateCameraControls(CURR_AREA_GROUP,CURR_AREA_NAME || "all")
    setSpacePlannerControls(controls,{minDistance:MIN_DISTANCE,maxDistance:MAX_DISTANCE})
    
    showAllWalls()
    hideWallsBoxHelpers()
    hideAnnotations()
    IS_SPACE_PLANNER_MODE = true
    dimensions.setIsSpacePlannerMode(true)
    floorplanner.setIsSpacePlannerMode(true)
  
    if(isKitchenPlannerMode){
      kitchenPlanner.updateDragIconPosition()
    }
    hideComponentLoader("changeFinishLoader")
    if(isProductConfigureMode){
      productConfigurationSpacePlannerActions()
    }
   
    hideFloor()
    applicationConfig.functions.customizer.setIsSpacePlannerMode(true)
  }

  export async function updateCameraControls(object:any,areaName:string,isTween:boolean = false) {
    if(areaWallsBoundingBoxMapping ){
      let box = areaWallsBoundingBoxMapping[areaName].boundingBox
      let target = box.center
      if(controls.camera.isOrthographicCamera){
        controls.setTarget(target.x,target.y,target.z,false)
        controls.fitToBox( CURR_AREA_GROUP, false, { paddingTop:1.5, paddingLeft:2, paddingBottom:2.5, paddingRight:2 },false )
      }
      if(controls.camera.isPerspectiveCamera){
        let fitToBoxDistance = controls.getDistanceToFitBox(box.dimensions.dimX,box.dimensions.dimY,box.dimensions.dimZ,true)
        if(isNaN(fitToBoxDistance)){
          fitToBoxDistance = 10
        }
        controls.setLookAt(target.x - 10,target.y + fitToBoxDistance + 5,target.z - 10,target.x,target.y,target.z,false)
      }
    }
    
  }


  export function switchCameraArea(controls,destCamera:any) {
    controls.camera = destCamera
    camera = destCamera
    // renderCamera(destCamera)
    composer.updateCamera(destCamera)
    return destCamera
  }

  export function enablePerspectiveCameraForRender() {
    // camera = perspectiveCameraForRender
  }

  export function disablePerspectiveCameraForRender() {
    camera = perspectiveCamera
  }


  export function resetCurrSelectedMeshes() {
    CURR_SELECTED_MESHES = [] 
    composer.removeOutlinePass()
    composer.setObjectsForOutline([])
  }

  export function checkIfMaterialAvailableForMesh(currMesh:any,categoryName:string) {
    let partName = currMesh?.name
    partName = getOriginalPartName(categoryName,partName)
    // let updatedTextures = categoryFilteredArray(applicationConfig.states.customizer.textures,applicationConfig?.data.categoryMaterialTypes,applicationConfig?.data.objectMaterialTypes,partName,categoryName)
    let updatedTextures = categoryFilteredArray(applicationConfig?.data?.materials,applicationConfig?.data.categoryMaterialTypes,applicationConfig?.data.objectMaterialTypes,partName,categoryName)
    return updatedTextures
}


  export function setCustomizableMeshesAndTexturesList() {
    //setmaterials
    resetCurrSelectedMeshes()
    let categoryName = CURR_SELECTED_PRODUCT.userData.configuration.categoryName
    let productsMeshes = []
    let allTextures = []
    CURR_SELECTED_PRODUCT.traverse(currMesh=>{
      if(currMesh.isMesh){
        const textures = checkIfMaterialAvailableForMesh(currMesh,categoryName) 
        if(textures.length){
          productsMeshes.push(currMesh)
          allTextures = [...allTextures,...textures]
        }
      }
    }) 

    CURR_SELECTED_MESHES = productsMeshes
    // composer.setObjectsForOutline(CURR_SELECTED_MESHES)
    if(categoryName.toLowerCase() === "sofa"){
      allTextures = allTextures.filter(currTexture => currTexture.company_name.toLowerCase() !== "ddecor")
    }
    applicationConfig.functions.customizer.setTextures(allTextures)
  }


  export async function updateAreaModelFinish(textureInfo:any,mesh:any = null) {
    return new Promise(resolve=>{
      if(textureInfo?.materialCode === "Default"){
        resolve("updated")
      }
      let materialToBeApplied = null
  
      // materialToBeApplied = configForSaveMaterials.checkIfMaterialExists(JSON.stringify(textureInfo))
      if(materialToBeApplied){
        updateMaterialForSelectedObjects(customizerConfig,textureInfo,mesh)
        // renderComposer()
        logger?.info("customizer","finish",`Update finish: ${mesh?.name} texture: ${JSON.stringify(textureInfo)}` )
        resolve("updated")
      }else{
        // getMaterialUpdated(textureLoader,textureInfo,mesh?.material).then((material:any)=>{
          // material.envMap = generatedEnvironmentMap.texture
          // material.needsUpdate = true
          updateMaterialForSelectedObjects(customizerConfig,textureInfo,mesh)

          // configForSaveMaterials.addMaterialToConfig(material)
          logger?.info("customizer","finish",`Update finish: ${mesh?.name} texture: ${JSON.stringify(textureInfo)}` )
          resolve("updated")
        // }).catch(err=>{
        //   logger?.info("customizer","finish",`Error in updaing finish: ` )
        //   console.log(err)
        //   resolve("updated")
        // })
      }
    })


  }

  export async function updateFinish(textureInfo:any) {
    if(CURR_SELECTED_PRODUCT){
      if(isProductConfigureMode){
        updateModulesFinish(textureInfo)
        return
      }
      await updateAreaModelFinish(textureInfo).then(data=>{
        let areaName = getAreaFromWallsBoundingBox(CURR_SELECTED_PRODUCT)
        projectConfiguration.setAreaIsConfigChanged(areaName,true)
      })
    }else{
      showToast("Please select product",2000,"er")
    }
    
  }



  export function toggleVerticalDragging() {
    if(IS_VERTICAL_DRAGGING){
      IS_VERTICAL_DRAGGING = false
      showToast("Vertical dragging disabled",2000)
    }else{
      IS_VERTICAL_DRAGGING = true
      showToast("Vertical dragging enabled",2000)
    }
  }


  export function enableVerticalDragging(){
    IS_VERTICAL_DRAGGING = true
  }
  
  export function disableVerticalDragging(){
    IS_VERTICAL_DRAGGING = false
  }


  export function renderComposer() {
    stats.update();
    const delta = clock.getDelta();
    controls.update(delta);
    dimensions.updateLabelsVisibility()
    labelRenderer.render( scene, camera );

    updateFloorDimensionsPosition()


    const renderingType = customizerConfig.currentRenderingType
  
    if (customizerConfig.isFinalizeMode) {
      if (renderingType === "3d") {
        renderMultipleViews(scene,renderer,canvasDimensions,VIEWS_FOR_3D)
      } else if (renderingType === "2d") {
        renderMultipleViews(scene,renderer,canvasDimensions,VIEWS_FOR_3D)
        if (!customizerConfig.isFullScreenMode) {
          renderMultipleViews(scene,renderer,canvasDimensions,VIEWS_FOR_2D)
        } else {
          renderSingleCamera(perspectiveCamera)
        }
      }
      return;
    }
    if (isInteriorVisualization) {
      renderMultipleViews(scene,renderer,canvasDimensions,VIEWS_FOR_WALKTHROUGH)
      return;
    }
    composer?.render();
  }
  

 





function renderSingleCamera(cam:any) {
  renderer.setViewport( 0, 0, window.innerWidth, window.innerHeight );
  renderer.setScissor( 0, 0, window.innerWidth, window.innerHeight );
  renderer.setScissorTest( true );
  updateSceneCameraAspectRatio(cam,window.innerWidth,window.innerHeight,scale)
  renderer.render(scene, cam)
}

export function resetRenderer() {
  renderer.setViewport( 0, 0, canvasDimensions.width, canvasDimensions.height );
  renderer.setScissor( 0, 0, canvasDimensions.width, canvasDimensions.height );
  renderer.setScissorTest( true );
  updateSceneCameraAspectRatio(perspectiveCamera,canvasDimensions.width,canvasDimensions.height,scale)
  composer.onWindowResize(renderer,controls.camera,labelRenderer,IS_SPACE_PLANNER_MODE)
}

 

export function resetconfigForSaveMaterialsuration() {
  // configForSaveMaterials = new configForSaveMaterials()
}


export function drop(event:any) {
  event.preventDefault()
  if(IS_CUSTOMIZABLE_MODE){
    let texture={
      companyName:event.dataTransfer.getData("companyName"),
      collectionName:event.dataTransfer.getData("collectionName"),
      materialCode: event.dataTransfer.getData("materialCode"),
      materialType: event.dataTransfer.getData("materialType"),
      clearcoat: event.dataTransfer.getData("clearcoat"),
      materialId: event.dataTransfer.getData("materialId"),
      metalness: event.dataTransfer.getData("metalness"),
      normalStrength: event.dataTransfer.getData("normalStrength"),
      roughness: event.dataTransfer.getData("roughness"),
      sheen: event.dataTransfer.getData("sheen"),
      specular: event.dataTransfer.getData("specular"),
      transmission: event.dataTransfer.getData("transmission"),
      uvScale: event.dataTransfer.getData("uvScale")
    }
    hoverInteration(raycaster,composer,event.pageX,event.pageY,scene,area3DSceneWrapper,camera,IS_CUSTOMIZABLE_MODE,true)
    updateFinish(texture)
  }else{
    mouse.x = event.pageX
    mouse.y = event.pageY
    if(!customizerConfig.isFinalizeMode){
      dropProductCustomizer(event)
    }
  }
  
}





export function toggleProductLock(event:any = null) {
  
  if(IS_PRODUCT_LOCKED){
    IS_PRODUCT_LOCKED = false
    removeActiveClassFromAnnotations()
    $(event.target).attr("class","fas fa-lock-open color-primary")

  }else{
    IS_PRODUCT_LOCKED = true
    addActiveClassOnAnnotation(event)
    $(event.target).attr("class","fas fa-lock color-primary")
  }
}

export function disableProductLock() {
  IS_PRODUCT_LOCKED = false
  $("#productLockAnnotation").removeClass("--is-active")
  $("#productLockAnnotation").attr("class","fas fa-lock-open color-primary")
}

export function releaseMemoryCustomizer() {
  cancelAnimation()
  // cancelAnnotationAnimation()
  // cancelProductAnnotationsAnimation()
  cancelControlsCheckAnimation()
  controls?.dispose()

  scene?.clear()
  scene = undefined
  PMREMGeneratorObject = undefined
  renderer = undefined
  dracoLoader = undefined
  perspectiveCamera = undefined
  controls = undefined
  camera = undefined
 
  composer = undefined
  orthoCamera = undefined
  areaModel = undefined
  IS_CUSTOMIZABLE_MODE = false
  IS_SPACE_PLANNER_MODE  = false
  IS_SHOW_CLICK_HINT  = true
  isFloorPlanMode = false
  releaseMemoryInteriorVirtualization()
  resetUIMethods()
  releseMemoryFloorplan()
}


function animate() {
  try {
    animationFrameId = requestAnimationFrame( animate )
    renderComposer()
  } catch (error) {
  }
}

function cancelAnimation() {    
  cancelAnimationFrame(animationFrameId)
  animationFrameId = undefined
}


export function toggleShowDistance() {
  if(IS_SHOW_DISTANCE){
    IS_SHOW_DISTANCE = false
    hideDistanceAnnotations()
  }else{
    IS_SHOW_DISTANCE = true
    if(CURR_SELECTED_PRODUCT){
      updateDistanceCheckPointsPosition(CURR_SELECTED_PRODUCT)
      showDistanceAnnotations()
    }
  }
}


export function removeAllBoxHelpers() {
  scene.children.forEach(children => {
    if(children.type === "BoxHelper"){
      scene.remove(children)
    }
  });
 
}

export function setCurrAreaName(areaName:string) {
  applicationConfig.setCurrArea(areaName)
  if(areaName){
    $("#currProductAddingArea").text(`Adding product in ${areaName}`)
    $("#currProductAddingArea").removeClass("color-danger")
  }else{
    $("#currProductAddingArea").text("Area not found. Drag product to area")
    $("#currProductAddingArea").addClass("color-danger")
  }
}

export async function updateAreaCustomizer(areaName:string) {
  // deselectProduct()
  showAndHideLoader("changeFinishLoader",700)
  CURR_AREA_NAME = areaName
  groundGroup.remove(AREA_FLOOR)
  let areaWallsArray = []

  let areaBoundingBoxData = areaWallsBoundingBoxMapping[areaName].boundingBox

  if(areaName === projectConfiguration.projectName || areaName.toLowerCase() === "all"){
    setDefaultCurrAreaGroup()
    showAllItemsInScene()
    showWallsBoxHelpers()
    showAllProducts()
    areaWallsArray = All_WALLS
    $("#exitRoomButton").addClass("display-none")
    $("#roomDimensionsButton").addClass("display-none")
  }else{
    setCurrAreaGroup(areaName)
    hideAllMeshes(scene)
    areaWallsArray =  showAreaWalls(areaName)
    hideFloor() 
    showHideProducts(areaName,true)
    enableWallsHiding()
    $("#exitRoomButton").removeClass("display-none")
    $("#roomDimensionsButton").removeClass("display-none")
    addAreaFloor(areaName)
  }

  makeWallsTransparent(areaWallsArray,areaBoundingBoxData.center)
  if(isInteriorVisualization){
    setPositionFromControlsForInteriorVirualization(areaName)
  }else{
    updateCameraControls(null,areaName)
  }
  dimensions.hideDimensions()
}


function hideFloor() {
  floorplanner.group.traverse(((mesh:any)=>{
    if(mesh.isMesh && mesh.name.includes("Floor_")){
      mesh.visible = false
    }
  }))
}

function showFloor() {
  floorplanner.group.traverse(((mesh:any)=>{
    if(mesh.isMesh && mesh.name.includes("Floor_")){
      mesh.visible = true
    }
  }))
}

export function setCurrAreaGroup(areaName:string) {
  
  CURR_AREA_NAME = areaName
  CURR_AREA_GROUP = areaWallsGroupsMapping[areaName].group
  dimensions.updateCurrObject(CURR_AREA_GROUP)
  // return CURR_AREA_GROUP
}

export function getAreaGroupFromAreaName(areaName:string) {
  let group = new Group()
  let area = projectConfiguration.projectConfiguration[areaName]
  for (const wallName of area.walls) {
    let wall = getObjectByName(scene,wallName)
    if(wall){
      group.add(wall.clone())
    }
  }
  return group
}

export function resetCurrArea() {
  CURR_AREA_GROUP = floorplanner.group
  CURR_AREA_NAME = "all"
}

function hideAllMeshes(areaModel:any) {
  areaModel.children.forEach(mesh=>{
    if(mesh.isMesh && mesh.name.includes("Floor")){
        mesh.visible = false
    }
  })
}

export function showAreaWalls(areaName:string) {
  let areaBoundingBoxData = areaWallsBoundingBoxMapping[areaName].boundingBox
  let area = projectConfiguration.projectConfiguration[areaName]
  let areaWallsArray = []
  All_WALLS.forEach(currWall=>{
    if(area.walls.includes(currWall.name)){
      currWall.visible = true
      currWall.userData.box.visible = true
      areaWallsArray.push(currWall)
    }else{
      currWall.visible = false
      currWall.userData.box.visible = false
    }
  })
  // addFloor(areaBoundingBoxData)
  // showFloor(FLOOR) 
  return areaWallsArray
}

export function hideAllWalls() {
  All_WALLS.forEach(currWall=>{
      currWall.visible = false
      currWall.userData.box.visible = false
  })
  groundGroup.remove(FLOOR)
}

export function showAllItemsInScene() {
  scene.traverse(mesh=>{
    if(mesh.isMesh && !mesh.name.includes("hidden")){
      mesh.visible = true
    }
  })
}


export function hideProducts(areaName:string) {
  let configuration = projectConfiguration.projectConfiguration[areaName].areaConfiguration
  let productsToBeVisible = []
  for (const productKey in configuration) {
    for (const instanceKey in configuration[productKey]) {
      if(configuration[productKey][instanceKey].enabled){
        productsToBeVisible.push(instanceKey)
      }
    }
  }

  product3DModelsList.forEach(object => {
    if(object.userData.productInstanceName){
      if(!productsToBeVisible.includes(object.userData.productInstanceName)){
        object.visible = false
      }else{
        object.visible = true
      }
    }
  });
}

export function showHideProducts(areaName:string,isShow:boolean) {
  const areaProductsArray = projectConfiguration.getAreaProductsArray(areaName) 
  product3DModelsList.forEach((object:any) => {
    const productInstanceName = object.userData.productInstanceName
    if(productInstanceName){
      if(areaProductsArray.includes(productInstanceName)){
        object.visible = isShow
      }else{
        object.visible = !isShow
      }
    }
  });
}

export function toggleVisibilityOfAreaProducts(isShow:boolean) {
  product3DModelsList.forEach(object => {
    if(object.userData.productInstanceName){
        object.visible = isShow
    }
  });
}

export function showAllProducts() {
  product3DModelsList.forEach(object => {
    if(object.userData.productInstanceName){
      object.visible = true
    }
  });
}



// Is confirm kitchen required when returning back from isolation / elevation view
export async function enableKitchenPlannerMode(isConfirmKitchen:any = false) {
  if(!customizerConfig.isProductsLoaded){
    showToast("Please wait until products are loading",4000,"error")
    return
  }
  isKitchenPlannerMode = true
  applicationConfig.functions.customizer.setIsKitchenPlannerMode(true)
  await waitFor(300)
  updateAreaCustomizer("Kitchen")
  $(".hide-in-kitchen-planner").addClass("display-none")
  $(".show-in-kitchen-planner").removeClass("display-none")
  customizerConfig.functions.setCurrProductId(3291)
  customizerConfig.functions.setCurrProductName("Lshape1")
  applicationConfig.functions.customizer.changeTabOption("AddModule")
  toggleVisibilityOfAreaProducts(false)

  if(!kitchenPlanner.group){
    $("#kitchenDragIcon").removeClass("display-none")
    $("#selectKitchenWindow").removeClass("display-none")
    showKitchen2dModeElements()
  }else{
    kitchenPlanner.group.visible = true
    kitchenPlanner.updateDragIconPosition()
    hideKitchen2dModeElements()
    if(isConfirmKitchen){
      kitchenPlanner.confirmCustomizeKitchen()
    }
  }
  kitchenPlanner.clamp.createBoundingBoxForClamp(CURR_AREA_GROUP)
  $("#customizerGoBack").removeClass("display-none")
  removeAllBoxHelpers()
  enableWallsHiding()
}

export function disableKitchenPlannerMode() {
  deselectProduct()
  isKitchenPlannerMode = false
  updateAreaCustomizer(projectConfiguration.projectName)
  $(".hide-in-kitchen-planner").removeClass("display-none")
  $(".show-in-kitchen-planner").addClass("display-none")
  applicationConfig.functions.customizer.setIsKitchenPlannerMode(false)
  $("#kitchenDragIcon").addClass("display-none")
  $("#customizerGoBack").addClass("display-none")
  $("#selectKitchenWindow").addClass("display-none")
  kitchenPlanner.addModulesToGroup()
}

 
export async function loadConfigFromConfigName(configId:number) {
  showComponentLoader("changeFinishLoader")
  projectConfiguration.createConfigFromConfigId(configId)

  await waitFor(1000)
  let childrens = []
  product3DModelsList?.forEach(object => {
    if(object.userData.productInstanceName){
      childrens.push(object)
    }
  });
  scene.remove(...childrens)
  await waitFor(500)
  addProjectProducts()
  resetAreaRenderFlag()
  applicationConfig.functions.customizer.setRefreshRenders(Number(new Date()))
  hideComponentLoader("changeFinishLoader")
}

export function updateCurrentConfig(configId:number) {
  projectConfiguration.createConfigFromConfigId(configId)
}


export function enableMeasurementTool(event:any) {
  deselectProduct()
  measurementTool.enable()
}


export async function enableFloorplanMode() {
  isFloorPlanMode = true
  customizerConfig.isFloorplanMode = true
  applicationConfig.functions.customizer.setIsFloorPlanMode(true)
  hideFloorplanOptionWindow()
  setDefaultCurrAreaGroup()
  hideFloorplanModeElements()
  // enableFloorplanMode()
  if(!IS_SPACE_PLANNER_MODE){
    await enableSpacePlanner(500)
  }
  if(isInteriorVisualization){
    await disableInteriorVirtualization()
  }
  showAllItemsInScene()
  hideWallsBoxHelpers()
  showAllProducts()
  undo.updateMode("floorplan") 
  enableFloorplanControls()
  if(projectConfiguration.isFloorplanCreatedFromShape){
    removeWallsElementOfShapeFloorplan()
    floorplanner.createEventListeneres()
  }
  document.body.style.cursor = "pointer";
}

export function disableFloorplanMode() {
  isFloorPlanMode = false
  customizerConfig.isFloorplanMode = false
  applicationConfig.functions.customizer.setIsFloorPlanMode(false)
  disableSpacePlanner()
  removeAllAnchors()
  showFloorplanModeElements()
  // disableFloorplanMode()
  disableSelectWalls()
  disableFloorplanControls()
  setInitialWallColor()
  undo.updateMode("360")
  if(projectConfiguration.isFloorplanCreatedFromShape){
    postFloorplanEditActions()
  }
  document.body.style.cursor = "unset";
}

export async function enableFinalizeMode() {
  resetTruckValue()
  // setIsCameraSettingMode(true)
  customizerConfig.setIsFinalizeMode(true)
  setDefaultCurrAreaGroup()
  changeViewMode(null,"3d")
  disableWallsHiding()
  showAllWalls()
  hideWallSelectionHint()
  setInitialWallColor()
  closeControlsPanelAndHideProducts()
  updateSceneCameraFov(perspectiveCamera,75)
  

  updateSettingPositionCameraView()
 
  enableInteriorControls()
  setOrthographicCameraPosition()

  toggleCeilingVisibility(true)
  toggleMoveCircleVisibility(false)
  applicationConfig.functions.customizer.setIsFinalizeMode(true)
  hideFloorplanModeElements()

  showGrid()
}

export function disableFinalizeMode() {
  resetTruckValue()
  // setIsCameraSettingMode(false)
  if(isInteriorVisualization){
    updateCameraSettingViewData("walkthrough")
    enableInteriorVirtualization(false)
  }

  setDefaultCurrAreaGroup()
  
  customizerConfig.setIsFinalizeMode(false)
  applicationConfig.functions.customizer.setIsFinalizeMode(false)
  if(!isInteriorVisualization){
    removeAllAnchors()
    disableSpacePlanner(50)
    updateSceneCameraFov(perspectiveCamera,45)
    toggleCeilingVisibility(false)
  }

  showFloorplanModeElements()

  toggleMoveCircleVisibility(true)
 
  resetRenderer()
  $("#cameraViewFrame").removeClass("--is-active")
  hideComponentLoader("canvasLoader")
  undo.updateMode("360")
}

export function addModelToScene(modelObject:any) {
  scene.add(modelObject)
  product3DModelsList.push(modelObject)
}

export function removeProductFromScene(object:any) {
  scene.remove(object)
  product3DModelsList = product3DModelsList.filter(currModel => currModel.uuid !== object.uuid)
}