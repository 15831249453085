import {getColorOptions, getObjectMaterialTypes, getTextures,
    getProjects,
    getAllCustomizableObjects,
    getAllViews,
    getForegroundSavedConfig,
    getAllProjectAreas,
    getCategoryMaterialTypes,
    getAllProjectAreaProducts,
    getProductAttributes,
    getProjectAreaCategories,
    getAllCategories,
    getAllSubCategories,
    getProductsList,
    getMoodBoardSavedConfig,
    getProductModules,
    getModulesPrice,
    getMoodboardList,
    getAllAreas,
    getAllMaterialTypes,
    getModulesTypes,
    getAllCollections,
    getApplicationMaterials,
    getProjectMaterials,
    getCategoryModules,
    getUploadedFilesDetails,
    getLogs,
    getMoodboardAreaCategories,
    getUsersList,
    getClientsList,
    getMaterialCompanies,
    getAllModules,
    getProductSavedConfigs,
    getAreacategoryRelationship} from "../../services/api"
import { applicationConfig, getSubDomainName } from "../methods";

export default class Authorization{
    userType: string;
    moduleName: string;
    shareItemId: Array<number>;

    fetchDataList = [
        getTextures,
        getObjectMaterialTypes,
        getColorOptions,
        getProjects,
        getAllCustomizableObjects,
        getAllViews,
        getAllProjectAreas,
        getCategoryMaterialTypes,
        getForegroundSavedConfig,
        getAllProjectAreaProducts,
        getProductAttributes,
        getProjectAreaCategories,
        getAllCategories,
        getAllSubCategories,
        getProductsList,
        getMoodBoardSavedConfig,
        getProductModules,
        getModulesPrice,
        getMoodboardList,
        getAllAreas,
        getAllMaterialTypes,
        getModulesTypes,
        getAllCollections,
        getApplicationMaterials,
        getProjectMaterials,
        getCategoryModules,
        getUploadedFilesDetails,
        getLogs,
        getMoodboardAreaCategories,
        getUsersList,
        getClientsList,
        getMaterialCompanies,
        getAllModules,
        getProductSavedConfigs,
        getAreacategoryRelationship
    ]

    moduleDataMapping = {
        'products':[
            getProductsList,
            getProductModules,
            getModulesPrice,
        ],
        "projects":[
            getProjects,
            getAllProjectAreaProducts,
            getAllViews,
            getAllProjectAreas,
            getForegroundSavedConfig,
            getProjectAreaCategories,
            getMoodBoardSavedConfig,
            getMoodboardList,
            getAllAreas,
            getColorOptions,
            //For styleboard
            getProductsList,

        ],
        "collections":[
            getAllProjectAreas,
            getProjectAreaCategories,
            getMoodBoardSavedConfig,
            getMoodboardList,
            getAllAreas,
            getColorOptions,
            //For styleboard
            getProductsList,
        ],
        "common":[
            getTextures,
            getProductAttributes,
            getObjectMaterialTypes,
            getAllCustomizableObjects,
            getCategoryMaterialTypes,
            getAllCategories,
            getAllSubCategories,
            getAllMaterialTypes,
            getModulesTypes,
            getAllCollections,
            getApplicationMaterials,
            getProjectMaterials,
            getCategoryModules,
            getUploadedFilesDetails,
            getLogs,
            getMoodboardAreaCategories,
            getUsersList,
            getClientsList,
            getMaterialCompanies,
            getAllModules,
            getProductSavedConfigs,
            getAreacategoryRelationship
        ],
        "all":this.fetchDataList
    }


    constructor(userType:string,moduleName:string,shareItemId:Array<number>){
        this.userType = userType
        if(moduleName){
            this.moduleName = moduleName
        }else{
            this.moduleName = "all"
        }
        this.shareItemId = shareItemId
    }

    getData(){
        let promisesArray = []
        let modulesData = this.moduleDataMapping[this.moduleName]
        let commonData = this.moduleDataMapping["common"]
        for (const api of this.fetchDataList) {
            if(modulesData.includes(api) || commonData.includes(api)){
                let promise = api()
                promisesArray.push(promise)
            }else{
                promisesArray.push(null)
            }
        }
        return promisesArray
    }

    //products
    //projects
    //styleboard
    //common


}



export function canUserAccessRoute(routeName:string) {
    let subDomain = getSubDomainName()
    if(subDomain === "crm"){
        if(getCrmRoutesListForUserType().includes(routeName)){
            return true
        }else{
            return false 
        }
    }

    if(subDomain === "staging"){
        return true
    }

    if(subDomain === "store"){
        if(getStoreRoutesListForUserType().includes(routeName)){
            return true
        }else{
            return false 
        }
    }

    if(subDomain === "elevate"){
        return true
    }

    
    if(subDomain.includes("upgraded-three")){
        return true
    }
   
    return false 
}


export function getCrmRoutesListForUserType() {

    const userType = applicationConfig?.user?.userType

    switch (userType?.toLowerCase()) {

        case "admin":
            return ["/leads","/","/visitors","/customers","/tickets","/customer-tickets"]
        break;

        // case "insidesales":
        //     return ["/leads","/","/customers"]
        // break;
    
        // case "security":
        //     return ["/visitors"]
        // break;

        case "manager":
            return ["/leads","/","/visitors","/customers","/tickets","/customer-tickets"]
        break;

  

        // case "customercare":
        //     return ["/tickets","/customer-tickets"]
        // break;

        
        // case "salesspecialist":
        //     return ["/leads"]
        // break;

        // case "dispatcher":
        //     return ["/tickets"]
        // break;
    
        default:
        break;
    }
  
    return []
  }

  export function getOpusRoutesListForUserType() {

    const userType = applicationConfig?.user?.userType
    let allRoutes = ["/customize","/projects","/designs","/products/:category","/product-categories","/collections","/edit-moodboard","/3dwalkthrough","/product-3dcustomization","/finish","/users","/share"]

    if(!applicationConfig?.user){
        return ["/share"]
    }

    switch (userType?.toLowerCase()) {

        case "admin":
            return allRoutes
        break;

        case "manager":
            return ["/products/:category","/product-categories","/product-3dcustomization"]
        break;
    
        default:
        break;
    }
  
    return []
  }

  export function getStoreRoutesListForUserType() {

    let allRoutes = ["/products","/kanvas","/store/product-details/:sku","/store/products/:category","/search","/orders","/customizein3d","/fabricate"]
    return allRoutes
    const userType = applicationConfig?.user?.userType
    switch (userType?.toLowerCase()) {

        case "admin":
            return allRoutes
        break;

        case "manager":
            return allRoutes
        break;

        case "user":
            return allRoutes
        break;
    
        default:
        break;
    }
  
    return []
  }


  export function getUserDefaultRoute() {

    let subDomain = getSubDomainName()
    if(subDomain === "crm"){
        const userType = applicationConfig?.user?.userType
        switch (userType?.toLowerCase()) {
            case "insidesales":
                return "/leads"
        
            case "security":
                return "/visitors"
        
            case "manager":
                return "/leads"

            case "customercare":
                return "/tickets"

            case "salesspecialist":
                return "/leads"

            case "dispatcher":
                return "/tickets"
        
            default:
                return "/access-denied"
        }
    }

    if(subDomain === "staging"){
        const userType = applicationConfig?.user?.userType
        switch (userType?.toLowerCase()) {
        
            case "admin":
                return "/projects"

            case "manager":
                return "/product-categories"
        
            default:
                return "/products"
        }

        return "/products"
    }

    if(subDomain === "store"){
        const userType = applicationConfig?.user?.userType

        switch (userType?.toLowerCase()) {
        
            case "admin":
                return "/products"

            case "manager":
                return "/products"

            case "user":
                return "/products"
    
            
        
        
            default:
                return "/access-denied"
        }
    }
    return "/access-denied"
  }