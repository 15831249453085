import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { getShareLink } from "../../../../../services/api";
import { hideComponentLoader, showComponentLoader } from "../../../../../utils/UI_methods/global";
import { Configuration } from "../../../../../utils/customizein3d/Configuration";
import { projectConfiguration } from "../../../../../utils/customizein3d/area3dmodel/area3dModel";
import { configuration } from "../../../../../utils/customizein3d/modules/customizein3d";
import { applicationConfig, getArrayOfDistinctValuesFromObject, getFilteredArray, logger, openNativeShareOptions } from "../../../../../utils/methods";
import "../../../../style/admin/createproject.css";
import Loader from "../../../shared-components/main-ui/Loader";
import Form from "./Form";
import LinkDetails from "./LinkDetails";
import ProductInfo from "./ProductInfo";
import ProjectInfo from "./ProjectInfo";


const CollectionsList: React.FC<{
  shareItemId:any
  moduleName:string
  isOpenCollections:boolean
  setIsOpenCollection:(val:boolean)=>void
  defaultConfiguration?:any
}> = (props) => {

  const [shareItemName, setshareItemName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productSubCategory, setProductSubCategory] = useState("");

  const [shareData,setShareData] = useState<any>({});
  const[loaderId] = useState("sharelinkloader")


  useEffect(()=>{
    if(props.isOpenCollections){
      setIsShareLinkGenerated(false)
    }
  },[props.isOpenCollections])

  const[isShareLinkGenerated,setIsShareLinkGenerated] = useState(false)

  const[currShareItemId,setCurrShareItem]:any = useState()

  useEffect(()=>{
    //In case of multiple share 
    if(typeof props.shareItemId === "number"){
      setCurrShareItem(props.shareItemId)
    }else{
      if(props.shareItemId?.length){
        setCurrShareItem(props.shareItemId[0])
      }
    }
  },[props.shareItemId])

  var selectedCollections = []
  var tokenValidity:any = 0

  useEffect(()=>{
    if(props.moduleName==='products'){
      let product = getFilteredArray(applicationConfig?.data.productsList,"product_id",String(currShareItemId))
      if(product.length && product){
        setshareItemName(product[0].product_name)
        setProductCategory(product[0].category_name)
        setProductSubCategory(product[0].sub_category_name)
      }
    }else{
      let project = getFilteredArray(applicationConfig?.data.projects,"project_id",String(currShareItemId))
      if(project?.length){
        setshareItemName(project[0].project_name)
      }
    }
  },[applicationConfig,currShareItemId])

   
  function setSelectedCollections(collections:any){
    // collections = getArrayOfDistinctValues(collections,"collection_name")
    selectedCollections = [...collections]
  }

  function setTokenValidity(validity:any){
    // collections = getArrayOfDistinctValues(collections,"collection_name")
    tokenValidity = validity
  }

  function shareItem() {
    showComponentLoader(loaderId)
    let collectionList = selectedCollections
    if(collectionList?.length){
      if(typeof collectionList[0] != "string"){
        collectionList = getArrayOfDistinctValuesFromObject(collectionList,"collection_name")
      }
    }
    let productConfiguration = props.defaultConfiguration || configuration || new Configuration(props.shareItemId)
    let  data={
        moduleName:props.moduleName,
        shareItemId:props.shareItemId,
        // shareItemId:[3141,3084],
        collectionNames:JSON.stringify(collectionList),
        clientId:applicationConfig?.clientId,
        clientName:applicationConfig?.clientName,
        tokenValidity:tokenValidity || 1/24, // default 1 hr
        configuration:props.moduleName === "products" ? productConfiguration : projectConfiguration
    }
    console.log(data)

    getShareLink(data).then(data=>{
      console.log(data)
      if(data.data){
        let shareLink = window.location.origin + "/share/" + data.data.data.token
        setShareData({link:shareLink,collectionList:data.data.data.collectionNames,tokenValidity:data.data.data.tokenValidity})
        setIsShareLinkGenerated(true)
        openNativeShareOptions(shareLink,shareItemName,loaderId)
        hideComponentLoader(loaderId)
        $("#shareLinkContainer").fadeIn()
        logger?.info(props.moduleName,"shareItem","Shared item  "+ JSON.stringify(data?.data?.data || {}))
        return
      }
      hideComponentLoader(loaderId)
      //Create logs
    }).catch(err=>{
      console.log(err)
      hideComponentLoader(loaderId)

      //Create logs
      logger.error(props.moduleName,"shareItem","Error in sharing  "+ err + "item details" + JSON.stringify(data))
    })
  }




  return (
    <React.Fragment>
    <div className="modal-header">
      <div className="center-aligned-row heading1">Review share details</div>
      <div className="top-right center-aligned-column full-height">
        <IonIcon icon={close} className="font-large" onClick={()=>props.setIsOpenCollection(false)}></IonIcon>
      </div>
    </div>
    <div className="modal-body padding10">
      <div className="share-link-wrapper full-height">
      <Loader
        data={{}}
        loaderType="spinner"
        className="fade-background-loader"
        id={loaderId}
      />
      {props.moduleName==="products"?
      <ProductInfo
        shareItemId={props.shareItemId}
        productName={shareItemName}
        category={productCategory}
        subCategory={productSubCategory}
      />
      :
      <ProjectInfo
        projectName={shareItemName}
      />
      
      }
      {!isShareLinkGenerated?
      <Form
        shareItemId={props.shareItemId}
        moduleName={props.moduleName}
        setSelectedCollections={setSelectedCollections}
        setTokenValidity={setTokenValidity}
        productCategory={productCategory}
      />:
      <LinkDetails
        shareData={shareData}
        moduleName={props.moduleName}
      />
      }
      {/* <div className="bottom-middle full-width text-center font-small color-dark padding10">Note: Only selected collection's materials will be shared with the client</div> */}
      </div>
    </div>
    <div className="modal-footer">
      {!isShareLinkGenerated?
        <div>
          <button className="op-btn-primary op-btn" onClick={shareItem}
          >Generate Shareable Link</button>
          </div>
        :
        <div>
            <button className="op-btn-light op-btn" onClick={()=>props.setIsOpenCollection(false)}
          >Close</button>
        </div>
        }
    </div>
   

    </React.Fragment>
    
  );
};

export default CollectionsList;
