import { IonModal } from "@ionic/react";
import { archiveOutline, listOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { showGlobalToast } from "../../../../../utils/UI_methods/global";
import { applicationConfig, getFilteredArray, getObjectArrayOfDistictValues, getObjectByParameter } from "../../../../../utils/methods";
import { getFormattedPrice, getRandomNumber } from "../../../../../utils/products/productviewer_shopify";
import { getFormatedDate } from "../../../../../utils/store/crm/crm";
import { STORE } from "../../../../../utils/store/storeConfiguration";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import ControlsTab from "../../../../common/ui-components/tabs/ControlsTab";
import CartProductSummary from "../cart/CartProductSummary";

 

const OrdersList: React.FC<{
}> = (props) => {
  const[isOpenSummary,setIsOpenSummary] = useState(false)
  const[currProduct,setCurrProduct] = useState(null)
  const[currConfiguration,setCurrConfiguration] = useState(null)
  const[ordersList,setOrdersList] = useState([])
  const[orderItemsList,setOrderItemsList] = useState([])

  const [currTab, setCurrTab] = useState<string>("allOrders");


  var tabItems = [
    {tabName:"allOrders",
    label:"All Orders",
    ionIcon:listOutline,
    count:ordersList.length
    },
    {tabName:"archived",
    label:"Archived Orders",
    ionIcon:archiveOutline,
    count:0
    }
  ]


  useEffect(()=>{
    if(STORE.activeCustomer){
      fetchOrders()
    }
  },[])

  function updateTabHandle(tabName: string) {
    setCurrTab(tabName)
  }

  function fetchOrders() {
    STORE.storeApi.orders.getOrders(STORE.activeCustomer.id).then((data:any)=>{
      if(!data.error){
        if(data.data?.data?.length){
          let orderItems = data.data.data 
          orderItems = orderItems.filter(currOrder => currOrder.customer_id === STORE.activeCustomer.id)
          setOrderItemsList(orderItems)
          const orders = getObjectArrayOfDistictValues(orderItems,"order_id")
          setOrdersList(orders)
        }
      }
    })
  }

  function viewHandle(currItem:any) {
    if(currItem.configuration){
      setCurrConfiguration(JSON.parse(currItem.configuration))
      setCurrProduct(getObjectByParameter(applicationConfig?.data?.productsList,"product_id",currItem.product_id))
      setIsOpenSummary(true)
    }else{
      showGlobalToast("Config not found",2000,"error")
    }
  }

  return (
    <div className="full-height-width ">

        <div className="orders-type-tabs" style={{width:"50%"}}>
          <ControlsTab
            configObj={{}}
            tabItems={tabItems}
            currSelectedValue={currTab}
            action={updateTabHandle}
            theme="black"
          />
        </div>


        <IonModal
          id=""
          cssClass="vertical-modal"
          onDidDismiss={()=>setIsOpenSummary(false)}
          isOpen={isOpenSummary}
          >
            <>
            {currConfiguration && currProduct?
              <CartProductSummary productConfiguration={currConfiguration} product={currProduct} closeHandle={()=>{setIsOpenSummary(false)}} />
            :null}
            </>
        </IonModal>

        <div className="padding10 full-width">
          <table className="table full-width store-quotation-table orders-list-table">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Order ID</th>
                    <th>Order Date</th>
                    <th>Product</th>
                    <th>Customer Name</th>
                    <th>Total Amount</th>
                    <th>Payment Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            {ordersList?ordersList.map((order:any,index:any)=>{
                const orderItems = getFilteredArray(orderItemsList,"order_id",order.order_id)
                  return (
                        <tbody>
                            {orderItems?.map((currItem:any,i:number)=>{
                                const item = currItem
                                const product = getObjectByParameter(applicationConfig?.data?.productsList,"product_id",item.product_id)
                                return(
                                    <tr key={i}>
                                        <td>{i+1}</td>
                                        <td className="heading2" >{`#${getRandomNumber(1000,25412)}`}</td>
                                        <td>{getFormatedDate(order.order_date)}</td>
                                        <td>{item?.product_name}</td>
                                        <td>{item?.customer_name}</td>
                                        <td>{getFormattedPrice(order.total_price)}</td>
                                        <td className="color-success">Paid</td>
                                        <td style={{margin:"auto"}}>
                                          <Button2
                                            configObj={{id:""}}
                                            action={()=>{viewHandle(order)}}
                                            label={"Config"}
                                            fontAwesomeIcon={"fas fa-cogs color-warning"}
                                            iconPos="left"
                                            classNames={"no-shadow button-ex-small color-white bg-color-light"}
                                          />
                                    </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                  )
              }):null}

          </table>
        </div>

       

    </div>
  );
};

export default OrdersList;
