import $ from "jquery"
import { Box3, Group, Sprite, SpriteMaterial, TextureLoader, Vector3 } from "three"
import { getColorOptions } from "../../../services/api"
import { ProductInstance } from "../../customizer/ProjectConfiguration"
import { SpritesScenes } from "../../fabricate/scenes"
import { applicationConfig, getUser, logger, waitFor } from "../../methods"
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../UI_methods/global"
import { detachModule } from "../common"
import { camera, configuration, controls, fitToModuelsScene, group, scene, sceneBackgrounds } from "../modules/customizein3d"


let spriteHeight = 10
const cameraDirection = new Vector3();

export class Sprites{
    
    sprites = []
    group:any = new Group()
    configuration = configuration
    foregroundProducts = []
    productsList = []
    currRoom:string = "Room3_View1"

    isEnabled:boolean = false

    setCurrCategory:(cat:string)=>void
    currSelectedSprite:Sprite = null

    colorApplicableCategory = ["Floor","Wallpaper","Curtain","Carpet"]

    currSelectedCategory:string = ""

    savedControlState:any = {
        position:new Vector3(-1.2879935443706183,1.0308277673915447,3.6207987016576704),
        target:new Vector3(1.875950982534668,1.0308277673915445,1.2142869910676313),
        zoom:1
    }
    constructor(){
        let currScene = SpritesScenes[0]
        this.productsList = currScene.productsList
        this.currRoom = currScene.room_name
    }

    async enable(){
        if(!this.isEnabled){
            showComponentLoader("changeFinishLoader")
            this.isEnabled = true
            if(this.savedControlState){
                camera.position.copy(this.savedControlState.position);
                controls.target.copy(this.savedControlState.target);
                camera.zoom = this.savedControlState.zoom;
                camera.updateProjectionMatrix();
                controls.update();
            }
            await waitFor(1000)
            this.createScene()
            $(".hide-on-sprites").addClass("visibility-hidden")
            $(".show-on-sprites").removeClass("visibility-hidden")
            if(!applicationConfig.data?.colorOptions){
                await getColorOptions().then(data=>{
                    applicationConfig.data.colorOptions = data
                })
            }
            await waitFor(1000)
            hideComponentLoader("changeFinishLoader")
            this.setCurrSelectedSprite("Wallpaper")
            this.setCurrCategory("Wallpaper")
            logger?.info("customizein3d","E-Cus-BG")
        }
        
    }

    async disable(){
        showComponentLoader("changeFinishLoader")
        this.isEnabled = false
        this.savedControlState = {
            position: camera.position.clone(),
            target: controls.target.clone(),
            zoom: camera.zoom,
        };


        await waitFor(500)

        for (const sprite of this.sprites) {
            detachModule(sprite)
        }
        this.sprites = []
        $(".hide-on-sprites").removeClass("visibility-hidden")
        $(".show-on-sprites").addClass("visibility-hidden")

        fitToModuelsScene()
        sceneBackgrounds.disable()
        hideComponentLoader("changeFinishLoader")
        logger?.info("customizein3d","D-Cus-BG")
    }


    createScene(){
        if(!this.sprites.length){
            let clientName = getUser()?.username
            let location = window?.location?.href
            // if(clientName === "ovl_Shreedhar"){
                this.addForegroundProducts()
            // }
        }
    }

    addSprite(path:string) {

        return new Promise((resolve,reject)=>{
            let loader:any = new TextureLoader();
            loader.crossOrigin = ''; 
            let setSpritePos = this.setSpritePos

            // getTextureMap(path,textureLoader).then((textureMap:any)=>{
               
            //   }).catch(err=>{
            //     console.error('An error occurred while loading the texture:', err);
            //     reject(err)
            // })
        
            loader.load(path,(textureMap) => { // On load
                textureMap.colorSpace  = "srgb"
                const spriteMaterials = new SpriteMaterial({ map: textureMap,toneMapped:false,color:0xffffff});
                    const sprite = new Sprite(spriteMaterials);   
                    this.sprites.push(sprite);
                    textureMap.colorSpace  = "srgb"
                    setSpritePos(sprite)
                    
                    const halfHeight = camera.position.z / Math.tan((camera.fov / 2) * (Math.PI / 180));
                    const yScale = halfHeight * 2;
                    const xScale = yScale * sprite.material.map.image.width / sprite.material.map.image.height;
                    sprite.scale.set(xScale / 1.01, yScale / 1.01, 1);
                    spriteHeight = yScale

                    sprite.userData.isBackgroundSprite = true
                    scene.add(sprite);
                    resolve(sprite)
                },
                undefined, // Optional callback function for progress events
                (err) => { // On error
                    console.error('An error occurred while loading the texture:', err);
                    reject(err)
                }
            );
        }).catch(err=>{

        })
        
    }


    setSpritePos(object:any){

        // object.scale.set(500, 500, 1);
        // object.position.set(0, 0, -1);


        return

        let resPos = new Vector3()
        let objectWorldPos = new Vector3()
        let cameraWorldPos = new Vector3()
        group.getWorldPosition(objectWorldPos)
        camera.getWorldPosition(cameraWorldPos)
        resPos.subVectors(cameraWorldPos,objectWorldPos)
        resPos.normalize()
        let finalPos = new Vector3()
        // finalPos = resPos.multiplyScalar(10).add(objectWorldPos)
        finalPos = resPos.clone().multiplyScalar(0.00001).add(objectWorldPos);

        object.position.copy(finalPos)

    }

    updateSpritesPos(){
        try {
            for (const sprite of this.sprites) {
                // sprite.position.copy(camera.position);
                // sprite.position.set(camera.position.x, camera.position.y, camera.position.z - 5);
                // sprite.rotation.copy(camera.rotation); 
                camera.getWorldDirection(cameraDirection);
                // Set sprite position based on the camera position and direction
                // const distance = -10; 
                const fov = camera.fov * (Math.PI / 180); // Convert FOV to radians
                const heightAtDistance = 2 * Math.tan(fov / 2); // height of the frustum at unit distance
                const distance = -(spriteHeight / heightAtDistance);


                sprite.position.copy(camera.position).add(cameraDirection.multiplyScalar(-distance));

            } 
        } catch (error) {
            console.log(error)
        }
        
    }

    async addForegroundProducts() {
        // showComponentLoader("mainLoaderSpinner")
        
        try {
            let foregroundProducts = this.foregroundProducts
            let configuration = this.configuration
            await this.loadBackground()
            for (const product of this.productsList) {
                let collection = product.collections[0]
                let companyName = collection.companyName
                let collectionName = collection.collectionName
                let categoryName = product.categoryName
                let allParts = product.parts
                let materialCode = "1"
                for (const currPart of allParts) {
                // let suffix = `public/sprites/${this.currRoom}/Foreground/${product.productName}/${this.currRoom}_${product.productName}_${companyName}_${collectionName}_${materialCode}/${this.currRoom}_${product.productName}_${currPart}_${companyName}_${collectionName}_${materialCode}0000.webp`
                let defaultMaterialSuffix = `public/sprites/${this.currRoom}/Foreground/${product.productName}/${this.currRoom}_${product.productName}_Default_Default_Default/${this.currRoom}_${product.productName}_${currPart}_Default_Default_Default0000.webp`
                // let path = `https://opusassets.s3.ap-south-1.amazonaws.com/` + suffix
                let defaultMatpath = `https://opusassets.s3.ap-south-1.amazonaws.com/` + defaultMaterialSuffix
                // let sprite:any = await this.addSprite(path)
                let sprite:any =  await this.addSprite(defaultMatpath)
                if(sprite?.isSprite){
                    sprite.name = categoryName
                }
                }
              }
        } catch (error) {
                console.log(error)
        }

        
      }


    setCurrSelectedSprite(currCat:string){
        try {
            if(this.isEnabled){
                this.currSelectedSprite = this.sprites.find(currSprite => currSprite.name === currCat)
                this.currSelectedCategory = currCat
                showGlobalToast(`${currCat} Selected`,2000)
            } 
        } catch (error) {
            
        }
        
    }
      
    async addBackgroundFiller() {
        let src = `https://opusassets.s3.ap-south-1.amazonaws.com/public/sprites/assets/living-room-with-tv-sofas-blurred.jpg`
        this.addSprite(src)
    }

    async loadBackground() {
        let suffix = `sprites/${this.currRoom}/Background/Background/${this.currRoom}_Background0000.webp`
        let src = applicationConfig.aws_public_url + suffix
        return await this.addSprite(src)
        
    }

    updateCameraAndControls(object:any) {
        // Calculate the bounding box of the scene
        let boundingBox = new Box3().setFromObject(object);
        let center = boundingBox.getCenter(new Vector3());
        let size = boundingBox.getSize(new Vector3());
    
        // Calculate the max dimension of the bounding box
        let maxDim = Math.max(size.x, size.y, size.z);
    
        // Calculate the distance the camera needs to be to fit the entire scene
        let fov = camera.fov * (Math.PI / 180); // Convert vertical FOV to radians
        let cameraDistance = Math.abs(maxDim / 2 / Math.tan(fov / 2));
    
        // Position the camera to fit the entire scene
        camera.position.set(center.x, center.y, cameraDistance);
        camera.lookAt(center);
    
        // Update the controls to look at the center of the scene
        controls.target.set(center.x, center.y, center.z);
        controls.update();
    }
    

    updateColor(data:any){
        if(this.currSelectedSprite){
            this.currSelectedSprite.material.transparent = true;

            // Set the desired opacity level (0 to 1)
            this.currSelectedSprite.material.opacity = 0.85; // 50% transparent
            let color:any = `0x${data.hex_code}`
            this.currSelectedSprite.material.color.setHex(color)
            logger?.info("customizein3d","U-color")
        }else{
            showGlobalToast("Please select a product",2000,"error")
        }    
    }

    resetColor(){
        for (const sprite of this.sprites) {
            sprite.material.color.setHex(0xffffff);
        }
    }
}   

class Configuration{

    productsList = []
  
    constructor(){
  
    }
    addProduct(productInfo:any){
      let product = new ProductInstance(productInfo)
      this.productsList.push(product)
      return product
    }
    updateConfig(productInstance:ProductInstance,partName:string,texture:any){
      productInstance.configuration[partName] = texture
    }
  }