import $ from "jquery"
import { Box3, Group, Plane, Vector3 } from "three"
import { categoryMeshMapping } from "../../data"
import { floor } from "../enviornment"
import { isPointerDownRotationHandler } from "./actions"
import { IS_SPACE_PLANNER_MODE, addonsGroup, camera, controls, dimensions, dragging, element, floorplanner, group, renderer } from "./customizein3d"
import { getConvertedLengthBreadth, getFormatedFeetInchDimensions, getObjectDimensionPosition } from "./helper"
import { CURR_SELECTED_MODULE, deselectModule, raycaster, removeBoxHelper, rendererDimensions, updateBoxHelper } from "./raycasting"
import { toScreenPosition, updateGroupCenter } from "../common"
import { pointerUp } from "../../image-viewer/imageviewer"
import { convert3dPointsToFeet } from "../floorplanner/helper"
import { isTouchDevice } from "../../customizer/helper"
import { disableDragging } from "./dragControls"
import { waitFor } from "../../methods"
import { STORE } from "../../store/storeConfiguration"

const vector = new Vector3()
const boundingBox = new Box3()
var floorPositionDimensions = null
let center = new Vector3()


var canvas = null
var floorDimElements = []
var floorDimensionsAnimationId = undefined
var moduleAnnotationAnimationId = undefined
var planeForRaycasting = new Plane(new Vector3(0, 1, 0), 0)
var target = new Vector3()

export var isPointerDownModulesDragIcon = false

let annotation = null

export function updateFloorDimensionsPosition() {
  
  if(IS_SPACE_PLANNER_MODE){
    canvas = renderer?.domElement
    let walls = floorplanner.walls
    if(walls.length){
      for (const currWall of walls) {
          let currElement = currWall.dimensionElement
          if(currElement){
            let wallTranform = getObjectDimensionPosition(boundingBox,currWall.mesh)
            let vertex1 = currWall.startVertex.position
            let vertex2 = currWall.endVertex.position
  
            // var distance = vertex1.distanceTo(vertex2);
            // let dimData = getConvertedLengthBreadth(distance,distance,"feet")
            let dimData = convert3dPointsToFeet(vertex1,vertex2)
            
            
            vector?.set(wallTranform.center.x,wallTranform.center.y,wallTranform.center.z)
            vector?.project(camera)
            vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
            vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))
            currElement.css("top",`${vector?.y}px`)
            currElement.css("left",`${vector?.x}px`)
            currElement.text(`${String(dimData.feet)}' ${String(dimData.inch)}"`)
          }
      }
    }
    
  }
  // animateFloorDimensions()
}



export function updateFloorDimensionsPositionOld() {
  return

  floorPositionDimensions = getObjectDimensionPosition(boundingBox,floor)
  let breadth = floorPositionDimensions.dimensions.dimX
  let length = floorPositionDimensions.dimensions.dimZ

  let dimData = getConvertedLengthBreadth(length,breadth,"inch")
  breadth = getFormatedFeetInchDimensions(Number(dimData.breadth))
  length = getFormatedFeetInchDimensions(Number(dimData.length))

  let topPositions = {
    x:floorPositionDimensions.positions.min.x + (floorPositionDimensions.dimensions.dimX/2) ,
    y:0,
    z:floorPositionDimensions.positions.min.z  
  }
  let rightPositions = {
    x:floorPositionDimensions.positions.max.x,
    y:0,
    z:floorPositionDimensions.positions.min.z + (floorPositionDimensions.dimensions.dimZ/2)   
  }
  let bottomPositions = {
    x:floorPositionDimensions.positions.min.x + (floorPositionDimensions.dimensions.dimX/2),
    y:0,
    z:floorPositionDimensions.positions.max.z
  }
  let leftPositions = {
    x:floorPositionDimensions.positions.min.x,
    y:0,
    z:floorPositionDimensions.positions.min.z + (floorPositionDimensions.dimensions.dimZ/2) 
  }

  let topElement:any = document.querySelector(`#floorTopDim`)
  let bottomElement:any = document.querySelector(`#floorBottomDim`)
  let leftElement:any = document.querySelector(`#floorLeftDim`)
  let rightElement:any = document.querySelector(`#floorRightDim`)

  canvas = renderer?.domElement

  floorDimElements = [
    {
      element:topElement,
      pos:topPositions
    },
    {
      element:bottomElement,
      pos:bottomPositions
    },
    {
      element:leftElement,
      pos:leftPositions
    },
    {
      element:rightElement,
      pos:rightPositions
    }
  ]

  for (const currElement of floorDimElements) {
    if(camera){
      vector?.set(currElement.pos.x,currElement.pos.y,currElement.pos.z)
      vector?.project(camera)
      vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
      vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))
      currElement.element.style.top = `${vector?.y}px`
      currElement.element.style.left = `${vector?.x + 15}px`
    }
    
  }

  animateFloorDimensions()
}


export function updateAnnotationsPosition() {
  if (!CURR_SELECTED_MODULE) {
    cancelmoduleAnnotationAnimation();
    return;
  }

  let object = CURR_SELECTED_MODULE;

  let meshName = categoryMeshMapping["Sofa"];
  if (meshName) {
    object?.children.forEach((currObject) => {
      if (currObject.isMesh) {
        if (currObject.name.includes(meshName)) {
          object = currObject;
        }
      }
    });
  }

  let box = getObjectDimensionPosition(null, object);
  let data = box.positions;

  let annotation: any = document.querySelector(`#productAnnotationWrapper`);

  let xOffset = 0
  let yOffset = 40
  if(isTouchDevice()){
    let width = $(annotation).width()
    xOffset = width
    yOffset = 80
  }

  canvas = renderer?.domElement;
  if (camera) {
    vector?.set(data.max.x, data.max.y, data.max.z);
    toScreenPosition(vector, camera, canvas);

    annotation.style.top = `${vector?.y - yOffset}px`;
    annotation.style.left = `${vector?.x - xOffset}px`;
    animateModuleAnnotation();
  } else {
    cancelmoduleAnnotationAnimation();
  }
}




export function updateDragIconPosition(group:any,renderer:any,camera:any,groupsArray:Array<Group> = [group,addonsGroup]){
  if(IS_SPACE_PLANNER_MODE){
    if(!annotation){
      annotation = document.querySelector(`#moduelsDragIcon`)
    }
    if(group){
      if(groupsArray){
        const combinedBoundingBox = new Box3();
        groupsArray.forEach(group => {
          combinedBoundingBox.union(new Box3().setFromObject(group));
        });
        center = combinedBoundingBox.getCenter(center);
      }else{
        center = getObjectDimensionPosition(boundingBox,group).center
      }
    
      const canvas = renderer?.domElement
    
      vector?.set(center.x,center.y,center.z)
      vector?.project(camera)
    
      vector.x = Math.round((0.5 + vector?.x / 2) * (canvas.width))
      vector.y = Math.round((0.5 - vector?.y / 2) * (canvas.height))
    
      annotation.style.top = `${vector?.y - $(`#moduelsDragIcon`).height() / 2 }px`
      annotation.style.left = `${vector?.x - $(`#moduelsDragIcon`).width() / 2}px`

    }
  }
}
 

export async function pointerDownDragIcon(event:any){
  event.preventDefault()
  event.stopPropagation()
  deselectModule()
  await waitFor(50)
  updateGroupCenter(group)
  // dragging.pointerUp(event)
  isPointerDownModulesDragIcon = true
  dimensions.hideDimensions()


  // dimensionGrp = getObjectByName(scene,DIMENSIONS_NAME)
  // controls?.enabled = false
  // scene.remove(dimensionGrp)

}

export function pointerMoveDragIcon(event:any,group:any,element:any,camera:any){
  if (event.cancelable) {
    event.preventDefault();
  }
  if(isPointerDownModulesDragIcon){
    let y = event.clientY || event.touches[0].clientY 
    let x = event.clientX || event.touches[0].clientX 

    $(`#moduelsDragIcon`).css("top",`${y - $(".main-header-wrapper").height() - $(`#moduelsDragIcon`).height() / 2}px`) 
    $(`#moduelsDragIcon`).css("left",`${x - $(`#moduelsDragIcon`).width() / 2}px`) 


    let position = getClicked3DPoint(x,y,vector,element,camera)
    group.position.set(position.x,group.position.y,position.z)
    updateBoxHelper()
  }
}

export function pointerUpDragIcon(event:any){
  isPointerDownModulesDragIcon = false
  pointerUp(event)
  // if(STORE.getCurrCategory() === "Wardrobe" && IS_SPACE_PLANNER_MODE){
  //   detachAllLoftForUpdateDimensions()
  // }
}


function animateFloorDimensions() {
  try {
    floorDimensionsAnimationId = requestAnimationFrame( updateFloorDimensionsPosition )
  } catch (error) {
  }
}

export function cancelFloorDimensionsAnimation() {
  cancelAnimationFrame(floorDimensionsAnimationId)
  floorDimensionsAnimationId = undefined
}


function animateModuleAnnotation() {
  try {
    moduleAnnotationAnimationId = requestAnimationFrame( updateAnnotationsPosition )
  } catch (error) {
  }
}

export function cancelmoduleAnnotationAnimation() {
  cancelAnimationFrame(moduleAnnotationAnimationId)
  moduleAnnotationAnimationId = undefined
}


export function getClicked3DPoint(x:number, y:number,mouseVector:any,element:any,camera:any) {

  y = y - element.getBoundingClientRect().top + window.scrollY
  x = x - element.getBoundingClientRect().left + window.scrollX
  renderer.getSize(rendererDimensions)

  x = (x / rendererDimensions.x) * 2 - 1
  y = -(y / rendererDimensions.y) * 2 + 1

  mouseVector?.set(x, y,0.5)
  raycaster.setFromCamera(mouseVector, camera);
  return raycaster.ray.intersectPlane(planeForRaycasting, target);
};

