import $ from "jquery"
import { Euler, Group, MathUtils, Quaternion, Vector3 } from "three"
import { saveToCache } from "../../../cache/cache"
import { camera } from "../../../customizer/ProjectConfiguration"
import { applicationConfig, disposeVariables, waitFor } from "../../../methods"
import { hideComponentLoader, showComponentLoader, showToast, updateLoaderProgress } from "../../../UI_methods/global"
import { makeObjectDraggable } from "../../modules/helper"
import { camera as sceneCamera, controls, customizerConfig, orthoCamera, perspectiveCamera, projectConfiguration, renderer, scene, undo } from "../area3dModel"
import { areaWallsBoundingBoxMapping } from "../walls"
import { addPulseAnimation,  enableAnchorDrag, getAnchorElement, hideAllAreaAnchors, mouseDownAnchor, removePulseAnimation, setCurrSelectedAnchor, setMousePoints, showAllAreaAnchors, updateAllAreaAnchorsPosition, updateAnchorPosition, updateAreaNameWithClippingValue, updateCameraTransform, updateSceneCameraFromAnchorValues } from "./floorplanUI"
import { addAnchorFor3dRender, cameraFor2dRender, loadSavedCamera, resetCameraFor2dRender, rotateCameraIconFromCameraControls, saveRenderCamera, set2dCameraForRender, setCameraFor2dRender } from "./render2d"
import { camera as cameraClass } from "../../../customizer/ProjectConfiguration"
import { isInteriorVisualization, updateInteriorCameraView } from "../interior_visualization"
import { updateUndoVisibility } from "../UI_methods"
import { disableRotation, enableRotation } from "../../controls"

var axis = new Vector3(0,1,0)

export var rendersCamerasGroup = new Group()
// export var cameraFor2dRender = null
export var isPointerDownCone = false
var startX,startY,endX,endY = 0
var posX,posY,posZ = 0

// export var renderCamerasFor3d = []

export var areaCameraMapping = {}
var currCameraCount = 1

 
export function init() {
    controls.enabled = false
    hideCameraHeightSlider()
}

 

export async function takeSceneScreeShot(currCamera:camera) {
    let blobUrl = renderer.domElement.toDataURL('image/jpeg') 
    var bufferView = new Buffer(blobUrl.split(",")[1], 'base64');
    let key = currCamera.cameraName + ".png"
    await saveToCache(key,bufferView).then(message=>{
      console.log(message)
      disposeVariables([bufferView])
    }).catch(err=>{
    })
}

export async function updateCameraScreenshot(areaName:string) {
    let cameraObj = projectConfiguration.getCameraFromAreaName(areaName)
    if(cameraObj){
        showComponentLoader("canvasLoader")
        updateLoaderProgress("canvasLoader",1,1,"Updating screenshot..")
        gotoCameraView(cameraObj)
        await waitFor(200)
        updateCameraTransform(cameraObj.areaName)
        await takeSceneScreeShot(cameraObj)
        hideComponentLoader("canvasLoader")
    }
    
}



export function gotoCameraView(cameraObj:camera) {
    if(cameraObj){
        // #change
        //Change to camera name
        let position = projectConfiguration.projectConfiguration[cameraObj.areaName]?.anchor  
        if(position){
            sceneCamera.position.set(position.x,position.y,position.z)
            const quaternion = new Quaternion(0,0,0,1);
            sceneCamera.applyQuaternion(quaternion); 
            sceneCamera.quaternion.normalize(); 
        }else{
            console.log("camera pos not found")
        }
    }
}

export function removeRenderCamerasFromScene() {
    scene.remove(rendersCamerasGroup)
}

export async function addCamerasFromConfiguration(camerasList:any) {
    for (const currCamera of camerasList) {
        addAnchorFor3dRender(currCamera)
        updateAnchorPosition(currCamera?.cameraId,currCamera?.transform.position)
    }
    removePulseAnimation()
    setCameraFor2dRender(null)
}

export function updateAnchorRotation(anchor:any,rotation:any) {
    anchor.css("transform",`rotate(${rotation})`)
}

export function updateCameraRotation(currCamera:camera) {
    // var rotation = new Euler().setFromQuaternion( new Quaternion(quaternion._x || quaternion.x,quaternion._y || quaternion.y,quaternion._z || quaternion.z,quaternion._w || quaternion.w),"XYZ");
    
    perspectiveCamera.rotation.set(0,0,0)
    perspectiveCamera.rotateOnWorldAxis(axis,currCamera.azimuthAngle + 1.5708) 
    perspectiveCamera.updateProjectionMatrix() 
}

export async function addCamera() {
    $("#initialMessgaeWindow").addClass("display-none")
    addAnchorFor3dRender()
    controls.enabled = true
    showCameraHeightSlider()
}

export function setCameraPositionRotation(position:Vector3) {
    perspectiveCamera.position.set(position.x,position.y,position.z)
    perspectiveCamera.updateProjectionMatrix()  
}

export function setInitialCameraRotation() {
    perspectiveCamera.rotation.set(0,0,0)
    prevRotationValue = 0
    perspectiveCamera.rotateOnWorldAxis(axis,Number(-90) * (Math.PI / 180)) 
    cameraFor2dRender?.setRotation(0,-Math.PI/2)
    perspectiveCamera.updateProjectionMatrix()  
}


export function addPulseAnimationFromCameraId(id:string) {
    removePulseAnimation()
    getAnchorElement(id).addClass("pulse-anchor")
}

export function getAnchorIcon(currCamera:camera) {
    let parentContainer = document.getElementById("area3DSceneWrapper")

    let anchorElement = $("<div></div>").addClass("anchor-icon-container anchor-3d top-middle zindex30").
    attr("id",`areaAnchor${currCamera.cameraId}`)
    .appendTo($(parentContainer))

    $("<div></div>").addClass("anchor-icon")
    .html(`<i class='fas fa-video pointer-none font-large icon'></i>`)
    .mousedown((event)=>{
        setcameraFor2dRender(currCamera)
        onPointerDown(event)
    })
    .appendTo(anchorElement)
    
    $("<i></i>").addClass("fas fa-caret-up pointer-none anchor-cone top-right")
    .appendTo(anchorElement)

    $("<i></i>").addClass("fas fa-arrows-alt-v padding5 alt rotate-icon font-medium top-right")
    .mousemove(onPointerMoveCone)
    .mousedown((event)=>{
        setcameraFor2dRender(currCamera)
        onPointerDownCone(event)
    })
    .mouseup(onPointerUpCone)
    .appendTo(anchorElement)


    $("<div></div>").addClass("heading2 pointer-none anchor-tooltip font-ex-small middle")
    .text(`${String(currCameraCount)}`)
    .appendTo(anchorElement)

    // var box:any = document.getElementById(`areaAnchor${currCamera.cameraId || projectConfiguration.projectName}`)
    // box.setAttribute('data-angle', 0);


    currCameraCount += 1

    return anchorElement
}



export function onPointerDown(event:any) {
    enableAnchorDrag() 
    mouseDownAnchor(event)
    updateSceneCameraFromAnchorValues("")
}

export function onPointerDownCone(event:any) {
    
    isPointerDownCone = true
    if(cameraFor2dRender){
        var box:any = document.getElementById(`areaAnchor${cameraFor2dRender?.cameraId || projectConfiguration.projectName}`)
        if(isInteriorVisualization){
            box = document.getElementById(`areaAnchor${projectConfiguration.projectName}`)
        }
        var rect = box.getBoundingClientRect();
    
        box.setAttribute('data-center-x', rect.left + rect.width / 2);
        box.setAttribute('data-center-y', rect.top + rect.height / 2);
    
        undo.add("rotation",{cameraId:cameraFor2dRender.cameraId,azimuthAngle:controls.azimuthAngle,polarAngle:controls.polarAngle})
    }
}

export function onPointerMoveCone(event:any) {
    if(isPointerDownCone && cameraFor2dRender){
        // var box:any = document.getElementById(`areaAnchor${cameraFor2dRender?.cameraId || projectConfiguration.projectName}`)
        var angle = getDragAngle(event);
        let degree = Math.round((angle * (180 / Math.PI) * -1) + 100);
        if(true){
            let diff = angle + controls.azimuthAngle * MathUtils.DEG2RAD
            // diff = getSnappedAngle(diff*MathUtils.RAD2DEG,90) * MathUtils.DEG2RAD
            controls.rotateAzimuthTo(Number(diff))
            getAnchorElement(cameraFor2dRender?.cameraId).css("transform",`rotateZ(${degree+170}deg)`)
            cameraFor2dRender.setRotation(controls.polarAngle,controls.azimuthAngle)
        }else{
            let anchor = getAnchorElement(cameraFor2dRender?.cameraId) 
            let dataAngle = Number(anchor.attr("data-angle")) || 0
            // let diff = (angle - prevRotationValue)
            let diff = angle + Number(dataAngle)  * MathUtils.DEG2RAD

            // achhor.css("transform",`rotateZ(${diff}deg)`)
            getAnchorElement(cameraFor2dRender?.cameraId).css("transform",`rotateZ(${degree+170}deg)`)
            anchor.attr("data-angle",diff)
            perspectiveCamera.rotation.set(0,0,0)
            perspectiveCamera.rotateOnWorldAxis(axis,Number(diff))
            perspectiveCamera.updateProjectionMatrix()
            cameraFor2dRender?.transform.updateTransform(cameraFor2dRender.transform.position,perspectiveCamera.quaternion.clone(),perspectiveCamera.scale,0)
            prevRotationValue = angle
            let azimuthAngle = angle - Math.PI / 2
            cameraFor2dRender.setRotation(0,azimuthAngle)
        }
    }
}

function snapCameraRotation(event:any) {
    if(cameraFor2dRender){
        var angle = controls.azimuthAngle
        angle = getSnappedAngle(angle*MathUtils.RAD2DEG,90) * MathUtils.DEG2RAD
        controls.rotateAzimuthTo(Number(angle))
        let anchor = getAnchorElement(cameraFor2dRender.cameraId)
        rotateCameraIconFromCameraControls(anchor,controls.azimuthAngle) 
    }
}


export function getSnappedAngle(angle:number,step:number) {
    // angle = Math.abs(angle) % 90
    
    if(90 - (Math.abs(angle) % 90) < 15){
        return Math.round(angle / step) * step
    }
    return angle
    if(angle / 90 < 10){
        console.log(90 - (angle % 90))
        return 
    }
    return angle * MathUtils.DEG2RAD
}
export function onPointerUpCone(event:any) {
    isPointerDownCone = false
    if(cameraFor2dRender){
        var box:any = document.getElementById(`areaAnchor${cameraFor2dRender?.cameraId || projectConfiguration.projectName}`)
        if(box){
            box.setAttribute('data-angle', getDragAngle(event));
        }
        snapCameraRotation(event)
    }
}


export function selectAreaCamera(areaName:string) {
    addPulseAnimation(areaName)
    // cameraFor2dRender = areaName
    let position = projectConfiguration.projectConfiguration[areaName].anchor
    perspectiveCamera.position.set(position.x,position.y,position.z)
    // perspectiveCamera.rotation.set(0,0,0)
    prevRotationValue = 0
    // perspectiveCamera.rotateOnWorldAxis(axis,Number(-180) * (Math.PI / 180))
    perspectiveCamera.updateProjectionMatrix()
    // focusArea(areaName)
}

export function markAsConfirmed() {
    getAnchorElement(cameraFor2dRender?.cameraId).addClass("--is-confirmed")
}


function getDragAngle(event:any) {
    var box:any = document.getElementById(`areaAnchor${cameraFor2dRender?.cameraId || projectConfiguration.projectName}`)
    if(isInteriorVisualization){
        box = document.getElementById(`areaAnchor${projectConfiguration.projectName}`)
    }
    var startAngle = parseFloat(box.getAttribute('data-angle')) || 0;
    var center = {
      x: parseFloat(box.getAttribute('data-center-x')) || 0,
      y: parseFloat(box.getAttribute('data-center-y')) || 0
    };
    var angle = Math.atan2(center.x - event.clientX,center.y - event.clientY);
    return angle
    return angle - startAngle;
  }

 

var prevRotationValue = 0

export function rotationCameraAnchorHandle(event:any) {
    // let r = 1
    let rotation = Number(event.target.value)
    let diff = rotation - prevRotationValue
    //   CURR_SELECTED_PRODUCT.rotateOnWorldAxis(axis,Number(diff) * (Math.PI / 180))
    //   applicationConfig.functions.rangeslider.setDefaultValue(rotation)

 
  
    perspectiveCamera.rotateOnWorldAxis(axis,Number(-diff) * (Math.PI / 180))
    getAnchorElement(cameraFor2dRender).css("transform",`rotateZ(${rotation}deg)`)
    applicationConfig.functions.rangeslider.setDefaultValue(rotation)
    $("#rangeSliderValue").text(String(event.target.value))
    prevRotationValue = rotation
  }



export function updateCameraHeight360(event:any) {
    let positionY = Number(event.target.value)
    positionY = positionY / 100

    perspectiveCamera.position.set(perspectiveCamera.position.x,Number(positionY),perspectiveCamera.position.z)
    perspectiveCamera.updateProjectionMatrix()
    
    // projectConfiguration.updateCameraTransform(cameraFor2dRender,camera)
}

export async function confirmAddCamera() {
    markAsConfirmed()
    removePulseAnimation()
    //check if exist

    let isExists = projectConfiguration.cameras.find(currCamera=>currCamera.cameraId === cameraFor2dRender.cameraId)
    if(!isExists){
        projectConfiguration.appendCamera(cameraFor2dRender)
        undo.add("add",{cameraId:cameraFor2dRender.cameraId})
    }

    updateAreaNameWithClippingValue()
    cameraFor2dRender.transform.updateTransform(controls.getPosition(),perspectiveCamera.quaternion.clone(),perspectiveCamera.scale,0)
    //update camera make an api call 
    await saveRenderCamera(cameraFor2dRender,perspectiveCamera.clone())
    resetCameraFor2dRender()
    setCurrSelectedAnchor(null)
    projectConfiguration.updateLocalStorage()
    controls.enabled = false
    hideCameraHeightSlider()
}

export function removeCamera() {
    removePulseAnimation()
    let currCamera = projectConfiguration.cameras.find(currCamera=>currCamera.cameraId === cameraFor2dRender.cameraId)
    if(currCamera){
        //Back to prevPosition
        loadSavedCamera(currCamera)
        updateAnchorPosition(currCamera.cameraId,currCamera.transform.position)
        console.log("Exists")
    }else{
        getAnchorElement(cameraFor2dRender?.cameraId).remove()
    }
    resetCameraFor2dRender()
    controls.enabled = false
    hideCameraHeightSlider()
}
 






export function focusArea(areaName:string) {
    hideAllAreaAnchors()
    let center = areaWallsBoundingBoxMapping[areaName].boundingBox.center
    let posY = orthoCamera.position.y
    controls.moveTo(center.x,posY+50,center.z,true)
    setTimeout(() => {
        updateAllAreaAnchorsPosition()
        showAllAreaAnchors()
    }, 500);
}
 

export function  setAreaCamera(areaName:string,cameraGroup:any) {
    areaCameraMapping[areaName] = cameraGroup
    
}

  export function setcameraFor2dRender(currCamera:camera) {
    // cameraFor2dRender = currCamera
    setCameraFor2dRender(currCamera)
    addPulseAnimationFromCameraId(String(currCamera.cameraId))
    // setInitialCameraRotation()
    // updateCameraRotation(currCamera.transform.rotation)
}



export function undoHandle3dRender() {
    let item = undo.getLastItem()
    if(!item){
      showToast("Nothing to undo",2000,"error")
      return
    }
    let action = item.action
    let data = item.data

    let anchor =  getAnchorElement(data.cameraId) 
    let currCamera = projectConfiguration.cameras.find(currCamera=>currCamera.cameraId === data.cameraId)

    switch (action) {
        case "add":
            //remove camera from ui and configuration
            anchor.remove()
            projectConfiguration.removeCamera(data.cameraId)
            showToast("Camera deleted",2000)
        break;

        case "position":
            //remove camera from ui and configuration
            if(anchor){
                updateAnchorPosition(data?.cameraId,data?.prevPosition)
            }
            //Update config
            if(currCamera){
                currCamera.transform.updatePosition(data.prevPosition)
                updateInteriorCameraView(null,data.prevPosition,true)  
            }

            showToast("Position updated",2000)
        break;

        case "rotation":
            //remove camera from ui and configuration
            if(anchor){
                rotateCameraIconFromCameraControls(anchor,data.azimuthAngle)
            }
            //Update config
            if(currCamera){
                currCamera.setRotation(0,data.azimuthAngle)
                controls.rotateTo(data.azimuthAngle,0,false)
            }

            showToast("Rotation updated",2000)
        break;
    
        default:
        break;
    }

    undo.removeLastItem()
    updateUndoVisibility()
    projectConfiguration.updateLocalStorage()
}



function hideCameraHeightSlider() {
    $("#rangeSliderForInteriorView").addClass("visibility-hidden")
    $("#cameraMinimapFrame").addClass("block")
}

export function showCameraHeightSlider() {
    
    $("#rangeSliderForInteriorView").removeClass("visibility-hidden")
    $("#cameraMinimapFrame").removeClass("block")
}













 