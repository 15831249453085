import $ from "jquery";
import { MathUtils, Object3D, Quaternion, Vector3 } from "three";
import { showToast } from "../../UI_methods/global";
import { mouseUpCropper } from "../../cropper/cropper";
import { duplicateModulesGroup } from "../../kitchenplanner/groups";
import { removeNearestFillers } from "../../kitchenplanner/helper";
import { isPointerDownKitchenDragIcon, pointerMoveKitchenDrag, pointerUpKitchenDrag } from "../../kitchenplanner/kitchenPlanner";
import { applicationConfig, logger, waitFor } from "../../methods";
import { pointerUpDragIcon } from "../modules/annotations";
import { CURR_SELECTED_PRODUCT, applyRaycastingInAreaModel, raycaster, sceneHoverInteraction, updateInteriorCameraPosition } from "../raycasting";
import { disableRotationMode, updateAttachIconVisibility, updateUndoVisibility } from "./UI_methods";
import { createProductAnnotations, hideDistanceAnnotations, pointerMoveAnnotationDrag, pointerUpAnnotationDrag } from "./annotations";
import { IS_CUSTOMIZABLE_MODE, addModelToScene, area3DSceneWrapper, camera, composer, controls, customizerConfig, deselectProduct, enableMultiselectionMode, groupForRotatingProduct, isKitchenPlannerMode, kitchenPlanner, openMaterialWindow, projectConfiguration, removeProductFromScene, scene, setCurrAreaName, undo } from "./area3dModel";
import { addBoxHelper, isDraggingProduct, removeBoxHelper, updateBoxHelper } from "./dragging";
import { isCameraSetting, isPointerDownOnAnchor, mouseMoveAnchor, mouseUpAnchor, undoHandleFloorplan } from "./floorplan/floorplanUI";
import { cameraFor2dRender, undoHandle2dRender } from "./floorplan/render2d";
import { onPointerMoveCone, onPointerUpCone, undoHandle3dRender } from "./floorplan/render3d_new";
import { addProductToRotationGroup, getOriginalPartName, getProductModelFromProductInstanceName, postAddingActions, removeProductFromRotationGroup } from "./helper";
import { isInteriorVisualization } from "./interior_visualization";
import { multipleSelectMouseDown, multipleSelectMouseMove } from "./multiselect";
import { duplicateModule, isProductConfigureMode, pointerMoveAnnotationDragAction, removeModule } from "./productConfigure";
import { getAreaFromPoint, getAreaFromWallsBoundingBox } from "./walls";

var axis = new Vector3(0,1,0)
var verticalAxis = new Vector3(1,0,0)


var mouseX = 0,mouseY = 0;
export var posStartX = 0
export var posEndX = 0

export var posStartY = 0
export var posEndY = 0
var touchStartTimeStamp = 0;
var touchEndTimeStamp   = 0;


var isAllowRotate = false

export var pointerDown = false
export var pointerUp = false

var rotateInterval = null

export var isProductRotating = false
export var isAddedToGroup = false

var prevRotation = new Quaternion()

export function rotateProduct() {
    rotate(CURR_SELECTED_PRODUCT,-Math.PI/2)
    updateBoxHelper()
    let delta = CURR_SELECTED_PRODUCT?.userData?.rotationDelta || 0
    CURR_SELECTED_PRODUCT.userData.rotationDelta = delta - 90
    // customizerConfig.updateTransform(CURR_SELECTED_PRODUCT?.userData.productName,CURR_SELECTED_PRODUCT)
}

export function rotate(object:any,value:any) {
    object.rotateOnWorldAxis(axis,value)
    if(CURR_SELECTED_PRODUCT && !isKitchenPlannerMode){
      setCurrAreaName(getAreaFromWallsBoundingBox(CURR_SELECTED_PRODUCT))
      updateCurrSelectedProductConfig()
    }
}


export function rotateCamera() {
  // orthoCamera.rotateOnWorldAxis(axis,-Math.PI/2)
  controls.rotate(Math.PI/2,0,true)
}



export function updateCurrSelectedProductConfig(object:any = CURR_SELECTED_PRODUCT) {
  try {
    if(isKitchenPlannerMode){
       kitchenPlanner.updateKitchenModulesConfiguration()  
       return 
    }
    if(object){
      var target = new Vector3()
      let rotation = new Quaternion()
      object.getWorldPosition(target)
      object.getWorldQuaternion(rotation)
      let configuration = object.userData.configuration
      if(configuration){
        configuration.transform.updateTransform(object.position,rotation,object.scale,object.userData.rotationDelta)
      }else{
        // projectConfiguration.updateProductTransform(productInfo,object,target,rotation,object.scale,object.userData.rotationDelta)
      }
      projectConfiguration.updateLocalStorage()
    }
  } catch (error) {
    console.log(error)
  }

}

export function undoHandle() {
  let mode = undo.mode
  switch (mode.toLowerCase()) {
    case "360":
      undoHandle360()
    break;

    case "2d":
      undoHandle2dRender()
    break;

    case "3d":
      undoHandle3dRender()
    break;

    case "floorplan":
      undoHandleFloorplan()
    break;
  
    default:
      break;
  }
}

export function undoHandle360() {
  let item = undo.getLastItem()
  if(!item){
    showToast("Nothing to undo",2000,"error")
    return
  }
  let action = item.action
  let data = item.data
  let productInstance = null
  let object = null
  switch (action) {  
      case "add":
        removeProduct(data.addedModel,true,false)
        showToast("Deleted modal",2000)
      break;

      case "delete":
        addDeletedModel(data.deletedModel)
        showToast("Added modal",2000)
      break;

      case "duplicate":
        removeProduct(data.addedModel,true,false)
      break;
      case "replace":
        //Delete added model
        removeProduct(data.addedModel,true,false)

        //Add deleted model
        addDeletedModel(data.deletedModel)
        showToast("Replaced modal",2000)
      break;
      case "position":
        let position = data.prevPosition
        object = getProductModelFromProductInstanceName(data.productInstanceName)
        if(object){
          object.position.set(position.x,position.y,position.z)
          let detectedArea = getAreaFromWallsBoundingBox(object)

          productInstance =  projectConfiguration.getProductInstanceFromName(data.productInstanceName)
          if(productInstance){
            productInstance.transform.updatePosition(position)
            projectConfiguration.deleteProductInstance(data.productInstanceName)
            projectConfiguration.getAreaObject(detectedArea).setProductConfig(productInstance,productInstance.originalProductName,productInstance.productName)
          }
        }

        showToast("Updated position",2000)
        
      break;

      case "rotation":
        let prevQuaternion = data.prevRotation
        
        object = getProductModelFromProductInstanceName(data.productInstanceName)
        
        let quaternion = new Quaternion(prevQuaternion._x,prevQuaternion._y,prevQuaternion._z,prevQuaternion._w)
        object.quaternion.copy(quaternion)

        productInstance =  object.userData.configuration
        productInstance.transform.updateRotation(object.quaternion)
        showToast("Updated rotation",2000)

      break;
      case "finish":
        let meshes = data.meshes
        object = getProductModelFromProductInstanceName(data.productInstanceName)
        let categoryName = object.userData.categoryName

        meshes.forEach(currMesh => {
          currMesh.material = data.material
          let partName = getOriginalPartName(categoryName,currMesh.name)
          projectConfiguration.updateFinish(object.userData.originalProductName,object.userData.productInstanceName,partName,data.textureInfo)
        });
        showToast("Updated finish",2000)

      break;
  
      default:
      break;
  }
  undo.removeLastItem()
  updateUndoVisibility()
  createProductAnnotations()
  deselectProduct()
  projectConfiguration.updateLocalStorage()
}

function addDeletedModel(deletedModel:Object3D) {
  addModelToScene(deletedModel)
  let productInstance =  deletedModel.userData.configuration
  let areaName = getAreaFromPoint(productInstance.transform.position)
  let areaConfig = projectConfiguration.getAreaObject(areaName)
  if(areaConfig){
    areaConfig.setProductConfig(productInstance,productInstance.originalProductName,productInstance.productName)
  }
}


export function removeProduct(object:any=CURR_SELECTED_PRODUCT,isDeselect:boolean,isAddToUndo:boolean = true) {
  
    removeBoxHelper()
    if(isKitchenPlannerMode){
      if(isAddToUndo){
        kitchenPlanner.undo.add("delete",{deletedModel:object})
      }
      kitchenPlanner.removeModule(object)
      return
    }
    if(object?.userData.isKitchenGroup){
      kitchenPlanner.removeKitchen()
      return
    }
    if(isProductConfigureMode){
      removeModule(object)
      return
    }
    removeCompleteProduct(object,isDeselect,isAddToUndo)
    if(isDeselect){
      deselectProduct()
    }
  }

  function removeCompleteProduct(object:Object3D,isDeselect:boolean,isAddToUndo:boolean) {
    let detectedArea = getAreaFromWallsBoundingBox(object)
    setCurrAreaName(detectedArea)
    removeProductFromScene(object)
    createProductAnnotations()
    logger?.info("customizer","delete",`Delete product ${object?.userData?.productName}` )
  
    object.userData.configuration = projectConfiguration.getProductInstanceFromName(object.userData.productInstanceName)
    if(isAddToUndo){
      undo.add("delete",{deletedModel:object})
    }
    projectConfiguration.removeProduct(object?.userData?.originalProductName,object?.userData?.productInstanceName)
    projectConfiguration.setAreaIsConfigChanged(detectedArea,true)
    updateUndoVisibility()
  }


 


export function isAllowRotateRotate(evt:any) {
    evt.preventDefault()
    let deltaX = evt.clientX - mouseX,
    deltaY = evt.clientY - mouseY;
    mouseX = evt.clientX;
    mouseY = evt.clientY;
    CURR_SELECTED_PRODUCT?.rotateOnWorldAxis(axis,deltaX)
    // rotateScene(deltaX, deltaY);
}

export function mouseMoveRotate(evt:any) {
    evt.preventDefault()

    mouseX = evt.clientX;
    mouseY = evt.clientY; 
}


  export function onPointerUp(event:any) {
    pointerDown = false
    pointerUp = true
    disableRotation()
    $(".interior-anchor-container").removeClass("display-none")
    // dragging.pointerUp(event)

    if(isDraggingProduct || !projectConfiguration.isDefinedFloorplan){
      return
    }
    if(isPointerDownOnAnchor){
      mouseUpAnchor(event)
      return
    }
    if(isPointerDownKitchenDragIcon){
      pointerUpKitchenDrag(event)
      return
    }
    if(customizerConfig.isFinalizeMode || isInteriorVisualization){
      onPointerUpCone(event)
    }
    if(!isCameraSetting){
      enableControls(controls)
    }else{
      mouseUpCropper()
    }
    pointerUpAnnotationDrag(event)
    pointerUpDragIcon(event)
    // onPointerUpCone(event)
    posEndX = event.pageX
    posEndY = event.pageY
    if(posEndX - posStartX != 0 || posEndY - posStartY != 0){
      return
    }
    touchEndTimeStamp = event.timeStamp;
    let time = touchEndTimeStamp - touchStartTimeStamp
    if(time>=1000 && IS_CUSTOMIZABLE_MODE){
      enableMultiselectionMode()
      showToast("Multiple selection enabled",1500)
    }

    if(!isInteriorVisualization){
      applyRaycastingInAreaModel(event,raycaster,customizerConfig,applicationConfig?.data?.objectMaterialTypes,composer,event.pageX,event.pageY,scene,area3DSceneWrapper,camera,IS_CUSTOMIZABLE_MODE,openMaterialWindow)
    }
    
  }

  export function onPointerDown(event:any) {
    // dragging.pointerDown(event)
    // if(CURR_SELECTED_PRODUCT){
    //   dragging.setObject(CURR_SELECTED_PRODUCT)
    // }
    if(isDraggingProduct || !projectConfiguration.isDefinedFloorplan){
      return
    }
    pointerDown = true
    pointerUp = false

    posStartX = event.pageX
    posStartY = event.pageY

    touchStartTimeStamp = event.timeStamp;

    // setCurrClickedMesh(scene,element,event.pageX,event.pageY,camera)

    multipleSelectMouseDown(event)

 
  }

  export function onDblClick(event:any) {
    if(isDraggingProduct || !projectConfiguration.isDefinedFloorplan){
      return
    }
    if(isInteriorVisualization){
      updateInteriorCameraPosition(raycaster,event.pageX,event.pageY,scene,area3DSceneWrapper,camera)
    }else{
      applyRaycastingInAreaModel(event,raycaster,customizerConfig,applicationConfig?.data?.objectMaterialTypes,composer,event.pageX,event.pageY,scene,area3DSceneWrapper,camera,IS_CUSTOMIZABLE_MODE,openMaterialWindow,"all")
    }
  }

  export function onMouseMove(event:any) {
    // dragging.pointerMove(event)
    if(isDraggingProduct || !projectConfiguration.isDefinedFloorplan){
      return
    }
    try {
      if(customizerConfig.isFinalizeMode){
        onPointerMoveCone(event)
      }
      if(isPointerDownOnAnchor){
        mouseMoveAnchor(event)
        return
      }
      if(isPointerDownKitchenDragIcon){
        pointerMoveKitchenDrag(event)
        return
      }
      pointerMoveAnnotationDrag(event)
      multipleSelectMouseMove(event)
      sceneHoverInteraction(raycaster,event.pageX,event.pageY,scene,area3DSceneWrapper,camera)
      pointerMoveAnnotationDragAction(event)
    } catch (error) {
      console.log(error)
    }
   
  }

  export function enableRotation() {
    isAllowRotate = true
  }

  export function disableRotation() {
    isAllowRotate = false
  }


  export async function duplicateProduct() {
    try {
      if(isKitchenPlannerMode){
        if(CURR_SELECTED_PRODUCT?.userData.isModulesGroup){
          duplicateModulesGroup()
        }else{
          kitchenPlanner.duplicateModule(CURR_SELECTED_PRODUCT)
        }
        return
      }
      if(isProductConfigureMode){
        duplicateModule()
        return
      }
      let model = CURR_SELECTED_PRODUCT
      if(CURR_SELECTED_PRODUCT?.userData.isProductModular){
        model = CURR_SELECTED_PRODUCT?.children[0]
      }
      let detectedArea = getAreaFromWallsBoundingBox(model)           
      if(detectedArea){
        setCurrAreaName(detectedArea)
        let clonedObject = CURR_SELECTED_PRODUCT?.clone()
        let productInstance = clonedObject.userData.configuration
        clonedObject.userData.isAttachedToWall = false
        addModelToScene(clonedObject)
        setDuplicateProductPosition(clonedObject)
        addModelToScene(clonedObject)
        postAddingActions(clonedObject)
        let clonnedProductInstance = projectConfiguration.addProduct(productInstance,clonedObject,detectedArea)
        clonnedProductInstance.configuration = productInstance.configuration
        updateCurrSelectedProductConfig(clonedObject)
        undo.add("duplicate",{addedModel:clonedObject})
        projectConfiguration.setAreaIsConfigChanged(detectedArea,true)
        updateAttachIconVisibility()
        deselectProduct()
      }
    } catch (error) {
      console.log(error)
      showToast(error,2000,"error")
    }
    
  }



  export function setDuplicateProductPosition(clonedObject:Object3D) {
    let delta = 0.5
    let worldDirection = new Vector3()
    if(clonedObject.userData.isProductModular){
      let modulesList = clonedObject.children
      for (let i = 0; i < modulesList.length; i++) {
        const clonedModule = modulesList[i];
        clonedModule.getWorldDirection(worldDirection)
        let position = clonedObject.children[i].position
        let finalPosition = position.add(worldDirection.multiplyScalar(delta))
        clonedModule.position.set(finalPosition.x,finalPosition.y,finalPosition.z)
        clonedObject.add(clonedModule)
      }
      return
    }
    clonedObject.getWorldDirection(worldDirection)
    let position = clonedObject.position
    let finalPosition = position.add(worldDirection.multiplyScalar(delta))
    clonedObject.position.set(finalPosition.x,finalPosition.y,finalPosition.z)
  }



  var prevRotationValue = 0
  const snapDelta = 25
  // var rotationSnaps = [0,90,180,-90,-180]
  var rotationSnaps = [0,45,-45,135,-135,90,180,-90,-180]

  export function startRotation() {
    prevRotationValue = 0
    CURR_SELECTED_PRODUCT.getWorldQuaternion(prevRotation)
    isProductRotating = true
   
    removeBoxHelper()
    if(isKitchenPlannerMode){
      removeNearestFillers(CURR_SELECTED_PRODUCT)
    }
  }



  export async function endRotation(rotation:number) {
    disableRotationMode()
    if(CURR_SELECTED_PRODUCT){
      isAddedToGroup = false
      isProductRotating = false
      snapRotation(rotation)
      await waitFor(200)
      removeProductFromRotationGroup(CURR_SELECTED_PRODUCT,CURR_SELECTED_PRODUCT?.userData.groupForRotation,scene)
      addBoxHelper(CURR_SELECTED_PRODUCT)

      if(!isProductConfigureMode || !isKitchenPlannerMode){
        updateCurrSelectedProductConfig()
        undo.add("rotation",{prevRotation:prevRotation,productInstanceName:CURR_SELECTED_PRODUCT?.userData.productInstanceName})
        let detectedArea = getAreaFromWallsBoundingBox(CURR_SELECTED_PRODUCT)
        projectConfiguration.setAreaIsConfigChanged(detectedArea,true)
      }
      if(isKitchenPlannerMode){
        kitchenPlanner.clamp.resetClampObjectParameters()
        kitchenPlanner.undo.add("rotation",{prevRotation:prevRotation,objectId:CURR_SELECTED_PRODUCT?.uuid})
      }
    }

    
    // hideRotationSlider()
  }



  function snapRotation(rotation:number) {
    if(customizerConfig.isSnap){
      const objectCurrRotation =  MathUtils.radToDeg(groupForRotatingProduct.rotation.y)
      for (const snap of rotationSnaps) {
        let diff = snap - objectCurrRotation
        if(diff > -snapDelta && diff < snapDelta){
          groupForRotatingProduct.rotation.y = MathUtils.degToRad(snap) 
          // groupForRotatingProduct.rotateOnWorldAxis(axis,Number(diff) * (Math.PI / 180))
        }
      }
    }
  }


  export function rotationRangeSliderHandle(currValueInDegree:any) {
    if(typeof currValueInDegree !== "number"){
      currValueInDegree = currValueInDegree.detail.value
    }
    if(CURR_SELECTED_PRODUCT){
      if(!isAddedToGroup){
        addProductToRotationGroup(CURR_SELECTED_PRODUCT,CURR_SELECTED_PRODUCT?.userData.groupForRotation)
        isAddedToGroup = true
      }
      let model = groupForRotatingProduct
      let diff = currValueInDegree - prevRotationValue
      // model.rotateOnWorldAxis(axis,- Number(diff) * (Math.PI / 180))
      prevRotationValue = currValueInDegree

      model.rotation.y = MathUtils.degToRad(currValueInDegree)
      hideDistanceAnnotations()
    }
  }

  export function rotationRangeSliderHandleOld(event:any) {
    if(CURR_SELECTED_PRODUCT){
      let model = groupForRotatingProduct
      let rotation = Number(event.target.value)

      let diff = rotation - prevRotationValue

      model.rotateOnWorldAxis(axis,Number(diff) * (Math.PI / 180))
      prevRotationValue = rotation
      applicationConfig.functions.rangeslider.setDefaultValue(rotation)
      $("#rangeSliderValue").text(String(event.target.value))

      hideDistanceAnnotations()
    }
  }

  export function rotationProductByValue(deg:number) {
    if(CURR_SELECTED_PRODUCT ){
        let model = groupForRotatingProduct
        model.rotation.set(0,MathUtils.degToRad(deg),0)
        updateBoxHelper()
        // $("#rangeSliderValue").text(String(event.target.value))
    }
  }

  export function rotationRangeSliderHandleForCamera(event:any) {
      // let r = 1
      let rotation = Number(event.target.value)
      let diff = rotation - prevRotationValue
      // camera.rotateOnWorldAxis(axis,Number(diff) * (Math.PI / 180))
      camera.rotateOnWorldAxis(axis,Number(diff) * (Math.PI / 180))
      // prevRotationValue = rotation
      camera.updateProjectionMatrix()
      $("#cameraRotationX").val(String(camera.quaternion.x))
      $("#cameraRotationY").val(String(camera.quaternion.y))
      $("#cameraRotationZ").val(String(camera.quaternion.z))
      $("#cameraRotationW").val(String(camera.quaternion.w))

  }



  export function resetRotationSlider() {
    prevRotationValue = 0
    applicationConfig.functions.rangeslider.setDefaultValue(0)
  }



  export function enableControls(controls:any) {
    controls.enablePan = true
    controls.enabled = true
  }

  export function disableControls(controls:any) {
    controls.enablePan = false
    controls.enabled = false
  }

  export function rotateCameraUp() {
    stopCameraRotateInterval()
    rotateInterval = setInterval(()=>{rotateRenderCamera("up",0.01)},5) 
  }

  export function rotateCameraDown() {
    stopCameraRotateInterval()
    rotateInterval = setInterval(()=>{rotateRenderCamera("down",0.01)},5) 
  }

  export function rotateCameraLeft() {
    stopCameraRotateInterval()
    rotateInterval = setInterval(()=>{rotateRenderCamera("left",0.01)},5) 
  }

  export function rotateCameraRight() {
    stopCameraRotateInterval()
    rotateInterval = setInterval(()=>{rotateRenderCamera("right",0.01)},5) 
  }

  export function stopCameraRotateInterval() {
    clearInterval(rotateInterval)
  }

  var startX = 0
  var startY = 0

  var isMouseDown = false
  export function rotateCameraPointerDown(event:any) {
    isMouseDown = true
    startX = event.pageX
    startY = event.pageY
  }

  export function rotateCameraPointerMove(event:any) {
    if(isMouseDown){
  
      let diffX = event.pageX - startX
      let diffY = event.pageY - startY

      startX = event.pageX
      startY = event.pageY
  
      camera.rotateOnAxis(axis,Number(diffX) * (Math.PI / 180))
      if(customizerConfig.currentRenderingType === "2d"){
        camera.rotateOnWorldAxis(verticalAxis,Number(diffY) * (Math.PI / 180))
      }
    }
  }

  export function rotateCameraPointerUp(event:any) {
    isMouseDown = false 
  }

  let prevTruckValue = 0

  export function updateHeightUsingTruck(event:any) {
    let currValue = Number(event.target.value)
    if(prevTruckValue === 0){
      prevTruckValue = currValue
    }
    // let diff = currValue - prevTruckValue
    // controls.truck(0,-diff/100,0)
    let position = controls.getPosition()
    let target = controls.getTarget()
    let posY = currValue
    controls.setLookAt(position.x,posY/100,position.z,target.x,posY/100,target.z,false)
    cameraFor2dRender?.transform.updateTransform(controls.getPosition(),cameraFor2dRender?.transform.rotation,cameraFor2dRender?.transform.scale,0)
    prevTruckValue = currValue
  }


  export function resetTruckValue() {
    prevTruckValue = 0
  }

  export function rotateRenderCamera(direction:string,rotateBy:number) {
    let position = controls.getPosition()
    let posY = position.y
    let posX = position.x
    switch (direction) {
      case "up":
        // camera.rotateOnAxis(verticalAxis,Number(-rotateBy) * (Math.PI / 180))
        posY = posY + rotateBy
        controls.truck(0,-rotateBy,0)
        // controls.setLookAt(position.x,posY,position   .z,position.x,posY,position.z,false)
      break;

      case "down":
        // camera.rotateOnAxis(verticalAxis,Number(rotateBy) * (Math.PI / 180))
        posY = posY - rotateBy
        // controls.truck(position.x,posY,position.z)
        controls.truck(0,rotateBy,0)

      break;

      case "left":
        // camera.rotateOnWorldAxis(axis,Number(rotateBy) * (Math.PI / 180))
        posX = posX - rotateBy
        // controls.truck(posX,position.y,position.z)
        controls.truck(-rotateBy,0,0)

      break;

      case "right":
        // camera.rotateOnWorldAxis(axis,Number(-rotateBy) * (Math.PI / 180))
        posX = posX + rotateBy
        // controls.truck(posX,position.y,position.z)
        controls.truck(rotateBy,0,0)

      break;
      default:
      break;
    }
    camera.updateProjectionMatrix()
  }