import { getObjectByParameter } from "../../methods"
import { getRandomNumber } from "../../products/productviewer_shopify"
import { CRM } from "./crmConfiguration"

export class ActivityEvent{
    id:number = getRandomNumber(1,1000)
    activityTypeId = 0
    title:string = ""
    allDay:boolean = true
    start:Date
    end:Date
    timestamp:number
    
    constructor(start:Date,eventName:string){
        this.timestamp = start?.getTime()
        this.start = start
        this.end = this.start
        this.title = eventName
        this.activityTypeId = getObjectByParameter(CRM.data.activityTypes,"activity_type",eventName)?.id
    }
    

    updateActivityType(type:string){
        this.title = type
        this.activityTypeId = getObjectByParameter(CRM.data.activityTypes,"activity_type",this.title)?.id
    }
}


export function getTodayDateObjectOnlyDate() {
    let todayDate = new Date()
    let year = todayDate.getFullYear()
    let month = todayDate.getMonth() + 1
    let date = todayDate.getDate()
    return new Date(year, month - 1,date)
}

export function getTodayTimestampOnlyDate() {
    let todayDate = new Date()
    let year = todayDate.getFullYear()
    let month = todayDate.getMonth() + 1
    let date = todayDate.getDate()
    return new Date(year, month - 1,date).getTime()
}


export function getTodayTimestampWithDayDifference(diff:number) {
    // var originalDate = new Date(originalTimestamp * 1000);
    // var newDate = new Date(originalDate.getTime() - (24 * 60 * 60 * 1000));
    // var newTimestamp = Math.floor(newDate.getTime() / 1000);
    // return newTimestamp
    let todayDate = new Date()

    let year = todayDate.getFullYear()
    let month = todayDate.getMonth() + 1
    let date = todayDate.getDate() + diff

    return new Date(year, month - 1,date).getTime()

}


export function getOnlyDateFromTimestamp(todayDate:Date) {
    let year = todayDate.getFullYear()
    let month = todayDate.getMonth() + 1
    let date = todayDate.getDate()
    return new Date(year, month - 1,date)
}