import React, { useEffect, useState } from "react";
import { hideComponentLoader } from "../../../../utils/UI_methods/global";
import { isDeviceIpadPotrait } from "../../../../utils/customizein3d/modules/UI_methods";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../../utils/methods";
import ProductImage from "../../ui-components/elements/ProductImage";
import HorizontalScroll from "../../ui-components/scrollbar/HorizontalScroll";
import Loader from "../main-ui/Loader";
import { loadWardrobeModuleToScene } from "../../../../utils/customizein3d/modules/wardrobe";
import { getProductModuleUrl } from "../../../../utils/customizein3d/modules/helper";
import Button2 from "../../ui-components/buttons/Button2";
import { STORE } from "../../../../utils/store/storeConfiguration";
import ControlsTab from "../../ui-components/tabs/ControlsTab";


const WardrobeModules: React.FC<{
  action:(module:any)=>void
  moduleName:string
  dragStart:(event:any)=>void
  product:any
  parentContainerId:string
}> = (props) => {

  const[isOpenFilters,setIsOpenFilters] = useState(false)
  
  // const[modules,setModules]:any = useState([])
  const[productsList,setProductsList]:any = useState([])
  const[filteredProducts,setFilteredProducts]:any = useState([])
  const[currSubCategory,setCurrSubCategory] = useState("")
  const[subCategories,setSubCategories] = useState([])
  const[product]:any = useState(props.product)
  const[currModuleType,setCurrModuleType] = useState("")

  const[moduleImagesLoader] = useState("ModuleImagesLoader"+props.parentContainerId||"")

  const[tabItems,setTabItems] = useState([])
  const[productModules,setProductModules]:any = useState([])
  const[filteredModules,setFilteredModules]:any = useState([])


  const[currProduct,setCurrProduct] = useState(props.product)

  useEffect(()=>{
    let products = getFilteredArray(applicationConfig.data.allProductsList,"category_name","Wardrobe")
    products = products.filter(currProduct => currProduct.product_name !== "Wardrobe2")
    setProductsList(products)
  },[])

  useEffect(()=>{
    if(productsList.length){
      let items:any = getArrayOfDistinctValues(productsList,"sub_category_name")
      setSubCategories(items)
      setCurrSubCategory(items[0])
      hideComponentLoader(moduleImagesLoader)
    }
  },[productsList])
  

  useEffect(()=>{
    if(currSubCategory){
      setFilteredProducts(getFilteredArray(productsList,"sub_category_name",currSubCategory))
    }
  },[currSubCategory,productsList])

  useEffect(()=>{
    if(productModules.length && currModuleType){
      let modules = productModules.filter(currModule => currModule.module_type === currModuleType)
      setFilteredModules(modules)
    }
  },[currModuleType,productModules])

  
  useEffect(()=>{
    if(currProduct){
      let productId = currProduct.product_id
      let modules = applicationConfig.data.productModules.filter(currModule => currModule.product_id === productId)
      setProductModules(modules)
    }
  },[currProduct])

  useEffect(()=>{
    if(productModules.length){
      let items:any = getArrayOfDistinctValues(productModules,"module_type") || []
      let tabList = []
      for (const currItem of items) {
        tabList.push({tabName:currItem,label:currItem})
      }
      setTabItems(tabList)
      setCurrModuleType(items[0])
    }else{
      setTabItems([])
    }
  },[productModules])



  function HintText(){
    return(
      <div className="hint-text-container controls-tab-content">
        <strong className="hint-text heading2 color-dark">
          No modules available
        </strong>
      </div>
    )
  }

  function SubModuleTypes() {
    return(
        <>
          {subCategories.map((category:any,index:number)=>{
            return(
              <div className={currSubCategory===category?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrSubCategory(category)}>{category}</div>
              </div>
            )
          })}
        </>
    )
  }

  function ProductModules() {
    return(
      <div className="full-height-width overflow-y-scroll" style={{paddingBottom:"7rem"}} >
        <div className="display-flex-row" style={{alignContent:"baseline"}}>
            {filteredModules?.map((module:any,index:number)=>{
              let key = getProductModuleUrl(applicationConfig?.BASE_URL_ASSETS,applicationConfig?.clientName,module.module_name,product?.category_name,module.sub_category_name)
              return(
                <div className={`module-container margin0 padding5 click-effect center-aligned-column ${isDeviceIpadPotrait()?" --is-potrait-mode":""}`} 
                  data-product-name={module.module_name}
                    data-product-category={module.category_name}
                    data-product-subcategory={module.sub_category_name || "Default"}
                    data-sub-module-type={module.sub_module_type || "Default"}
                    data-product-price={module.price || 0}
                    data-product-id={module.module_id}
                    data-image-type="module"
                    
                    onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                    draggable={props.moduleName === "kitchen"?"true":"false"}

                    id={(props.parentContainerId||"module_")+key} 
                    data-module-type={module.module_type} 
                    key={index} 
                    onClick={(event:any)=>{
                    if(props.moduleName === "moodboard"){
                      props.action(event)
                    }else{
                      props.action(module)
                    }
                }}>

                  <div className="white-card shadow-medium">
                  <img 
                    
                    data-product-name={module.module_name}
                    data-product-category={module.category_name}
                    data-product-subcategory={module.sub_category_name || "Default"}
                    data-sub-module-type={module.sub_module_type || "Default"}
                    data-product-price={module.price || 0}
                    data-module-type={module.module_type} 
                    data-product-id={module.module_id}
                    data-image-type="module"
                    
                    onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                    className="module-image zindex1"
                    draggable="false"

                    src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/moduleImages/Wardrobe/${currProduct?.product_name}_${module.module_name}.webp`} 
                    alt="" style={{padding:"0",height:"7rem",objectFit:"contain"}} 
                  />
                  <div className="center-aligned-row text-center font-small module-details pointer-none store-color-primary heading4">{index+1}</div>

                  </div>
                  
                </div>
              )
            })}
      </div>
      </div>
    )

  }

  function ModuleTypes() {
    return(
        <>
          {tabItems.map((currTab:any,i:number)=>{
            return(
              <div onClick={()=>setCurrModuleType(currTab.tabName)} className={`marginleft5 pointer ${currModuleType === currTab.tabName?"bg-color-warning color-white":"border bg-color-light  color-black"}`} key={i} style={{padding:"5px 10px",borderRadius:"5px",whiteSpace:"nowrap",minWidth:"6rem"}}>
                <div className='heading4 text-ellipsis font-small text-center pointer-none' >{currTab.label}</div>
              </div>
            )
          })}
        </>
    )
  }

 
  return (
    <div className="product-modules-wrapper position-relative" id={`${props.parentContainerId}`}>


      {productsList.length?
          <>
      <Loader
        loaderType="spinner"
        className="fade-background-loader --is-active zindex1"
        id={moduleImagesLoader}
        data={{}}  
      />

 
      <div className="full-height product-module-container" id="productModulesWrapper">

     

        {/* <div className="marginTop5 marginBottom5">
            <HorizontalScroll
              itemsClass="sub-categories-container"
              Component={SubModuleTypes}   
              itemsList={subCategories}
              id={`SubModuleTypesCot`}     
            />
        </div> */}

        {currProduct?
         <div className="full-height-width  marginTop5">

          <div className="full-width display-flex-row position-relative space-between">
            <Button2
              configObj={{id:""}}
              action={()=>{setCurrProduct(null)}}
              label={""}
              fontAwesomeIcon={"fas fa-arrow-left color-danger"}
              iconPos="left"
              classNames={"shadow-medium button-small no-border"}
            />
            <div className="center-aligned-column middle heading3">
                {currProduct?.product_name}
            </div>
            <div></div>
          </div>
         

        {productModules.length?
        <div className="full-width marginTop5 border-top">
         {tabItems.length <= 3?
            <ControlsTab
              configObj={applicationConfig}
              tabItems={tabItems}
              currSelectedValue={currModuleType}
              action={setCurrModuleType}
              theme="red"
            />
          :
            <HorizontalScroll
              itemsClass="sub-categories-container"
              Component={ModuleTypes}   
              itemsList={tabItems}
              id={`modulesTypes`}     
            />
          }
        </div>
        :
          <HintText/>
        }
        <ProductModules/>
       </div>
        :
        <div className="full-height-width" >
            <div className="heading3 color-danger marginTop5 text-center">Choose Design</div>
            <div className="display-flex-row full-height  overflow-y-scroll" style={{alignContent:"baseline"}}>
              {filteredProducts?.map((product:any,index:number)=>{
                return(
                  <div style={{marginBottom:"0",width:"50%"}} key={index} className={`module-container padding5 marginTop10 click-effect center-aligned-column ${isDeviceIpadPotrait()?" --is-potrait-mode":""}`} 
                    onClick={(event:any)=>{
                      setCurrProduct(product)
                    }
                    
                  }
                  >
                    <div className="white-card padding5 full-width shadow-medium" style={{minHeight:"5rem"}} >
                      <ProductImage product={product}/>
                      <div className="center-aligned-row font-small text-center module-details pointer-none store-color-primary heading3"> {product.product_name.replace("_78","")}</div>
                    </div>
                  </div>
                )
              })}

            </div>
            
       
        </div>
        
        }

        
        
   
      </div>
      </>
      :

      <div className="full-width fill-available-height center-aligned-column">
        <HintText/>
      </div>
      
      }


    </div>
  );
};

export default WardrobeModules;
