import React, { useEffect, useState } from "react";
import { getAllProductsList } from "../../../../services/api";
import { hideComponentLoader, showGlobalToast, updateDragImagePosition } from "../../../../utils/UI_methods/global";
import { untarAndSaveToCache } from "../../../../utils/cache/cache";
import { isDeviceIpadPotrait } from "../../../../utils/customizein3d/modules/UI_methods";
import { getProductModuleUrl } from "../../../../utils/customizein3d/modules/helper";
import { dragStartProduct, onTouchEndProductDrag, onTouchStartProductDrag } from "../../../../utils/customizein3d/modules/imageDragging";
import { loadWardrobeModuleToScene } from "../../../../utils/customizein3d/modules/wardrobe";
import { isTouchDevice } from "../../../../utils/customizer/helper";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray, getUser, stringWithoutWhiteSpace } from "../../../../utils/methods";
import { addEventListenerOnImages } from "../../../../utils/moodboard/helper";
import ProductImage from "../../ui-components/elements/ProductImage";
import HorizontalScroll from "../../ui-components/scrollbar/HorizontalScroll";
import ControlsTab from "../../ui-components/tabs/ControlsTab";
import Loader from "../main-ui/Loader";


const Addons: React.FC<{
  product:any
  action:(module:any)=>void
  appendProductAddon:(module:any,product:any)=>void
  moduleName:string
  dragStart:(event:any)=>void
  parentContainerId:string
}> = (props) => {

  
  // const[modules,setModules]:any = useState([])
  const[productModules,setProductModules]:any = useState([])
  const[filteredSofaModules,setFilteredSofaModules]:any = useState([])
  const[productsList,setProductsList]:any = useState([])
  const[currModuleType,setCurrModuleType] = useState("")
  const[isDragggable] = useState(props.product.category_name === "Sofa"?true:false)

  const[moduleImagesLoader] = useState("ModuleImagesLoader"+props.parentContainerId||"")


  const[addonsCategories] = useState(getAddonsCate())
  const[currAddonCategory,setCurrAddonCategory] = useState(props.product?.category_name === "Sofa"?"Sofa":"Dresser")

  const[tabItems,setTabItems] = useState([])


  const[allProductsList,setAllProductsList] = useState([])


  function getAddonsCate() {
    let result = ["Sofa","CentreTable"]
    let category = props.product.category_name 
    if(applicationConfig.isMasterLogin()){
      result =  ["Sofa","Dresser","Carpet","Decor","CentreTable","TvUnit",,"Console","Chair","Other","Ottoman","Pillow"]
    }
    if(category === "Wardrobe" || category === "Dresser" || category === "Cot"){
      result = ["Dresser","Cot","Wardrobe","CrockeryUnit","TvUnit","KidsFurniture","PujaUnit"]
    }
    if(category === "Sofa"){
      result = ["Sofa","Carpet","CentreTable","Decor"]
    }
    return result
  }
  

  useEffect(()=>{
    if(props.moduleName.toLowerCase() != "customizein3d"){
      addEventListenerOnImages(".module-image")
    }
    if(productModules.length){
      let items = getArrayOfDistinctValues(productModules,"module_type")
      let tabList = []
      items = items.filter(item=>item !== "Left" && item !== "Centre" && item !== "Corner")
      for (const currItem of items) {
        tabList.push({tabName:currItem,label:currItem})
      }
      setTabItems(tabList)
    }else{
      setTabItems([])
    }
  },[productModules])

  useEffect(()=>{
    if(getUser()?.isShareMode){
      getAllProductsList().then(data=>{
        applicationConfig.data.allProductsList = data
        setAllProductsList(data)
      })
    }else{
      setAllProductsList(applicationConfig?.data.allProductsList)
    }
  },[])

  useEffect(()=>{
    downloadAllAddons()
  },[allProductsList])


  function downloadAllAddons() {
    let productAddonsCategories = ["Carpet","Decor","CentreTable","TvUnit","Console","Chair","Other","Ottoman","Pillow"]
    let products = allProductsList
    let addonsList = products?.filter(currProduct => productAddonsCategories.includes(currProduct.category_name))
  
    for (const currProduct of addonsList) {
      let productName = currProduct.product_name || currProduct.productName
      let categoryName = currProduct.category_name || currProduct.categoryName
      let subCategoryName = currProduct.sub_category_name || currProduct.subCategoryName
      let clientName = applicationConfig?.clientName
      if(currProduct){
        clientName = currProduct?.storefront_name === "OVL" ? "OVL" : applicationConfig?.clientName
      }
      let tarfilekey = applicationConfig.awsConfig.getTarFileKey("productModels",{clientName:clientName,productName:stringWithoutWhiteSpace(productName),categoryName:stringWithoutWhiteSpace(categoryName),subCategoryName:stringWithoutWhiteSpace(subCategoryName)})
      let urlPrifix = `productModels/${clientName}/${categoryName}/${subCategoryName}`
      untarAndSaveToCache(applicationConfig.awsConfig,urlPrifix,stringWithoutWhiteSpace(tarfilekey))
    }
    
  }
  

  useEffect(()=>{
    let filteredArray = []
      if(currAddonCategory === "Sofa"){
        filteredArray = applicationConfig?.data.productModules.filter(currModule => currModule.module_type === "Standalone" || currModule.is_addon)
        let productIdsList = getArrayOfDistinctValues(allProductsList,"product_id")
        filteredArray = filteredArray.filter(currModule => productIdsList.includes(currModule.product_id))
        setProductModules(filteredArray)
        setCurrModuleType(filteredArray[0]?.module_type)
      }else{
        let products = allProductsList
        products = getFilteredArray(products,"category_name",currAddonCategory)
        products = products?.filter(product => !product.product_name.includes("Frame") && !product.product_name.includes("Mirror"))
        setProductsList(products)
        setProductModules([])
      }
  },[props.product,currAddonCategory,allProductsList])

  useEffect(()=>{
    if(productModules.length && currAddonCategory === "Sofa"){
      // let href = window.location.href
      // if(true){
      //   loadModuleImages(moduleImagesLoader,productModules,"Sofa",props.parentContainerId||"module_")
      // }
    }else{
      hideComponentLoader(moduleImagesLoader)
    }
  },[productModules,props.product,currAddonCategory,currModuleType])


  //For updating the tab content 
  useEffect(()=>{
    setTimeout(() => {
      if(currAddonCategory === "Sofa"){
        // filterUIElements(".module-container","data-module-type",currModuleType,props.parentContainerId)
        setFilteredSofaModules(getFilteredArray(productModules,"module_type",currModuleType))
      }
      hideComponentLoader(moduleImagesLoader)
    }, 200);
  },[currModuleType,currAddonCategory,productModules])


 

  function HintText(){
    return(
      <div className="full-width fill-available-height center-aligned-column">
      <div className="hint-text-container controls-tab-content">
        <strong className="hint-text heading2 color-dark">
          No modules available
        </strong>
      </div>
      </div>
     
    )
  }

  
  
  function AddonsCategories() {
    return(
        <>
          {addonsCategories.map((category:any,index:number)=>{
            return(
              <div className={currAddonCategory===category?"marginleft10  marginTop5 bg-color-primary color-white":"marginleft10 marginTop5 border"} key={index} style={{padding:"5px 10px",borderRadius:"20px",whiteSpace:"nowrap"}}>
                <div className='heading4 font-small text-center' onClick={()=>setCurrAddonCategory(category)}>{category}</div>
              </div>
            )
          })}
        </>
    )
  }


 
  return (
    <div className="product-modules-wrapper position-relative" id={`${props.parentContainerId}`}>
      <Loader
        loaderType="spinner"
        className="fade-background-loader --is-active zindex1"
        id={moduleImagesLoader}
        data={{}}  
      />
 
      <div className="full-height product-module-container" id="productAddonsWrapper">
        <div className="marginTop5 marginBottom5">
          <HorizontalScroll
            itemsClass="sub-categories-container"
            Component={AddonsCategories}   
            itemsList={addonsCategories}
            id={`AddAddonsScrollBar`}     
          />
        </div>
        {productModules.length && currAddonCategory === "Sofa"?
          <ControlsTab
            configObj={applicationConfig}
            tabItems={tabItems}
            currSelectedValue={currModuleType}
            action={setCurrModuleType}
            theme="black"
          />
        :
         null
        }
        {currAddonCategory === "Sofa"?
          <div className="display-flex-row full-height product-addons-container  overflow-y-scroll" style={{alignContent:"baseline",paddingBottom:"12rem"}}>
          {filteredSofaModules?.map((module:any,index:number)=>{
          let key = getProductModuleUrl(applicationConfig?.BASE_URL_ASSETS,applicationConfig?.clientName,module.module_name,props.product.category_name,module.sub_category_name)
          return(
          <div className={`module-container click-effect center-aligned-column ${isDeviceIpadPotrait()?" --is-potrait-mode":""}`} 
              data-product-name={module.module_name}
                data-product-category={module.category_name}
                data-product-subcategory={module.sub_category_name || "Default"}
                data-sub-module-type={module.sub_module_type || "Default"}
                data-product-price={module.price || 0}
                data-product-id={module.module_id}
                data-image-type="module"
                
                onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                draggable={props.moduleName === "kitchen"?"true":"false"}

                id={(props.parentContainerId||"module_")+key} 
                data-module-type={module.module_type} 
                key={index} 
                onClick={(event:any)=>{
                if(props.moduleName === "moodboard"){
                  props.action(event)
                }else{
                  props.action(module)
                }
              }}>
                    <img 
                      data-product-name={module.module_name}
                      data-product-category={module.category_name}
                      data-product-subcategory={module.sub_category_name || "Default"}
                      data-sub-module-type={module.sub_module_type || "Default"}
                      data-product-price={module.price || 0}
                      data-module-type={module.module_type} 
                      data-product-id={module.module_id}
                      data-image-type="module"
                      onDragStart={props.moduleName === "kitchen"?props.dragStart:null}
                      className="addon-image  zindex1"
                      draggable="false"

                      src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/AddonsImages/Sofa/${module.module_type}/${module.product_name}_${module.module_type}.webp`}
                      alt="" style={{padding:"1rem",height:"8rem",objectFit:"contain"}} 
                    />
                    <div className="center-aligned-row text-center module-details pointer-none store-color-primary heading3"> {`${module.product_name}`}</div>
                    <div className="center-aligned-row text-center module-details pointer-none heading4 font-small">{module.module_description}</div>
                  </div>
                )
              })}
          </div>
        :

        <div className="display-flex-row full-height  overflow-y-scroll" style={{alignContent:"baseline"}}>
        {productsList?.map((product:any,index:number)=>{
          let module = {
            module_name:product.product_name,
            category_name:product.category_name,
            sub_category_name:product.sub_category_name,
            sub_module_type:product.sub_module_type,
            module_id:product.product_id,
            module_type:"Product",
            price:product.price,
            allow_rotation:1,
            allow_movement:1,
            allow_duplication:1,
            allow_delete:1,
          }
        return(
        <div className={`module-container padding5 center-aligned-column ${isDeviceIpadPotrait()?" --is-potrait-mode":""}`} 
            data-product-name={module.module_name}
              data-product-category={module.category_name}
              data-product-subcategory={module.sub_category_name || "Default"}
              data-sub-module-type={module.sub_module_type || "Default"}
              data-product-price={module.price || 0}
              data-product-id={module.module_id}
              data-storefront={product.storefront_name}
              data-image-type="module"
              
              onDragStart={dragStartProduct}
              draggable={isDragggable}

              onDrag={updateDragImagePosition}

              data-module-type={module.module_type} 
              key={index} 
              onClick={(event:any)=>{
                if(isDragggable){
                  showGlobalToast("Drag and drop")
                }else{
                  loadWardrobeModuleToScene(product)
                }
              }}
            >
              <ProductImage product={product}/>
              {isTouchDevice() && isDragggable?
                <div className="top-right padding5 image-drag-icon-wrapper" 
                    onTouchStart={onTouchStartProductDrag}
                    onTouchEnd={onTouchEndProductDrag}
                    >
                  <div className="image-drag-icon pointer-none">
                    <i className="fas fa-grip-vertical color-dark font-large"></i>
                  </div>
                </div>
              :null}
                  <div className="center-aligned-row text-center marginTop5 module-details pointer-none store-color-primary font-small heading4"> {`${product.product_name}`}</div>
                </div>
              )
            })}

            {!productsList.length?
            <div className="middle heading4">Addons Not Available</div>
            :null}
        </div>
        
        
        
        }
        


      </div>

   


    </div>
  );
};

export default Addons;
