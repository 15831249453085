import React, { useState } from "react";
import { addProduct, downloadProductModuelesInEfs, getProductsList, updateProduct } from "../../../../services/api";
import { hideComponentLoader, showComponentLoader, showGlobalToast } from "../../../../utils/UI_methods/global";
import { applicationConfig, getArrayOfDistinctValues, logger, stringWithoutWhiteSpace } from "../../../../utils/methods";
import { createProductForm } from "../../../../utils/products/createProductForm";
import { updateProductTarFile, updateProductsList, uploadProductImage } from "../../../../utils/products/createproduct";
import Review from "../common/Review";
import ProductDetails from "./ProductDetails";
import { useClearCache } from "react-clear-cache";


const ReviewProduct: React.FC<{
  configObj: any;
  setCurrState: (value: string) => void;
  closeModal: (value: boolean) => void;
  productDetails:createProductForm
  setProductDetails:(value:any)=>void
  isUpdateMode:boolean
}> = (props) => {


    const[productFormLoader] = useState("ProductFormLoader")
 
    const { isLatestVersion, emptyCacheStorage } = useClearCache();
 
    async function saveProductDetails() {
      let product = props.productDetails
      if(props.isUpdateMode){
        if(product?.productModuleFiles){
          setModulesIds()
          await uploadProductModules()
          let moduleFileNamesArray = getArrayOfDistinctValues(props.productDetails.productModuleFiles,"name")
          downloadProductModuelesInEfs(product,moduleFileNamesArray)
          props.productDetails.isUpdateProductModulesMapping = true
          console.log(product.productModuleFiles)
        }
        await updateProductDetails(props.productDetails)
        if(product.galleryImages?.length){
          product.productImageFile = product.galleryImages[0]
          await uploadProductImage(product)
          emptyCacheStorage();
          showGlobalToast("Product Image Updated",2000)
        }
        props.closeModal(false)
        return
      }

      if(!product.productName || !product.categoryId || !product.subCategoryId){
        alert("Please check required fields")
        return
      }
      showComponentLoader("mainLoaderSpinner")
      if(product?.productModuleFiles){
        setModulesIds()
      }

      console.log(applicationConfig?.data?.modulesList,product.moduleIds)
      await addProduct(product).then(async (res)=>{
        console.log(res)
        if(res.data.error){
          props.closeModal(false)
          showGlobalToast(`Internal server error`,2000,"error")
          hideComponentLoader("mainLoaderSpinner")
          return
        }
        showComponentLoader("mainLoaderSpinner")
        props.closeModal(false)
        console.log(res)
        await updateProductsList()
        //Create logs
        // logger?.info("products","createProduct","Created product  "+ JSON.stringify(product))

        if(!product.productImageFile && product.galleryImages.length){
          product.productImageFile = product.galleryImages[0]
        }

        if(product?.productImageFile){
          await uploadProductImage(product)
        }
        if(product?.productModuleFiles){
          await uploadProductModules()
          let moduleFileNamesArray = getArrayOfDistinctValues(props.productDetails.productModuleFiles,"name")
          downloadProductModuelesInEfs(product,moduleFileNamesArray)
        }
        if(product?.product3dModel){
          await uploadProduct3dModel()
        }
        props.closeModal(false)
        showGlobalToast(`Product added successfully`)
        hideComponentLoader("mainLoaderSpinner")
        }).catch(err=>{
          hideComponentLoader("mainLoaderSpinner")
          console.log(err)
           logger.error("products","createProduct","Error in creating product")
        })
    }


    function setModulesIds(){
      let modulesList = props.productDetails.productModuleFiles
      for (const currModule of modulesList) {
        let fileName = currModule.name
        // let moduleName = fileName.substring(fileName.indexOf("_")+1,fileName.lastIndexOf("."))
        let moduleName = fileName.replace(".glb","")
        moduleName = moduleName.replace(props.productDetails.productName+"_","")
        if(moduleName){
          moduleName = moduleName.toLowerCase()
          let module = applicationConfig?.data?.modulesList.find(currModule => stringWithoutWhiteSpace(moduleName)  === stringWithoutWhiteSpace(currModule.module_name.toLowerCase())
          )
          if(module){
            props.productDetails.moduleIds.push(module.module_id)
          }else{
            console.log("Not found "+ moduleName)
          }
        }
      }
      
    }

    async function updateProductDetails(productData:any){
      await updateProduct(productData).then(res=>{
        console.log(res)
      }).catch(err=>{
        console.log(err)
      })
    }




    async function uploadProductModules() {
      let product = props.productDetails
      let modulesList = props.productDetails.productModuleFiles
      let promisesList = []
      let albumName = "models"
      for (const currModule of modulesList) {
        let fileName = currModule.name
        var albumPhotosKey = encodeURIComponent(albumName) + "/" + props.configObj?.clientName + "/" + product.productName + "/"
        promisesList.push(await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,currModule,fileName))  
      }
      await Promise.all(promisesList)
      let prefix = stringWithoutWhiteSpace(`models/${applicationConfig?.clientName}/${product.productName}/`)
      let fileName = stringWithoutWhiteSpace(`${product.productName}.tar.gz`)
      await updateProductTarFile(prefix,fileName)
    }

    async function uploadProduct3dModel() {
      let product = props.productDetails
      let albumName = "productModels"
      let fileName = product.productName + ".glb"
      var albumPhotosKey = encodeURIComponent(albumName) + "/" + props.configObj?.clientName + "/" + product.categoryName + "/" + product.subCategory + "/"
      await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,product.product3dModel,fileName)
      let prefix = stringWithoutWhiteSpace(`productModels/${applicationConfig?.clientName}/${product.categoryName}/${product.subCategory}/`)
      fileName = stringWithoutWhiteSpace(`${product.productName}.tar.gz`)
      await updateProductTarFile(prefix,fileName)


      // https://opusassets.s3.ap-south-1.amazonaws.com/public/models/DemoClient2/Sofa/LShape/ElizabethCorner.glb
      albumName = "public"
      fileName = product.productName + ".glb"
      albumPhotosKey = encodeURIComponent(albumName) + "/models/" + encodeURIComponent(applicationConfig?.clientName) + "/" + encodeURIComponent(product.categoryName) + "/" + encodeURIComponent(product.subCategory) + "/";
      console.log(albumName,albumPhotosKey,fileName)
      await applicationConfig.awsConfig.uploadImageToS3(albumPhotosKey,product.product3dModel,fileName,true)
    }


    function gotoProduct(productData:any) {
      props.configObj.functions.products.setCurrSelectedCategory(productData.categoryName)
      setTimeout(() => {
        props.configObj.functions.products.setProductsList([{product_name:productData.productName,category_name:productData.categoryName,sub_category_name:productData.subCategory}])
      }, 500);
    }

 
 
  return (
    <React.Fragment>
      <Review
        configObj={props.configObj}
        setCurrState={props.setCurrState}
        closeModal={props.closeModal}
        save={saveProductDetails}
        Details={()=>(
          <ProductDetails galleryImages={props.productDetails.galleryImages} productDetails={props.productDetails} />
        )}
        formData={props.productDetails}
        module="product"
      />
    </React.Fragment>
  );
};

export default ReviewProduct;
