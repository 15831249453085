import React from "react";
import Button2 from "../../../../common/ui-components/buttons/Button2";
import $ from "jquery"
import { showGlobalToast } from "../../../../../utils/UI_methods/global";

const CustomerMobileForm: React.FC<{
}> = (props) => {
   

  function hide() {
    $("#mobileInputWindow").addClass("display-none")
    showGlobalToast("Your request has been submitted",2000)
  }
  
  return (
      <>
      <div id="mobileInputWindow" className="full-height-width top-left position-fixed display-none" style={{background:"rgba(0,0,0,0.5)",zIndex:1001}}>
        <div className="middle white-card center-aligned-column" style={{height:"25rem",width:"25rem"}}>
          <div className="ion-padding full-width">
            <div className="center-aligned-column">
              <img src="https://touchplan.io/wp-content/uploads/2021/07/Demo-Company-Logo.jpeg" width={120} alt="" />
            </div>
            <div className="heading2 ion-margin-top text-center font-medium">Enter Your Mobile Number</div>
            <div className="center-aligned-column full-width">
              <input type="number" className="input input-large ion-margin-top" style={{width:"90%"}} />
            </div>
            <div className="center-aligned-column ion-margin-top">
              <Button2
                configObj={{id:""}}
                action={hide}
                label={"Submit Request"}
                fontAwesomeIcon={"fas fa-arrow-right color"}
                iconPos="right"
                classNames={"text-center button-large button-rounded ion-margin-top bg-color-warning"}
              />
            </div>
            
          </div>
          
        </div>
      </div>
      </>
    );
};

export default CustomerMobileForm;
