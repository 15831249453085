import $ from "jquery"
import { Box3, Object3D, Quaternion, Vector3 } from "three"
import { hideComponentLoader, showComponentLoader, showGlobalToast, showToast } from "../../UI_methods/global"
import { applicationConfig, getFilteredArray, getObjectByParameter, waitFor } from "../../methods"
import { STORE } from "../../store/storeConfiguration"
import { updateActionPanelInformation, updateLoftDimensionValue } from "../UImethods"
import { addModuleToConfiguration, detachModule, loadAndApplyTexture, loadAndApplyTextureToMesh, updateGroupCenter } from "../common"
import { GRID } from "../enviornment"
import { MODULE_NAME, addAddonToConfiguration, boundingBox, configuration, delete3dObject, dimensions, disableLoading, fitToModuelsScene, group, postAddToSceneActionSofa, postAddingActions, removeModuleFromConfiguration, saveInitialConfig, scene, setModulePosition } from "./customizein3d"
import { getModelDimensions, getObjectByObjectId, getObjectDimensionPosition, updateOtherModelPositions, updatePositionOfAddon } from "./helper"
import { addProductToScene } from "./imageDragging"
import { CURR_SELECTED_MODULE, deselectModule, removeBoxHelper, selectModule, setCurrSelectedModule } from "./raycasting"



let loftsList = []
let doorsList = []

const axis = new Vector3(0,1,0)


export async function loadWardrobeModuleToScene(defaultProduct:any = null,isAddedAsAddon = undefined) {
    try {
      showComponentLoader("canvasLoader")
      deselectModule()
      let wardrobes = getFilteredArray(applicationConfig.data.allProductsList,"category_name","Wardrobe")
      if(wardrobes.length){
        if(!defaultProduct){
            defaultProduct = STORE?.currProduct || getObjectByParameter(wardrobes,"product_name","Module7")
        }
        if(defaultProduct){
            let mainProductCategory = STORE.getCurrCategory()
            let category = defaultProduct.category_name
            let module = getModuleInfo(defaultProduct)

            if(isAddedAsAddon === undefined){
                isAddedAsAddon = mainProductCategory === "Wardrobe" && (category === "Wardrobe" || category === "Dresser" )?false:true 
            }

            // updateLoaderProgress("canvasLoader",0,1,"Downloading...")
            // await untarAndSaveToCache(applicationConfig.awsConfig,`models/${module.product_name}`,key)
            // updateLoaderProgressWithInterval("canvasLoader","Loading model",70,50)
            addProductToScene(module,defaultProduct).then(async (object:Object3D)=>{
                let isAdded = await postAddToSceneActionSofa(object,module,isAddedAsAddon,true)
                if(category === "Dresser"){
                    object.userData.isAttched = true
                    // setPositionOfDresser(object)
                } 
                if(!isAdded){
                    object.clear()
                    showToast("Invalid Config",2000,"error")
                    // reject("Invalid Config")
                }
                disableLoading()
                // addPointLightsToScene(scene,group,15)
                // addPointLightsToScene(scene,floorplanner.group,10)
                GRID.position.setY(group.position.y - 0.01)
                updateGroupCenter(group)
                saveInitialConfig()
                // applyPrevModelTexture(group,configuration,object)
                copyModelTexture(object,mainProductCategory === category)
                updateLoftsList(object)
                updateAddonPosition(object,defaultProduct.category_name)
                object.userData.isLoftVisible = true
                if(mainProductCategory === "Wardrobe"){
                    hideLoft(object)
                }
              }).catch(err=>{
            })
        }else{
            showGlobalToast("No default wardrobe",2000,"error")
        }
      }else{
        showGlobalToast("No wardrobes",2000,"error")
      }
    
    //   startPageTour() 
    } catch (error) {
      disableLoading()
    }
}

export function postWardrobeAddActions(modelObject:any){
    let mainProductCategory = STORE.getCurrCategory()
    if(mainProductCategory === "Wardrobe"){
        let defaultProduct = STORE?.currProduct
        let category = defaultProduct.category_name
        updateLoftsList(modelObject)
        hideLoft(modelObject)
        setTimeout(() => {
            copyModelTexture(modelObject,mainProductCategory === category)
        }, 500);
    }
}


export async function toggleAttachWardrobeModule() {
    showComponentLoader("changeFinishLoader")
    if(CURR_SELECTED_MODULE){
        let object = CURR_SELECTED_MODULE
        deselectModule()
        let isAttach = object.userData.isAttched
        let module = object.userData.configuration
        removeModuleFromConfiguration(configuration,object)
        detachModule(object)
        module.isAddedAsAddon = !isAttach
        object.userData.isAttched = !isAttach
        if(isAttach){
            updatePositionOfAddon(object,configuration)
            group.attach(object)
            addAddonToConfiguration(module,object,configuration)
            showGlobalToast("Detached Module")
            updateOtherModelPositions(group,configuration,boundingBox,axis,module.subCategoryName,false)
        }else{
            setModulePosition(group,object,module,configuration,"lshape")
            group.attach(object)
            addModuleToConfiguration(module,object,configuration)
            showGlobalToast("Attached Module")
        }
        fitToModuelsScene()
    }
    hideComponentLoader("changeFinishLoader")

}

 

function copyModelTexture(targetObject:any,isCatagorySame:boolean){
    if(group.children.length < 2){
        return
    }


    let object = null

    const isWardrobeActions = STORE.getCurrCategory() === "Wardrobe" && !isLoftAttach(targetObject)

    if(isCatagorySame || true){
        // object = group.children.find(currObject => getCatgoryName(currObject) === getCatgoryName(targetObject))
        object = group.children[0]
        if(object){
            let textureInfo = object.userData.configuration.configuration[0]
            if(textureInfo){
                loadAndApplyTexture(targetObject,textureInfo,configuration.product.productName,MODULE_NAME,getCatgoryName(targetObject),configuration)
            } 

            if(isWardrobeActions){
                let loft = getLoftFromObject(targetObject)
                loft?.traverse((mesh:any) => { 
                  if ( mesh.isMesh) {
                  loadAndApplyTextureToMesh(targetObject,textureInfo,mesh,configuration.product.productName,MODULE_NAME, STORE.getCurrCategory(),configuration).then(data=>{
                    })
                  }
                });
            }
           
        }
    }
}

function getCatgoryName(object:any) {
    return object?.userData?.configuration?.categoryName
}


function getModuleInfo(product:any){
    return {
        module_name:product.product_name,
        category_name:product.category_name,
        sub_category_name:product.sub_category_name,
        sub_module_type:"Default",
        module_id:product.productId,
        storefront_name:product.storefront_name,
        module_type:"Product",
        price:0,
        allow_rotation:1,
        allow_movement:1,
        allow_duplication:1,
        allow_delete:1,
      }
}



function setPositionOfDresser(modelObject:any) {
      //Prev model used when rearranging the models 
      let module = configuration.getLastModelObject(modelObject) 
      let previousModelObj = getObjectByObjectId(scene,module.moduleObjectId)
  
      modelObject.rotation.set(0,0,0,"XYZ")
      
      let rotationOffset = 0
  
      let previousModelObjDimensions:any = getModelDimensions(previousModelObj,new Box3())
  
      let previousModelWidth_X = previousModelObjDimensions.dimX
      let previousModelWidth_Z = previousModelObjDimensions.dimZ
  
      let worldPosition = new Vector3()
      previousModelObj.getWorldPosition(worldPosition)
  
      let positions={
        posX : worldPosition.x, 
        posY : worldPosition.y,
        posZ : worldPosition.z
      }
     
      modelObject.position.copy(new Vector3(positions.posX + (previousModelWidth_X / 2),positions.posY,positions.posZ -  (previousModelWidth_Z / 2)))
      let worldRotation = new Quaternion()
      previousModelObj.getWorldQuaternion(worldRotation)
      modelObject.applyQuaternion(worldRotation)
      modelObject.rotateOnAxis(axis,rotationOffset)
   
}



export function openWardrobeModulePanel(){
    $("#wardrobeModuePanel").fadeIn()
    updateLoftToggleText(CURR_SELECTED_MODULE)
}

export function closeWardrobeModulePanel(){
    $("#wardrobeModuePanel").fadeOut()
}


export function updateLoftToggleText(object:any) {
    if(object){
        let isLoftVisible = object.userData.isLoftVisible
        if(isLoftVisible){
            $("#loftToggleButton").find((".text")).text("Hide Loft")
            $("#loftToggleValue").text("Visible")
        }else{
            $("#loftToggleButton").find((".text")).text("Show Loft")
            $("#loftToggleValue").text("Hidden")
        }
    }
 
}

export async function toggleLoft() {
    let object = CURR_SELECTED_MODULE
    let data = loftsList.find(currData => currData.object.uuid === object?.uuid)
    if(data?.loft){
        showComponentLoader("changeFinishLoader")
        let selectedObject = CURR_SELECTED_MODULE
        let isLoftVisible = CURR_SELECTED_MODULE.userData.isLoftVisible
        let configuration = CURR_SELECTED_MODULE.userData.configuration
        if(isLoftVisible){
            detachModule(data.loft)
            CURR_SELECTED_MODULE.userData.isLoftVisible = false
            showGlobalToast("Loft Removed",2000)
        }else{
            data.object.attach(data.loft)
            CURR_SELECTED_MODULE.userData.isLoftVisible = true
            showGlobalToast("Loft Added",2000)
        }
        if(configuration){
            configuration.additionalConfiguration.hasLoft = CURR_SELECTED_MODULE.userData.isLoftVisible
        }
        removeBoxHelper()
        updateLoftToggleText(CURR_SELECTED_MODULE)
        deselectModule()
        fitToModuelsScene()
        if(dimensions.isDimensionsVisible){
            dimensions.hideDimensions()
            await waitFor(200)
            dimensions.showDimensions()
        }
        await waitFor(200)
        setCurrSelectedModule(selectedObject)
        selectModule()
        openWardrobeModulePanel()
        hideComponentLoader("changeFinishLoader")
    }
}

export async function toggleDoors(event:any) {
    for (const data of doorsList) {
        if(data?.door){
            let door = data.door
            let isDoorVisible = door.userData.isDoorVisible
            if(isDoorVisible){
                door.visible = false
                door.userData.isDoorVisible = false
                $(event.target).addClass("border-danger")
            }else{
                door.visible = true
                door.userData.isDoorVisible = true
                $(event.target).removeClass("border-danger")
            }
        }
    }
}

export async function showDoors() {
    for (const data of doorsList) {
        if(data?.door){
            let door = data.door
            door.visible = true
            door.userData.isDoorVisible = true
        }
    }
}

export async function hideDoors() {
    for (const data of doorsList) {
        if(data?.door){
            let door = data.door
            door.visible = false
            door.userData.isDoorVisible = false
        }
    }
}

 

export function hideLoft(object:any) {
    let data = loftsList.find(currData => currData.object.uuid === object?.uuid)
    if(data){
        detachModule(data.loft)
    }
    object.userData.isLoftVisible = false
    // let configuration = CURR_SELECTED_MODULE.userData.configuration
    // if(configuration){
    //     configuration.additionalConfiguration.hasLoft = false
    // }
    // console.log(configuration)
    fitToModuelsScene()
}


export function isLoftAttach(object:any) {
    return object.userData.isLoftVisible
}

export function updateLoftsList(object:any) {
    // loftsList = []
    object.traverse(mesh=>{
        if(mesh.name.toLowerCase().includes("loft")){
            
            // detachModule(mesh)
            loftsList.push({
                object:object,
                loft:mesh
            })
           
        }
    }) 

    object.traverse(mesh=>{
        if(mesh.name.toLowerCase().includes("door") && mesh.isMesh){
            mesh.userData.isDoorVisible = true
            doorsList.push({
                object:mesh,
                door:mesh
            })
        }
    }) 
}


export function attachLoftForUpdateDimensions(object:any){
    let data = loftsList.find(currData => currData.object.uuid === object.uuid)
    if(data){
        data.loft.visible = false
        data.object.attach(data.loft)  
    }
}

export function detachLoftForUpdateDimensions(object:any){
    let data = loftsList.find(currData => currData.object.uuid === object?.uuid)
    if(data){
        data.loft.visible = true
        detachModule(data.loft)
    }
}


export function getLoftFromObject(object:any){
    return loftsList.find(currData => currData.object.uuid === object?.uuid)?.loft || null
}


export function attachAllLoftForUpdateDimensions(){
    for (const data of loftsList) {
        data.object.attach(data.loft)  
        data.object.userData.isLoftVisible = true
    }
}

export function detachAllLoftForUpdateDimensions(){
    for (const data of loftsList) {
        detachModule(data.loft) 
        data.object.userData.isLoftVisible = false
    }
}
 

export function updateAddonPosition(modelObject:any,categoryName:string) {
    let data = getObjectDimensionPosition(null,group)
    modelObject.rotation.set(0,0,0,"XYZ")

    if(categoryName === "Cot"){
        modelObject.rotateOnWorldAxis(axis,-Math.PI)
        // modelObject.position.copy(new Vector3(posX,posY,posZ))
        modelObject.translateZ(-2);
    }
  }
 

export function resizeLoft(action:string){
    if(CURR_SELECTED_MODULE){
        if(!CURR_SELECTED_MODULE.userData.isLoftVisible){
            showGlobalToast("Enable loft",2000,"error")
            return
        }
        let data = loftsList.find(currData => currData.object.uuid === CURR_SELECTED_MODULE?.uuid)
        let currLoft = data?.loft
        if(currLoft){
            const increaseWidthByMeters = (1 / 1000 ) * 0.5
            let currBreadth = currLoft.scale.x 
            let currDepth = currLoft.scale.y
            if(action==="plus"){
                let result = currLoft.scale.z + increaseWidthByMeters;
                currLoft.scale.set(currBreadth,currDepth,result)
              }else{
                let result = currLoft.scale.z - increaseWidthByMeters;
                currLoft.scale.set(currBreadth,currDepth,result)
            }
            removeBoxHelper()
            updateActionPanelInformation(CURR_SELECTED_MODULE,boundingBox)
            if(dimensions.isDimensionsVisible){
                dimensions.hideDimensions()
                setTimeout(() => {
                  dimensions.showDimensions()
                }, 200);
            }
        }
    }
    
}

export function wardrobeAccordionClickActions(text:string) {
    if(text.toLowerCase().includes("internal")){
        hideDoors()
    }else{
        showDoors()
    }
}