import React, { useState } from "react";
import { applicationConfig, logoutHandle } from "../../../../../utils/methods";
import { useHistory } from "react-router";
import Modal2 from "../../../../common/shared-components/Modals/Modal2";
import ThemeSetting from "../../../../common/shared-components/main-ui/ThemeSetting";
import { getFormatedDate } from "../../../../../utils/store/crm/crm";

 
const UserOptions: React.FC<{
}> = (props) => {


  let history = useHistory()
  const[isSettingsOpen,setIsSettingsOpen] = useState(false)


  function gotoOrders() {
    history.push("/orders")
  }


 
  return (
    <div className='header-user-options white-card border crm-user-options' style={{width:"100%",padding:"15px 15px",maxWidth:"20rem"}}>
      

      <Modal2  
        onDismiss={() => setIsSettingsOpen(false)} 
        isOpen={isSettingsOpen}
        id="setting"
        classNames='project-action-modal'
        Content={()=>(
          <ThemeSetting close={() => setIsSettingsOpen(false)}/>
        )}
      />

      <div className="user-info-container full-width center-aligned-row">
        <div className="padding5">
          <div className="center-aligned-row">
            <div className="bg-color-warning uppercase font-large color-white center-aligned-column white-card no-shadow" style={{width:"58px",height:"58px",borderRadius:"50%"}}>
              {applicationConfig?.user?.username.substring(0,2)}
            </div>
          </div>
          <div className="heading3 marginTop5 font-medium text-center capitalize ">{applicationConfig?.user?.username}</div>
          {/* <div className="heading4 marginTop5 font-md-small text-center">{applicationConfig?.user?.email}</div> */}
        </div>
      </div>

      <div className="full-width">
        <div className="padding5">


          <div className='option marginTop5 display-flex-row'  onClick={()=>{setIsSettingsOpen(true)}}>
            <div><i className="fas fa-cog  color-dark"></i></div>
            <div className='marginleft10 heading4 color-black center-aligned-column'>Setting</div>
          </div>

          <div className='option marginTop5 display-flex-row' onClick={logoutHandle}>
            <div><i className="fas fa-power-off  color-dark"></i></div>
            <div className='marginleft10 heading4 color-black center-aligned-column'>Logout</div>
          </div>

        </div>
      </div>






  


      <div className="user-info-container full-width center-aligned-row">
        <div className="padding5">
          <div className="center-aligned-row">
            <img src={`https://opusassets.s3.ap-south-1.amazonaws.com/public/assets/clientLogos/${applicationConfig?.clientName}.png`} width={100} alt="" />
          </div>
          <div className="display-none no-wrap marginTop5">
            <span className="center-aligned-column"><i className="far fa-copyright font-small color-dark"></i></span>
            <div className="heading4  marginleft5 font-md-small text-center capitalize "> 2024 - 1.0.0</div>
          </div>
        </div>
      </div>

      <div className="subscription-details marginTop5">
        {/* <div className="heading3 font-md-small text-center">Subscription Details</div> */}

        <div className="display-flex-row space-between marginTop5">
          <span className="heading4 color-black font-small">Subscription Status </span>
          <div className="display-flex-row space-between">
            <div className="center-aligned-column">
              <span className="bg-color-success" style={{width:"0.5rem",height:"0.5rem",borderRadius:"50%"}}></span>
            </div>
            <span className="heading4 color-black marginleft5 font-small">Active</span>
          </div>

        </div>


        <div className="display-flex-row space-between marginTop5">
          <span className="heading4 color-dark font-small">Start date </span>
          <span className="heading4 color-black marginleft10 font-small">{getFormatedDate(applicationConfig?.user?.subscriptionStartDate)}</span>
        </div>

        <div className="display-flex-row space-between marginTop5">
          <span className="heading4 color-dark font-small">End date </span>
          <span className="heading4 color-danger marginleft10 font-small">{getFormatedDate(applicationConfig?.user?.subscriptionEndDate)}</span>
        </div>

        {applicationConfig?.clientName !== "TheLevelFurniture"?
        <div className="full-width center-aligned-row no-wrap marginTop10 no-wrap">
          <span className="heading4 font-ex-small center-aligned-column ">Powered by </span>
          <a className="font-ex-small center-aligned-column color-secondary" style={{textDecoration:"none",marginLeft:"2px"}}> Omniview Labs</a>
        </div>
        :null}

        

      </div>
      
      
    </div>
  );
};

export default UserOptions;
