import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { rippleEffectOnClick } from '../../../../utils/UI_methods/ui_effects';
import { closeFiltersPanelMobile } from '../../../../utils/UI_methods/global';
import Button2 from '../../ui-components/buttons/Button2';
import { applicationConfig, getFilteredArray, getSubCategoryDisplayName } from '../../../../utils/methods';

  
  const Categories: React.FC<{
    updateProducts:(cate:string,subCat:string)=>void
    subCategories:Array<any>
    currSelectedCategory:string
    currSelectedSubCategory:string
    productsList:any
  }> = (props) => {


    const[subCategories,setsubCategories] = useState([])
    useEffect(()=>{
      let list = props.subCategories
      if(props.subCategories.length){
        if(!list.includes("All")){
          list = ["All",...list]
        }
      }
      list.sort((a,b)=>a>b?1:-1).reverse()
      setsubCategories(list)
    },[props.subCategories])


    function getProductListLenght(subCate:string) {
      let productsList = getFilteredArray(props.productsList,"category_name",props.currSelectedCategory) 
      if(subCate === "All"){
        return productsList.length
      }
      return getFilteredArray(productsList,"sub_category_name",subCate).length
    }

    
    return (
       <>
       <div className="hide-in-desktop">
          <div className='center-aligned-row full-width ion-padding'>
            <Button2
              configObj={{}}
              action={closeFiltersPanelMobile}
              label={"Close menu"}
              fontAwesomeIcon={"fas fa-chevron-down color-primary"}
              iconPos="left"
              classNames={"shadow-light margin0 button-small bg-color-light"}
            />
            {/* <div className='subcategories-close' onClick={closeFiltersPanelMobile}></div> */}
          </div>
          <div className="padding10 heading1">Select subcategory</div>
        </div>
        
        {/* <div id={"subCategoryAll"} className={`border-bottom click-effect position-relative overflow-hidden sub-category ion-padding pointer ${props.currSelectedSubCategory === "All"?" --is-active":" "}`}  style={{paddingLeft:"2rem"}} onClick={(event:any)=>{
          props.updateProducts(props.currSelectedCategory,"All")
        }
        }>
          <div  className="heading4 text pointer-none" >All</div>
        </div> */}
        {subCategories.map((subCategory:any,indexSub:number)=>{
            return(
              <div id={"subCategory"+subCategory} onPointerDown={rippleEffectOnClick} className={`border-bottom click-effect position-relative overflow-hidden sub-category ion-padding pointer ${props.currSelectedSubCategory === subCategory?" --is-active":" "}`} key={indexSub} style={{paddingLeft:"2rem"}} onClick={(event)=>{
                props.updateProducts(props.currSelectedCategory,subCategory)
              }}>
                <div className="heading4 pointer-none text" key={indexSub}>{getSubCategoryDisplayName(subCategory) || ""}  ({getProductListLenght(subCategory)})</div>
              </div>
            )
          })}
       </>
    );
  };

  export default Categories;