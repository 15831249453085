import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { applicationConfig, getArrayOfDistinctValues, getFilteredArray } from "../../../../../../utils/methods";
import { closeSubCategories, hideItemsPanel, openProductSearch, toggleProductSearch } from "../../../../../../utils/moodboard/UI_methods";
import { closeCategories } from "../../../../../../utils/moodboard/helper";
import Categories from "../../../../../common/shared-components/products/Categories";
import SubCategories from "../../../../../common/shared-components/products/SubCategories";
import ProductsSearch from "../../../../../common/ui-components/search/ProductsSearch";
import DropDown from "../../../../../common/ui-components/filters/DropDown";

const ProductCategories: React.FC<{
  configObj: any;
  filterProducts:(category:string,subCategory:string)=>void
  currSelectedCategory:string
  filterProductsWithCategory:(category:string)=>void
  filterProductsWithSubCategory:(category:string,sub:string)=>void
  activateSearchMode:(products:Array<any>)=>void
  moduleName:string
  setSubCategories:(array:Array<any>)=>void
  parentContainerId:string
}> = (props) => {

  const[categories,setCategories] = useState([])
  const[subCategories,setSubCategories] = useState([])
  const[currSelectedCategory,setCurrSelectedCategory] = useState(props.currSelectedCategory)

  useEffect(()=>{
    if(props.configObj){
      let categoriesList = getArrayOfDistinctValues(props.configObj?.data.productsList,"category_name");
      setCategories(categoriesList)

      // let currSubCategories = getFilteredArray(props.configObj?.data.subCategories,"category_name",props.currSelectedCategory);
      // setCurrSelectedCategory(props.currSelectedCategory)
      // setSubCategories(currSubCategories)
      // props.setSubCategories(currSubCategories)
    }
  },[props.configObj,props.currSelectedCategory])

  function updateProducts(category:string,subCategory:string) {
 
    // setTimeout(() => {
      props.filterProducts(category,subCategory)
      // closeSubCategories()
      closeCategories(props.parentContainerId)
      closeSubCategories()
    // }, 500);
  }

  function updateSubCategories(category:string) {
    let currSubCategories = getFilteredArray(props.configObj?.data.subCategories,"category_name",category);
    setSubCategories(currSubCategories)
    setCurrSelectedCategory(category)
    // expandSubCategories()
    updateProducts(category,"All")
  }

  
  function SearchOnCategory() {
    return(
      <ProductsSearch
        configObj={props.configObj}
        currSelectedCategory={currSelectedCategory}
        activateSearchMode={props.activateSearchMode}
        filterProductsWithCategory={props.filterProductsWithCategory}
        filterProductsWithSubCategory={props.filterProductsWithSubCategory}
        idSuffix={"CategoryProducts"}
      />
    )
  }

   
  function SearchOnSubCate() {
    return(
        <ProductsSearch
          configObj={props.configObj}
          currSelectedCategory={currSelectedCategory}
          activateSearchMode={props.activateSearchMode}
          filterProductsWithSubCategory={props.filterProductsWithSubCategory}
          filterProductsWithCategory={props.filterProductsWithCategory}
          idSuffix={"SubCategoryProducts"}
        />
      
    )
  }
  

  return (
    <div className="moodboard-category-wrapper full-height-width" id="moodboardCategoriesWrapper">

      {/* <Loader
        className=""
        id={"productsLoader"}
        data={{}}
        loaderType="spinner"
      /> */}
      
      {/* <div className="pointer position-relative display-flex-row space-between padding1rem border-bottom full-width" >
              <span className="color-black heading2" onClick={()=>hideItemsPanel(props.moduleName)}>
                <i className="fas fa-chevron-left color-danger marginRight5" aria-hidden="true"></i>
                Main Menu
              </span>
             
              <div className='right-middle white-card shadow-light border' style={{padding:"5px 10px"}} onClick={()=>openProductSearch(props.parentContainerId,"CategoryProducts")}>
                  <i className="fas fa-search color-black font-md-small" aria-hidden="true"></i>
              </div>

              

              <div className="full-width top-left padding5 moodboard-product-search-wrapper">
                  <SearchOnCategory/>
                  <IonIcon onClick={toggleProductSearch} icon={close} className="font-medium right-middle zindex30"/>
              </div>
          </div> */}

        <div className="full-height-width overflow-y-scroll">
          <div className="full-width padding5 position-relative">
              <SearchOnCategory/>
              {/* <IonIcon onClick={toggleProductSearch} icon={close} className="font-medium right-middle zindex30"/> */}
          </div>
          <Categories
            updateSubCategories={updateSubCategories}
            categories={categories}
          />
        </div>

     
    

      <div className="sub-categories-wrapper background-white top-left padding0 full-height-width" id="subCategoryWrapper" >
          {/* Header */}
          <div className="pointer position-relative display-flex-row space-between padding1rem border-bottom full-width" >
              <span className="color-black heading2" onClick={closeSubCategories}>
                <i className="fas fa-chevron-left color-danger marginRight5" aria-hidden="true"></i>
                Categories / {currSelectedCategory}
              </span>

              <div className="" onClick={()=>openProductSearch(props.parentContainerId,"SubCategoryProducts")}>
                  <i className="fas fa-search color-black pointer-none" aria-hidden="true"></i>
              </div>

              <div className="full-width top-left padding5 moodboard-product-search-wrapper">
                  <SearchOnSubCate/>
                  <IonIcon onClick={toggleProductSearch} icon={close} className="font-medium right-middle zindex30"/>
              </div>

          </div>

       
          <SubCategories
             updateProducts={updateProducts}
             subCategories={getArrayOfDistinctValues(subCategories,"sub_category_name")}
             currSelectedCategory={currSelectedCategory}
             currSelectedSubCategory={""}
             productsList={applicationConfig?.data?.productsList}
          />
          
      </div>

    </div>
  );
};

export default ProductCategories;
